<script setup lang="ts">
import { computed, ref, watch } from 'vue'

interface Props {
  position?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top start'
    | 'top end'
    | 'bottom start'
    | 'bottom end'
    | 'left start'
    | 'left end'
    | 'right start'
    | 'right end'
  text?: string
  totalItems: number
  currentItem: number
  show: boolean
  displayMode?: 'percent' | 'fraction'
}

const props = withDefaults(defineProps<Props>(), {
  position: 'bottom',
  text: 'Processing...',
  totalItems: 0,
  currentItem: 0,
  show: false,
  displayMode: 'fraction'
})

const progress = ref(0)

watch(
  () => props.currentItem,
  newValue => {
    progress.value = (newValue / props.totalItems) * 100
  }
)

const progressDisplay = computed(() => {
  if (props.displayMode === 'percent') {
    return `${Math.ceil(progress.value)}%`
  } else {
    return `${props.currentItem} of ${props.totalItems}`
  }
})
</script>

<template>
  <v-snackbar :location="position" :model-value="show" :timeout="-1">
    {{ text }}
    <v-progress-linear :model-value="progress" color="success" height="25">
      <strong>{{ progressDisplay }}</strong>
    </v-progress-linear>
  </v-snackbar>
</template>
