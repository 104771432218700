<script setup lang="ts">
import { store } from "@/stores/store";

const role = computed(() => store.$state?.auth?.role || "");
const avatar = computed(() => store.$state?.auth?.avatar || "");
const fullName = computed(() => store.$state?.auth?.fullName || "");

const firstRole = computed(() => {
  const role = store.$state?.auth?.role;
  return Array.isArray(role) ? role[0] : role || "";
});

const initials = computed(() => {
  return (
    fullName.value
      ?.split(" ")
      .map((n: string) => n[0])
      .join("") || ""
  );
});

const roleColor = computed(() => {
  return firstRole.value === "admin"
    ? "primary"
    : firstRole.value === "user"
    ? "info"
    : "error";
});
</script>

<template>
  <VBadge
    dot
    location="bottom right"
    offset-x="3"
    offset-y="3"
    bordered
    :color="roleColor"
    v-if="firstRole"
  >
    <VAvatar variant="tonal" class="cursor-pointer" :color="roleColor">
      <VImg v-if="avatar" :src="avatar" />
      <span v-else>{{ initials }}</span>

      <!-- SECTION Menu -->
      <VMenu activator="parent" width="230" location="bottom end" offset="14px">
        <VList>
          <!-- 👉 User Avatar & Name -->
          <VListItem>
            <template #prepend>
              <VListItemAction start>
                <VBadge
                  dot
                  location="bottom right"
                  offset-x="3"
                  offset-y="3"
                  color="success"
                >
                  <VAvatar :color="roleColor">
                    <VImg v-if="avatar" :src="avatar" />
                    <span v-else>{{ initials }}</span>
                  </VAvatar>
                </VBadge>
              </VListItemAction>
            </template>

            <VListItemTitle class="font-weight-semibold">
              {{ fullName }}
            </VListItemTitle>
            <VListItemSubtitle>
              {{ Array.isArray(role) ? role.join(", ") : role }}
            </VListItemSubtitle>
          </VListItem>

          <VDivider class="my-2" />

          <!-- 👉 Profile -->
          <VListItem link>
            <template #prepend>
              <VIcon class="me-2" icon="tabler-user" size="22" />
            </template>

            <VListItemTitle>Profile</VListItemTitle>
          </VListItem>

          <!-- 👉 Settings -->
          <VListItem to="mqtt-page">
            <template #prepend>
              <VIcon class="me-2" icon="tabler-message" size="22" />
            </template>

            <VListItemTitle>MQTT Messages</VListItemTitle>
          </VListItem>

          <!-- Divider -->
          <VDivider class="my-2" />

          <!-- 👉 Logout -->
          <VListItem @click="store.auth.logout()">
            <template #prepend>
              <VIcon class="me-2" icon="tabler-logout" size="22" />
            </template>

            <VListItemTitle>Logout</VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>
      <!-- !SECTION -->
    </VAvatar>
  </VBadge>
</template>
