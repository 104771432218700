<script setup lang="ts">
import { store } from '@/stores/store'
import { computed, ref } from 'vue'
import SelectOrganizationDialog from './SelectOrganizationDialog.vue'

const props = defineProps({
  isBulkAddOrganizationsDialogVisible: Boolean
})

const emit = defineEmits([
  'update:isBulkAddOrganizationsDialogVisible',
  'organizations-created'
])

interface Organization {
  name: string
  dsc: string
  qty: number
  subOrganizations: Organization[]
  level: number
}

const organizations = ref<Organization[]>([
  { name: '', dsc: '', qty: 1, subOrganizations: [], level: 0 }
])
const parentOrganizationId = ref('')
const isSelectOrganizationDialogVisible = ref(false)
const incrementCounter = ref(1)
const stateIndex = ref(0)

const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
]

const parentOrgName = computed(
  () => store.getOrganizationNameById(parentOrganizationId.value) || ''
)

const addOrganization = (
  parentIndex: number | null = null,
  subIndex: number | null = null
) => {
  console.log(
    `Adding organization at parentIndex: ${parentIndex}, subIndex: ${subIndex}`
  )
  const newOrg: Organization = {
    name: '',
    dsc: '',
    qty: 1,
    subOrganizations: [],
    level: 0
  }

  const addSubOrg = (org: Organization, level: number) => {
    newOrg.level = level + 1
    org.subOrganizations.push(newOrg)
  }

  if (parentIndex === null) {
    organizations.value.push(newOrg)
  } else {
    let parentOrg = organizations.value[parentIndex]
    if (subIndex !== null) {
      for (let i = 0; i < subIndex; i++) {
        parentOrg = parentOrg.subOrganizations[parentOrg.subOrganizations.length - 1]
      }
      addSubOrg(parentOrg, parentOrg.level)
    } else {
      addSubOrg(parentOrg, parentOrg.level)
    }
  }
  incrementCounter.value++
  stateIndex.value = (stateIndex.value + 1) % states.length
  console.log('Incremented counter:', incrementCounter.value)
}

const removeOrganization = (
  index: number,
  parentIndex: number | null = null
) => {
  if (parentIndex === null) {
    organizations.value.splice(index, 1)
  } else {
    organizations.value[parentIndex].subOrganizations.splice(index, 1)
  }
  if (index === 0 && parentIndex === null) {
    incrementCounter.value = 1
    stateIndex.value = 0
  }
}

const flattenOrganizations = (
  orgs: Organization[],
  parentId: string | null = null,
  level: number = 0,
  parentIndex: number[] = []
): any[] => {
  console.log(`Flattening organizations at level ${level}, parentId: ${parentId}`)
  return orgs.flatMap((org, index) => {
    const currentIndex = [...parentIndex, index]
    console.log(`Processing org: ${JSON.stringify(org)}, level: ${level}, index: ${currentIndex.join(',')}`)
    const baseOrg = {
      organization_id: parentId || parentOrganizationId.value,
      name: org.name
        .replace('{{ parent_name }}', parentOrgName.value)
        .replace('{{ increment }}', (incrementCounter.value + index).toString())
        .replace('{{ state }}', states[(stateIndex.value + index) % states.length]),
      dsc: org.dsc
        .replace('{{ parent_name }}', parentOrgName.value)
        .replace('{{ increment }}', (incrementCounter.value + index).toString())
        .replace('{{ state }}', states[(stateIndex.value + index) % states.length]),
      index: currentIndex.join(',')
    }
    console.log(`Base org created:`, JSON.stringify(baseOrg, null, 2))
    
    const currentOrgs = Array(org.qty).fill(0).map((_, i) => {
      const currentOrg = {
        ...baseOrg,
        name: baseOrg.name.replace('{{ increment }}', (incrementCounter.value + index + i).toString()),
        dsc: baseOrg.dsc.replace('{{ increment }}', (incrementCounter.value + index + i).toString()),
        index: i === 0 ? baseOrg.index : `${baseOrg.index},${i}`
      }
      console.log(`Created org instance ${i + 1}:`, JSON.stringify(currentOrg, null, 2))
      return currentOrg
    })
    
    console.log(`Created ${currentOrgs.length} instances of the current org`)
    
    const subOrgs = flattenOrganizations(org.subOrganizations, currentOrgs[0].name, level + 1, currentIndex)
    console.log(`Processed ${subOrgs.length} sub-organizations for ${org.name}`)
    
    const result = [...currentOrgs, ...subOrgs]
    console.log(`Final result for level ${level}:`, JSON.stringify(result, null, 2))
    return result
  })
}


const saveOrganizations = async () => {
  try {
    console.log('Starting saveOrganizations function')
    console.log(
      'Initial organizations:',
      JSON.stringify(organizations.value, null, 2)
    )
    const organizationsToSave = flattenOrganizations(organizations.value)
    console.log(
      'Final structure of organizations to save:',
      JSON.stringify(organizationsToSave, null, 2)
    )

    // Implement the API call to save the organizations
    const payloads = organizationsToSave.map(org => [
      { Method: 'Insert Organization' },
      {
        name: org.name,
        dsc: org.dsc,
        organization_id: org.organization_id,
        subisallowed: true // Assuming we want to allow sub-organizations by default
      }
    ])

    const results = await store.api.postData(payloads)
    console.log('API results:', results)

    if (results.some(result => !result[0].Success)) {
      throw new Error('Some organizations failed to be created')
    }

    // Emit the event to close the dialog and refresh the data
    console.log('Emitting organizations-created event')
    emit('organizations-created')
    console.log('Emitting update:isBulkAddOrganizationsDialogVisible event')
    emit('update:isBulkAddOrganizationsDialogVisible', false)

    // Reset the form
    organizations.value = [
      { name: '', dsc: '', qty: 1, subOrganizations: [], level: 0 }
    ]
    parentOrganizationId.value = ''
    incrementCounter.value = 1
    stateIndex.value = 0

    store.setSnack('Organizations created successfully', {
      color: 'success',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
  } catch (error) {
    console.error('Error creating organizations:', error)
    store.setSnack(
      `Failed to create organizations: ${(error as Error).message}`,
      {
        color: 'error',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'white'
      }
    )
  }
}

const handleOrganizationSelected = (organizationId: string) => {
  parentOrganizationId.value = organizationId
  isSelectOrganizationDialogVisible.value = false
}

// Remove the renderOrganizationRows function
</script>

<template>
  <VDialog
    :model-value="isBulkAddOrganizationsDialogVisible"
    @update:model-value="
      $emit('update:isBulkAddOrganizationsDialogVisible', $event)
    "
    max-width="800px"
    persistent
  >
    <VCard>
      <VIcon
        class="icon-close"
        @click="$emit('update:isBulkAddOrganizationsDialogVisible', false)"
        icon="tabler-x"
      />

      <VCardTitle>Bulk Add Organizations</VCardTitle>
      <VCardText>
        <VForm @submit.prevent="saveOrganizations">
          <VRow>
            <VCol cols="12">
              <AppTextField
                :model-value="
                  store.getOrganizationNameById(parentOrganizationId)
                "
                label="Parent Organization"
                readonly
                outlined
                dense
                @click="isSelectOrganizationDialogVisible = true"
              >
                <template v-slot:append-inner>
                  <VIcon
                    @click="isSelectOrganizationDialogVisible = true"
                    color="primary"
                  >
                    mdi-chevron-down
                  </VIcon>
                </template>
              </AppTextField>
            </VCol>
          </VRow>
          <template v-for="(org, index) in organizations" :key="index">
            <VRow :style="{ marginLeft: `${org.level * 20}px` }">
              <VCol cols="1">
                <VChip size="small">[{{ index }}]</VChip>
              </VCol>
              <VCol cols="3">
                <AppTextField
                  v-model="org.name"
                  label="Organization Name"
                  :rules="[(v: any) => !!v || 'Organization Name is required']"
                  required
                  outlined
                  dense
                />
              </VCol>
              <VCol cols="4">
                <AppTextField
                  v-model="org.dsc"
                  label="Description"
                  :rules="[(v: any) => !!v || 'Description is required']"
                  required
                  outlined
                  dense
                />
              </VCol>
              <VCol cols="2">
                <AppTextField
                  v-model="org.qty"
                  label="Quantity"
                  type="number"
                  :rules="[
                    (v: any) => !!v || 'Quantity is required',
                    (v: any) => v > 0 || 'Quantity must be greater than 0'
                  ]"
                  required
                  outlined
                  dense
                />
              </VCol>
              <VCol cols="2">
                <VBtn
                  icon
                  @click="removeOrganization(index, null)"
                  v-if="organizations.length > 1"
                >
                  <VIcon>mdi-delete</VIcon>
                </VBtn>
                <VBtn icon @click="addOrganization(index, null)">
                  <VIcon>mdi-plus</VIcon>
                </VBtn>
              </VCol>
            </VRow>
            <template
              v-for="(subOrg, subIndex) in org.subOrganizations"
              :key="`${index}-${subIndex}`"
            >
              <VRow :style="{ marginLeft: `${subOrg.level * 20}px` }">
                <VCol cols="1">
                  <VChip size="small">[{{ index }},{{ subIndex }}]</VChip>
                </VCol>
                <VCol cols="3">
                  <AppTextField
                    v-model="subOrg.name"
                    label="Sub-Organization Name"
                    :rules="[(v: any) => !!v || 'Organization Name is required']"
                    required
                    outlined
                    dense
                  />
                </VCol>
                <VCol cols="4">
                  <AppTextField
                    v-model="subOrg.dsc"
                    label="Description"
                    :rules="[(v: any) => !!v || 'Description is required']"
                    required
                    outlined
                    dense
                  />
                </VCol>
                <VCol cols="2">
                  <AppTextField
                    v-model="subOrg.qty"
                    label="Quantity"
                    type="number"
                    :rules="[
                      (v: any) => !!v || 'Quantity is required',
                      (v: any) => v > 0 || 'Quantity must be greater than 0'
                    ]"
                    required
                    outlined
                    dense
                  />
                </VCol>
                <VCol cols="2">
                  <VBtn icon @click="removeOrganization(subIndex, index)">
                    <VIcon>mdi-delete</VIcon>
                  </VBtn>
                  <VBtn icon @click="addOrganization(index, subIndex)">
                    <VIcon>mdi-plus</VIcon>
                  </VBtn>
                </VCol>
              </VRow>
            </template>
          </template>
          <VRow>
            <VCol cols="12">
              <VCard outlined class="pa-3 mt-3">
                <strong>Template String Legend:</strong>
                <ul class="mt-2">
                  <li>
                    <code v-text="'{{ parent_name }}'"></code>: Name of the
                    parent organization
                  </li>
                  <li>
                    <code v-text="'{{ increment }}'"></code>: Incremental number
                    starting from 1
                  </li>
                  <li>
                    <code v-text="'{{ state }}'"></code>: US state names in
                    alphabetical order
                  </li>
                </ul>
                <p class="mt-2">
                  Example:
                  <span
                    v-text="
                      '{{ parent_name }} - Branch {{ increment }} ({{ state }})'
                    "
                  ></span>
                </p>
              </VCard>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12" class="text-center">
              <VBtn color="primary" @click="addOrganization()"
                >Add Another Organization</VBtn
              >
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12" class="text-center">
              <VBtn
                variant="flat"
                color="primary"
                text
                @click="
                  $emit('update:isBulkAddOrganizationsDialogVisible', false)
                "
                >Cancel</VBtn
              >
              <VBtn
                variant="flat"
                color="primary"
                text
                @click="saveOrganizations"
                :disabled="!parentOrganizationId"
                >Create Organizations</VBtn
              >
            </VCol>
          </VRow>
        </VForm>
      </VCardText>
    </VCard>
  </VDialog>

  <SelectOrganizationDialog
    v-model:isSelectOrganizationDialogVisible="
      isSelectOrganizationDialogVisible
    "
    @organization-selected="handleOrganizationSelected"
    :selectOrganizationIdData="parentOrganizationId"
    :isFromCreateOrganizationDialog="true"
  />
</template>

<style lang="scss" scoped>
.icon-close {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  inset-block-start: 0.75em;
  inset-inline-end: 0.95em;
}

.v-card-title {
  margin-block-end: -0.75em;
  padding-block: 0.95em 0;
  padding-inline: 1.25em 0;
}
</style>
