<template>
  <v-snackbar
    v-model="store.snack.visible"
    :color="store.snack.args.color"
    :location="store.snack.args.location"
    :timeout="store.snack.args.timeout || 7500"
    :variant="store.snack.args.variant"
    :style="{
      ...store.snack.args
    }"
  >
    <div class="d-flex">
      <span class="mr-auto" :style="store.snack.args">{{
        store.snack.snackMessage
      }}</span>
      <v-btn
        @click="handleButtonClick"
        text
        small
        class="ml-2"
        :color="store.snack.args.buttonColor"
        :style="
          store.snack.args.buttonTextColor
            ? { color: store.snack.args.buttonTextColor }
            : {}
        "
      >
        {{ store.snack.args.buttonText }}
      </v-btn>
      <v-btn
        v-if="store.snack.args.closeButtonText"
        @click="closeSnackbar"
        text
        small
        class="ml-2"
        :color="store.snack.args.buttonColor"
        :text-color="store.snack.args.buttonTextColor"
      >
        {{ store.snack.args.closeButtonText }}
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script setup lang="ts">
import { store } from '@/stores/store'

const handleButtonClick = () => {
  if (store.snack.args.buttonAction) {
    store.snack.args.buttonAction()
  } else {
    closeSnackbar()
  }
}

const closeSnackbar = () => {
  store.snack.visible = false
  if (store.snack.args.onClose) {
    store.snack.args.onClose()
  }
}
</script>
