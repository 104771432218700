/* stylelint-disable declaration-block-no-duplicate-properties */ /*
stylelint-disable declaration-block-no-duplicate-properties */ /*
stylelint-disable property-no-vendor-prefix */
<script setup lang="ts">
import { vDraggable } from '@/directives/draggable'
import { camelToTitle } from '@/stores/flattenObj'
import { store } from '@/stores/store'
import { useQRCode } from '@vueuse/integrations/useQRCode'
import cloneDeep from 'lodash/cloneDeep'
import { useTheme } from 'vuetify'

const vuetifyTheme = useTheme()

interface Props {
  device?: Device
  isAnywhereDialogVisible: boolean
}
interface Device {
  name: string
  mac: string
  firmware: string
  builtOn: string
  inServiceAt: string
  activityDate: string
  organization: string
  id: string
  organization_id: string
}

interface Emit {
  (e: 'update:isAnywhereDialogVisible', value: boolean): void
  (e: 'submit', value: Device): void
}

const props = withDefaults(defineProps<Props>(), {
  device: () =>
    ({
      name: '',
      mac: '',
      firmware: '',
      inServiceAt: '',
      activityDate: '',
      organization: '',
      id: '',
      organization_id: ''
    } as Device)
})

// Device data
const deviceHistory = ref([])

const anywhereData = ref({})

const unnestObjects = (obj: Object, category: string) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      unnestObjects(obj[key], key)
    } else {
      if (!anywhereData.value[category]) anywhereData.value[category] = {}
      anywhereData.value[category][key] = obj[key]
      anywhereData.value[category].category = category
    }
  }
  return anywhereData.value
}

// lookup device in store.collections["Anywhere"] and store.collections["Anywhere Messages"]
const device = computed(() => {
  const filterKeys = [
    'name',
    'mac',
    'Mac',
    'publickey',
    'reason',
    'Reason',
    'anywhere_id',
    'organization_id',
    'message',
    'uuid',
    'id',
    'MessagingToken',
    'MsgUuid',
    'msguuid',
    /uuid/i
  ]

  const Attributes = cloneDeep(
    store.collections['Anywhere'].data.find(
      (x: { id: string }) => x.id === props.device.id
    ) ||
      store.collections['Cab'].data.find(
        (x: { mac: string }) => x.mac === props.device.mac
      ) ||
      Object.fromEntries(
        Object.entries(props.device).filter(
          (x: [string, any]) =>
            x[1] !== undefined &&
            x[1] !== '' &&
            !filterKeys.includes(x[0] as any)
        ) as [string, any][]
      )
  )

  // remove any keys in Attributes that have a period in the key name
  Object.keys(Attributes).forEach(key => {
    if (key.includes('.')) {
      delete Attributes[key]
    }
  })

  anywhereData.value = { Attributes }

  const deviceHistory = cloneDeep(
    (store.collections['Anywhere Messages'].data.filter(
      (x: { anywhere_id: string }) => x.anywhere_id === props.device.id
    ) || [])[0]
  )

  // add device data to device object
  anywhereData.value = Object.assign(
    anywhereData.value,
    unnestObjects(deviceHistory, 'Attributes')
  )

  console.log('final device', props, device, anywhereData.value)

  // loop through keys in deviceHistory and add to device object
  /*   deviceHistory.value.forEach((x: { [x: string]: any; Reason?: any }) => {
    let category = "Attributes";
    console.log("category", x);
    Object.keys(x).forEach((key) => {
      // if key is an object, add to keys array if not already there, otherwise add to device object
      if (typeof x[key] === "object") {
        device[key] = Object.assign(device[key] || {}, x[key]);
      } else {
        device[category] = Object.assign(device[category] || {}, {
          [key]: x[key],
        });
      }
    });
  }); */

  //

  // create an array of keys from device object setting the category key to the key value
  const deviceData = Object.keys(anywhereData.value).map(key => {
    return typeof anywhereData.value[key] === 'object'
      ? {
          category: key,
          ...anywhereData.value[key]
        }
      : {
          category: 'Attributes',
          [key]: anywhereData.value[key]
        }
  })

  console.log('deviceData', device, deviceData)

  // move objects from deviceData arrays and add as a new array in deviceData with key name as category
  /*   deviceData.forEach((x) => {
    Object.keys(x).forEach((key) => {
      if (typeof x[key] === "object" && x[key] !== null) {
        deviceData.push({
          parent: x.category,
          category: key,
          ...x[key],
        });
        delete x[key];
      }
    });
  }); */

  // loop through deviceData array and delete keys that are in filterKeys array
  /* const deviceDataFiltered = deviceData.map((x) => {
    Object.keys(x).forEach((key) => {
      if (filterKeys.find((k) => k === key || k === key.toLowerCase())) {
        delete x[key];
      }
    });
    return x;
  }); */

  const deviceDataFiltered = deviceData

  // sort deviceData array by length except for attributes
  const deviceDataSorted = deviceDataFiltered.sort((a: any, b: any) => {
    if (a.category === 'Attributes') return -1
    if (b.category === 'Attributes') return 1
    return Object.keys(b).length - Object.keys(a).length
  })

  // check each key in deviceData array where category is Attributes and if the key exists in deviceData with a different category, delete it
  console.log('deviceData Sorted', deviceDataSorted)

  deviceDataSorted.forEach((x: any) => {
    if (x.category === 'Attributes') {
      Object.keys(x).forEach(key => {
        if (key !== 'category') {
          const found =
            deviceDataSorted.find(
              (y: any) =>
                y.category.toLowerCase() !== 'attributes' &&
                Object.keys(y).some(k => k.toLowerCase() === key.toLowerCase())
            ) || filterKeys.find(k => k === key || k === key.toLowerCase())

          if (found) {
            // delete x[key];
          }
        }
      })
    }
  })

  console.trace('deviceData Sorted', deviceDataSorted)

  return deviceDataSorted
})

// get all unique keys from device object
const keys = computed(() => {
  const keys = new Set()
  device.value.forEach(x => {
    Object.keys(x as Array<Device>).forEach(key => {
      keys.add(key)
    })
  })
  return Array.from(keys)
})

let qrcode = ref('')
const qrcodeEnv = ref('prod')
const isDevelopment =
  window.location.hostname.includes('dev') ||
  window.location.hostname.includes('localhost')

// Load initial data
const loadDeviceData = () => {
  console.debug('loadDeviceData', props.device)

  deviceHistory.value = store.collections['Anywhere Messages'].data.filter(
    (x: { anywhere_id: string }) => x.anywhere_id === props.device.id
  )
  console.debug('deviceHistory', deviceHistory.value)
  /**
   * qrcode json
   * {"Mac":"DC:82:43:F4:AC:D7","DiscoveryUrl":"https://discovery.dev.responsetech.ltd"}
   *  */
  const qrCodeJSON = {
    Mac: props.device.mac,
    DiscoveryUrl:
      qrcodeEnv.value === 'prod'
        ? 'https://discovery.responsetech.ltd'
        : 'https://discovery.dev.responsetech.ltd'
  }

  qrcode = useQRCode(JSON.stringify(qrCodeJSON))
}

// load initial data on mounted
onMounted(() => {
  // loadDeviceData();
})

// watch for device changes
watch(
  () => props.device,
  () => {
    loadDeviceData()
  }
)

// watch for qrcodeEnv changes
watch(
  () => qrcodeEnv.value,
  () => {
    loadDeviceData()
  }
)

// watch store collection Anywhere Messages
watch(
  () => store.collections['Anywhere Messages'].data,
  () => {
    loadDeviceData()
  }
)

const sortKey = ref('category')
const sortOrder = ref('asc')

const dialogWidth = computed(() => {
  const width = window.innerWidth
  const widthInPixels = 1200
  const maxWidth = '95%'
  return {
    width: widthInPixels,
    maxWidth
  }
})
/* onBeforeUnmount(() => {
  if (props?.device?.organization_id) {
    console.log("unsubscribing from anywhere messages", props.device);
    store.broker.manualUnSubscribe({
      topic: `Org/${props.device.organization_id}/Anywhere/message`,
      qos: 0,
    });
  }
}); */
</script>
<template>
  <VDialog
    :width="dialogWidth.width"
    :max-width="dialogWidth.maxWidth"
    :model-value="props.isAnywhereDialogVisible"
    @update:model-value="(val: any) => $emit('update:isAnywhereDialogVisible', val)"
  >
    <VCard v-draggable>
      <VCardTitle class="pt-5 pb-0 mx-2">
        {{ props.device?.name || 'Cab' }}
      </VCardTitle>
      <!-- 👉 Dialog close btn -->
      <DialogCloseBtn @click="$emit('update:isAnywhereDialogVisible', false)" />

      <div class="attributes">
        <div class="card-columns">
          <VCard variant="outlined" class="column v-card--variant-elevated">
            <VRadioGroup
              inline
              class="center"
              v-model="qrcodeEnv"
              v-show="isDevelopment"
            >
              <VRadio label="Production" value="prod" class="px-2"></VRadio>
              <VRadio label="Development" value="dev"></VRadio>
            </VRadioGroup>
            <div class="qrcode center">
              <img
                :src="qrcode"
                :alt="props.device.mac"
                :title="props.device.mac"
                class="qrcode"
              />
              <div class="mac">
                {{ props.device.mac }}
              </div>
            </div>
            <!--
            <VCard
              subtitle="Organization"
              :text="
                String(props.device.organization).charAt(0).toUpperCase() +
                String(props.device.organization).slice(1)
              "
              variant="plain"
            /> -->
            <!--             <VCard
              v-if="props.device.inServiceAt"
              subtitle="IN SERVICE AT"
              :text="props.device.inServiceAt"
              variant="plain"
            />
            <VCard
              v-if="props.device.builtOn"
              subtitle="BUILT ON"
              :text="props.device.builtOn"
              variant="plain"
            /> -->
            <!--             <VCard
              v-for="(key, index) in Object.keys(device[0]).filter(
                (x) => x !== 'category' && x !== 'parent'
              )"
              :key="index"
              :subtitle="key.length > 2 ? camelToTitle(key) : key.toUpperCase()"
              :text="
                String(device[0][key]).charAt(0).toUpperCase() +
                String(device[0][key]).slice(1)
              "
              variant="plain"
            /> -->
          </VCard>
          <VCard
            variant="outlined"
            v-for="item in device"
            :key="item.category"
            class="column v-card--variant-elevated"
          >
            <VCardText class="pt-3 my-0 category"
              ><h3>{{ camelToTitle(item.category) }}</h3>
            </VCardText>
            <VCard
              v-for="(key, index) in Object.keys(item).filter(
                x => x !== 'category' && x !== 'parent'
              )"
              :key="index"
              :subtitle="key.length > 2 ? camelToTitle(key) : key.toUpperCase()"
              :text="
                String(item[key]).charAt(0).toUpperCase() +
                String(item[key]).slice(1)
              "
              variant="plain"
            />
          </VCard>
        </div>
      </div>
      <!--
      <VDataIterator
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="device"
        :search="search"
        :sort-by="sortBy"
      >
        <template v-slot:header>
          <v-toolbar dark color="blue-darken-3" class="px-2 mb-2">
            <v-text-field
              v-model="search"
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              variant="solo"
              density="comfortable"
            ></v-text-field>
            <v-spacer></v-spacer>

            <v-btn-toggle v-model="sortOrder" mandatory>
              <v-btn color="blue" value="asc">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn color="blue" value="desc">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>
        </template>

        <template v-slot:no-data>
          <v-alert class="ma-2">No results</v-alert>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.category"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card>
                <v-card-title class="subheading font-weight-bold">
                  {{ camelToTitle(item.raw.category) }}
                </v-card-title>

                <v-divider></v-divider>

                <v-list density="compact">
                  <v-list-item
                    v-for="(key, index) in filteredKeys"
                    :key="index"
                    :title="camelToTitle(key)"
                    :subtitle="String(item.raw[key])"
                    :class="
                      String(item.raw[key]) === 'undefined' ||
                      String(item.raw[key]) === 'null'
                        ? 'hide'
                        : ''
                    "
                  ></v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <div class="d-flex align-center justify-space-around pa-4">
            <span class="grey--text">Items per page</span>
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn
                  variant="text"
                  color="primary"
                  class="ml-2"
                  append-icon="mdi-chevron-down"
                  v-bind="props"
                >
                  {{ itemsPerPage }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  :title="number"
                  @click="itemsPerPage = number"
                ></v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>
            <v-card-actions>
              <VCol cols="12" class="text-center">
                <VBtn
                  class="me-3"
                  variant="flat"
                  color="primary"
                  @click="$emit('update:isAnywhereDialogVisible', false)"
                >
                  Close
                </VBtn>
              </VCol>
            </v-card-actions>
            <v-spacer></v-spacer>

            <span class="mr-4 grey--text">
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn icon size="small" @click="prevPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon size="small" class="ml-2" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </template>
      </VDataIterator>
      -->
    </VCard>
  </VDialog>
</template>
<style lang="scss">
.card-columns {
  margin: 1.5em;
  column-count: 1;
  column-gap: 1.25rem;
}

@media only screen and (min-width: 600px) {
  .card-columns {
    column-count: 2;
  }
}

@media only screen and (min-width: 960px) {
  .card-columns {
    column-count: 3;
  }
}

@media only screen and (min-width: 1190px) {
  .card-columns {
    column-count: 4;
  }
}

.card-columns .v-card {
  inline-size: 100%;
  margin-block-end: 0;
  padding-block-end: 0;
}

.column {
  margin-block-end: 1em !important;
  padding-block: 0.75em !important;
  padding-inline: 0 !important;
}

div.qrcode {
  padding-block: 0 0.85em;
  padding-inline: 0.25em;
}

img.qrcode {
  inline-size: 100%;
}

.attributes .v-card-item {
  padding-block: 0.25em 0.25rem !important;
  padding-inline: 0 !important;
}

.attributes .v-card-subtitle {
  background: linear-gradient(45deg, #0000001f, transparent);
  color: rgba(var(--v-theme-on-background)) !important;
  font-size: 1.25em;
  font-variant: all-petite-caps;
  font-weight: 700;
  line-height: 1.15em;
  padding-block: 0.25em 0.35rem !important;
  padding-inline: 24px 0 !important;
}

div.mac {
  font-size: 0.9em;
  margin-block-start: -1.25em;
}

.attributes .v-card-text {
  // color: #000;
  font-weight: 300 !important;
  padding-block: 0.5em 0.5em;
}

.v-btn--size-default {
  --v-btn-size: 0.9375rem;
  --v-btn-height: 38px;

  font-size: var(--v-btn-size);
  min-inline-size: 38px;
  padding-block: 0;
  padding-inline: 19px;
}

.v-input.v-input--horizontal.v-input--center-affix.v-input--density-comfortable.v-locale--is-ltr.v-radio-group.center {
  display: flex;
  justify-content: space-around;
  margin-block-end: -1.25em;
}
</style>
