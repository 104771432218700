<template>
  <VDialog v-model="visible" persistent max-width="400px">
    <VCard v-draggable>
      <VCardTitle>
        <span class="headline">Firmware Upgrade</span>
      </VCardTitle>
      <VCardText>
        <!-- list of firmwares with file icon -->
        <VList nav lines="two" border width="100%">
          <template
            v-for="(firmware, index) of availableFirmwares"
            :key="index"
          >
            <VListItem
              :title="firmware.name"
              :subtitle="firmware.version"
              @click="selectFirmware(firmware.name)"
            >
              <template #prepend>
                <VAvatar color="secondary" variant="tonal">
                  <VIcon :size="22" icon="tabler-file-code" />
                </VAvatar>
              </template>
            </VListItem>
            <VDivider v-if="index !== availableFirmwares.length - 1" />
          </template>
        </VList>
      </VCardText>
      <VCardActions>
        <VSpacer></VSpacer>
        <VBtn variant="flat" color="primary" text @click="handleClose"
          >Cancel</VBtn
        >
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script setup lang="ts">
import { vDraggable } from '@/directives/draggable'
import { v4 as uuidv4 } from 'uuid'
import { computed, ref } from 'vue'
import { store } from '../stores/store' // Correct store reference{"PetMfg":{"Mac":"F8:42:84:C3:31:56","DateOfMfg":"2021-06-25"}}

// Accessing the correct state from the store
const visible = computed(() => store.isAnywhereUpgradeDialogVisible)
const selectedFirmware = ref(store.selectedFirmware)
const availableFirmwares = ref([
  {
    name: 'pet_App2.0.12_Rel1.0.0.zip', // > 12.0.0
    version: '2.0.12',
    release: '1.0.0',
    bootLoader: false,
    ImageSha256:
      '05cda198b53f60e88c58d1f7612a7c0c604a531666cd73e8f848802608638190'
  },
  {
    name: 'pet_Boot1.0.2_App2.0.12_Rel1.0.0.zip', // < 2.0.12
    version: '2.0.12',
    release: '1.0.0',
    bootLoader: true,
    ImageSha256:
      'e152f668b79f84444442a539e2f2cee205e476a7f07898531c9b7bf2de6fd63f'
  }
])

async function selectFirmware(firmware: string) {
  store.setSelectedFirmware(firmware)
  const currentDomain = window.location.origin.includes('localhost')
    ? 'https://hq-ui.resptech.ltd'
    : window.location.origin
  const ImageUrl = currentDomain + '/firmware/' + firmware
  const ImageSha256 = availableFirmwares.value.find(
    x => x.name === firmware
  )?.ImageSha256

  const rtl_unique_identifier = store.broker.selectedCabSight.uuid
  const selectedItems = store.collections[store.selectedCollection].selected
  /**[
{
"Method": "Upgrade Anywhere",
"TraceUuid": "fdfe426e-55eb-4dd9-a8e3-54ad546d6b39"
},
{
"rtl_unique_identifier": "fdfe426e-55eb-4dd9-a8e3-54ad546d6b39",
"Device": [
{
"Uuid": "fdfe426e-55eb-4dd9-a8e3-54ad546d6b39",
"ImageUrl": "fdfe426e-55eb-4dd9-a8e3-54ad546d6b39",
"ImageSha256": "fdfe426e-55eb-4dd9-a8e3-54ad546d6b39"
}
]
}
] */

  const payLoads = [
    {
      Method: 'Upgrade Anywhere',
      TraceUuid: uuidv4()
    },
    {
      rtl_unique_identifier,
      Devices: selectedItems.map(x => ({
        Uuid: x,
        ImageUrl,
        ImageSha256
      }))
    }
  ]
  console.debug('payLoads', payLoads)

  // publish payloads to api

  const results = await store.api.postToAPI([payLoads])
  const finalResults = JSON.parse(JSON.stringify(results))
  console.debug('finalResults', finalResults)

  /* payLoads.forEach((x: any) => {
    store.broker.doPublish({
      topic: "dev/Service/CabSight/userAction",
      message: JSON.stringify(x),
    });
  }); */

  store.setSnack(`Upgrading ${selectedItems.length} Cabs`, {
    color: 'info',
    variant: ' elevated',
    location: 'bottom',
    buttonText: 'Close',
    buttonTextColor: 'white'
  })

  // remove selected items
  store.collections[store.selectedCollection].selected = []
  store.closeAnywhereUpgradeDialog()
}

const handleClose = () => {
  store.closeAnywhereUpgradeDialog()
}
</script>

<style scoped>
.field-row {
  display: flex;
  gap: 10px;
  margin-block-end: 15px;
}

svg.v-icon.notranslate.v-theme--light.v-icon--size-default.iconify.iconify--mdi {
  margin-block: 0.5em;
  margin-inline: 0;
}
</style>
