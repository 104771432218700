<script setup lang="ts">
import { camelToTitle } from '@/stores/flattenObj';
import { store } from '@/stores/store';
import { onMounted, onUnmounted, ref } from 'vue';

interface Item {
  organization_id: string
}

const isMobile = ref(false);

onMounted(() => {
  const checkMobile = () => {
    isMobile.value = window.innerWidth < 768; // Adjust this breakpoint as needed
  };

  window.addEventListener('resize', checkMobile);
  checkMobile(); // Initial check

  onUnmounted(() => {
    window.removeEventListener('resize', checkMobile);
  });
});

const showOrganizationRow = computed(() => {
  return (category: string) => {
    if (category !== 'Organizations') return true;
    if (!isMobile.value) return true;
    return !store.getActiveOrganizationId;
  };
});

function stringToColor(str: string) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 14)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }
  return color
}

function getContrast(hexColor: string) {
  let r = parseInt(hexColor.substr(1, 2), 16)
  let g = parseInt(hexColor.substr(3, 2), 16)
  let b = parseInt(hexColor.substr(5, 2), 16)

  const rLinear = r / 255
  const gLinear = g / 255
  const bLinear = b / 255

  const rSrgb =
    rLinear <= 0.03928
      ? rLinear / 12.92
      : Math.pow((rLinear + 0.055) / 1.055, 2.4)
  const gSrgb =
    gLinear <= 0.03928
      ? gLinear / 12.92
      : Math.pow((gLinear + 0.055) / 1.055, 2.4)
  const bSrgb =
    bLinear <= 0.03928
      ? bLinear / 12.92
      : Math.pow((bLinear + 0.055) / 1.055, 2.4)

  const luminance = 0.2126 * rSrgb + 0.7152 * gSrgb + 0.0722 * bSrgb

  return luminance > 0.5 ? 'black' : 'white'
}

const activeOrganization = computed(() => store.getActiveOrganizationId)

const serviceStore = computed(() => {
  const services = Object.keys(store.services)
    .map(x => {
      return {
        [x]: {
          title: camelToTitle(x.replace(/_/g, ' ').replace(/\./g, ''))
            .replace('Service', '')
            .replace('Svc', '')
            .trim(),
          category: `Services`,
          data: Object.values(store.services[x]),
          headers: Object.keys(Object.values(store.services[x])[0] || []).map(
            x => {
              // return key as title, remove underscores and dots
              return {
                title: camelToTitle(x.replace(/_/g, ' ').replace(/\./g, ''))
                  .replace('Author', '')
                  .replace('Statistics', '')
                  .trim(),
                key: x
              }
            }
          ),
          selected: [],
          loading: false
        }
      }
    })
    .reduce((a, b) => ({ ...a, ...b }), {})

  // Add Entra Users to services
  services['EntraUsers'] = {
    title: 'Entra Users',
    category: 'External',
    data: store.entraUsers,
    headers: [
      { title: 'Display Name', key: 'displayName' },
      { title: 'User Principal Name', key: 'userPrincipalName' }
    ],
    selected: [],
    loading: false
  }

  return services || {}
})

const collectionStore = computed(() => {
  return Object.assign(store.collections, serviceStore.value)
})

const skip = reactive([
  'License',
  'log',
  'Anywhere Messages',
  'Organization',
  'ParentOrg'
])

const collectionTypes = computed(() => {
  return Object.keys(collectionStore.value)
    .reverse()
    .filter((x: string) => !skip.includes(x))
    .map(x => {
      return {
        title: `${store.collections[x].title}`,
        category: `${store.collections[x].category}`,
        value: x
      }
    })
})

const collectionCategories = computed(() => {
  return Object.keys(collectionStore.value)
    .filter((x: string) => !skip.includes(x))
    .map(x => {
      return {
        title: `${store.collections[x].title}`,
        category: `${store.collections[x].category}`,
        value: x
      }
    })
    .filter(
      (v: { category: any }, i: any, a: any[]) =>
        a.findIndex((t: { category: any }) => t.category === v.category) === i
    ) // in cats move Organizations to top, Services to second, Devices to third, Sensors to fourth
    .sort((a: { category: string }, b: { category: string }) => {
      if (a.category === 'Organizations') return -1
      if (b.category === 'Organizations') return 1
      if (a.category === 'Services') return -1
      if (b.category === 'Services') return 1
      if (a.category === 'External') return -1
      if (b.category === 'External') return 1
      if (a.category === 'Devices') return -1
      if (b.category === 'Devices') return 1
      if (a.category === 'Sensors') return -1
      if (b.category === 'Sensors') return 1
      return 0
    })
})

const cardTitle = computed(() =>
  store.getActiveOrganization.name
    ? `${store.getActiveOrganization.name.replace(
        /\w\S*/g,
        (txt: string) =>
          txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      )} Devices`
    : `System Overview`
)
const cardSubtitle = computed(() =>
  store.getActiveOrganization.id
    ? `Aggregated view of systems associated with ${store.getActiveOrganization.name}.`
    : `Aggregated view of systems across all organizations.`
)

const selectedCollection = ref(store.selectedCollection)

watch(selectedCollection, newValue => {
  store.selectedCollection = newValue
})

const currentTab = ref('Devices')
const itemCount = (key: { title: string; value: string }) => {
  const collectionData = store.collections[key.value]
  if (!collectionData || !collectionData.data) return 0

  if (store.getActiveOrganizationId && key.title !== 'Cab') {
    return collectionData.data.filter(
      (item: Item) => item.organization_id === store.getActiveOrganizationId
    ).length
  }

  return collectionData.data.length
}
function computedFontSize(count: number) {
  return count > 999 ? "0.8em" : "0.9em";
}

function adjustCardHeights() {
  if (!isMobile.value) {
    const leftCard = document.querySelector('.left-card');
    const rightCard = document.querySelector('.right-card');
    if (leftCard && rightCard) {
      (leftCard as HTMLElement).style.height = (rightCard as HTMLElement).offsetHeight + 'px';
    }
  } else {
    // Reset heights on mobile
    const leftCard = document.querySelector('.left-card') as HTMLElement;
    const rightCard = document.querySelector('.right-card') as HTMLElement;
    if (leftCard && rightCard) {
      leftCard.style.height = 'auto';
      rightCard.style.height = 'auto';
    }
  }
}

onMounted(() => {
  window.addEventListener('resize', adjustCardHeights);
  adjustCardHeights(); // Initial adjustment
});

onUnmounted(() => {
  window.removeEventListener('resize', adjustCardHeights);
});
</script>

<template>
  <VCard :title="cardTitle" :subtitle="cardSubtitle" class="devices">
    <template #append>
      <!-- closeicon -->
      <!-- <div class="mt-n4 me-n2 close-icon" v-if="activeOrganization">
        <VIcon size="large" @click="store.setActiveOrganization('')"
          >mdi-close</VIcon
        >
      </div> -->
    </template>
    <VDivider />

    <VWindow>
      <VCardText class="device-container">
        <VList v-for="category in collectionCategories.filter(x => x.category)" :key="category.category">
          <h3
            class="petite-caps"
            v-show="
              (category.category !== 'Services' ||
              (category.category === 'Services' && activeOrganization === '')) &&
              showOrganizationRow(category.category)
            "
          >
            {{ category.category }}
          </h3>

          <VItemGroup
            v-model="selectedCollection"
            :class="category.category + '-group'"
            v-show="
              (category.category !== 'Services' ||
              (category.category === 'Services' && activeOrganization === '')) &&
              showOrganizationRow(category.category)
            "
          >
            <VContainer>
              <VRow>
                <VItem
                  v-for="key in collectionTypes.filter(
                  (x: { category: any; }) => x.category === category.category
                )"
                  :key="key.value"
                  v-slot="{ isSelected, toggle }"
                  :value="key.value"
                >
                  <VSkeletonLoader
                    class="mx-auto rounded me-1 ms-1 mt-1 box border"
                    :data-item="key.value"
                    :data-item-category="category.category"
                    :data-item-count="itemCount(key)"
                    v-show="itemCount(key) === false"
                  ></VSkeletonLoader>
                  <div
                    :class="
                      isSelected
                        ? 'elevation-1 box-selected'
                        : 'border-dashed elevation-5 box-unselected'
                    "
                    class="d-flex flex-column justify-center align-center cursor-pointer rounded px-4 py-4 me-1 ms-1 mt-1 box border"
                    @click="toggle"
                    v-if="itemCount(key) !== false"
                  >
                    <div stacked>
                      <VAvatar
                        rounded
                        size="47"
                        :color="
                          stringToColor(
                            key.title.slice(-key.title.length / 1.175)
                          )
                        "
                        class="mb-1 mt-2 elevation-0"
                        :style="{
                          color: getContrast(
                            stringToColor(
                              key.title.slice(-key.title.length / 1.175)
                            )
                          ),
                          opacity: isSelected ? 1 : 0.8
                        }"
                      >
                        <span :class="'contrast-label' + (itemCount(key) > 999 ? ' smaller-label':'')">{{
                          itemCount(key) > 0 ? itemCount(key) : 0
                        }}</span>
                      </VAvatar>
                      <!-- <VBadge
                        rounded="sm"
                        :content="getSensorLicense.anywherenum"
                        location="top end"
                        :offset-x="0"
                        :offset-y="-20"
                        bordered
                        color="background-1"
                        transition="scale-rotate-transition"
                        v-show="
                          category.category === 'Sensors' &&
                          store.getActiveOrganizationId &&
                          key.title !== 'Cab'
                        "
                      ></VBadge> -->
                    </div>

                    <p class="mb-0 no-wrap petite-caps">
                      {{ key.title }}
                    </p>
                  </div>
                </VItem>
              </VRow>
            </VContainer>
          </VItemGroup>
        </VList>
      </VCardText>
    </VWindow>
    <VWindow v-show="currentTab === 'Services'">
      <VCardText>
        <VItemGroup v-model="selectedCollection">
          <VContainer>
            <VRow>
              <VItem
                v-for="key in Object.keys(store.services)"
                :key="key"
                v-slot="{ isSelected, toggle }"
                :value="key"
              >
                <div
                  :class="
                    isSelected
                      ? 'elevation-1 box-selected'
                      : 'border-dashed elevation-5 box-unselected'
                  "
                  class="d-flex flex-column justify-center align-center cursor-pointer rounded px-4 py-4 me-1 ms-1 mt-1 box border"
                  @click="toggle"
                >
                  <div stacked>
                    <VAvatar
                      rounded
                      size="47"
                      :color="stringToColor(key)"
                      class="mb-1 mt-2 elevation-0"
                      :style="{
                        color: getContrast(stringToColor(key)),
                        opacity: isSelected ? 1 : 1
                      }"
                    >
                      <span :class="'contrast-label' + (Object.keys(store.services[key]).length > 999 ? ' smaller-label':'')">
                        {{ Object.keys(store.services[key]).length }}</span
                      >
                    </VAvatar>
                  </div>
                  <p class="mb-0 no-wrap petite-caps">
                    {{ key }}
                  </p>
                </div>
              </VItem>
            </VRow>
          </VContainer>
        </VItemGroup>
      </VCardText>
    </VWindow>
  </VCard>
</template>

<style lang="scss">
.v-skeleton-loader {
  overflow: hidden;
}

.v-list {
  display: inline-block;
  inline-size: fit-content;
  padding-block: 0.5em 0;
  padding-inline: 0 0.5em;
}

.v-btn__content {
  font-size: 0.73rem;
  font-weight: 500;
  letter-spacing: 0.25px;
}

.v-slide-group-item--active.v-tab--selected > span.v-btn__content > span {
  font-weight: 700 !important;
}

.border.d-flex.flex-column.justify-center.align-center.cursor-pointer.rounded.px-5.py-2.me-6.elevation-3:hover {
  background: #fffaf0 !important;
}

.v-badge__badge {
  font-size: 0.7em;
}

.v-badge--bordered .v-badge__badge::after {
  transform: scale(1);
}

.no-wrap {
  white-space: nowrap;
}

.device-container {
  padding-block: 0 24px !important;
  padding-inline: 20px !important;
}

/* .devices > div.v-card-item {
  padding-block: 24px 10px !important;
  padding-inline: 20px !important;
} */

div.border-dashed {
  margin-block: 0.125em;
  margin-inline: 0;
}

.v-item-group.v-theme--light {
  border: 1px solid #bfbebe70;
  border-radius: 0.5em;
  background: #8888880f;
  padding-block: 0.4em;
  padding-inline: 0.4em;
}

.v-data-table-header__content {
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.1px;
}

.v-avatar.v-avatar--density-default {
  border: 1px solid #898989;
}

.box {
  border-width: 1px !important;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  block-size: 100px;
  font-size: 0.98em !important;
  inline-size: 110px;
}

.box-unselected {
  background: rgb(255 255 255 / 10%) !important;
  font-weight: 400;
  opacity: 0.9;
}

.box-selected {
  background: rgba(50, 115, 220, 5%) !important;
  font-weight: 700;
  opacity: 1 !important;
}

.contrast-label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25em;
  background-color: rgba(var(--v-theme-grey-50), var(--v-high-emphasis-opacity)) !important;
  block-size: 25px;
  color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity));
  font-size: 0.9em;
  font-weight: 700;
  min-inline-size: 30px;
}

.smaller-label {
  font-size: 0.8em;
  letter-spacing: -0.5px;
}

.v-card-item {
  grid-template-columns: max-content;
  padding-block: 1.4em;
  padding-inline: 1.5em;
}

.close-icon {
  position: absolute;
  margin: 1em;
  cursor: pointer;
  inset-inline-end: 0;
  padding-block: 0;
  padding-inline: 2em;
}

.v-card--variant-plain {
  opacity: 0.8;

  &:hover {
    background-color: #fff8a527;
    opacity: 1;
  }
}

p.mb-0.no-wrap.petite-caps {
  block-size: 1em; // 14.6875px;
  letter-spacing: -0.5px;
}
</style>
