import { store } from "@/stores/store";
/**
 * Function to capture JSON packets from the input element
 * and process the JSON data.
 *
 */

export function initializeJSONPacketCapture() {
  document.addEventListener("DOMContentLoaded", () => {
    console.warn("Anywhere JSON packet capture initialized");
    let buffer = "";
    let capturing = false;
    let braceCount = 0;
    let timeoutId: NodeJS.Timeout | null = null;
    const captureTimeout = 5000; // Set timeout duration in milliseconds

    // Function to handle the JSON packet
    function handleJSONPacket(jsonPacket: string) {
      try {
        /* mfg data contains the following invalid JSON data:
        {"PetMfg":{"Mac":"F2:02:BA:5B:EF:51","DateOfMfg":"2024-07-04'’}}
        replace the '’ with " to make it a valid JSON data
        */
        jsonPacket = jsonPacket.replace(/''/g, '"');

        const parsedData = JSON.parse(jsonPacket);
        console.log("Parsed JSON data:", parsedData);
        // publish to dev/Org/{uuid}/Elite/{uuid}/japiSubscribeTo
        store.openAnywhereRegisterDialog(parsedData);
      } catch (error) {
        console.error("Invalid JSON packet:", error);
      }
    }

    // Function to reset the capture process
    function resetCapture() {
      buffer = "";
      capturing = false;
      braceCount = 0;
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      // Re-enable all input fields
      document.querySelectorAll("input, textarea").forEach((input) => {
        input.removeAttribute("disabled");
      });
    }

    // Event listener for input element
    window.addEventListener("keypress", (event) => {
      const char = event.key;

      if (char === "{" && !capturing) {
        // make sure organization is selected or an Elite is selected
        if (
          !store.activeOrganization &&
          !store.collections.Elite.selected.length
        ) {
          store.setSnack(
            "Please select an organization or an Elite to register a button.",
            {
              color: "error",
              location: "bottom",
              buttonText: "OK",
              buttonTextColor: "white",
            }
          );
          return;
        }

        if (
          store.activeOrganization &&
          !store.collections.Elite.selected.length
        ) {
          const elites = store.collections.Elite.data.filter(
            (elite: { organization_id: string }) =>
              elite.organization_id === store.activeOrganization
          );
          if (elites.length > 1) {
            store.setSnack("Please select an Elite to register a button.", {
              color: "error",
              location: "bottom",
              buttonText: "OK",
              buttonTextColor: "white",
            });
            return;
          }
          store.setSelectedElite(elites[0].id);
        }

        capturing = true;
        buffer = char;
        braceCount = 1;
        timeoutId = setTimeout(resetCapture, captureTimeout); // Set timeout to abort capture

        // Disable all input fields
        document.querySelectorAll("input, textarea").forEach((input) => {
          input.setAttribute("disabled", "true");
        });
      } else if (capturing) {
        buffer += char;

        if (char === "{") {
          braceCount++;
        } else if (char === "}") {
          braceCount--;
        }

        if (braceCount === 0) {
          handleJSONPacket(buffer);
          resetCapture();
        }
      }
    });

    console.log("Anywhere JSON packet capture initialized");
  });
}
