<script setup lang="ts">
import navItems from '@/navigation/vertical'
import { store } from '@/stores/store'
import { useThemeConfig } from '@core/composable/useThemeConfig'
// Components
import Footer from '@/layouts/components/Footer.vue'
import NavbarThemeSwitcher from '@/layouts/components/NavbarThemeSwitcher.vue'
import UserProfile from '@/layouts/components/UserProfile.vue'

// @layouts plugin````````````````````````````````````
import { VerticalNavLayout } from '@layouts'
import { VBtn } from 'vuetify/components/VBtn'
import { VSpacer } from 'vuetify/lib/components/index.mjs'

const role = computed(() => store.$state?.auth?.role || '')

const { appRouteTransition, isLessThanOverlayNavBreakpoint } = useThemeConfig()
const { width: windowWidth } = useWindowSize()
const reconnect = () => {
  console.log('Reconnecting...')
  store.broker.connecting = false
  store.broker.retryTimes = 0
  store.broker.init()
  // close toast
  store.snack.visible = false
}
const broker = ref(store.broker)

const mqttStatus = computed(() => {
  return broker.value?.connecting
    ? 'Connecting...'
    : broker.value?.connected
    ? 'Connected'
    : broker.value?.connected === null
    ? null
    : 'Disconnected'
})

const downloadApp = () => {
  // detect if android by user agent
  const osType = navigator.userAgent.match(/Android/i)
    ? 'android'
    : navigator.userAgent.match(/iPhone|iPad|iPod/i)
    ? 'ios'
    : 'other'

  const downloadUrl =
    osType === 'android'
      ? '/android/RTLAnywhere.apk'
      : 'https://testflight.apple.com/join/N329Ch70'
  window.open(downloadUrl, '_blank')
}

const isMobile = () => {
  const osType = navigator.userAgent.match(/Android/i)
    ? 'android'
    : navigator.userAgent.match(/iPhone|iPad|iPod/i)
    ? 'ios'
    : 'other'
  return osType === 'android' || osType === 'ios'
}

const copyToClipboard = () => {
  const isIdTokenExpired = store.auth.checkIdTokenExpiration()
  console.log('copying token to clipboard', {
    isExpired: isIdTokenExpired
  })
  if (isIdTokenExpired) {
    store.setSnack('idToken expired', {
      color: 'error',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })

    return
  }

  const token = store.auth.idToken
  navigator.clipboard.writeText(token)
}

const environment = computed(() => store.auth.getEnvironment)
const environmentColor = computed(() => {
  return environment.value === 'production'
    ? 'success'
    : environment.value === 'sales'
    ? 'warning'
    : environment.value === 'development'
    ? 'error'
    : 'info'
})
</script>

<template>
  <VerticalNavLayout :nav-items="navItems">
    <!-- 👉 navbar -->
    <template #navbar="{ toggleVerticalOverlayNavActive }">
      <div class="d-flex h-100 align-center">
        <RouterLink
          to="/"
          class="app-logo d-flex align-center gap-x-3 mt-2 app-title-wrapper"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
              width="40pt"
              height="45pt"
              viewBox="0 0 180.000000 185.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,185.000000) scale(0.100000,-0.100000)"
                fill="#ab1b30"
                stroke="none"
              >
                <path
                  d="M460 1397 l-445 -445 442 -448 442 -448 445 445 445 445 -442 448 -442 447 -445 -444z m412 -14 c122 -125 142 -157 142 -234 2 -106 -86 -193 -198 -196 -69 -2 -63 7 -91 -125 -9 -43 -20 -78 -24 -78 -4 0 -29 22 -56 50 l-48 49 26 88 27 88 -42 42 -42 43 -58 -57 -58 -57 -45 44 c-25 24 -45 49 -45 55 0 10 389 395 400 395 3 0 54 -48 112 -107z m420 -420 c82 -81 148 -153 148 -158 0 -6 -16 -26 -35 -45 l-35 -34 -155 154 -155 154 37 38 c20 21 39 38 42 38 3 0 72 -66 153 -147z m-160 -160 c82 -81 148 -152 148 -157 0 -15 -62 -76 -76 -76 -7 0 -78 68 -158 152 l-146 152 37 38 c20 21 39 38 42 38 3 0 72 -66 153 -147z m-160 -160 c82 -81 148 -152 148 -157 0 -14 -62 -76 -74 -76 -6 0 -78 68 -160 151 l-149 150 38 39 c22 22 41 40 44 40 3 0 72 -66 153 -147z"
                ></path>
                <path
                  d="M710 1255 l-64 -65 44 -46 c41 -42 93 -74 119 -74 6 0 26 14 46 31 55 49 45 97 -37 176 l-44 43 -64 -65z"
                ></path>
              </g>
            </svg></div
        ></RouterLink>
        <VBtn
          v-if="isLessThanOverlayNavBreakpoint(windowWidth) && 0"
          icon
          variant="text"
          color="default"
          class="ms-n3"
          size="small"
          @click="toggleVerticalOverlayNavActive(true)"
        >
          <VIcon icon="tabler-menu-2" size="24" />
        </VBtn>

        <NavbarThemeSwitcher />

        <IconBtn
          :color="
            mqttStatus === 'Connected'
              ? 'success'
              : mqttStatus === null
              ? 'secondary'
              : mqttStatus === 'Connecting...'
              ? 'secondary'
              : 'error'
          "
          class="ms-3"
          :class="mqttStatus === 'Connecting...' ? 'spin-icon' : ''"
          @click="reconnect"
          v-if="role"
          ><div><VIcon icon="tabler-access-point" size="26" /></div>

          <VTooltip activator="parent" location="bottom">
            <span>MQTT {{ mqttStatus }}</span>
          </VTooltip>
        </IconBtn>

        <VSpacer />
        <VBtn
          icon
          variant="text"
          color="default"
          class="mr-3"
          size="small"
          @click="downloadApp()"
          v-if="isMobile()"
        >
          <!-- app download icon -->

          <div><VIcon icon="tabler-download" size="26" /></div>
          <VTooltip activator="parent" location="bottom">
            <span>Download </span>
          </VTooltip>
        </VBtn>

        <v-alert
          :text="environment"
          :color="environmentColor"
          variant="tonal"
          class="mr-3"
          id="env"
        ></v-alert>

        <!-- copy bear token to clipboard -->
        <IconBtn @click="copyToClipboard()" class="mr-3" id="copy-icon">
          <div><VIcon icon="tabler-key" size="26" /></div>
          <VTooltip activator="parent" location="bottom">
            <span>Copy Token</span>
          </VTooltip>
        </IconBtn>

        <UserProfile />
      </div>
    </template>
    <RouterView v-slot="{ Component }">
      <Transition :name="appRouteTransition" mode="out-in">
        <Component :is="Component" />
      </Transition>
    </RouterView>

    <!-- 👉 Footer -->
    <template #footer>
      <Footer />
    </template>
  </VerticalNavLayout>
</template>

<style lang="scss">
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin-icon {
  animation: spin 1s linear infinite;
}

.mqtt-label {
  position: absolute;
  font-size: 0.7em;
  inline-size: 100%;
  margin-block: 2.5em 0;
  margin-inline: auto;
  text-align: center;
}

.v-theme-provider {
  display: none;
}

.layout-wrapper.layout-nav-type-vertical.layout-vertical-nav-collapse.layout-content-wrapper {
  padding-inline-start: 0 !important;
}

.app-logo {
  padding-block: 0;
  padding-inline: 0 1em;
}

#env {
  border-radius: 0.5em;
  font-size: 0.7em;
  inset-block-start: 0;
  inset-inline-end: 0;
  max-inline-size: 9em;
  padding-block: 0.2em;
  padding-inline: 0.5em;
  text-align: center;
  text-transform: uppercase;
}
</style>
