<script setup lang="ts">
import { findKey, isEmpty } from '@/@core/utils/index'
import { deleteEntraUser } from '@/services/graphApiService'
import { flattenObj } from '@/stores/flattenObj'
import { store } from '@/stores/store'
import {
  ActionList,
  Alarm,
  Device,
  EliteLicense,
  FinalLicense
} from '@/types/entities'

import { getUserSettings, saveUserSettings } from '@/services/graphApiService'
import { useQRCode } from '@vueuse/integrations/useQRCode'
import { format, parseISO } from 'date-fns'
import { jsPDF } from 'jspdf'
import { v4 as uuidv4 } from 'uuid'
import { computed, onMounted, ref, watch } from 'vue'
import { VTooltip } from 'vuetify/lib/components/index.mjs'
import AddRemoveColumnsDialog from './AddRemoveColumnsDialog.vue'
import AnywhereMessagesDialog from './AnywhereMessagesDialog.vue'
import AnywhereUpgradeDialog from './AnywhereUpgradeDialog.vue'
import CreateEliteDialog from './CreateEliteDialog.vue'
import CreateEntraUserDialog from './CreateEntraUserDialog.vue'
import CreateOrganizationDialog from './CreateOrganizationDialog.vue'
import ProgressSnackbar from './ProgressSnackbar.vue'
import RequestFormFactorDialog from './RequestFormFactorDialog.vue'
import RequestLicenseDialog from './RequestLicenseDialog.vue'
import SelectOrganizationDialog from './SelectOrganizationDialog.vue'

interface QRCode {
  Mac: string
  DiscoveryUrl: string
}

const qrCodes = ref<QRCode[]>([])

const generatePDF = async () => {
  try {
    const doc = new jsPDF()
    for (let index = 0; index < qrCodes.value.length; index++) {
      const qrCode = qrCodes.value[index]
      if (index > 0) {
        doc.addPage()
      }
      const qrCodeImage = useQRCode(JSON.stringify(qrCode))
      // Wait for the QR code image to be generated
      await new Promise(resolve => setTimeout(resolve, 100))
      if (qrCodeImage.value) {
        doc.addImage(qrCodeImage.value, 'PNG', 10, 10, 100, 100)
        doc.text(qrCode.Mac, 10, 120)
      } else {
        console.error('Failed to generate QR code image')
        doc.text('Failed to generate QR code', 10, 10)
      }
    }
    doc.save('qr_codes.pdf')
    store.isQRCodeGenerationDialogVisible = false
  } catch (error) {
    console.error('Error generating PDF:', error)
    store.setSnack('Failed to generate PDF', {
      color: 'error',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
  }
}

const isRowSelectable = (item: any) => {
  if (collection.value !== 'Cab') return true

  const environment =
    store.getEnvironment === 'production'
      ? 'prod'
      : store.getEnvironment === 'sales'
      ? 'sale'
      : 'dev'

  return (
    item['IdpDevice.Environment Name'] === environment ||
    !item['IdpDevice.Environment Name']
  )
}

const props = defineProps({
  collection: { type: String, default: '' },
  showSelect: { type: Boolean, default: false },
  itemValue: { type: String, default: 'id' },
  itemTitle: { type: String, default: 'name' },
  showLoading: { type: Boolean, default: false },
  expandableRows: { type: Boolean, default: false },
  filter: { type: Object, default: {} },
  relationship: { type: Object, default: {} }
})

const showSelect = computed(() => props.showSelect)
const expandableRows = computed(() => props.expandableRows)
const relationship = computed(() => props.relationship)
const search = ref('')
const searchOperator = ref('contains')
const isValidRegex = ref(true)

const validateRegex = (value: string) => {
  if (searchOperator.value === 'regex') {
    try {
      new RegExp(value)
      isValidRegex.value = true
    } catch (e) {
      isValidRegex.value = false
      console.error('Invalid regex:', e)
    }
  } else {
    isValidRegex.value = true
  }
}

watch(search, newValue => {
  validateRegex(newValue)
})

watch(searchOperator, () => {
  validateRegex(search.value)
})

const expandableData = ref([])
const expandableHeaders = ref([])
const expandableRowsLoading = ref(false)
const booleanFields = ref([] as Array<string>)
const allFields = ref([] as Array<string>)
const licenseHistory = ref([] as Array<any>)

const isRequestLicenseDialogVisible = ref(false)
const isCreateOrganizationDialogVisible = ref(false)
const isCreateEliteDialogVisible = ref(false)
const isCreateEntraUserDialogVisible = ref(false)

const refreshEntraUsers = async () => {
  await store.fetchEntraUsers()
  store.collections['EntraUsers'].data = store.entraUsers
}
const newOrganizationParentId = ref('')
const isEditMode = ref(false)
const eliteToEdit = ref({} as any)
const organizationToEdit = ref({} as any)
const entraUserToEdit = ref({} as any)

const scrollToTable = () => {
  const table = document.querySelector('.layout-footer')
  if (table) {
    console.log('scrollToTable', table)
    table.scrollIntoView({
      behavior: 'smooth'
    })
  }
}

const selectedCollection = computed(() => {
  return store.selectedCollection
})

const collection = computed(() => {
  setTimeout(() => {
    scrollToTable()
  }, 1000)

  scrollToTable()
  if (typeof selectedCollection.value === 'string') {
    return selectedCollection.value
      .replace('(Bound)', '')
      .replaceAll(' ', '')
      .trim()
  } else {
    return ''
  }
})

watch(collection, newValue => {
  if (newValue === 'EntraUsers') {
    refreshEntraUsers()
  }
})

const selectedCollectionTitle = computed(() => {
  return (
    store.collections[store.selectedCollection].title || collection.value || ''
  )
})

const tableContainer = ref(<HTMLElement | null>null)
const rowHeight = 80

let isScrolling = false
let scrollTimeout: number

const handleScroll = (e: {
  target: {
    scrollTop: any
    scrollTo: (arg0: { top: number; behavior: string }) => void
  }
}) => {
  if (!isScrolling) {
    isScrolling = true
    cancelAnimationFrame(scrollTimeout)
  }

  scrollTimeout = requestAnimationFrame(() => {
    const scrollTop = e.target.scrollTop
    const targetRow = Math.round(scrollTop / rowHeight)
    const targetScrollTop = targetRow * rowHeight

    if (Math.abs(scrollTop - targetScrollTop) > 1) {
      e.target.scrollTo({
        top: targetScrollTop,
        behavior: 'smooth'
      })
    }

    isScrolling = false
  })
}

onMounted(async () => {
  console.log('Entities card mounted', collection.value)
  if (collection.value !== '') {
    if (store.collections[collection.value] === undefined) {
      console.debug('Mounted collection not found', collection.value)
      store.collections[collection.value] = {
        data: [],
        headers: [],
        selected: []
      }
    }
    // console.debug('Mounted collection found', collection.value)

    /* if (tableContainer.value) {
      const tableBody = tableContainer.value.querySelector('.v-table__wrapper')
      if (tableBody) {
        console.log('tableBody found', tableBody)
        ;(tableBody as HTMLElement).style.scrollSnapType = 'y mandatory'
      } else {
        console.error('tableBody not found')
      }
    } else {
      console.error('tableContainer not found')
    } */

    if (collection.value === 'EntraUsers') {
      console.log('refreshing entra users', store.collections[collection.value])
      refreshEntraUsers()
      console.log('refreshed entra users', store.collections[collection.value])
    }
    await loadUserColumnsSettings()
  } else {
    console.error('No collection selected')
  }
})

// Watch for changes in the collection and reload user settings
watch(collection, async () => {
  await loadUserColumnsSettings()
})

const { parentCollection, childKey } = relationship.value

const activeParentOrg = computed(() => store.getActiveParentOrgId)
const activeOrganization = computed(() => store.getActiveOrganizationId)

const organizationsByParentOrg = computed(() => {
  return store.collections.Organization.data.filter(
    (x: { organization_id: string }) =>
      x.organization_id === activeParentOrg.value
  )
})

const collectionItemsByParentOrg = (collection: string) => {
  return computed(() => {
    return store.collections[collection].data.filter(
      (x: { organization_id: string; id: string }) =>
        organizationsByParentOrg.value.find(
          (y: { id: string }) => y.id === x.organization_id || y.id === x.id
        )
    )
  })
}

const getTableData = computed(() =>
  (parentCollection && collection.value !== 'Cab'
    ? activeOrganization.value
      ? store.collections[collection.value]?.data?.filter(
          (x: any) => x[childKey] === store.getActiveOrganizationId
        )
      : activeParentOrg.value
      ? collection.value === 'Organization'
        ? organizationsByParentOrg.value
        : getCollectionItemsByParentOrg.value
      : store.collections[collection.value]?.data
    : store.broker.dataListByCabSight
  )?.map((x: any) => {
    // delete x.publickey
    // if Cab, look up organization id in Anywhere Collection
    if (collection.value === 'Cab') {
      const anywhereItem = store.collections['Anywhere'].data.find(
        (y: { id: object }) => y.id === x.uuid
      )

      if (anywhereItem && anywhereItem.organization_id)
        x.organization_id = anywhereItem.organization_id
    }
    return x
  })
)

const tableData = computed(() => getTableData.value)

const filteredData = computed(() => {
  if (!search.value || !isValidRegex.value) return tableData.value

  const searchValue = search.value
  const operator = searchOperator.value

  const results = tableData.value.filter(item => {
    return Object.keys(item).some(key => {
      const value = item[key]
      if (typeof value === 'string') {
        if (operator === 'contains') {
          // check if value contains the search term
          return value.toLowerCase().includes(searchValue.toLowerCase())
        } else if (operator === 'equals') {
          return value.toLowerCase() === searchValue.toLowerCase()
        } else if (operator === 'regex') {
          const regex = new RegExp(searchValue, 'i')
          return regex.test(value)
        }
      }
      return false
    })
  })
  // console.log('filteredData', { search: searchValue, operator, results })
  return results
})

async function itemSelected(_event: any, obj: any = null): Promise<void> {
  console.debug(
    'itemSelected',
    {
      _event,
      obj
    },
    store.collections[collection.value].selected
  )
  if (!obj?.item?.value) return

  if (expandableRows.value && collection.value === 'Elite') {
    readLicenseHistory(obj.item.value)
  } else {
    if (
      store.collections[collection.value] &&
      !store.collections[collection.value].selected.includes(obj.item.value)
    )
      store.collections[collection.value].selected.push(obj.item.value)
  }
}

const handleExpanded = (value: any) => {
  console.debug('handleExpanded', {
    expandableRows: expandableRows.value,
    value
  })
  if (value && expandableRows.value) {
    expandableRowsLoading.value = true
    itemSelected(null, { item: { value } })
  }
}

// watch store.collections[collection.value].selected for changes
/* watch(
  () => store.collections[collection.value].selected,
  (newValue, oldValue) => {
    console.debug("store.collections[collection.value].selected", {
      newValue,
      oldValue,
    });
    if (newValue.length !== 0) {
      expandableRowsLoading.value = true;
      readLicenseHistory();
    } else {
      licenseHistory.value = [];
    }
  }
); */

const icons = {
  add: 'mdi-plus',
  edit: 'mdi-pencil',
  remove: 'mdi-delete',
  refresh: 'mdi-refresh',
  viewDetails: 'mdi-eye',
  addElite: 'mdi-plus',
  bind: 'mdi-link',
  unbind: 'mdi-link-off',
  test: 'mdi-test-tube',
  upgrade: 'mdi-arrow-up',
  requestLicense: 'mdi-license',
  clear: 'mdi-close',
  migrate: 'mdi-transfer-right'
}

const actionsList: ActionList = {
  Elite: [
    {
      title: 'Request License',
      value: 'requestLicense',
      singleSelect: true,
      icon: 'mdi-license'
    },
    { title: 'Edit', value: 'edit', singleSelect: true },
    { title: 'Remove', value: 'remove' }
  ],
  EntraUsers: [
    {
      title: 'Edit',
      value: 'edit',
      singleSelect: true,
      icon: 'mdi-account-edit'
    },
    { title: 'Remove', value: 'remove', icon: 'mdi-account-remove' }
  ],
  Cab: [
    {
      title: 'Bind',
      value: 'bind' /*
      condition: (item: any) => {
        console.log('bind condition', item?.State)
        return item?.State === 'Unbound'
      } */
    },
    {
      title: 'Unbind',
      value: 'unbind' /*
      condition: (item: any) => {
        console.log('unbind condition', item?.State)
        return item?.State === 'Bound'
      } */
    },
    { title: 'View Details', value: 'viewDetails', singleSelect: true },
    { title: 'Upgrade', value: 'upgrade' }
  ],
  Anywhere: [
    { title: 'Migrate', value: 'bind' },
    { title: 'Test', value: 'test' },
    { title: 'View Details', value: 'viewDetails', singleSelect: true },
    { title: 'Upgrade', value: 'upgrade' },
    { title: 'Generate QR Codes', value: 'generateQRCodes', icon: 'mdi-qrcode' }
  ],
  All: [{ title: 'Refresh', value: 'refresh' }],
  CabItem: [{ title: 'Clear List', value: 'clear' }],
  Organization: [
    { title: 'Add', value: 'add', singleSelect: true },
    { title: 'Edit', value: 'edit', singleSelect: true },
    { title: 'Remove', value: 'remove' },
    { title: 'View Details', value: 'viewDetails', singleSelect: true }
  ],
  EliteTopLevel: [{ title: 'Add', value: 'addElite', singleSelect: true }],
  EntraUsersTopLevel: [
    {
      title: 'Add',
      value: 'add',
      singleSelect: true,
      icon: 'mdi-account-plus'
    }
  ]
}

const actions = computed(() => {
  addEventListener
  if (collection.value === '') return []
  let actions =
    collection.value === 'Cab' ||
    Object.keys(store.services).includes(collection.value)
      ? (actionsList[collection.value] || []).concat(actionsList['CabItem'])
      : (actionsList[collection.value] || []).concat(actionsList['All'])

  // Add collection-specific top-level actions
  if (collection.value === 'Elite') {
    actions = actions.concat(actionsList['EliteTopLevel'])
  }

  if (collection.value === 'EntraUsers') {
    // console.warn('EntraUsersTopLevel', actionsList['EntraUsersTopLevel'])
    actions = actions.concat(actionsList['EntraUsersTopLevel'])
  }
  // filter out actions that have a condition that returns false
  actions = actions.filter(
    (x: { condition?: (item: any) => boolean }) =>
      !x.condition ||
      (x.condition &&
        x.condition(
          store.collections[collection.value].selected.length === 1
            ? store.collections[collection.value].data.find(
                (y: { id: any }) =>
                  y.id === store.collections[collection.value].selected[0]
              )
            : false
        )) ||
      store.collections[collection.value].data.map(
        y =>
          x.condition &&
          x.condition(
            store.collections[collection.value].data.find(
              (z: { id: any }) => z.id === y.id
            )
          )
      )
  )

  // remove actions that are singleSelect and more than one item is selected
  // only return items in actionsList['All'] if no items are selected
  const returnVal = (
    store.collections[collection.value].selected.length > 1
      ? actions.filter((x: { singleSelect?: boolean }) => !x.singleSelect)
      : store.collections[collection.value].selected.length === 0
      ? actionsList['All'].concat(
          collection.value === 'Elite'
            ? actionsList['EliteTopLevel']
            : collection.value === 'EntraUsers'
            ? actionsList['EntraUsersTopLevel']
            : []
        )
      : actions
  ).map(x => {
    return {
      ...x,
      icon:
        x.icon ||
        icons[x.title.toLowerCase() as keyof typeof icons] ||
        icons[x.value as keyof typeof icons] ||
        'mdi-bullet'
    }
  })
  // console.warn('actions', collection.value, { actions, returnVal })
  return returnVal
})

const itemActions = computed(() => {
  if (Object.keys(store.services).includes(collection.value)) return []
  if (collection.value === '') return []
  return (
    actionsList[collection.value]?.filter(
      action => action.value !== 'addElite'
    ) || []
  )
    .filter(
      (x: { condition?: (item: any) => boolean }) =>
        !x.condition || x.condition(store.collections[collection.value].data[0])
    )
    .map(x => {
      return {
        ...x,
        icon:
          icons[x.title.toLowerCase() as keyof typeof icons] ||
          icons[x.value] ||
          'mdi-bullet'
      }
    })
})

// console.debug('actions', collection.value, { actions })

const isSelectOrganizationDialogVisible = ref(false)
const selectOrganizationIdData = ref('')

const isRequestFormFactorDialogVisible = ref(false)
const requestFormFactor = ref([] as any)

const showProgressSnackbar = ref(false)
const progressSnackbarText = ref('')
const progressSnackbarTotal = ref(0)
const progressSnackbarCurrent = ref(0)

const entityActions = {
  remove: async (collection: string) => {
    try {
      console.info('remove', collection)
      const selectedItems = store.collections[collection].selected

      // Confirmation prompt
      const confirmResult = await new Promise(resolve => {
        store.setSnack(
          `Are you sure you want to remove ${selectedItems.length} ${collection}(s)?`,
          {
            color: 'warning',
            variant: 'elevated',
            location: 'bottom',
            buttonText: 'Confirm',
            buttonTextColor: 'white',
            timeout: -1, // Don't auto-hide
            closeButtonText: 'Cancel',
            buttonAction: () => resolve(true),
            onClose: () => resolve(false)
          }
        )
      })

      if (!confirmResult) {
        console.info('Remove operation cancelled by user')
        // unselect items
        store.collections[collection].selected = []
        return
      }

      if (collection === 'EntraUsers') {
        for (const userId of selectedItems) {
          await deleteEntraUser(userId)
          refreshEntraUsers()
        }
      } else if (collection === 'Cab') {
        const payLoads = selectedItems.map((x: any) => [
          { Method: `Remove ${collection}` },
          { id: x }
        ])
        const results = await store.api.postData(payLoads)
        const finalResults = JSON.parse(JSON.stringify(results))

        if (!finalResults.success) {
          // throw new Error with the first error message
          throw new Error(
            `Failed to remove ${collection}: ${finalResults[0].Message}`
          )
        }
      } else {
        const payLoads = [
          { Method: `Remove ${collection}` },
          selectedItems.map((x: any) =>
            collection === 'Elite'
              ? { id: x }
              : { id: x, organization_id: store.getActiveOrganizationId }
          )
        ]
        const results = await store.api.postData(payLoads)
        const finalResults = JSON.parse(JSON.stringify(results))

        if (!finalResults.success) {
          throw new Error('Failed to remove items')
        }
      }

      // remove selected items from Anywhere collection
      if (collection === 'Cab') {
        console.info(
          'remove cabs from Anywhere collection',
          selectedItems,
          store.collections['Anywhere'].data.length
        )
        store.collections['Anywhere'].data = store.collections[
          'Anywhere'
        ].data.filter((x: any) => !selectedItems.includes(x.id))
        console.info(
          'remove cabs from Anywhere collection',
          selectedItems,
          store.collections['Anywhere'].data.length
        )
      }

      store.collections[collection].selected = []

      store.setSnack(`Removed ${selectedItems.length} ${collection}(s)`, {
        color: 'success',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: '#333333'
      })

      // refresh data
      // console.log('refreshData', collection, 'remove')
      await store.api.refreshData(collection)
    } catch (error) {
      console.error('remove', error)
      store.setSnack(
        `Failed to remove ${collection}: ${(error as Error).message}`,
        {
          color: 'error',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: 'white'
        }
      )
    }
  },
  generateQRCodes: async (collection: string) => {
    try {
      console.info('generateQRCodes', collection)
      const selectedItems = store.collections[collection].selected
      const qrCodeData = selectedItems.map(id => {
        const item = store.collections[collection].data.find(x => x.id === id)
        return {
          Mac: item.mac,
          DiscoveryUrl: 'https://discovery.responsetech.ltd'
        }
      })

      // Set the QR code data and open the dialog
      qrCodes.value = qrCodeData
      store.isQRCodeGenerationDialogVisible = true
    } catch (error) {
      console.error('generateQRCodes', error)
      store.setSnack(
        `Failed to generate QR codes: ${(error as Error).message}`,
        {
          color: 'error',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: 'white'
        }
      )
    }
  },
  add: async (collection: string) => {
    try {
      isEditMode.value = false
      if (collection === 'Organization') {
        /* console.info(
          'add',
          collection,
          store.collections.Organization.selected[0]
        ) */
        // get selected organization id
        newOrganizationParentId.value =
          store.collections.Organization.selected[0] || ''

        organizationToEdit.value = {
          id: '',
          name: '',
          dsc: ''
        }
        isCreateOrganizationDialogVisible.value = true

        // reset selected
        store.collections.Organization.selected = []
      } else if (collection === 'EntraUsers') {
        // console.info('add', collection)
        isCreateEntraUserDialogVisible.value = true
      } else {
        // TODO: Implement add functionality for other collections
      }
    } catch (error) {
      console.error('add', error)
    }
  },
  edit: async (collection: string) => {
    try {
      isEditMode.value = true
      if (collection === 'Organization') {
        const selectedOrgId = store.collections.Organization.selected[0]
        const orgToEdit = store.collections.Organization.data.find(
          (org: { Name: string; id: string }) => org.id === selectedOrgId
        )

        /* console.info(
          'edit',
          collection,
          store.collections.Organization.selected[0],
          orgToEdit
        ) */

        if (orgToEdit) {
          newOrganizationParentId.value = orgToEdit.organization_id
          organizationToEdit.value = orgToEdit
          isCreateOrganizationDialogVisible.value = true
        } else {
          throw new Error('Selected organization not found')
        }

        // reset selected
        store.collections.Organization.selected = []
      } else if (collection === 'Elite') {
        const selectedEliteId = store.collections.Elite.selected[0]
        const eliteToEditData = store.collections.Elite.data.find(
          (elite: { id: string }) => elite.id === selectedEliteId
        )

        if (eliteToEditData) {
          eliteToEdit.value = eliteToEditData
          isCreateEliteDialogVisible.value = true
          /* console.info(
            'edit',
            collection,
            store.collections.Elite.selected[0],
            eliteToEditData
          ) */
        } else {
          throw new Error('Selected Elite not found')
        }

        // reset selected
        store.collections.Elite.selected = []
      } else if (collection === 'EntraUsers') {
        /* console.log(
          'edit',
          collection,
          store.collections.EntraUsers.selected[0],
          store.collections.EntraUsers.data
        ) */
        const selectedEntraUserId = store.collections.EntraUsers.selected[0]
        const entraUserToEditData = store.collections.EntraUsers.data.find(
          (user: { id: string }) => user.id === selectedEntraUserId
        )

        if (entraUserToEditData) {
          entraUserToEdit.value = entraUserToEditData
          isCreateEntraUserDialogVisible.value = true
          /* console.info(
            'edit',
            collection,
            store.collections.EntraUsers.selected[0],
            entraUserToEditData
          ) */
        } else {
          throw new Error('Selected Entra User not found')
        }

        // reset selected
        store.collections.EntraUsers.selected = []
      } else {
        // TODO: Implement edit functionality for other collections
      }
    } catch (error) {
      console.error('edit', error)
    }
  },
  upgrade: async (_collection: string) => {
    try {
      // console.info('upgrade', collection)
      // rtl_unique_identifier is the uuid of selected Cabsight
      if (!store.broker.selectedCabSight?.uuid) {
        store.setSnack('No Cabsight selected', {
          color: 'error',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: 'white'
        })
        return
      }
      store.openAnywhereUpgradeDialog()
    } catch (error) {
      console.error('upgrade', error)
    }
  },
  bind: async (collection: string) => {
    try {
      console.info(collection === 'Cab' ? 'bind' : 'migrate', collection)

      if (collection === 'Cab')
        selectOrganizationIdData.value =
          selectOrganizationIdData.value || store.getActiveOrganizationId

      // console.debug('selectOrganizationIdData', selectOrganizationIdData.value)

      // if no organization is selected open selectOrganizationDialog
      if (!selectOrganizationIdData.value) {
        isSelectOrganizationDialogVisible.value = true
        return
      }

      const selectedItems = JSON.parse(
        JSON.stringify(store.collections[collection].selected)
      )

      // update and check bound list if collection is Cab
      let boundList = []
      if (collection === 'Cab') {
        /* const options = {
          method: 'POST',
          headers: store.api.apiData().headers,
          body: new URLSearchParams({
            MethodArray: `[{"Method":"Select Anywhere Bound"}]`,
            '': ''
          })
        }
        const response = await fetch(store.api.apiData().api.URI, options)
        const boundData = await response.json()
        boundList =
          boundData[0] && boundData[0].Success ? boundData[1].Records : [] */
        boundList = store.collections['Anywhere'].data.filter(
          (x: any) => x.organization_id === selectOrganizationIdData.value
        )
      }

      const payLoads = selectedItems
        .map((x: any) => {
          // check if mac is already bound
          /*  const isBound =
          collection === 'Cab'
            ? boundList.find((y: any) => y.id === x)
            : ({} as any) */
          if (collection === 'Cab' && boundList.find((y: any) => y.id === x)) {
            // look up orgainzation name of bound device
            const organization = store.collections['Organization'].data.find(
              (z: any) =>
                z.id ===
                (
                  boundList.find((y: any) => y.id === x) || {
                    organization_id: ''
                  }
                ).organization_id
            )

            // console.debug('isBound', isBound, organization)
            store.setSnack(
              `Cab ${x} is already bound to ${organization.name}`,
              {
                color: 'info',
                variant: 'elevated',
                location: 'bottom',
                buttonText: 'Close',
                buttonTextColor: 'white'
              }
            )

            // remove from selected
            store.collections[collection].selected = store.collections[
              collection
            ].selected.filter((y: any) => y !== x)

            return false
          } else {
            if (x) {
              const method =
                collection === 'Cab' ? 'Bind Anywhere' : 'Migrate Anywhere'

              // lookup app version by id
              const item = store.collections[collection].data.find(
                (y: { uuid: string; id: string }) => y.uuid === x || y.id === x
              ) || { AppVersion: '' }

              const appVersion = item.AppVersion || item.firmware

              if (!appVersion) {
                console.error('App version not found for id:', x, item)

                // remove from selected
                store.collections['Anywhere'].selected = selectedItems.filter(
                  (y: { id: any }) => y.id === x
                )

                return false
              }

              console.warn('processing: ', x, { appVersion })

              return [
                { Method: method },
                {
                  id: x,
                  organization_id: selectOrganizationIdData.value,
                  firmware: appVersion
                }
              ]
            }
          }
        })
        .filter((x: any) => x)

      // console.debug('payLoads', payLoads)

      // remove empty arrays from payLoads
      /**
       * { "id": "00164a0c-b124-4045-8394-3afc29b16fa2", "organization_id":
"45b6aff4-e155-418f-9d9f-80e415e9bb2c", "firmware": "3.0.0" }

       */
      /* payLoads[0].forEach((x: any, i: number) => {
        if (x.length === 0) {
          payLoads[0].splice(i, 1)
        }
      }) */

      console.log('payLoads', payLoads)

      if (payLoads.length) {
        requestFormFactor.value = payLoads
        console.debug('payLoads', requestFormFactor.value)

        selectOrganizationIdData.value = ''

        // prompt for form factor if collection is Cab
        if (collection === 'Cab') {
          isRequestFormFactorDialogVisible.value = true
        } else {
          showProgressSnackbar.value = true
          progressSnackbarText.value = `Migrating ${collection}s...`
          progressSnackbarTotal.value = payLoads.length
          progressSnackbarCurrent.value = 0

          const results = [] as any[]
          for (let index = 0; index < payLoads.length; index++) {
            const payload = payLoads[index]
            const result = await store.api.postData(payload)
            progressSnackbarCurrent.value = index + 1
            results.push(result)
          }

          showProgressSnackbar.value = false

          const finalResults = results.flat()
          const processedCount = finalResults.filter(r => r.success).length

          // console.debug('results', { results, finalResults })
          // if promise is resolved, remove results from selectedItems
          if (processedCount > 0) {
            finalResults.forEach((x: any) => {
              if (x.success) {
                store.collections[collection].selected = store.collections[
                  collection
                ].selected.filter((y: any) => y !== x.id)
              }
            })

            /* console.debug(
              'selected remaining:',
              collection,
              store.collections[collection].selected
            ) */

            store.setSnack(
              `Successfully ${
                collection === 'Cab' ? 'bound' : 'migrated'
              } ${processedCount} ${collection}s`,
              {
                color: 'info',
                variant: 'elevated',
                location: 'bottom',
                buttonText: 'Close',
                buttonTextColor: 'white'
              }
            )

            // refresh data
            // console.log('refreshData', collection, 'bind')

            // remove bound items from selectedItems
            store.collections[collection].selected = store.collections[
              collection
            ].selected.filter(
              (x: any) => !finalResults.find((y: any) => y.id === x)?.success
            )

            // refresh data

            await store.api.refreshData(collection)
            if (collection === 'Anywhere') {
              // console.log('refreshData', collection, 'bind 2')
              await store.api.refreshData('Anywhere Messages')
            }
          }
        }
      } else console.log(`No ${collection}s selected`)
    } catch (error) {
      console.error('bind', error)
      showProgressSnackbar.value = false
    }
  },
  unbind: async (collection: any) => {
    try {
      console.info('unbound', collection)
      const selectedItems = store.collections[collection].selected

      /**
       * example payload
       * [ { Method: 'Remove Anywhere' },
       * [{ id: x },{ id: x },{ id: x }]

       */

      // create payload
      const payLoads = [
        { Method: 'Unbind Anywhere' },
        selectedItems.map((x: any) => ({ id: x }))
      ]

      console.warn('payLoads', payLoads)

      showProgressSnackbar.value = true
      progressSnackbarText.value = `Unbinding ${collection}s...`
      progressSnackbarTotal.value = payLoads[1].length
      progressSnackbarCurrent.value = 0

      /* const results = [] as any[]
      for (let index = 0; index < payLoads.length; index++) {
        const payload = payLoads[index]
        const result = await store.api.postData([payload])
        progressSnackbarCurrent.value = index + 1
        results.push(result)
      } */

      const results = await store.api.postData(payLoads)
      progressSnackbarCurrent.value = payLoads[1].length

      showProgressSnackbar.value = false

      /**
       * example results
       {
          "success": true,
          "processed": [
              {
                  "id": "0016b888-3ea4-4045-8394-3afc29b16fa2"
              },
              {
                  "id": "0016804e-9227-4045-8394-3afc29b16fa2"
              }
          ]
        }
       *
       */

      console.debug('results', results)

      // parse number of processed items from Message text
      const processedCount = parseInt(
        (results as any).processed.length.toString() || '0'
      )

      // if promise is resolved, remove results from selectedItems
      if (processedCount > 0) {
        // Clear all selected items
        store.collections[collection].selected = []

        /* console.debug(
          'selected cleared:',
          store.collections[collection].selected
        ) */

        store.setSnack(
          `Successfully unbound ${processedCount} ${collection}s`,
          {
            color: 'info',
            variant: 'elevated',
            location: 'bottom',
            buttonText: 'Close',
            buttonTextColor: 'white'
          }
        )

        // remove cab items from Anywhere collection
        if (collection === 'Cab') {
          store.collections['Anywhere'].data = store.collections[
            'Anywhere'
          ].data.filter(
            (x: any) =>
              !selectedItems.includes(x.id) &&
              x.organization_id !== store.getActiveOrganizationId
          )
        }
      } else {
        throw new Error('No items processed')
      }
    } catch (error: any) {
      showProgressSnackbar.value = false
      // parse ids from error message
      /**
       * example error
       * [{
          "Message": "*ERROR* could not find anywhere ids: 3, 4, 5",
          "Success": false,
          "Reply": "Unbind Anywhere"
         }]
       */
      const errorMessage = error?.[0]?.Message || ''
      const ids =
        error?.[0]?.Message?.split(':')[1]
          ?.trim()
          ?.split(',')
          ?.map((x: string) => x.trim()) || []

      console.error('unbind', error, ids)

      // remove failed items from selectedItems
      store.collections[collection].selected = store.collections[
        collection
      ].selected.filter((x: any) => !ids.includes(x))

      store.setSnack(
        `Failed to unbind ${ids.length} ${collection}s: ${errorMessage}`,
        {
          color: 'error',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: 'white'
        }
      )
    }
  },
  clear: async (collection: any) => {
    console.info('clear', collection)
    store.collections[collection].selected = []
    store.collections[collection].data = []
  },
  test: async (collection: any) => {
    try {
      console.info('test', collection)
      const selectedItems = store.collections[collection].selected
      const items = selectedItems
        .filter((x: any) => x !== null && x !== '' && x)
        .map((x: any) => ({
          Uuid: x,
          EventUuid: uuidv4(),
          TestUuid: uuidv4()
        }))
      const payLoad = [{ Method: `Perform ${collection} Test` }, items]

      // console.debug('payLoad', payLoad)

      const results = await store.api.postToAPI([payLoad])
      const finalResults = JSON.parse(JSON.stringify(results))

      const processedCount = selectedItems.length

      // console.debug('results', { finalResults })
      // if promise is resolved, remove results from selectedItems
      if (finalResults[0][0].Success) {
        store.collections[collection].selected = []
        store.setSnack(`${finalResults[0][0]?.Message}`, {
          color: 'success',
          variant: 'flat',
          location: 'bottom',
          buttonText: '',
          buttonTextColor: 'white'
        })
      }

      /* console.debug(
        'selected remaining:',
        store.collections[collection].selected
      ) */
    } catch (error) {
      console.error('test', error)
    }
  },
  refresh: async (collection: string) => {
    try {
      console.info('refresh', collection, store.collections[collection].data)

      store.collections[collection].selected = []
      store.collections[collection].data = []

      // if a service, requery conf broker
      if (Object.keys(store.services).includes(collection)) {
        store.auth.authorize(false).then((result: any) => {
          // find the "Services" key in result
          const services = result[1]['Services']

          // find the collection in services by "Service Account Type" key
          const service = services.find(
            (x: any) => x['Service Account Type'] === collection
          )

          // populate the collection with the service Instances
          store.collections[collection].data = service['Instances'] || []
        })
        return
      }
      if (collection !== 'Cab') {
        // console.log('refreshData', collection, 'refresh')
        if (collection === 'EntraUsers') {
          /* const users = await getEntraUsers()
          store.collections[collection].data = users || [] */
          refreshEntraUsers()
        } else {
          await store.api.refreshData(collection)
        }
        if (collection === 'Anywhere') {
          // console.log('refreshData', collection, 'refresh 2')
          await store.api.refreshData('Anywhere Messages')
        }
      } else {
        // publish refresh
        const payLoad = [
          { Method: 'Refresh Anywhere List', TraceUuid: uuidv4() },
          {
            rtl_unique_identifier: store.broker.selectedCabSight.uuid
          }
        ]

        // post payLoad to api
        const result = await store.api.postToAPI([payLoad])
        // console.debug('result', result)
      }
    } catch (error) {
      console.error('refresh', error)
      // Ensure data is always an array, even if an error occurs
      store.collections[collection].data =
        store.collections[collection].data || []
    }
  },
  requestLicense: async (collection: string) => {
    try {
      console.debug(
        'requestLicense',
        collection,
        store.collections[collection].selected
      )
      const selectedItem = store.collections[collection].selected[0]
      const eliteObj = store.collections[collection].data.find(
        (x: { id: any }) => x.id === selectedItem
      )

      // get organization id for the selected elite
      const organization = store.getOrganizationDetailsById(
        eliteObj['organization_id']
      )

      store.collections[collection].selected = []

      // get current license for elite
      const endpoint = 'bl/resptech/license/select'

      const result = (
        (await store.api.postToEndpoint(endpoint, [
          { elite_id: [eliteObj['id']] }
        ])) as any
      )[0]

      const value = result.value // JSON.parse(result.value)
      delete result.value

      console.log('result', result, value)

      const currentLicense = Object.assign({}, result, value)

      console.debug('currentLicense', currentLicense)

      const eosLevel = currentLicense?.['EOS Level'] || '0'
      const eosValue = extractInteger(eosLevel) || 0
      console.debug('eosLevel', eosLevel, eosValue)

      const version = currentLicense?.['Version'] || '1.0'

      const licenseObj = {
        id: currentLicense?.id || '',
        activity_account_id:
          currentLicense?.activity_account_id ||
          organization?.activity_account_id ||
          '',
        elite_id: eliteObj['id'],
        Version: parseFloat(version),
        Product: currentLicense?.['Product'] || 'Centurion Elite',
        HardwareId: eliteObj['mac'],
        SystemId: organization.systemid,
        EosLevel: eosLevel,
        NumberOfNotifications: eosValue * 5,
        NumberOfSensors: currentLicense?.['Number of Inovonics'] || 0,
        NumberOfPCAlertSeats: currentLicense?.['Number of PCAlert Seats'] || 0,
        NumberOfPCDuressSeats:
          currentLicense?.['Number of PCDuress Seats'] || 0,
        NumberOfAnywhereButtons:
          currentLicense?.['Number of Anywhere Buttons'] || 0,
        NumberOfRapidSOSEnabledSensors:
          currentLicense?.['Number of RapidSOS Enabled Sensors'] || 0,
        ModemSupport: currentLicense?.['Modem Support'] || false,
        CloudSupport: currentLicense?.['Cloud Support'] || false,
        SSOSupport: currentLicense?.['SSO Support'] || false,
        Organization: {
          Name: organization.name,
          UUID: organization.id,
          organization_id: organization.orgainzation_id
        }
      }

      license.value = licenseObj
      isDialogVisible.value = true
      store.collections[collection].selected = []
      console.debug('licenseObj', licenseObj)
    } catch (error) {
      console.error('requestLicense', error)
    }
  },
  addElite: async (collection: string) => {
    try {
      isEditMode.value = false
      console.info('add', collection)

      eliteToEdit.value = {
        id: '',
        name: '',
        dsc: ''
      }
      isCreateEliteDialogVisible.value = true
    } catch (error) {
      console.error('add', collection, error)
    }
  },
  viewDetails: async (collection: string) => {
    console.info('viewDetails', collection)
    const selectedItem = store.collections[collection].selected[0]
    const item = store.collections[collection].data.find(
      (x: { id: string; uuid: string }) =>
        x.id === selectedItem || x.uuid === selectedItem
    )
    console.debug('viewDetails', { selectedItem, item })
    // unselct item
    store.collections[collection].selected = []
    anywhereDetails(item)
  }
}

const handleOrganizationSelected = (organizationId: string) => {
  console.debug('handleOrganizationSelected', organizationId)
  selectOrganizationIdData.value = organizationId
  isSelectOrganizationDialogVisible.value = false
  entityActions.bind(collection.value)
}

const handleClick = (value: any, _item: any) => {
  // Handle click event
  const valueId: string = value.id
  // console.debug('handleClick', { value, item, valueId })

  const action = entityActions[value as keyof typeof entityActions]
  if (typeof action === 'function') {
    action(collection.value)
  } else {
    console.error(`Action ${value} is not a function`)
  }
}

const handleClickInline = (value: any, item: any) => {
  // Handle click event
  // if row level checkbox isn't checked, check it
  // console.debug('handleClickInline', { value, item })

  if (
    !store.collections[collection.value].selected.includes(item.id || item.uuid)
  ) {
    store.collections[collection.value].selected.push(item.id || item.uuid)
  }

  handleClick(value, item)
}

const getCollectionItemsByParentOrg = computed(() =>
  collectionItemsByParentOrg(collection.value)
)
const cabSightSelections = computed(() => {
  // return store.broker.cabSights with count of devices
  const cabSights =
    store.broker.cabSights?.map((x: any) => {
      const count =
        store.collections['Cab']?.data?.filter(
          (y: any) => y['Author.Uuid'] === x.uuid
        )?.length || 0
      return {
        uuid: x.uuid,
        description: `${x.description} (${count})`
      }
    }) || []
  // console.debug('cabSightSelections', cabSights)
  cabSights.push({ description: 'All', uuid: '' })
  return cabSights
})

const readLicenseHistory = async (elite_id: Array<string>, refresh = false) => {
  try {
    // get license id by elite_id
    console.trace('readLicenseHistory', elite_id)

    if (!elite_id.length) {
      expandableRowsLoading.value = false
      return
    }

    /* console.debug(
        'current',
        store.collections['License'].data.filter(
          x => elite_id.indexOf(x.elite_id) !== -1
        ),
        elite_id.filter(x => {
          if (!store.collections['License'].data.find(y => y.elite_id === x))
            return x
        })
      ) */
    booleanFields.value = []
    const payLoad = [
      {
        elite_id: !refresh
          ? elite_id.filter(x => {
              if (
                !store.collections['License'].data.find(y => y.elite_id === x)
              )
                return x
            })
          : elite_id
      }
    ]
    if (payLoad[0].elite_id.length) {
      const endpoint = 'bl/resptech/license/select'
      const results = await store.api.postToEndpoint(endpoint, payLoad)

      console.debug('readLicenseHistory', results)

      if (results.length) {
        // add results to store
        store.collections['License'].data = (
          store.collections['License'].data.filter(
            (x: { elite_id: string }) => elite_id.indexOf(x.elite_id) === -1
          ) || []
        ).concat(results)
      }
    }

    // get license history for all elite_ids
    licenseHistory.value = store.collections['License'].data.filter(
      (x): boolean => elite_id.indexOf(x.elite_id) !== -1
    )

    // console.log('current', licenseHistory.value)

    const licenses = /* current.concat(history) */ licenseHistory.value.map(
      x => {
        const json = x.value // JSON.parse(x.value)

        const flattened = flattenObj(
          Array.isArray(json)
            ? json.reduce((acc, cur) => {
                return { ...acc, ...cur }
              }, {})
            : json
        )
        // sort flattened object by key
        const sorted = Object.keys(flattened)
          .sort()
          .reduce((acc, key) => {
            return { ...acc, [key]: flattened[key] }
          }, {})

        // convert boolean true values to string true
        Object.keys(sorted).forEach((key: string) => {
          if (
            sorted[key] === true ||
            sorted[key] === 'true' ||
            sorted[key] === false ||
            sorted[key] === 'false'
          ) {
            console.debug('boool key', key)
            const camelCaseKey = key
            // delete sorted[key]
            if (booleanFields.value.indexOf(camelCaseKey) === -1)
              booleanFields.value.push(camelCaseKey)

            sorted[camelCaseKey] = !!sorted[key]
          }
        })

        if (x.value) sorted['License'] = x.value
        sorted['elite_id'] = x.elite_id

        const defaultValues = {
          Version: sorted['Product'] ? 1.01 : 1.0,
          Product: 'Centurion Elite'
        }

        // fill in missing keys with default values
        Object.keys(defaultValues).forEach(key => {
          if (!sorted[key]) sorted[key] = defaultValues[key]
        })
        return sorted
      }
    )

    // remove elements with empty license
    const finalLicenses = licenses.filter(x => isEmpty(x['License']) === false)
    // move boolean fields to end of object
    finalLicenses.forEach(x => {
      booleanFields.value.forEach(y => {
        const value = x[y]
        delete x[y]
        x[y] = value
      })
    })

    const deleteKeys = [
      'Signature',
      'Organization.Anywhere',
      'Organization.Name',
      'Organization.UUID',
      'elite_id',
      'Mac',
      'License'
    ]

    // get all unique keys for headers
    const headers = Array.from(
      new Set(
        finalLicenses
          .map(x => Object.keys(x).filter(x => deleteKeys.indexOf(x) === -1))
          .flat()
      )
    ).map(x => {
      return {
        key: x,
        title: x
      }
    })

    headers.push({ title: 'License', key: 'actions' })

    const centerHeaders = [
      'Number of Anywhere Buttons',
      'Number of Notifications',
      'Number of PCAlert Seats',
      'Number of PCDuress Seats',
      'Number of Sensors',
      'Version',
      'Modem Support',
      'SSO Support',
      'actions'
    ]
    // add align center to centerHeaders
    headers.forEach(x => {
      if (centerHeaders.indexOf(x.key) !== -1) x['align'] = 'center'
      // remove 'Number of' from key
      if (x.key.indexOf('Number of') !== -1) {
        x.title = x.title.replace('Number of', '')
      }
    })

    expandableData.value = finalLicenses as any
    expandableHeaders.value = headers as any
    expandableRowsLoading.value = false
    allFields.value = Object.keys(finalLicenses?.[0] || {})
  } catch (error) {
    console.error('Error in readLicenseHistory:', error)
    expandableRowsLoading.value = false
    // You might want to set an error state or show a notification to the user
    store.setSnack('Failed to read license history', {
      color: 'error',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
  }
}

const downloadLicense = (licenseJson: any) => {
  const license = licenseJson // JSON.parse(licenseJson)
  console.debug('downloadLicense', licenseJson)
  // create a blob of the data and initiate a download
  let stringifiedLicense = JSON.stringify(license)
    .replace('[', '')
    .replace(']', '')
    .replace('},{', '}{')
  stringifiedLicense = stringifiedLicense.replace(/\\n/g, '\n') // replace escaped newlines with actual newlines
  const blob = new Blob([stringifiedLicense], {
    type: 'text/plain;charset=utf-8'
  })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `license_${license?.['Mac'].replaceAll(':', '') || 'unknown'}_${
    license['Version']
  }_${license?.['Organization']?.['Name'] || 'Elite'}.json`.toLocaleLowerCase()
  document.body.appendChild(a)
  a.click()
  a.remove()
}

const headers = computed(() => {
  if (collection.value === 'Cab' || collection.value === 'Pet') {
    return [
      { key: 'Mac', title: 'Mac Address' },
      { key: 'AppVersion', title: 'AppVersion' },
      { key: 'Author.BuiltOn', title: 'Built On' },
      { key: 'BatteryPercent', title: 'Battery', align: 'center' },
      { key: 'IdpDevice.Environment Name', title: 'Environment' }
    ]
  } else if (collection.value === 'Anywhere') {
    const baseColumns = [
      { key: 'MostRecent.CabDevice.SensorNumber', title: 'Sensor Number' },
      { key: 'name', title: 'Name' },
      { key: 'activity_date', title: 'Activity Date' },
      { key: 'dsc', title: 'Description' },
      { key: 'mac', title: 'Mac Address' }
    ]
    const noOrgColumns = [{ key: 'organization_id', title: 'Organization' }]
    const orgColumns = [
      {
        key: 'CabDevice.BatteryPercent',
        title: 'Cab Battery',
        align: 'center'
      },
      { key: 'IsAlarmActive', title: 'Alarm Active', align: 'center' },
      { key: 'TestDelivered', title: 'Test Result', align: 'center' }
    ]
    return activeOrganization.value
      ? baseColumns.concat(orgColumns)
      : baseColumns.concat(noOrgColumns)
  } else if (collection.value === 'EntraUsers') {
    return [
      { key: 'id', title: 'ID' },
      { key: 'displayName', title: 'Display Name' },
      { key: 'givenName', title: 'First Name' },
      { key: 'surname', title: 'Last Name' },
      { key: 'userPrincipalName', title: 'User Principal Name' },
      { key: 'mail', title: 'Email' },
      { key: 'jobTitle', title: 'Job Title' },
      { key: 'department', title: 'Department' },
      { key: 'mobilePhone', title: 'Mobile Phone' },
      { key: 'officeLocation', title: 'Office Location' }
    ]
  } else {
    return expandableRows.value
      ? [{ title: '', key: 'data-table-expand' }].concat(
          store.collections[collection.value].headers
        )
      : store.collections[collection.value].headers
  }
})

const filterHeaders = {
  Elite: [
    'publickey',
    'username',
    'password',
    'backuppassword',
    'rootpassword',
    'entitytype_id_cloud_core',
    'entitytype_id_cloud_notification',
    'entitytype_id_cloud_webpagebase',
    'entitytype_id_dns_mqbroker',
    'entitytype_id_url_remaster',
    'organization_id'
  ],
  Envoy: [
    'publickey',
    'username',
    'password',
    'backuppassword',
    'rootpassword',
    'entitytype_id_cloud_core',
    'entitytype_id_cloud_notification',
    'entitytype_id_cloud_webpagebase',
    'entitytype_id_dns_mqbroker',
    'entitytype_id_url_remaster',
    'organization_id'
  ],
  Gateway: [
    'publickey',
    'username',
    'password',
    'backuppassword',
    'rootpassword',
    'entitytype_id_cloud_core',
    'entitytype_id_cloud_notification',
    'entitytype_id_cloud_webpagebase',
    'entitytype_id_dns_mqbroker',
    'entitytype_id_url_remaster',
    'organization_id'
  ],
  // regex for starts with "Details" ie "Details  Elite Configuration  Notification Text" or "Details  Sent At"
  //Anywhere: [/Details.*/, 'organization_id'],
  cabsight: ['Author.Service Account Type'],
  petsight: ['Author.Service Account Type'],
  license: ['Author.Service Account Type'],
  admin: ['Author.Service Account Type'],
  auth: ['Author.Service Account Type']
}

const addHeaders = {
  /* Elite: [{ title: 'Request License', key: 'action', align: 'center' }], */
  /* Anywhere: [{ key: "action", title: "Details", align: "center" }],
  Cab: [{ key: "action", title: "Details", align: "center" }], */
}

const filteredHeaders = computed(() => {
  if (typeof headers.value !== 'object' || headers.value.length === undefined)
    return []

  const collectionHeaders = store.collections[collection.value].headers

  /* console.warn('filteredHeaders', {
    collection: collection.value,
    headers: headers.value,
    collectionHeaders
  }) */

  const filteredHeaders = headers.value.filter((x: { key: any }) => {
    const filterKeys = filterHeaders[collection.value] || []
    const matchRegex = filterKeys.find((y: any) => x.key.match(y))
    return !filterKeys.includes(x.key) && !matchRegex
  })

  const collectionHeadersKeys = collectionHeaders.map((x: { key: any }) => {
    return { align: ' d-none', ...x }
  })

  const mergedHeaders = collectionHeadersKeys.concat(filteredHeaders)

  /* console.warn('filteredHeaders', collection, {
    collectionHeaders,
    filteredHeaders,
    mergedHeaders
  }) */

  return mergedHeaders
})

const selectedHeaders = computed({
  get: () => {
    if (collection.value === 'EntraUsers') {
      return [
        { key: 'displayName', title: 'Display Name' },
        { key: 'mail', title: 'Email' },
        { key: 'jobTitle', title: 'Job Title' },
        { key: 'department', title: 'Department' }
      ]
    }
    return store.getSelectedHeaders() || []
  },
  set: newHeaders => {
    store.setSelectedHeaders(newHeaders)
    saveUserColumnsSettings()
  }
})

const saveUserColumnsSettings = async () => {
  try {
    const settings = {
      selectedColumns: {
        [collection.value]: selectedHeaders.value.map(header => header.key)
      }
    }
    await saveUserSettings(settings)
  } catch (error) {
    console.error('Failed to save user column settings:', error)
    store.setSnack('Failed to save column settings', {
      color: 'error',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
  }
}

const loadUserColumnsSettings = async () => {
  try {
    const settings = await getUserSettings()
    if (
      settings &&
      settings.selectedColumns &&
      settings.selectedColumns[collection.value]
    ) {
      const savedColumns = settings.selectedColumns[collection.value]
      const newHeaders = filteredHeaders.value.filter(header =>
        savedColumns.includes(header.key)
      )
      store.setSelectedHeaders(newHeaders)
    }
  } catch (error) {
    console.error('Failed to load user column settings:', error)
  }
}

const finalHeaders = computed(() => {
  const finalHeaders = selectedHeaders.value.length
    ? selectedHeaders.value.map((x: { key: any; align: string }) => {
        // return x without align if set to d-none
        return x.align === ' d-none' ? { ...x, align: '' } : x
      })
    : filteredHeaders.value

  // if no active organization, add organization to headers if not already there
  if (!activeOrganization.value) {
    const orgAvailable = finalHeaders.findIndex(
      (x: { key: string }) => x.key === 'organization_id'
    )

    if (orgAvailable === -1) {
      finalHeaders.push({
        key: 'organization_id',
        title: 'Organization',
        align: 'center'
      })
    }
  }

  // merge itemActions with final headers
  const addAddHeaders = addHeaders[collection.value] || []
  if (itemActions.value && itemActions.value.length) {
    // check if addAddHeaders already has itemActions
    const hasItemActions = addAddHeaders.find(
      (x: { key: string }) => x.key === 'itemActions'
    )
    if (!hasItemActions)
      addAddHeaders.push({
        key: 'itemActions',
        // title is more button
        title: '',
        align: 'end',
        sortable: false
      })
  }

  finalHeaders.push(...addAddHeaders)
  return finalHeaders
})

const isDialogVisible = ref(false)
const license = ref({} as EliteLicense)

function extractInteger(s: string): number | null {
  const match = s.match(/\d+/)
  return match ? parseInt(match[0]) : null
}

const isAnywhereDialogVisible = ref(false)
const device = ref({} as Device)

const anywhereDetails = (anywhereObj: {
  name: any
  mac: any
  Mac: any
  firmware: any
  builtOn: any
  inserviceat: any
  activitydate: any
  organization_id: any
  id: any
}) => {
  // console.debug('anywhereDetails', anywhereObj)
  const dialogData = {
    name: anywhereObj.name,
    mac: anywhereObj.mac || anywhereObj.Mac,
    firmware: anywhereObj.firmware,
    builtOn: anywhereObj['Author.BuiltOn'] || '',
    inServiceAt: anywhereObj.inserviceat
      ? formatDate(anywhereObj.inserviceat)
      : '',
    activityDate: anywhereObj.activitydate
      ? formatDate(anywhereObj.activitydate)
      : '',
    organization: anywhereObj.organization_id
      ? getOrgName(anywhereObj.organization_id)
      : '',
    id: anywhereObj.id,
    organization_id: anywhereObj.organization_id
  }
  // console.log('refreshData', 'Anywhere Messages', 'anywhereDetails')
  store.api.refreshData('Anywhere Messages', {
    organization_id: anywhereObj.organization_id,
    anywhere_id: anywhereObj.id
  })

  // subscribe to anywhere messages
  /* store.broker.manualSubscribe({
    topic: `Org/${anywhereObj.organization_id}/Anywhere/mostRecent`,
    qos: 0
  }) */
  device.value = dialogData
  isAnywhereDialogVisible.value = true
}

// Helper functions
function formatDate(isoDate: string) {
  return format(parseISO(isoDate), 'MMM d, yyyy')
}

function getOrgName(orgId: any) {
  // Lookup organization name by id
  return store.collections['Organization'].data.find(
    (org: { id: any }) => org.id === orgId
  ).name
}

const batteryPercent = item => {
  // get battery percent from anywhere_messages
  const anywhereMessage = store.collections['Anywhere Messages'].data.find(
    (x: { id: any }) => x.id === item.id
  )

  const batteryPercent =
    anywhereMessage?.CabDevice?.BatteryPercent ||
    anywhereMessage?.BatteryPercent ||
    anywhereMessage?.Periodic?.CabDevice?.BatteryPercent ||
    anywhereMessage?.Periodic?.BatteryPercent ||
    item['BatteryPercent'] ||
    item['CabDevice.BatteryPercent'] ||
    null
  // console.debug("batteryPercent", item.anywhere_id, batteryPercent);
  return batteryPercent
}

const testResult = (item): { icon: string; tooltip: string } => {
  const errors = [] as Array<string>

  // check "IsCabConnected": true && "BatteryLevelSufficient": true,   "PermissionsValid": true,   "EliteConfigured": true,   "TestDelivered": true
  if (item['TestResults.IsCabConnected'] !== true)
    errors.push('Cab not connected')
  if (item['TestResults.BatteryLevelSufficient'] !== true)
    errors.push('Battery level insufficient')
  if (item['TestResults.PermissionsValid'] !== true)
    errors.push('Permissions invalid')
  if (item['TestResults.EliteConfigured'] !== true)
    errors.push('Elite not configured')
  if (item['TestResults.TestDelivered'] !== true)
    errors.push('Test not delivered')

  // console.debug("testResult", { errors, item, msg });

  // return json with icon and tooltip message
  return {
    icon: errors.length ? 'mdi-alert-circle' : 'mdi-check',
    tooltip: errors.length ? errors.join(', ') : 'Test successful'
  }
}

const alarmActive = (item: Alarm) => {
  try {
    // console.warn('alarmActive item', item)
    // get alarm active from anywhere_messages
    const anywhereMessage =
      store.collections['Anywhere Messages'].data.find(
        (x: { anywhere_id: any }) => x.anywhere_id === item.anywhere_id
      ) || {}

    // const alarmActive = anywhereMessage?.Alarm || item["Alarm"] || null;

    const alarmActive =
      findKey(anywhereMessage, 'Alarm.IsActive') ||
      findKey(item, 'Alarm.IsActive') ||
      null

    /* const alarmCleared =
      anywhereMessage?.Alarm?.ClearedAt ||
      item['Alarm']?.ClearedAt ||
      anywhereMessage?.Alarm?.Alarm?.ClearedAt ||
      item['Alarm']?.Alarm?.ClearedAt ||
      null */
    const alarmCleared =
      findKey(anywhereMessage, 'ClearedAt') ||
      findKey(item, 'ClearedAt') ||
      null

    // console.warn('alarmCleared', item.anywhere_id, alarmCleared)
    /* const alarmTriggeredAt =
      anywhereMessage?.Alarm?.TriggeredAt ||
      anywhereMessage?.Alarm?.Alarm?.TriggeredAt ||
      item['Alarm']?.Alarm?.TriggeredAt ||
      item['Alarm']?.TriggeredAt ||
      null */

    const alarmTriggeredAt =
      findKey(anywhereMessage, 'TriggeredAt') ||
      findKey(item, 'TriggeredAt') ||
      null

    const icon = alarmActive ? 'mdi-alert' : ''

    // (alarmActive && alarmCleared) || !alarmActive ? '' : 'mdi-alert'

    const result = {
      icon,
      tooltip: `Alarm triggered at ${alarmTriggeredAt}`,
      alarm: alarmActive
      // alarm: anywhereMessage?.Alarm || item['Alarm'] || null
    }

    // console.debug("alarmActive", item.anywhere_id, result, item, anywhereMessage);

    return result
  } catch (error) {
    console.error('alarmActive', error, item)
    return {
      icon: '',
      tooltip: '',
      alarm: null
    }
  }
}

const baseSearchKeys = computed(() => {
  /*   if (collection.value === "Cab") {
    return [
      { title: "Mac Address", key: "Mac" },
      { title: "Firmware", key: "Firmware" },
      { title: "Built On", key: "Author.BuiltOn" },
      { title: "Battery", key: "BatteryPercent" },
    ];
  } else if (collection.value === "Anywhere") {
    return [
      { title: "Mac Address", key: "mac" },
      { title: "Firmware", key: "firmware" },
      { title: "In Service At", key: "inserviceat" },
      { title: "Label", key: "CabDevice.Label" },
      { title: "Battery", key: "CabDevice.BatteryPercent" },
      { title: "Alarm Active", key: "AlarmActive" },
      { title: "Test Result", key: "TestDelivered" },
      { title: "Organization", key: "organization_id" },
    ];
  } else { */
  const baseKeys = store.collections[collection.value]?.headers || []

  return baseKeys.length
    ? baseKeys.map((x: { title: string; key: string }) => {
        return {
          title: x.title,
          key: x.key
        }
      })
    : []

  // }
})

const searchKeys = computed(() => {
  return [
    { title: 'All', key: '' },
    ...baseSearchKeys.value.sort((a: { title: string }, b: { title: string }) =>
      a.title > b.title ? 1 : -1
    )
  ] as Array<{
    title: string
    key: string
  }>
})

const selectedSearchKey = ref({ ...searchKeys.value[0] })

// watch anywhere_messages for changes and update ctollectionItemsByParentOrg
/* watch(
  () => store.collections["Anywhere Messages"].data,
  (newValue, oldValue) => {
    console.debug("anywhere_messages", {
      newValue,
      oldValue,
    });

    // if new value is array, loop through and check for changes to BatteryPercent

    if (typeof newValue === "object" && newValue.length) {
      newValue.forEach((x, index) => {
        if (
          x?.CabDevice?.BatteryPercent !==
          oldValue[index]?.CabDevice?.BatteryPercent
        ) {
          // refresh collection
          store.api.refreshData("Anywhere Messages");
          if (collection.value === "Anywhere") {
            store.api.refreshData("Anywhere");
          }
        }
      });
    }

    /* if (
      newValue[0].CabDevice.BatteryPercent !==
      newValue[1].CabDevice.BatteryPercent
    ) {
      // locate row in collectionItemsByParentOrg and update
      const index = getCollectionItemsByParentOrg.value.findIndex(
        (x) => x.id === newValue[0].anywhere_id
      );
      console.debug("index", index);
      if (index !== -1) {
        getCollectionItemsByParentOrg.value[index] = newValue[0];
      }
    } * /
  },
  { deep: true }
); */

// add/remove columns dialog
const isAddRemoveColumnsDialogVisible = ref(false)

const addRemoveColumns = () => {
  // console.debug('addRemoveColumns', { headers })
  isAddRemoveColumnsDialogVisible.value = true
}

const requestLicense = async (payLoad: any) => {
  try {
    console.debug('requestLicense', payLoad)
    const results = await store.api.postToAPI([payLoad])

    console.debug('results', results, results.length, payLoad)
    if (results.length) {
      const finalResults = JSON.parse(JSON.stringify(results))?.[0] || {}
      // console.debug('finalResults', !!finalResults[0].Success)
      if (finalResults[0].Success) {
        store.setSnack(`Successfully requested license`, {
          color: 'success',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: '#333333'
        })
        isDialogVisible.value = false

        // update license history
        console.debug('update license history', payLoad[1].elite_id)
        await readLicenseHistory([payLoad[1].elite_id], true)
      } else {
        throw new Error(finalResults[0].Message || 'Failed to request license')
      }
    } else {
      throw new Error('No response from API')
    }
  } catch (error) {
    console.error('Error in requestLicense:', error)
    store.setSnack(`Failed to request license: ${(error as Error).message}`, {
      color: 'error',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
  }
}

const isLocalDev = process.env.NODE_ENV === 'development'
</script>

<template>
  <addRemoveColumnsDialog
    v-model:isAddRemoveColumnsDialogVisible="isAddRemoveColumnsDialogVisible"
    :filteredHeaders="filteredHeaders"
    :headers="headers"
    :collection="collection"
  />

  <requestLicenseDialog
    v-if="collection === 'Elite'"
    v-model:isDialogVisible="isDialogVisible"
    :license="(license as FinalLicense)"
    @submit="requestLicense"
  />

  <requestFormFactorDialog
    v-if="collection === 'Cab'"
    v-model:isRequestFormFactorDialogVisible="isRequestFormFactorDialogVisible"
    :requestFormFactor="requestFormFactor"
  />

  <anywhereMessagesDialog
    v-model:isAnywhereDialogVisible="isAnywhereDialogVisible"
    :device="device"
  />

  <selectOrganizationDialog
    v-model:isSelectOrganizationDialogVisible="
      isSelectOrganizationDialogVisible
    "
    @organization-selected="handleOrganizationSelected"
  />

  <anywhereUpgradeDialog
    v-model:isAnywhereUpgradeDialogVisible="
      store.isAnywhereUpgradeDialogVisible
    "
  />

  <createOrganizationDialog
    v-model:isCreateOrganizationDialogVisible="
      isCreateOrganizationDialogVisible
    "
    :parentOrganizationId="newOrganizationParentId"
    :editMode="isEditMode"
    :organizationToEdit="organizationToEdit"
    @organization-created="store.api.refreshData('Organization')"
    @organization-updated="store.api.refreshData('Organization')"
  />

  <v-dialog v-model="store.isQRCodeGenerationDialogVisible" max-width="500px">
    <v-card>
      <v-card-title>Generate QR Codes</v-card-title>
      <v-card-text>
        {{ qrCodes.length }} QR codes will be generated and saved as a PDF.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="generatePDF">Generate PDF</v-btn>
        <v-btn
          color="secondary"
          @click="store.isQRCodeGenerationDialogVisible = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <createEliteDialog
    v-model:isCreateEliteDialogVisible="isCreateEliteDialogVisible"
    :editMode="isEditMode"
    :eliteToEdit="eliteToEdit"
    :organizationId="store.getActiveOrganizationId"
    @elite-created="store.api.refreshData('Elite')"
    @elite-updated="store.api.refreshData('Elite')"
  />

  <createEntraUserDialog
    v-model:isCreateEntraUserDialogVisible="isCreateEntraUserDialogVisible"
    :editMode="isEditMode"
    :entraUserToEdit="entraUserToEdit"
    @entra-user-created="refreshEntraUsers"
    @entra-user-updated="refreshEntraUsers"
  />
  <ProgressSnackbar
    :show="showProgressSnackbar"
    :text="progressSnackbarText"
    :total-items="progressSnackbarTotal"
    :current-item="progressSnackbarCurrent"
  />
  <Transition name="fade" mode="out-in">
    <VCard class="entities" :title="selectedCollectionTitle">
      <template #subtitle>
        <span>
          {{ store.collections[collection].selected.length }} /
          {{
            filteredData.length !== tableData.length
              ? `${filteredData.length} (${tableData.length})`
              : tableData.length
          }}
        </span>

        <VProgressLinear
          :model-value="
            (store.collections[collection].selected.length /
              filteredData.length) *
            100
          "
          color="primary"
          height="10"
          rounded
        >
        </VProgressLinear>
      </template>
      <template #append>
        <VRow>
          <VCol cols="1" md="1" class="label pr-8">
            <div class="column-chooser">
              <VTooltip bottom>
                <template v-slot:activator="{ props }">
                  <icon-btn
                    v-bind="props"
                    name="editColumns"
                    @click="addRemoveColumns"
                  >
                    <v-icon color="secondary" class="editColumns"
                      >tabler-columns-3</v-icon
                    >
                  </icon-btn>
                </template>
                <span>Add/Remove Columns</span>
              </VTooltip>
            </div>
          </VCol>

          <VCol cols="12" md="3" class="label" v-if="collection === 'Cab'">
            <VSelect
              :items="cabSightSelections"
              item-title="description"
              item-value="uuid"
              label="CabSight"
              v-model="store.broker.selectedCabSight"
              return-object
              item-color="secondary"
              class="pa-1"
            />
          </VCol>

          <VCol cols="4" :md="collection === 'Cab' ? 3 : 4" class="label">
            <VSelect
              :items="searchKeys"
              item-title="title"
              item-value="key"
              v-model="selectedSearchKey"
              dense
              outlined
              hide-details
              label="Search by"
              color="secondary"
              class="pa-1"
            />
          </VCol>
          <VCol cols="1" md="1" class="label pl-0 pr-10 nocaret">
            <div class="">
              <VSelect
                v-model="searchOperator"
                :items="[
                  { title: '**', value: 'contains' },
                  { title: '==', value: 'equals' },
                  { title: '.*', value: 'regex' }
                ]"
                item-title="title"
                item-value="value"
                hide-details
                dense
                outlined
                class="py-1"
                width="4em"
              />
            </div>
          </VCol>
          <VCol cols="4" :md="collection === 'Cab' ? 4 : 6" class="label px-0">
            <AppTextField
              v-model="search"
              density="compact"
              :placeholder="'Search'"
              :append-inner-icon="search ? 'tabler-x' : 'tabler-search'"
              @click:append-inner="search = ''"
              dense
              outlined
              class="pa-1"
              :error="!isValidRegex"
              :error-messages="!isValidRegex ? 'Invalid regex' : ''"
            >
              <template v-slot:prepend-inner>
                <VTooltip location="bottom" v-if="searchOperator === 'regex'">
                  <template v-slot:activator="{ props }">
                    <VIcon
                      v-bind="props"
                      icon="tabler-info-circle"
                      color="secondary"
                      size="small"
                      class="cursor-pointer"
                    />
                  </template>
                  <span>
                    Common regex examples:<br />
                    ^abc - Starts with 'abc'<br />
                    abc$ - Ends with 'abc'<br />
                    a.c - 'a' followed by any character, then 'c'<br />
                    [aeiou] - Any vowel<br />
                    \d{3} - Exactly 3 digits
                  </span>
                </VTooltip>
              </template>
            </AppTextField>
          </VCol>
        </VRow>
      </template>
      <VDivider />
      <div class="table-container" ref="tableContainer">
        <VDataTableVirtual
          ref="collectionTable"
          :id="collection"
          v-model="store.collections[collection].selected"
          :headers="finalHeaders"
          :items="filteredData"
          :item-value="props.itemValue"
          :item-title="props.itemTitle"
          :show-select="showSelect && !!itemActions.length"
          :expand-on-click="expandableRows"
          class="text-body-2 entity-table snap-scroll-table"
          :hover="true"
          density="compact"
          :fixed-header="true"
          @update:expanded="handleExpanded"
          :loading="
            props.showLoading && !store.collections[collection].data.length
          "
          :item-selectable="item => isRowSelectable(item)"
        >
          <template #no-data v-if="props.showLoading">
            <VCard class="mt-6 mb-6 pt-6 pb-6 elevation-0 no-data">
              <VProgressLinear
                indeterminate
                color="primary"
                class="no-data"
                height="8"
                reverse
                :rounded="true"
              />
            </VCard>
          </template>

          <!-- More button -->
          <template #header.itemActions>
            <span class="more-btn more2" v-if="actions.length">
              <IconMoreBtn
                name="collectionMoreBtn"
                :menu-list="actions"
                :onItemClick="handleClick"
              />
            </span>
          </template>

          <template #item.action="{ item }">
            <div
              class="mr-5"
              v-if="collection === 'Anywhere' || collection === 'Cab'"
            >
              <IconBtn
                name="anywhereDetails"
                @click="anywhereDetails(item as any)"
                color="secondary"
              >
                <v-icon>tabler-list-details</v-icon>
              </IconBtn>
            </div>
          </template>
          <!-- id -->
          <template #item.id="{ item }">
            <div class="mr-5">
              {{ (item as any).id }}
              <VTooltip
                name="item.id"
                location="top"
                transition="scale-transition"
                activator="parent"
              >
                <span>{{
                  store.getItemNameById(collection, (item as any).id)
                }}</span>
              </VTooltip>
            </div>
          </template>

          <template #item.displayName="{ item }">
            <div class="mr-5">{{ (item as any).displayName }}</div>
          </template>

          <template #item.userPrincipalName="{ item }">
            <div class="mr-5">{{ (item as any).userPrincipalName }}</div>
          </template>

          <template #item.mail="{ item }">
            <div class="mr-5">{{ (item as any).mail }}</div>
          </template>

          <template #item.jobTitle="{ item }">
            <div class="mr-5">{{ (item as any).jobTitle }}</div>
          </template>

          <template #item.department="{ item }">
            <div class="mr-5">{{ (item as any).department }}</div>
          </template>

          <!-- Organization -->
          <template #item.organization_id="{ item }">
            <div class="mr-5">
              <!-- lookup org name by id
          {{ store.getItemNameById('Organization', item.organization_id) }}-->
              {{ (item as any).organization_id }}
              <VTooltip
                name="item.organization_id"
                location="top"
                transition="scale-transition"
                activator="parent"
              >
                <span>
                  {{
                    store.getItemNameById(
                      'Organization',
                      (item as any).organization_id
                    )
                  }}
                </span>
              </VTooltip>
            </div>
          </template>
          <template #item.IsAlarmActive="{ item }">
            <div
              class="mr-5"
              v-if="alarmActive(item as Alarm).icon && activeOrganization"
            >
              <VTooltip
                name="item.IsAlarmActive"
                location="top"
                transition="scale-transition"
                activator="parent"
              >
                <span>{{ alarmActive(item as Alarm).tooltip }}</span>
              </VTooltip>
              <v-icon color="warning" size="large">{{
                alarmActive(item as Alarm).icon
              }}</v-icon>
            </div>
          </template>
          <template #item.Event="{ item }">
            <!--         <div class="mr-5" v-if="item.IsAlarmActive">
          <VTooltip
            name="item.Event"
            location="top"
            transition="scale-transition"
            activator="parent"
            v-if="item.IsAlarmActive"
          >
            <span>{{
              item.Event === 'Marked' ? 'Marked' : 'Alarm Active'
            }}</span>
          </VTooltip>

          <v-icon color="error" size="large" v-if="item.IsAlarmActive"
            >mdi-alert</v-icon
          >
        </div> -->
            <div class="mr-5">
              {{ (item as any).Event }}
            </div>
          </template>
          <template #item.TestDelivered="{ item }">
            <div
              class="mr-5"
              v-if="(item as any).hasOwnProperty('TestResults.TestDelivered')"
            >
              <VTooltip
                name="item.TestDelivered"
                location="top"
                transition="scale-transition"
                activator="parent"
              >
                <span>{{ testResult(item).tooltip }}</span>
              </VTooltip>
              <v-icon
                :color="
                  testResult(item).tooltip === 'Test successful'
                    ? 'success'
                    : 'error'
                "
                size="large"
                >{{ testResult(item).icon }}</v-icon
              >
            </div>
          </template>
          <template #item.CabDevice.BatteryPercent="{ item }">
            <div>
              <div
                class="mr-5"
                v-if="batteryPercent(item) && batteryPercent(item) < 20"
              >
                <VTooltip
                  name="item.CabDevice.BatteryPercent"
                  location="top"
                  transition="scale-transition"
                  activator="parent"
                >
                  <span>{{ batteryPercent(item) }}%</span>
                </VTooltip>
                <v-icon color="error" size="large">tabler-battery-1</v-icon>
              </div>
              <div
                class="mr-5"
                v-else-if="batteryPercent(item) && batteryPercent(item) <= 50"
              >
                <VTooltip
                  name="item.CabDevice.BatteryPercent"
                  location="top"
                  transition="scale-transition"
                  activator="parent"
                >
                  <span>{{ batteryPercent(item) }}%</span>
                </VTooltip>
                <v-icon color="warning" size="large">tabler-battery-2</v-icon>
              </div>
              <div
                class="mr-5"
                v-else-if="batteryPercent(item) && batteryPercent(item) <= 75"
              >
                <VTooltip
                  name="item.CabDevice.BatteryPercent"
                  location="top"
                  transition="scale-transition"
                  activator="parent"
                >
                  <span>{{ batteryPercent(item) }}%</span>
                </VTooltip>
                <v-icon color="warning" size="large">tabler-battery-3</v-icon>
              </div>
              <div
                class="mr-5"
                v-else-if="batteryPercent(item) && batteryPercent(item)"
              >
                <VTooltip
                  name="item.CabDevice.BatteryPercent"
                  location="top"
                  transition="scale-transition"
                  activator="parent"
                >
                  <span>{{ batteryPercent(item) }}%</span>
                </VTooltip>
                <v-icon color="success" size="large">tabler-battery-4</v-icon>
              </div>
            </div>
          </template>
          <template #item.BatteryPercent="{ item }">
            <div
              class="mr-5"
              v-if="batteryPercent(item) && batteryPercent(item) <= 25"
            >
              <VTooltip
                name="item.BatteryPercent"
                location="top"
                transition="scale-transition"
                activator="parent"
              >
                <span>{{ batteryPercent(item) }}%</span>
              </VTooltip>
              <v-icon color="error" size="large">tabler-battery-1</v-icon>
            </div>
            <div
              class="mr-5"
              v-else-if="batteryPercent(item) && batteryPercent(item) <= 50"
            >
              <VTooltip
                name="item.BatteryPercent"
                location="top"
                transition="scale-transition"
                activator="parent"
              >
                <span>{{ batteryPercent(item) }}%</span>
              </VTooltip>
              <v-icon color="warning" size="large">tabler-battery-2</v-icon>
            </div>
            <div
              class="mr-5"
              v-else-if="batteryPercent(item) && batteryPercent(item) <= 75"
            >
              <VTooltip
                name="item.BatteryPercent"
                location="top"
                transition="scale-transition"
                activator="parent"
              >
                <span>{{ batteryPercent(item) }}%</span>
              </VTooltip>
              <v-icon color="warning" size="large">tabler-battery-3</v-icon>
            </div>
            <div
              class="mr-5"
              v-else-if="batteryPercent(item) && batteryPercent(item)"
            >
              <VTooltip
                name="item.BatteryPercent"
                location="top"
                transition="scale-transition"
                activator="parent"
              >
                <span>{{ batteryPercent(item) }}%</span>
              </VTooltip>
              <v-icon color="success" size="large">tabler-battery-4</v-icon>
            </div>
          </template>
          <template #item.itemActions="{ item }: { item: any }">
            <div>
              <!-- on MoreBtn click, add item to selectedItems and call handleClick -->
              <IconMoreBtn
                :name="`itemActions${(item as any).id}`"
                :menu-list="itemActions"
                :onItemClick="value => handleClickInline(value, item)"
                :item="item.id"
                :disabled="
                  store.collections[collection].selected.length > 1 ||
                  !isRowSelectable(item)
                "
                class="moreBtn"
              >
                <v-icon color="black">tabler-dots-vertical</v-icon>
              </IconMoreBtn>
            </div>
          </template>

          <!-- Expanded Row Data -->
          <template v-slot:expanded-row="{ item }" v-if="expandableRows">
            <tr class="v-data-table__tr">
              <td :colspan="headers.length" class="px-0 py-0">
                <v-data-table-virtual
                  :headers="expandableHeaders"
                  :items="(expandableData as Array<{ elite_id: string }>).filter(x => x.elite_id === (item as any).id) || []"
                  class="text-body-2 entity-table expandable-row"
                  :hover="true"
                  density="compact"
                  :loading="expandableRowsLoading"
                >
                  <!-- check all fields for empty values and replace with x icon -->
                  <template
                    v-for="field in allFields"
                    v-slot:[`item.${field}`]="{ item }"
                  >
                    <div v-if="item[field]" class="mr-5">
                      {{ item[field] }}
                    </div>
                    <div v-else class="mr-5">
                      <v-icon color="secondary">mdi-close</v-icon>
                    </div>
                  </template>
                  <!-- replace booleanFields values with check icon -->
                  <template
                    v-for="field in booleanFields"
                    v-slot:[`item.${field}`]="{ item }"
                  >
                    <div v-if="item[field]" class="mr-5">
                      <v-icon color="success">mdi-check</v-icon>
                    </div>
                    <div v-else class="mr-5">
                      <v-icon color="error">mdi-close</v-icon>
                    </div>
                  </template>

                  <!-- Actions -->
                  <template #item.actions="{ item }">
                    <div class="mr-5">
                      <IconBtn
                        name="downloadLicense"
                        @click="downloadLicense((item as any).License as any)"
                      >
                        <v-icon color="secondary">tabler-file-download</v-icon>
                      </IconBtn>
                    </div>
                  </template>
                </v-data-table-virtual>
              </td>
            </tr>
          </template>
        </VDataTableVirtual>
      </div>
      <!-- <button @click="store.collections[collection].selected = []">
        clear
      </button> -->
    </VCard>
  </Transition>
</template>

<style lang="scss">
.refresh-button {
  margin: 16px;
}

// first nqcaret
.nocaret {
  .v-field__append-inner {
    display: none;
  }

  .v-select .v-select__selection-text {
    margin-block-end: -0.2em;
    margin-block-start: 0.1em;
    margin-inline-start: 0.5em;
  }
}

svg.iconify {
  block-size: 2em;
  inline-size: 2em;
}

.entity-table {
  max-block-size: calc(100vh - 275px);
}

.editColumns {
  cursor: pointer;
  margin-inline: -3px;
}

.search-input {
  display: inline-block;
  inline-size: 82%;
}

.moreBtn {
  float: inline-end;
}

.moreBtnDiv {
  position: absolute;
  inset-inline-end: -1.4em;
}

.moreBtnDiv .more-btn {
  background-color: transparent;
}

table table {
  border-width: 1px 1px 1px 13px;
  border-style: solid;
  border-color: #d0d0d0;
  margin-block: 1em;
  margin-inline: 0 1em;
  max-inline-size: fit-content;
}

.expandable-row {
  border-width: 1px 0 0;
  border-style: solid;
  border-color: #d8d7d8;
  padding-inline: 0;
  padding-inline-start: 0;
}

.v-table__wrapper table,
.e-virtualtable .e-table {
  min-inline-size: 100%;
}

.v-table__wrapper table th:first-child,
.v-table__wrapper table td:first-child,
.v-table__wrapper table th:last-child,
.v-table__wrapper table td:last-child,
.e-table .e-row .e-rowcell:first-child,
.e-table .e-row .e-rowcell:last-child {
  position: sticky !important;
  z-index: 1;
  background-color: rgba(
    var(--v-theme-grey-50),
    var(--v-high-emphasis-opacity)
  ) !important;
}

.v-table__wrapper table th:first-child,
.v-table__wrapper table td:first-child,
.e-virtualtable .e-table .e-row .e-rowcell:first-child {
  inset-inline-start: 0;
}

.v-table__wrapper table th:first-child,
.e-virtualtable .e-table th:first-child {
  z-index: 9999 !important;
  padding-block: 1.5em !important;
}

/* stylelint-disable-next-line no-descending-specificity */
.v-table__wrapper table th:last-child,
.v-table__wrapper table td:last-child,
.e-virtualtable .e-table .e-row .e-rowcell:last-child {
  inline-size: 1em !important;
  inset-inline-end: 0;
  padding-block: 0 !important;
  padding-inline: 1.75em !important;
}

.entity-table > .v-table__wrapper,
.entity-table > .e-virtualtable {
  overflow: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.entities.v-card .v-card-title {
  position: relative;
  inset-block-start: 0.35em;
}

.v-card-item .v-card-subtitle {
  margin-inline-end: 0.75em;
  padding-block: 0 0.25rem;
  padding-inline: 0;
}

.entities.v-card .v-card-subtitle {
  position: relative;
  inset-block-start: -0.5em;
  line-height: 1.25rem;

  span {
    float: inline-end;
  }
}

.column-chooser {
  margin-block-start: 0.375em;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}

/* .table-container {
  overflow: hidden;
  block-size: calc(100vh - 275px);
} */

/* .snap-scroll-table {
  block-size: 100%;
}

.snap-scroll-table .v-table__wrapper {
  scroll-snap-type: y mandatory;
}

.snap-scroll-table .v-data-table__tr {
  block-size: 80px;
  scroll-snap-align: end;
} */
</style>
