import type { DirectiveBinding } from 'vue'

export const vDraggable = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    // el.style.position = 'absolute'
    const header = el.querySelector('.v-card-title') as HTMLElement
    if (header) {
      header.style.cursor = 'move'
      header.onmousedown = (e: MouseEvent) => {
        const startX = e.clientX - el.offsetLeft
        const startY = e.clientY - el.offsetTop
        document.onmousemove = (e: MouseEvent) => {
          el.style.left = `${e.clientX - startX}px`
          el.style.top = `${e.clientY - startY}px`
        }
        document.onmouseup = () => {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    }
  }
}
