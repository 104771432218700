<script setup lang="ts">
import { vDraggable } from '@/directives/draggable'
import { store } from '@/stores/store'
import { v4 as uuidv4 } from 'uuid'
import { useTheme } from 'vuetify'
const vuetifyTheme = useTheme()

const currentTheme = vuetifyTheme.current.value.colors

interface Organization {
  Name: string
  UUID: string
}

interface License {
  id: string
  activity_account_id: string
  activity_date: string
  elite_id: string
  Version: number
  Product: string
  HardwareId: string
  SystemId: string
  EosLevel: string
  NumberOfNotifications: number
  NumberOfSensors: number
  NumberOfPCAlertSeats: number
  NumberOfPCDuressSeats: number
  NumberOfAnywhereButtons: number
  NumberOfRapidSOSEnabledSensors: number
  ModemSupport: boolean
  CloudSupport: boolean
  SSOSupport: boolean
  Organization: Organization
}

interface Props {
  license?: License
  isDialogVisible: boolean
}

type licensePayload = Array<any>

interface Emit {
  (e: 'update:isDialogVisible', value: boolean): void
  (e: 'submit', value: License): void
}

// HardwareId, SystemID, and Organization is passed in as props
const props = withDefaults(defineProps<Props>(), {
  license: () =>
    ({
      id: '',
      activity_account_id: '',
      elite_id: '',
      Version: 0,
      Product: '',
      HardwareId: '',
      SystemId: '',
      EosLevel: '',
      NumberOfNotifications: 0,
      NumberOfSensors: 0,
      NumberOfPCAlertSeats: 0,
      NumberOfPCDuressSeats: 0,
      NumberOfAnywhereButtons: 0,
      NumberOfRapidSOSEnabledSensors: 0,
      ModemSupport: false,
      CloudSupport: false,
      SSOSupport: false,
      Organization: {
        Name: '',
        UUID: ''
      }
    } as License)
})

const emit = defineEmits<Emit>()

const license = ref<License>(props.license)
console.log('license', license.value, props.license)

const versions = ref({} as any)
interface supportOptions {
  value: string[]
}

const supportOptions = ref([] as string[])

function convertObject(inputObj) {
  console.log('inputObj', inputObj)

  let convertedObj = {
    Version: 1.4,
    Product: 'Centurion Elite',
    'License Date': new Date().toISOString(),
    activity_account_id: inputObj.activity_account_id,
    id: inputObj.id,
    elite_id: inputObj.elite_id,
    Mac: inputObj.HardwareId,
    'System Id': getSystemId(),
    'EOS Level': 'EOS' + inputObj.EosLevel,
    'Number of Notifications': sensorLimit.value,
    'Number of Inovonics': parseInt(inputObj.NumberOfSensors),
    'Number of PCAlert Seats': parseInt(inputObj.NumberOfPCAlertSeats),
    'Number of PCDuress Seats': parseInt(inputObj.NumberOfPCDuressSeats),
    'Number of Anywhere Buttons': parseInt(inputObj.NumberOfAnywhereButtons),
    'Number of RapidSOS Enabled Sensors': parseInt(
      inputObj.NumberOfRapidSOSEnabledSensors
    ),
    'Modem Support': supportOptions.value.includes('ModemSupport')
      ? true
      : false,
    'Cloud Support': true, //supportOptions.value.includes("CloudSupport") ? true : false,
    'SSO Support': supportOptions.value.includes('SSOSupport') ? true : false,
    Organization: {
      Name: inputObj.Organization.Name,
      UUID: inputObj.Organization.UUID
    }
  }

  return convertedObj
}

function resetForm() {
  emit('update:isDialogVisible', false)
  license.value = structuredClone(toRaw(props.license))
}

function onFormSubmit() {
  const Method = license.value.id ? 'Modify License' : 'Create License'

  const payLoad = [
    {
      Method
    },
    {
      id: license.value.id || uuidv4(),
      activity_account_id: license.value.activity_account_id,
      activity_date: new Date().toISOString(),
      elite_id: license.value.elite_id
    }
  ] as licensePayload

  const value = convertObject(license.value)

  // remove id, activity_account_id, elite_id
  delete value.id
  delete value.activity_account_id
  delete value.elite_id

  payLoad[1].value = JSON.stringify(value)

  console.debug('onFormSubmit', payLoad)
  emit('submit', payLoad as any)
  resetForm()
}

const getSystemId = () => {
  // return systemid from organization if it exists, other return serialnumber
  const organization = store.collections['Organization'].data.find(
    (org: { id: string }) => org.id === license.value.Organization.UUID
  )
  if (organization.systemid) {
    console.log('systemid', organization.systemid)
    return organization.systemid
  } else {
    // look up serialnumber from elite
    const elite = store.collections['Elite'].data.find(
      (elite: { id: string }) => elite.id === license.value.elite_id
    )

    // if first character is a letter, replace it with a C, otherwise prepend a C
    const serialnumber =
      elite?.serialnumber && elite.serialnumber.charAt(0).match(/[a-z]/i)
        ? 'C' + elite?.serialnumber?.slice(1)
        : 'C' + elite?.serialnumber

    console.log('serialnumber', serialnumber)

    return serialnumber
  }
}

// set license values to props.license

watch(
  () => props.license,
  newVal => {
    console.log('props.license', newVal)
    supportOptions.value = []
    license.value.EosLevel = newVal.EosLevel.replace('EOS', '')
    license.value.HardwareId = newVal.HardwareId
    license.value.NumberOfNotifications = newVal.NumberOfNotifications
    license.value.NumberOfPCAlertSeats = newVal.NumberOfPCAlertSeats
    license.value.NumberOfPCDuressSeats = newVal.NumberOfPCDuressSeats
    license.value.NumberOfSensors = newVal.NumberOfSensors
    license.value.NumberOfAnywhereButtons = newVal.NumberOfAnywhereButtons
    license.value.NumberOfRapidSOSEnabledSensors =
      newVal.NumberOfRapidSOSEnabledSensors
    license.value.ModemSupport = newVal.ModemSupport
    license.value.CloudSupport = true // newVal.CloudSupport;
    license.value.SSOSupport = newVal.SSOSupport
    license.value.Organization = newVal.Organization
    license.value.SystemId = getSystemId()
    license.value.elite_id = newVal.elite_id
    license.value.activity_account_id = newVal.activity_account_id
    license.value.id = newVal.id
    if (newVal.Version) {
      console.log('versions', versions.value, newVal.Version)
      const version = Object.entries(versions.value).find(
        ([_, value]: [unknown, any]) => value.license === '1.4'
      )?.[0]

      console.log('version', version)
      license.value.Version = version ? parseFloat(version) : 0
    } else {
      const latestVersion = Object.keys(versions.value).pop()
      if (latestVersion !== undefined) {
        license.value.Version = parseFloat(latestVersion)
      } else {
        license.value.Version = 0
      }
    }

    if (newVal.SSOSupport) {
      supportOptions.value.push('SSOSupport')
    }

    if (newVal.CloudSupport || true) {
      supportOptions.value.push('CloudSupport')
    }
    if (newVal.ModemSupport) {
      supportOptions.value.push('ModemSupport')
    }
    updates()
    console.log('license', license.value, props.license)
  },
  { deep: true } // This ensures that changes to nested properties are detected
)

onMounted(() => {
  license.value = structuredClone(toRaw(props.license))
  console.log('license', license.value, props.license)

  fetch('./json/eos.json')
    .then(response => response.json())
    .then(json => {
      versions.value = json
      console.log(json)
      license.value.Version = 0 as number
      license.value.NumberOfSensors = 0 as number
      license.value.NumberOfPCAlertSeats = 0 as number
      license.value.NumberOfPCDuressSeats = 0 as number
      license.value.NumberOfAnywhereButtons = 0 as number
      license.value.NumberOfRapidSOSEnabledSensors = 0 as number
    })

  updates()
})

const sensorLimit = computed(() => {
  return parseInt(license.value.EosLevel) * 1 * 5 || 0
})

const totalNumberOf = computed(() => {
  return (
    Number(license.value.NumberOfSensors) +
    /*  Number(license.value.NumberOfPCAlertSeats) + */
    Number(license.value.NumberOfPCDuressSeats) +
    Number(license.value.NumberOfAnywhereButtons)
  )
})

// rule that checks if the total number of sensors is greater than the sensor limit
const rules = [
  v => {
    console.log(
      'rules',
      v,
      totalNumberOf.value,
      sensorLimit.value * 1,
      updateCounters()
    )
    return totalNumberOf.value <= sensorLimit.value || 'Maximum exceeded'
  }
]

const updateCounters = () => {
  const counter = document.querySelectorAll(
    '.v-counter'
  ) as NodeListOf<HTMLElement>

  const innerTextContent =
    totalNumberOf.value.toString() +
    ' / ' +
    (
      sensorLimit.value -
      totalNumberOf.value +
      totalNumberOf.value * 1
    ).toString()

  counter.forEach(element => {
    element.innerText = innerTextContent
    if (totalNumberOf.value > sensorLimit.value) {
      element.style.display = 'block'
      element.style.color = 'red'
    } else {
      element.style.display = 'block'
      element.style.color = '#6c757d'
    }
  })
  console.log('counter', counter, innerTextContent)
  return innerTextContent.split(' / ')

  /* const messages = document.querySelectorAll(".v-messages") as NodeListOf<HTMLElement>;
  messages.forEach((element) => {
    console.log("element", element.innerHTML);
    if (totalNumberOf.value <= sensorLimit.value) {
      element.innerHTML = "";
    } else if (totalNumberOf.value > sensorLimit.value && element.innerHTML === "") {
      const system_added = document.querySelector(".v-messages__message");
      console.log("system_added", system_added);
      if (!system_added) {
        const messageDiv = document.createElement("div");
        messageDiv.classList.add("message-added");
        messageDiv.innerText = "Maximum exceeded";
        element.appendChild(messageDiv);
      }
    } else if (totalNumberOf.value > sensorLimit.value) {
      const system_added = document.querySelector(".v-messages__message");
      console.log("system_added", system_added);
      if (system_added) {
        const added = element.querySelector(".message-added");
        // delete added message
        if (added) {
          added.remove();
        }
      }
    }
  }); */
}

const updates = () => {
  console.log('updates')
  return updateCounters()
}

// run updates when all fields are filled
watch(
  () => license.value,
  newVal => {
    console.log('license', newVal)
    updates()
  },
  { deep: true } // This ensures that changes to nested properties are detected
)
</script>

<template>
  <VDialog
    width="auto"
    max-width="800"
    :model-value="props.isDialogVisible"
    @update:model-value="val => $emit('update:isDialogVisible', val)"
  >
    <VCard
      v-if="props.license"
      class="pa-sm-5 pa-3"
      :title="license.id ? 'Request License Upgrade' : 'Request New License'"
      :subtitle="license.Organization.Name + '&nbsp; | ' + license.elite_id"
      v-draggable
    >
      <!-- 👉 Dialog close btn -->
      <DialogCloseBtn @click="$emit('update:isDialogVisible', false)" />

      <VCardText>
        <!-- 👉 Form -->
        <VForm class="mt-4" @submit.prevent="onFormSubmit">
          <VRow>
            <VCol cols="6" md="4" v-show="false">
              <AppSelect
                v-model="license.Version"
                :items="Object.keys(versions)"
                placeholder="Select Version"
                label="Version"
                class="select"
              />
            </VCol>

            <VCol cols="2" md="2">
              <AppTextField
                v-model="license.EosLevel"
                label="EOS Level"
                prefix="EOS"
                variant="dense"
                @blur="
                  license.EosLevel = license.EosLevel.replace('EOS', '')
                    .toString()
                    .padStart(4, '0')
                "
              />
            </VCol>

            <VCol cols="2" md="2">
              <AppTextField
                v-model="license.NumberOfSensors"
                label="Sensors"
                type="number"
                :counter="sensorLimit"
                :rules="rules"
                :disabled="sensorLimit === 0"
                @focus="updates"
              />
            </VCol>
            <VCol cols="2" md="2">
              <AppTextField
                v-model="license.NumberOfAnywhereButtons"
                label="Anywhere Buttons"
                type="number"
                :counter="sensorLimit"
                :rules="rules"
                :disabled="
                  sensorLimit === 0 ||
                  versions[license.Version]?.CloudSupport === false
                "
              />
            </VCol>
            <VCol cols="2" md="2">
              <AppTextField
                v-model="license.NumberOfPCDuressSeats"
                label="PCDuress Seats"
                type="number"
                :counter="sensorLimit"
                :rules="rules"
                :disabled="sensorLimit === 0"
              />
            </VCol>
            <VCol cols="2" md="2">
              <AppTextField
                v-model="license.NumberOfPCAlertSeats"
                label="PCAlert Seats"
                type="number"
              />
            </VCol>
            <VCol cols="2" md="2">
              <AppTextField
                v-model="license.NumberOfRapidSOSEnabledSensors"
                label="RapidSOS Enabled"
                type="number"
              />
            </VCol>

            <VCol cols="12" md="12" class="checks">
              <VCheckbox
                v-model="supportOptions"
                label="Modem Support"
                value="ModemSupport"
                :disabled="versions[license.Version]?.ModemSupport === false"
                color="success"
              />

              <VCheckbox
                v-model="supportOptions"
                label="Cloud Support"
                color="success"
                value="CloudSupport"
                v-show="false"
              />

              <VCheckbox
                v-model="supportOptions"
                label="SSO Support"
                color="info"
                value="SSOSupport"
                :disabled="versions[license.Version]?.SSOSupport === false"
              />
            </VCol>

            <!-- 👉 Submit and Cancel button -->
            <VCol cols="12" class="text-center">
              <VBtn
                type="submit"
                class="me-3"
                :disabled="totalNumberOf > sensorLimit"
              >
                submit
              </VBtn>

              <VBtn variant="tonal" color="secondary" @click="resetForm">
                Cancel
              </VBtn>
            </VCol>
          </VRow>
        </VForm>
      </VCardText>
    </VCard>
  </VDialog>
</template>
<style scoped>
.checks {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.v-text-field__suffix {
  font-size: 0.8em !important;
}

.readonly {
  border: none !important;
}

.v-col,
.v-col-md-2,
.v-col-md-3,
.v-col-md-4,
.v-col-6 {
  margin-block-start: -5px;
  padding-block-start: 0;
}

.app-text-field {
  min-inline-size: 7em;
}
</style>

<style>
.v-list-item--active {
  background-color: #e3e3e3 !important;
}

.message-added {
  color: red;
}

/* .v-messages__message {
  display: none;
} */

.v-counter {
  display: block !important;
}
</style>
