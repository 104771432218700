export default [
  /*  {
    title: 'Home',
    to: { name: 'index' },
    icon: { icon: 'tabler-smart-home' },
  },

 {
     title: 'MQTT Explorer',
     to: { name: 'mqtt-page' },
     icon: { icon: 'tabler-access-point' },
   },
   {
     title: 'Alert!',
     to: { name: 'alert' },
     icon: { icon: 'tabler-alert-triangle' },
   },
   {
     title: 'Text to Speech',
     to: { name: 'text2speech' },
     icon: { icon: 'mdi-account-voice' },
   }, */
];
