<script setup lang="ts">
const { y } = useWindowScroll()

function scrollToTop () {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>

<template>
  <VScaleTransition class="-top d-print-none">
    <VBtn v-show="y > 200" icon density="comfortable" @click="scrollToTop">
      <VIcon size="22" icon="tabler-arrow-up" />
    </VBtn>
  </VScaleTransition>
</template>

<style lang="scss">
.-top {
  position: fixed !important;
  z-index: 999;
  inset-block-end: 2em;
  inset-inline-end: 25px;
  opacity: 0.75;
  transform-origin: center;
}
</style>
