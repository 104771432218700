<script setup lang="ts">
import { vDraggable } from '@/directives/draggable'
import {
  addUserToGroup,
  createEntraUser,
  getEntraGroups,
  getEntraUsers,
  getUserGroups,
  updateEntraUser
} from '@/services/graphApiService'
import { store } from '@/stores/store'
import { computed, onMounted, ref, watch } from 'vue'
import { mask } from 'vue-the-mask'
import { VCol } from 'vuetify/lib/components/index.mjs'
const vMask = mask

const isSubmitting = ref(false)

const props = defineProps({
  isCreateEntraUserDialogVisible: Boolean,
  editMode: Boolean,
  entraUserToEdit: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits([
  'update:isCreateEntraUserDialogVisible',
  'entra-user-created',
  'entra-user-updated'
])

const entraUser = ref({
  id: '',
  displayName: '',
  givenName: '',
  surname: '',
  userPrincipalName: '',
  mailNickname: '',
  mail: '',
  jobTitle: '',
  department: '',
  mobilePhone: '',
  officeLocation: '',
  passwordProfile: {
    forceChangePasswordNextSignIn: true,
    password: ''
  },
  groupId: ''
})

const resetForm = () => {
  entraUser.value = {
    id: '',
    displayName: '',
    givenName: '',
    surname: '',
    userPrincipalName: '',
    mailNickname: '',
    mail: '',
    jobTitle: '',
    department: '',
    mobilePhone: '',
    officeLocation: '',
    passwordProfile: {
      forceChangePasswordNextSignIn: true,
      password: ''
    },
    groupId: ''
  }
}

const emailDomain = ref('')
const availableGroups = ref<Group[]>([])
interface Group {
  id: string
  displayName: string
}

const userGroups = ref<Group[]>([])
const currentGroup = ref<string>('None')

const fetchGroups = async () => {
  try {
    const groups = await getEntraGroups()
    availableGroups.value = [
      { id: 'None', displayName: 'None' },
      ...groups.map(group => ({
        ...group,
        displayName: group.displayName.replace('Headquarters ', '')
      }))
    ]
  } catch (error) {
    console.error('Error fetching Entra groups:', error)
    store.setSnack('Failed to fetch Entra groups', {
      color: 'error',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
  }
}

const fetchUserGroups = async () => {
  if (props.editMode && entraUser.value.id) {
    try {
      userGroups.value = [] // Set to empty array to indicate loading
      const groups = await getUserGroups(entraUser.value.id)
      userGroups.value = groups
      console.log('Fetched user groups:', userGroups.value)

      // Set currentGroup based on user's group membership
      if (userGroups.value.length === 1) {
        currentGroup.value = userGroups.value[0].id
        entraUser.value.groupId = currentGroup.value
      } else {
        currentGroup.value = 'None'
        entraUser.value.groupId = 'None'
      }
    } catch (error) {
      console.error('Error fetching user groups:', error)
      userGroups.value = [] // Set to empty array on error
      currentGroup.value = 'None'
      entraUser.value.groupId = 'None'
      store.setSnack('Failed to fetch user groups', {
        color: 'error',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'white'
      })
    }
  }
}

onMounted(() => {
  fetchGroups()
  if (props.editMode) {
    fetchUserGroups()
  }
})

watch(
  () => props.editMode,
  newValue => {
    if (newValue) {
      fetchUserGroups()
    } else {
      currentGroup.value = 'None'
      entraUser.value.groupId = 'None'
    }
  }
)

watch(
  () => entraUser.value.id,
  newValue => {
    if (newValue && props.editMode) {
      fetchUserGroups()
    }
  }
)

watch(
  () => entraUser.value.mail,
  newValue => {
    const domainMatch = newValue.match(/@(.+)$/)
    if (domainMatch) {
      emailDomain.value = domainMatch[1]
    } else {
      emailDomain.value = ''
    }
  }
)

watch(
  () => props.entraUserToEdit,
  newValue => {
    if (newValue && Object.keys(newValue).length > 0) {
      entraUser.value = {
        id: newValue.id || '',
        displayName: newValue.displayName || '',
        givenName: newValue.givenName || '',
        surname: newValue.surname || '',
        userPrincipalName: newValue.userPrincipalName || '',
        mailNickname: newValue.mailNickname || '',
        mail: newValue.mail || '',
        jobTitle: newValue.jobTitle || '',
        department: newValue.department || '',
        mobilePhone: newValue.mobilePhone || '',
        officeLocation: newValue.officeLocation || '',
        passwordProfile: {
          forceChangePasswordNextSignIn:
            newValue.passwordProfile?.forceChangePasswordNextSignIn ?? true,
          password: '' // Clear password when editing
        },
        groupId: newValue.groupId || ''
      }
    } else {
      resetForm()
    }
  },
  { deep: true, immediate: true }
)

watch(
  () => props.isCreateEntraUserDialogVisible,
  newValue => {
    if (newValue && !props.editMode) {
      resetForm()
    }
  }
)

const refreshEntraUsers = async () => {
  try {
    const users = await getEntraUsers()
    if (Array.isArray(users)) {
      store.collections['EntraUsers'].data = users
    } else {
      throw new Error('Invalid response from getEntraUsers')
    }
  } catch (error) {
    console.error('Error fetching Entra users:', error)
    store.setSnack('Failed to refresh Entra users', {
      color: 'error',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
  }
}

const requiredField = (v: string) => !!v || 'This field is required'
const emailField = (v: string) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailPattern.test(v) || 'Invalid email format'
}

const isFormValid = computed(() => {
  if (!entraUser.value.givenName || !entraUser.value.surname) return false
  if (!props.editMode) {
    if (
      !entraUser.value.userPrincipalName ||
      !entraUser.value.mail ||
      (!isExternalUser.value && !entraUser.value.passwordProfile.password)
    )
      return false
    if (!emailField(entraUser.value.mail)) return false
  }
  return true
})

const suggestedUPN = computed(() => {
  if (
    entraUser.value.givenName &&
    entraUser.value.surname &&
    emailDomain.value
  ) {
    const firstName = entraUser.value.givenName
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '')
    const lastName = entraUser.value.surname
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '')
    return `${firstName}.${lastName}@${emailDomain.value}`
  }
  return ''
})

const isExternalUser = computed(() => {
  return !entraUser.value.mail.endsWith('@resptechltd.onmicrosoft.com')
})

const updateDisplayName = computed(() => {
  if (entraUser.value.givenName && entraUser.value.surname) {
    entraUser.value.displayName = `${entraUser.value.givenName} ${entraUser.value.surname}`
  }
})

watch(
  () => suggestedUPN.value,
  newValue => {
    if (!props.editMode && !entraUser.value.userPrincipalName) {
      entraUser.value.userPrincipalName = newValue
    }
  }
)

const saveEntraUser = async () => {
  isSubmitting.value = true
  try {
    console.log('Starting saveEntraUser function')
    if (!isFormValid.value) {
      throw new Error('Please fill in all required fields correctly')
    }

    let result
    if (props.editMode) {
      console.log('Edit mode: Updating existing user')
      if (!entraUser.value.id) {
        throw new Error('User ID is not available for update')
      }
      // Remove password from update payload
      const { passwordProfile, ...updateData } = entraUser.value
      console.log('Update data:', JSON.stringify(updateData, null, 2))
      try {
        result = await updateEntraUser(entraUser.value.id, updateData)
      } catch (updateError) {
        console.error('Error in updateEntraUser:', updateError)
        throw updateError
      }
    } else {
      console.log('Create mode: Creating new user')
      // Ensure all required fields are present for new user creation
      const newUserData: any = {
        accountEnabled: true,
        displayName: entraUser.value.displayName,
        givenName: entraUser.value.givenName,
        surname: entraUser.value.surname,
        userPrincipalName: suggestedUPN.value, // Set UPN to the suggested value
        mailNickname: suggestedUPN.value.split('@')[0], // Set mailNickname
        mail: entraUser.value.mail,
        passwordProfile: {
          ...entraUser.value.passwordProfile,
          forceChangePasswordNextSignIn: true
        }
      }

      // Only include non-empty optional fields
      if (entraUser.value.jobTitle)
        newUserData.jobTitle = entraUser.value.jobTitle
      if (entraUser.value.department)
        newUserData.department = entraUser.value.department
      if (entraUser.value.mobilePhone)
        newUserData.mobilePhone = entraUser.value.mobilePhone
      if (entraUser.value.officeLocation)
        newUserData.officeLocation = entraUser.value.officeLocation

      console.log('New user data:', JSON.stringify(newUserData, null, 2))
      try {
        result = await createEntraUser(newUserData)
      } catch (createError) {
        console.error('Error in createEntraUser:', createError)
        throw createError
      }
    }
    console.log('User operation result:', JSON.stringify(result, null, 2))

    if (!result || (!result.id && !result.invitedUser)) {
      throw new Error(
        'Failed to create or update user: No valid result returned'
      )
    }

    const userId = result.id || result.invitedUser.id

    // Assign user to group if a group is selected
    if (entraUser.value.groupId && entraUser.value.groupId !== 'None') {
      console.log(
        `Assigning user ${userId} to group ${entraUser.value.groupId}`
      )
      try {
        await assignUserToGroup(userId, entraUser.value.groupId)
      } catch (assignError) {
        console.error('Error in assignUserToGroup:', assignError)
        // Don't throw here, continue with the process
      }
    } else {
      console.log('No group assignment needed')
    }

    console.log('Refreshing Entra users list')
    try {
      await refreshEntraUsers()
    } catch (refreshError) {
      console.error('Error in refreshEntraUsers:', refreshError)
      // Don't throw here, continue with the process
    }

    emit(props.editMode ? 'entra-user-updated' : 'entra-user-created')
    emit('update:isCreateEntraUserDialogVisible', false)
    resetForm()
    store.setSnack(
      `Entra User ${
        props.editMode ? 'updated' : result.invitedUser ? 'invited' : 'created'
      } successfully`,
      {
        color: 'success',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'white'
      }
    )
  } catch (error) {
    console.error(
      `Error ${props.editMode ? 'updating' : 'creating'} Entra User:`,
      error
    )
    let errorMessage = 'An unknown error occurred'

    if (error instanceof Error) {
      errorMessage = error.message
      console.error('Error name:', error.name)
      console.error('Error message:', error.message)
      console.error('Error stack:', error.stack)
    }

    console.error(
      'Full error object:',
      JSON.stringify(error, Object.getOwnPropertyNames(error))
    )

    // Additional error logging
    if ((error as any).isAxiosError && (error as any).response) {
      console.error('Error response:', (error as any).response)
      if (error instanceof Error && (error as any).response) {
        console.error('Error response data:', (error as any).response.data)
      }
      if (error instanceof Error && (error as any).response) {
        console.error('Error response status:', (error as any).response.status)
      }
      if ((error as any).response) {
        console.error(
          'Error response headers:',
          (error as any).response.headers
        )
      }
    }
    if (error instanceof Error && (error as any).request) {
      console.error('Error request:', (error as any).request)
    }
    if ((error as any).config) {
      if (error instanceof Error && (error as any).config) {
        console.error('Error config:', (error as any).config)
      }
    }

    store.setSnack(
      `Failed to ${
        props.editMode ? 'update' : 'create'
      } Entra User: ${errorMessage}`,
      {
        color: 'error',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'white'
      }
    )
  } finally {
    isSubmitting.value = false
  }
}

const assignUserToGroup = async (userId, groupId) => {
  try {
    const result = await addUserToGroup(userId, groupId)
    if (result) {
      console.log(`User ${userId} assigned to group ${groupId} successfully`)
    } else {
      console.log(`User ${userId} is already a member of group ${groupId}`)
    }
  } catch (error) {
    console.error('Error assigning user to group:', error)
    store.setSnack('Failed to assign user to group', {
      color: 'error',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
    // Don't re-throw the error, just log it
  }
}
</script>

<template>
  <template>
    <VDialog
      :model-value="isCreateEntraUserDialogVisible"
      @update:model-value="
        $emit('update:isCreateEntraUserDialogVisible', $event)
      "
      max-width="500px"
      persistent
    >
      <VCard v-draggable :class="{ 'cursor-wait': isSubmitting }">
        <!-- 👉 Dialog close btn -->
        <VIcon
          class="icon-close"
          @click="$emit('update:isCreateEntraUserDialogVisible', false)"
          icon="tabler-x"
        />

        <VCardTitle>{{ editMode ? 'Edit' : 'Create' }} Entra User</VCardTitle>
        <VCardText>
          <VForm @submit.prevent="saveEntraUser">
            <VRow>
              <VCol cols="6">
                <AppTextField
                  v-model="entraUser.givenName"
                  label="First Name"
                  :rules="[requiredField]"
                  required
                  @change="updateDisplayName"
                />
              </VCol>
              <VCol cols="6">
                <AppTextField
                  v-model="entraUser.surname"
                  label="Last Name"
                  :rules="[requiredField]"
                  required
                  @change="updateDisplayName"
                />
              </VCol>
            </VRow>

            <VRow>
              <VCol cols="12">
                <AppTextField
                  v-model="entraUser.displayName"
                  label="Display Name"
                  :rules="[requiredField]"
                  required
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <AppTextField
                  v-model="entraUser.mail"
                  label="Email"
                  type="email"
                  :rules="[requiredField, emailField]"
                  required
                  autocomplete="new-email"
                />
              </VCol>
            </VRow>

            <VRow v-if="editMode">
              <VCol cols="12">
                <AppTextField
                  v-model="entraUser.userPrincipalName"
                  label="User Principal Name"
                  readonly
                  disabled
                >
                  <template v-slot:append-inner>
                    <VTooltip location="top">
                      <template #activator="{ props }">
                        <VIcon
                          v-bind="props"
                          icon="mdi-information-outline"
                          color="secondary"
                          class="cursor-pointer"
                        />
                      </template>
                      <span>User Principal Name cannot be changed</span>
                    </VTooltip>
                  </template>
                </AppTextField>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="6">
                <AppTextField
                  v-model="entraUser.jobTitle"
                  label="Job Title (optional)"
                />
              </VCol>
              <VCol cols="6">
                <AppTextField
                  v-model="entraUser.department"
                  label="Department (optional)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="6">
                <AppTextField
                  v-model="entraUser.mobilePhone"
                  label="Mobile Phone (optional)"
                  v-mask="'(###) ###-####'"
                />
              </VCol>
              <VCol cols="6">
                <AppTextField
                  v-model="entraUser.officeLocation"
                  label="Office Location (optional)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <AppSelect
                  v-model="entraUser.groupId"
                  :items="availableGroups"
                  label="Role"
                  placeholder="Select Role"
                  item-title="displayName"
                  item-value="id"
                >
                  <template v-slot:prepend-item>
                    <VListItem
                      v-if="editMode"
                      :title="`Current: ${
                        currentGroup === 'None'
                          ? 'None'
                          : (
                              availableGroups.find(g => g.id === currentGroup)
                                ?.displayName || 'Unknown'
                            ).replace('Headquarters ', '')
                      }`"
                      disabled
                    />
                    <VDivider class="mt-2 mb-2"></VDivider>
                  </template>
                </AppSelect>
              </VCol>
            </VRow>
            <template v-if="!editMode && !isExternalUser">
              <VRow>
                <VCol>
                  <AppTextField
                    v-model="entraUser.passwordProfile.password"
                    label="Initial Password"
                    type="password"
                    :rules="[requiredField]"
                    required
                    autocomplete="new-password"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VCheckbox
                    v-model="
                      entraUser.passwordProfile.forceChangePasswordNextSignIn
                    "
                    label="Force password change at next sign-in"
                  />
                </VCol>
              </VRow>
            </template>
            <VRow>
              <VCol cols="12" class="text-center">
                <VBtn
                  variant="flat"
                  color="primary"
                  @click="$emit('update:isCreateEntraUserDialogVisible', false)"
                >
                  Cancel
                </VBtn>
                <VBtn
                  variant="flat"
                  color="primary"
                  @click="saveEntraUser"
                  :disabled="!isFormValid"
                  :class="{ 'cursor-wait': isSubmitting }"
                >
                  {{ editMode ? 'Update' : 'Create' }}
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VDialog>
  </template>
</template>
<style lang="scss" scoped>
form .v-row {
  margin-block-start: 0 !important;
}

.icon-close {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  inset-block-start: 0.75em;
  inset-inline-end: 0.95em;
}

.v-card-title {
  margin-block-end: -0.75em;
  padding-block: 0.95em 0;
  padding-inline: 1.25em 0;
}

.cursor-wait,
.cursor-wait:hover {
  cursor: wait !important;
}

.v-btn.cursor-wait,
.v-btn.cursor-wait:hover {
  cursor: wait !important;
}
</style>
