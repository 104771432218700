<script setup lang="ts">
import SnackBar from '@/components/SnackBar.vue'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import { useTheme } from 'vuetify'
import ReloadPWA from './components/ReloadPWA.vue'
// import InstallPrompt from "./components/InstallPrompt.vue";

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
  handleSkinChanges
} = useThemeConfig()

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()
handleSkinChanges()
</script>

<template>
  <ReloadPWA />
  <!-- <InstallPrompt v-if="showInstallPrompt" @install="installApp" @decline="declineInstall" /> -->
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp
      :style="`--v-global-theme-primary: ${hexToRgb(
        global.current.value.colors.primary
      )}`"
    >
      <RouterView />
      <ScrollToTop />
      <SnackBar />
    </VApp>
  </VLocaleProvider>
</template>

<style lang="scss">
@media (max-width: 600px) {
  .layout-page-content {
    margin-block-start: 0.75rem !important;
    padding-inline: 0.75rem !important;
  }

  .layout-navbar,
  .layout-footer {
    padding-inline: 0.75rem !important;
  }
}
</style>
