/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */

const userRole = 'Admin' // store.authStore.userRole

// const isUserLoggedIn = () => store.authStore.isAuthenticated;
// const isUserLoggedIn = () => true;

// export { isUserLoggedIn, userRole };
export function isUserLoggedIn() {
  return !!(localStorage.getItem('userData') && localStorage.getItem('accessToken'))
}
