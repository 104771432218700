const __pages_import_0__ = () => import("/src/pages/components/DashboardOrganizations.vue");
const __pages_import_1__ = () => import("/src/pages/components/DashboardOrganizationDetail.vue");
const __pages_import_2__ = () => import("/src/pages/components/DashboardCollections.vue");
const __pages_import_3__ = () => import("/src/pages/mqtt-page.vue");
const __pages_import_4__ = () => import("/src/pages/login.vue");
import __pages_import_5__ from "/src/pages/index.vue";
const __pages_import_6__ = () => import("/src/pages/[...all].vue");

const routes = [{"name":"components-DashboardOrganizations","path":"/components/dashboardorganizations","component":__pages_import_0__,"props":true},{"name":"components-DashboardOrganizationDetail","path":"/components/dashboardorganizationdetail","component":__pages_import_1__,"props":true},{"name":"components-DashboardCollections","path":"/components/dashboardcollections","component":__pages_import_2__,"props":true},{"name":"mqtt-page","path":"/mqtt-page","component":__pages_import_3__,"props":true},{"name":"login","path":"/login","component":__pages_import_4__,"props":true},{"name":"index","path":"/","component":__pages_import_5__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_6__,"props":true,"meta":{"layout":"blank","action":"read","subject":"Auth"}}];

export default routes;