<script lang="ts" setup>
interface MenuItem {
  icon: string
  title: string
  value: string
  singleSelect?: boolean
}

interface Props {
  menuList?: MenuItem[]
  itemProps?: boolean
  onItemClick?: (value: any, item: any) => void
}

const props = defineProps<Props>()
</script>

<template>
  <IconBtn density="comfortable" color="secondary">
    <VIcon icon="tabler-dots-vertical" />
    <VMenu v-if="props.menuList" activator="parent">
      <VList>
        <VListItem
          v-for="item in props.menuList"
          :key="item.value"
          :value="item.value"
          :title="item.title"
          @click="
            () => props.onItemClick && props.onItemClick(item.value, item)
          "
        >
          <template v-slot:prepend>
            <VIcon :icon="item.icon" />
          </template>
        </VListItem>
      </VList>
    </VMenu>
  </IconBtn>
</template>
