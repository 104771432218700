import router from '@/router'
import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { jwtDecode } from 'jwt-decode'
import { defineStore } from 'pinia'
import { store } from './store'
import { AuthData, AuthState } from './types'
import { arrayToObject } from './utils'

const accountData = localStorage.getItem('userData')
  ? JSON.parse(localStorage.getItem('userData') as any)
  : null

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    msalConfig: {
      auth: {
        clientId: import.meta.env.VITE_MS_CLIENT_ID as string,
        authority: import.meta.env.VITE_MS_AUTHORITY as string
      },
      cache: {
        cacheLocation: 'localStorage'
      }
    },
    msalInstance: null,
    authURL: null,
    isAuthenticated: false,
    interactionInProgress: false,
    loginWindow: null,
    accessToken: '',
    idToken: '',
    role: accountData?.role || '',
    id: accountData?.id || '',
    fullName: accountData?.fullName || '',
    username: accountData?.username || '',
    avatar: accountData?.avatar || '',
    email: accountData?.email || '',
    discoveryData: [],
    data: [
      { Reply: '', Success: false, Error: '' },
      {
        'Cloud Resources': [],
        'MQTT Topics': [],
        'Service Account Description': ''
      }
    ],
    user: {
      'X-USERNAME': '', //"brian",
      'X-API-KEY': '', // "6446caac-7afa-456d-a82b-0d34afd62c10",
      PHPSESSID: '' // "gjealbh65n1hovgp0j0qu609ra",
    },
    tokenExpirationTimer: null
  }),
  getters: {
    authData: (state: {
      [x: string]: any
      data: any[]
      user: any
    }): AuthData => {
      const baseData = state.data[0]
      const resourceData = state.data[1] || {}

      // add hq to services array
      const hqServiceAccount = {
        'Service Account Type Description':
          resourceData['Service Account Description'],
        'Service Account Type': resourceData['Service Account Type'],
        'MQTT Topics': resourceData['MQTT Topics'],
        Instances: []
      }
      if (!resourceData.Services) resourceData.Services = []
      resourceData.Services.push(hqServiceAccount)

      // loop through resourceData.Services and strip '.>' from ["MQTT Topics"]
      if (resourceData.Services && resourceData.Services.length > 0)
        resourceData.Services.forEach((service: { ['MQTT Topics']: any[] }) => {
          service['MQTT Topics'].forEach((topic: any) => {
            topic.Topic = topic.Path.replace('.>', '')
          })
        })

      // flatten resourceData.Services[x]["MQTT Topics"]
      const topics =
        (resourceData?.Services &&
          resourceData?.Services.reduce(
            (accumulator: any[], current: { ['MQTT Topics']: any[] }) => {
              return accumulator.concat(current['MQTT Topics'])
            },
            []
          )) ||
        []

      return {
        ...baseData,
        'Cloud Resources': arrayToObject(
          resourceData['Cloud Resources'],
          'Name'
        ),
        'MQTT Topics': arrayToObject(
          topics,
          'Topic' /* resourceData.Services[0]["MQTT Topics"] */
        ),
        'Service Account Description':
          resourceData['Service Account Description'],
        user: state.user
      }
    },
    userRole: state => {
      return state.role?.[0]
    },
    getIsAuthenticated: state => {
      return state.isAuthenticated && !!state.accessToken && !!state.idToken
      //return !!state.accessToken;
    },
    initials: state => {
      return (
        state.fullName
          .split(' ')
          .map((n: string) => n[0])
          .join('') || ''
      )
    },
    getMsalConfig: state => {
      return state.msalConfig
    },
    getMsalInstance: state => {
      return state.msalInstance
    },
    getEnvironment () {
      const currentUrl = window.location.hostname || ''
      const currentName =
        this.authData?.['Cloud Resources']?.['Configuration Broker']?.Env ?? ''

      const environment = () => {
        if (currentUrl.includes('localhost')) {
          return 'local'
        } else if (currentName.includes('dev')) {
          return 'development'
        } else if (currentName.includes('sale')) {
          return 'sales'
        } else {
          return 'production'
        }
      }
      // save environment to local storage
      localStorage.setItem('environment', environment())

      return environment()
    }
  },
  actions: {
    checkIdTokenExpiration () {
      if (!this.idToken) return true
      const decodedToken = jwtDecode(this.idToken)
      if (!decodedToken || !decodedToken.exp) {
        console.warn('No expiration in token', decodedToken)
        return true
      }

      const expiration = decodedToken.exp * 1000
      const isExpired = Date.now() > expiration
      /* console.warn("decodedToken", decodedToken, {
        expiration: format(
          parseISO(new Date(expiration).toISOString()),
          "PPpp"
        ),
        now: format(parseISO(new Date(Date.now()).toISOString()), "PPpp"),
        isExpired,
      }); */

      return isExpired
    },
    async discover () {
      return new Promise(async (resolve, reject) => {
        // get domain and tld from url
        const domain = window.location.hostname.replace(
          'localhost',
          import.meta.env.VITE_DEFAULT_DOMAIN || 'hq.dev.responsetech.ltd'
        )
        const queryParms = new URLSearchParams({
          url: domain.replaceAll('.', '-')
        })

        // add query params to discovery broker uri
        const discoveryBrokerURI =
          import.meta.env.VITE_DISCOVERY_BROKER + '?' + queryParms.toString()

        console.log('discoveryBrokerURI', discoveryBrokerURI)
        const headers = new Headers()

        const requestOptions = {
          method: 'GET',
          headers,
          redirect: 'follow'
        }

        console.log('Connecting to ', discoveryBrokerURI)
        try {
          const discoveryBroker = await fetch(
            discoveryBrokerURI,
            requestOptions as any
          )
          const discoveryBrokerData = await discoveryBroker.json()

          console.log('Connected.', discoveryBrokerData)

          if (
            discoveryBrokerData[0].Success &&
            discoveryBrokerData[1] &&
            discoveryBrokerData[1][0] &&
            discoveryBrokerData[1][0].adkey
          ) {
            this.discoveryData = discoveryBrokerData
            const msalConfig = JSON.parse(discoveryBrokerData[1][0].adkey || {})
            // set msalConfig to state.msalConfig
            this.msalConfig = msalConfig.msalConfig
            // set authURL to state.authURL
            this.authURL = discoveryBrokerData[1][0].auth_url
            console.log('state', this.$state)
            resolve(this.data)
          } else {
            console.error('Failed to connect to discovery broker.', this.data)
            reject(new Error(this.data))
          }
        } catch (error) {
          console.error(error)
          reject(error)
        }
      })
    },
    async authorize (showLoading = true) {
      return new Promise(async (resolve, reject) => {
        try {
          await this.getAccessToken()

          if (this.checkIdTokenExpiration()) {
            console.debug('authorize: idToken expired, refreshing')
            await this.refreshToken()
          }

          if (!this.idToken) {
            console.debug('authorize: no idToken')
            return reject(new Error('Login required'))
          }

          // Set up a timer to check token expiration periodically
          this.setupTokenExpirationTimer()

          // ... rest of the authorize method ...
        } catch (error) {
          console.error('Error in authorize method:', error)
          reject(error)
        }

        const headers = new Headers()

        headers.append(
          'Authorization',
          // import.meta.env.VITE_AUTH_BROKER_TOKEN as string
          // `Bearer ${import.meta.env.VITE_AUTH_BROKER_TOKEN}`
          `Bearer ${this.idToken}`
        )

        const requestOptions = {
          method: 'POST',
          headers,
          redirect: 'follow'
        }

        console.log('Connecting to ', this.authURL)
        try {
          const loadingDiv = document.createElement('div')
          if (showLoading) {
            // create loading div
            const loadingDivExists = document.getElementById('loading-bg')
            if (loadingDivExists) {
              document.body.removeChild(loadingDivExists)
            }

            loadingDiv.id = 'loading-bg'
            loadingDiv.innerHTML = `
            <div class="loading-logo">
              <img src="/logo.png" alt="Logo">
            </div>
            <div class="loading">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
            </div>
          `

            loadingDiv.style.top = '0'
            document.body.appendChild(loadingDiv)
          }

          const authBroker = await fetch(this.authURL, requestOptions as any)
          const authBrokerData = await authBroker.json()

          this.data = authBrokerData

          console.log('Connection result:', this.data)

          if (this.data[0].Success) {
            this.data = authBrokerData
            if (showLoading) {
              // remove loading div
              document.body.removeChild(loadingDiv)
            }
            resolve(this.data)
          } else {
            console.error('Failed to connect to auth broker.', this.data)
            // throw snackbar error
            store.setSnack(
              `Failed to connect to auth broker:\n${
                this.data[0]?.Error ||
                this.data[0]?.Reply ||
                this.data[0]?.Message ||
                this.data[0]
              }`,
              {
                color: 'error',
                variant: 'elevated',
                location: 'bottom',
                buttonText: 'Close',
                buttonTextColor: 'white'
              }
            )
            document.body.removeChild(loadingDiv)
            reject(new Error(this.data))
          }
        } catch (error) {
          console.error(error)
          reject(error)
        }
      })
    },
    async getAccessToken () {
      return new Promise(async (resolve, reject) => {
        if (!this.msalInstance) {
          this.initializeMsal()
        }

        try {
          // Set the scopes you need for your API
          const scopes = ['User.Read']

          // Get the account info
          const accounts = this.msalInstance.getAllAccounts()
          if (accounts.length === 0) {
            return reject(new Error('No accounts found'))
          }

          const account = accounts[0]

          // Get the token
          const response = (await this.msalInstance.acquireTokenSilent({
            scopes,
            account
          })) as any

          // If the response contains an access token, return it
          if (response && response.accessToken) {
            // update tokens
            this.setTokens(response.accessToken, response.idToken)
            return resolve(response)
          }

          reject(new Error('Access token not found'))
        } catch (error) {
          console.warn('Failed to acquire token silently', error)
          // Try to refresh the token
          try {
            const refreshedResponse = await this.refreshToken()
            return resolve(refreshedResponse)
          } catch (refreshError) {
            console.warn('Failed to refresh token', refreshError)
            // If refresh fails, ask user to login again
            this.adfsLogin()
            reject(refreshError)
          }
        }
      })
    },

    async refreshToken () {
      return new Promise(async (resolve, reject) => {
        try {
          const accounts = this.msalInstance.getAllAccounts()
          if (accounts.length === 0) {
            return reject(new Error('No accounts found'))
          }

          const account = accounts[0]
          const scopes = ['User.Read']

          const response = await this.msalInstance.acquireTokenPopup({
            scopes,
            account
          })

          if (response && response.accessToken) {
            this.setTokens(response.accessToken, response.idToken)
            return resolve(response)
          }

          reject(new Error('Failed to refresh token'))
        } catch (error) {
          console.error('Error refreshing token', error)
          reject(error)
        }
      })
    },
    setTokens (accessToken: any, idToken: any) {
      // console.debug("setting Tokens:", { accessToken, idToken });
      if (accessToken !== this.accessToken || idToken !== this.idToken) {
        console.debug('tokens changed. setting from:', {
          accessToken: this.accessToken,
          idToken: this.idToken
        })
        console.debug('setting Tokens:', { accessToken, idToken })
      }
      this.accessToken = accessToken
      this.idToken = idToken
      store.setItem('accessToken', accessToken)
      store.setItem('idToken', idToken)
    },
    setUserRole (role: any) {
      this.role = [role]
    },
    async initializeMsal () {
      await this.discover()
      this.msalInstance = new PublicClientApplication(this.msalConfig)
      await this.msalInstance.initialize()
      console.debug('initMsal', this.msalInstance)
      return this.msalInstance
    },
    async adfsLogin () {
      return new Promise(async (resolve, reject) => {
        if (this.interactionInProgress) {
          console.warn('Login already in progress.')
          return reject(new Error('Login already in progress.'))
        }

        this.interactionInProgress = true
        try {
          // Always initialize MSAL before using it
          await this.initializeMsal()

          this.msalInstance.addEventCallback(message => {
            // console.warn("Event callback", message);
            if (message.eventType === EventType.POPUP_OPENED) {
              // Save the popup window for focusing later

              this.loginWindow = true // message.payload.popupWindow;
              console.warn('Popup opened', message)
            }

            if (
              message.eventType === EventType.LOGIN_FAILURE ||
              message.error
            ) {
              // Clear the popup window
              this.loginWindow = false
              console.warn('Popup closed', message)
            }
          })

          let authed = false
          const accounts = (await this.msalInstance?.getAllAccounts()) || []
          // console.debug("#### getAllAccount:", accounts);
          /* console.warn("#### userData:", localStorage.getItem("userData")); */
          if (accounts.length > 0) {
            try {
              const result = await this.getAccessToken()
              // console.debug("### getAccessToken:", result);
              this.isAuthenticated = true
              authed = true
              this.internalLogin(result)
              return resolve(result)
            } catch (e) {
              console.error(e)
              authed = false
              reject(e)
            }
          }

          // if (authed) console.warn("####################### authed:", authed);
          const response = await this.msalInstance.loginPopup()
          // console.warn("#### loginPopup:", response);
          if (response.account) {
            // response.accessToken is for authorization
            // response.idToken contains claims about the authenticated user
            this.internalLogin(response)
            resolve(response)
          }
        } catch (e) {
          console.log('Error, clearing state', e)
          store.setSnack(
            (e as Error)?.message || (e as { error?: string }).error || e,
            {
              color: 'warning',
              variant: 'flat',
              location: 'bottom',
              buttonText: 'Close',
              buttonTextColor: 'white'
            }
          )

          localStorage.removeItem('userData')
          localStorage.removeItem('accessToken')
          localStorage.removeItem('userAbilities')

          this.msalInstance = null
          // clear all sessions and cookies
          sessionStorage.removeItem('msal.login.request')
          sessionStorage.removeItem('msal.login.error')
          sessionStorage.removeItem('msal.error')
          sessionStorage.removeItem('msal.interaction.status')
          reject(e)
        } finally {
          this.loginWindow = false
          this.interactionInProgress = false
        }
      })
    },
    async logout () {
      if (this.interactionInProgress) {
        return
      }
      this.interactionInProgress = true
      try {
        if (!this.msalInstance) {
          await this.initializeMsal()
        }

        await this.msalInstance.logoutPopup()
        // router.push({ name: "login" });
      } catch (e) {
        console.error(e)
      } finally {
        console.log('Logout complete. Clearing state.')
        // delete local storage
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userAbilities')
        this.msalInstance = null
        this.interactionInProgress = false
        // clear all sessions and cookies
        sessionStorage.removeItem('msal.login.request')
        sessionStorage.removeItem('msal.login.error')
        sessionStorage.removeItem('msal.error')
        sessionStorage.removeItem('msal.interaction.status')
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i]
          const eqPos = cookie.indexOf('=')
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
          document.cookie =
            name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
        }

        this.isAuthenticated = false
        this.loginWindow = false

        router.push({ name: 'login' })
      }
    },
    internalLogin (userData: any) {
      userData.role = userData?.idTokenClaims?.roles[0] || 'None'
      userData.id = userData?.idTokenClaims?.oid || ''
      userData.fullName = userData?.idTokenClaims?.name || ''
      userData.username = userData?.idTokenClaims?.preferred_username || ''
      userData.avatar = userData?.idTokenClaims?.picture || ''
      userData.email = userData?.idTokenClaims?.email || ''
      localStorage.setItem('userData', JSON.stringify(userData))
      this.$patch = Object.assign(this, userData)
      this.setTokens(userData.accessToken, userData.idToken)
      this.setUserRole(userData.role)
      this.isAuthenticated = true

      document.getElementById('loading-bg')?.remove()
    },

    setupTokenExpirationTimer () {
      // Clear any existing timer
      if (this.tokenExpirationTimer) {
        clearInterval(this.tokenExpirationTimer)
      }

      // Set up a new timer to check token expiration every minute
      this.tokenExpirationTimer = setInterval(async () => {
        if (this.checkIdTokenExpiration()) {
          console.debug('Token expired, refreshing')
          try {
            await this.refreshToken()
          } catch (error) {
            console.error('Failed to refresh token:', error)
            // If refresh fails, redirect to login
            this.logout()
          }
        }
      }, 60000) // Check every minute
    }
  }
})
