/* eslint-disable import/order */
import "@/@iconify/icons-bundle";
import layoutsPlugin from "@/plugins/layouts";
import vuetify from "@/plugins/vuetify";
import { loadFonts } from "@/plugins/webfontloader";
import router from "@/router";
import "@core/scss/template/index.scss";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import * as Sentry from "@sentry/vue"; */
import "@styles/styles.scss";
import { registerLicense } from "@syncfusion/ej2-base";
/* import {
  Aggregate,
  ColumnChooser,
  ColumnMenu,
  CommandColumn,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  Freeze,
  Grid,
  GridPlugin,
  Group,
  PdfExport,
  Reorder,
  Resize,
  RowDD,
  Sort,
  Toolbar,
  VirtualScroll,
} from "@syncfusion/ej2-vue-grids"; */
import { createApp } from "vue";
import "vue3-openlayers/dist/vue3-openlayers.css";
import { aliases, fa } from "vuetify/iconsets/fa-svg";
import { initializeJSONPacketCapture } from "./@core/utils/captureJSON";
import App from "./App.vue";
import { pinia, store } from "./stores/store";

import "@syncfusion/ej2-base/styles/tailwind.css";
import "@syncfusion/ej2-buttons/styles/tailwind.css";
import "@syncfusion/ej2-calendars/styles/tailwind.css";
import "@syncfusion/ej2-dropdowns/styles/tailwind.css";
import "@syncfusion/ej2-inputs/styles/tailwind.css";
import "@syncfusion/ej2-navigations/styles/tailwind.css";
import "@syncfusion/ej2-popups/styles/tailwind.css";
import "@syncfusion/ej2-splitbuttons/styles/tailwind.css";
import "@syncfusion/ej2-vue-grids/styles/tailwind-dark.css";
import "@syncfusion/ej2-vue-grids/styles/tailwind.css";

loadFonts();
registerLicense(
  "ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5XdExhWX1WcX1VRWBY"
);
const app = createApp(App);

app.component("FontAwesomeIcon", FontAwesomeIcon).use(vuetify, {
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa,
    },
  },
});

initializeJSONPacketCapture();

/* Sentry.init({
  app: app,
  dsn: "https://0548c13314253004967a88eef8d256ca@o4506893594787840.ingest.us.sentry.io/4506893735821312",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/configuration\.resptech\.ltd\/EliteProxy/, /^https:\/\/configuration\.rce\.systems\/EliteProxy/, /^https:\/\/authorization\.rce\.systems/, /^https:\/\/authorization\.resptech\.ltd/, /^https:\/\/configuration\.resptech\.ltd/, /^https:\/\/configuration\.rce\.systems/, /^https:\/\/authorization\.rce\.systems/, /^https:\/\/authorization\.resptech\.ltd/, /^https:\/\/configuration\.resptech\.ltd\/EliteProxy/, /^https:\/\/configuration\.rce\.systems/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}); */

/* app.use(GridPlugin);
app.provide("Grid", Grid);
app.provide("grid", [
  Aggregate,
  ColumnChooser,
  ColumnMenu,
  CommandColumn,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  Freeze,
  Group,
  PdfExport,
  Reorder,
  Resize,
  RowDD,
  Sort,
  Toolbar,
  VirtualScroll,
]); */

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.active.postMessage({ type: "GET_CLIENT_ID" });
    store.updateServiceWorkerStatus("registered", "unknown");
  });

  navigator.serviceWorker.addEventListener("message", (event) => {
    if (event.data.type === "CLIENT_ID") {
      console.log("Received client ID:", event.data.clientId);
      // You can store this ID in your app state if needed
      store.updateServiceWorkerStatus("registered", event.data.clientId);
    }
  });
} else {
  store.updateServiceWorkerStatus("unregistered", "unknown");
}

app.use(router).use(layoutsPlugin).use(pinia).mount("#app");
