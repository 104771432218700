<template>
  <VDialog v-model="visible" persistent max-width="600px">
    <VCard>
      <VCardTitle>
        <span class="headline">Anywhere Register</span>
      </VCardTitle>
      <VCardText>
        <VForm ref="form">
          <div v-for="(field, index) in fields" :key="index" class="field-row">
            <v-text-field
              v-model="field.Mac"
              disabled
              label="MAC Address"
              readonly
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="field.DateOfMfg"
              disabled
              label="Date of Manufacturing"
              readonly
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model.number="field.SenNo"
              :disabled="field.disabled"
              label="Sensor Number"
              outlined
              dense
              class="sensor-number"
              @keypress="handleKeyPress($event, index)"
            ></v-text-field>
            <!-- status icon, either pending, success, timeout or error with tooltip -->
            <span v-if="field.status === 'pending'">
              <VIcon>mdi-clock-outline</VIcon>
              <VTooltip
                name="pending"
                location="top"
                transition="scale-transition"
                activator="parent"
              >
                <span>Pending</span>
              </VTooltip>
            </span>
            <span v-if="field.status === 'success'">
              <VIcon color="success">mdi-check</VIcon>
              <VTooltip
                name="success"
                location="top"
                transition="scale-transition"
                activator="parent"
              >
                <span>{{ field.errorMessage || "Success" }}</span>
              </VTooltip>
            </span>
            <span
              v-if="field.status === 'timeout'"
              class="pointer center"
              @click="publish(index)"
            >
              <VIcon color="warning">mdi-timer-refresh-outline</VIcon>
              <VTooltip
                name="timeout"
                location="top"
                transition="scale-transition"
                activator="parent"
                class="center"
              >
                <span>No response from broker<br /><small>(click to retry)</small></span>
              </VTooltip>
            </span>
            <span
              v-if="field.status === 'error'"
              class="pointer center"
              @click="publish(index)"
            >
              <VIcon color="error">mdi-alert-circle-outline</VIcon>
              <VTooltip
                name="error"
                location="top"
                transition="scale-transition"
                activator="parent"
              >
                <span>{{ field.errorMessage || "Error" }}</span>
              </VTooltip>
            </span>
            <VIcon v-if="field.status === ''"></VIcon>
          </div>
        </VForm>
      </VCardText>
      <VCardActions>
        <VSpacer></VSpacer>
        <VBtn variant="flat" color="primary" text @click="handleClose">Close</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";
import { computed, ref, watch } from "vue";
import { store } from "../stores/store"; // Correct store reference{"PetMfg":{"Mac":"F8:42:84:C3:31:56","DateOfMfg":"2021-06-25"}}

// Accessing the correct state from the store
const visible = computed(() => store.isAnywhereRegisterDialogVisible);

// Initialize fields with correct structure
const fields = ref(store.anywhereRegisterFields);

const duplicateCheck = (index: number) => {
  if (!fields.value[index].Mac) {
    fields.value[index].status = "";
    fields.value[index].errorMessage = "";
    return false;
  }
  // check for duplicate mac or sen no
  const duplicate = false; // fields.value.find((field) => field.Mac === fields.value[index].Mac);
  // check for duplicate sen no excluding the current index
  const duplicateSenNo = fields.value.find(
    (field, i) => field.SenNo === fields.value[index].SenNo && i !== index
  );
  if (duplicate || duplicateSenNo) {
    const duplicateField = duplicate ? "Mac Address" : "Sensor Number";
    store.setSnack(`Duplicate ${duplicateField}`, {
      color: "error",
      location: "bottom",
      buttonText: "OK",
      buttonTextColor: "white",
    });
    fields.value[index].status = "error";
    fields.value[index].errorMessage = `Duplicate ${duplicateField}`;
    return true;
  } else {
    fields.value[index].status = "";
    fields.value[index].errorMessage = "";
    return false;
  }
};

watch(
  fields,
  (newFields) => {
    store.anywhereRegisterFields = newFields;
  },
  { deep: true }
);

// Watch for changes in jsonPacket and update fields accordingly
watch(
  () => store.jsonPacket,
  (newPacket) => {
    console.warn("newPacket", newPacket);

    if (newPacket.PetMfg) {
      // check for duplicate mac
      if (fields.value.find((field) => field.Mac === newPacket.PetMfg.Mac)) {
        store.setSnack("Duplicate Mac Address", {
          color: "error",
          location: "bottom",
          buttonText: "OK",
          buttonTextColor: "white",
        });
        return;
      }

      // find first empty fields and update them
      const emptyField = fields.value.find(
        (field) => !field.Mac && !field.DateOfMfg // && !field.SenNo
      );
      if (emptyField) {
        emptyField.Mac = newPacket.PetMfg.Mac;
        emptyField.DateOfMfg = newPacket.PetMfg.DateOfMfg;

        // if senno is not empty, publish payload to topic
        if (emptyField.SenNo) {
          publish(fields.value.indexOf(emptyField));
        }
      } else {
        // if no empty fields, add a new row
        fields.value.push({
          ...newPacket.PetMfg,
          disabled: false,
          status: "",
          SenNo: null,
          traceUuid: "",
          errorMessage: "",
        });
      }
    } else {
      fields.value = [
        {
          Mac: "",
          DateOfMfg: "",
          SenNo: null,
          disabled: false,
          status: "",
          traceUuid: "",
          errorMessage: "",
        },
      ];
    }

    // menus.value = fields.value.map(() => false)

    // set focus on the SenNo field
    // set focus on the last SenNo field
    setTimeout(() => {
      const input = document.querySelectorAll(".sensor-number input");
      if (input) (input[input.length - 1] as HTMLInputElement).focus();
    }, 100);
  }
);

// on sensor-number blur or enter key press, disable the sensor-number field and new row of fields
const addRow = (index: number) => {
  // if no mac or date of mfg, SenNo is required
  if (!fields.value[index].Mac || !fields.value[index].DateOfMfg) {
    if (!fields.value[index].SenNo) {
      fields.value[index].status = "error";
      return;
    }
  }

  // check for duplicate mac or sen no
  if (duplicateCheck(index)) {
    return;
  }

  // if mac and date of mfg and senno are present, publish payload to topic
  if (
    fields.value[index].Mac &&
    fields.value[index].DateOfMfg &&
    fields.value[index].SenNo
  ) {
    /**
     * [{"Method":"Anywhere Register", "MethodUuid":"UUID X", "Caller JWT":""}, {Mac, Mfr Date, optional SenNo} ]
     */
    publish(index);
  }

  // fields.value[index].disabled = true;

  if (fields.value.length - 1 === index) {
    fields.value.push({
      Mac: "",
      DateOfMfg: "",
      SenNo: null,
      disabled: false,
      status: "",
      traceUuid: "",
      errorMessage: "",
    });

    setTimeout(() => {
      const input = document.querySelectorAll(".sensor-number input");
      if (input) (input[input.length - 1] as HTMLInputElement).focus();
    }, 100);
  }
};

const publish = (index: number) => {
  // check required fields
  if (
    !fields.value[index].Mac ||
    !fields.value[index].DateOfMfg ||
    !fields.value[index].SenNo
  ) {
    fields.value[index].status = "error";
    fields.value[index].errorMessage = "Missing required fields";
    return;
  }

  fields.value[index].status = "pending";
  fields.value[index].traceUuid = uuidv4();

  const eliteId = store.selectedElite || store.collections?.Elite?.data?.[0]?.id || "";
  const topic = store.broker.subscription
    .find((sub) => sub.Name === "centurion-japiSubscribeTo")
    ?.topic.replace("+", store?.activeOrganization || "")
    .replace("+", eliteId) as string;

  const message = [
    {
      Method: "Anywhere Register",
      TraceUuid: fields.value[index].traceUuid,
      Jwt: store.auth.idToken,
    },
    {
      Mac: fields.value[index].Mac,
      DateOfMfg: fields.value[index].DateOfMfg,
      SenNo: fields.value[index].SenNo,
    },
  ];

  console.warn("***** publishing *******", message, topic);

  store.broker.doPublish({
    topic,
    message: JSON.stringify(message),
  });

  fields.value[index].disabled = true;

  setTimeout(() => {
    if (fields.value[index].status === "pending") {
      fields.value[index].status = "timeout";
    }
  }, 5000);
};

// Handle key press event on sensor-number field
const handleKeyPress = (event: KeyboardEvent, index: number) => {
  if (event.key === "Enter") {
    addRow(index);
  }
};

// Handle dialog close
const handleClose = () => {
  store.closeAnywhereRegisterDialog();
};
</script>

<style scoped>
.field-row {
  display: flex;
  gap: 10px;
  margin-block-end: 15px;
}

svg.v-icon.notranslate.v-theme--light.v-icon--size-default.iconify.iconify--mdi {
  margin-block: 0.5em;
  margin-inline: 0;
}
</style>
