<script setup lang="ts">
import { vDraggable } from '@/directives/draggable'
import { saveUserSettings } from '@/services/graphApiService'
import { store } from '@/stores/store'
import draggable from 'vuedraggable'
import { useTheme } from 'vuetify'

const vuetifyTheme = useTheme()

const props = defineProps([
  'filteredHeaders',
  'headers',
  'collection',
  'isAddRemoveColumnsDialogVisible'
])

const collection = ref(props.collection)
const headers = ref(props.headers)
const baseFilteredHeaders = ref(props.filteredHeaders)

console.debug('headers', collection.value, headers.value)

interface Emit {
  (e: 'update:isAddRemoveColumnsDialogVisible', value: boolean): void
  (e: 'submit', value: any): void
}

const emit = defineEmits<Emit>()

const selectedHeaders = ref(
  store.getSelectedHeaders().filter((header: any) => header.title)
)

const filteredHeaders = computed(() => {
  const rtn = baseFilteredHeaders.value
    .filter((header: any, index: number, self: any[]) => {
      // Check if this header is not in selectedHeaders and is unique in headers
      return (
        !selectedHeaders.value.some(
          (selectedHeader: any) => selectedHeader.title === header.title
        ) && index === self.findIndex((h: any) => h.title === header.title)
      )
    }) // filter blank headers
    .filter((header: any) => header.title !== '') // if empty, append placeHolder

  return rtn.length ? rtn : [{ title: 'Drag Columns Here', key: 'placeHolder' }]
})

// watch selectedHeaders for changes
watch(
  () => selectedHeaders.value,
  val => {
    console.debug('watch selectedHeaders', val)
    if (!val.length) {
      selectedHeaders.value = [
        ...selectedHeaders.value,
        { title: 'Drag Columns Here', key: 'placeHolder' }
      ]
    }
  }
)

function resetForm() {
  emit('update:isAddRemoveColumnsDialogVisible', false)
}

async function onFormSubmit() {
  const collection = props.collection
  console.debug('payLoad', collection, selectedHeaders.value)

  const filteredHeaders = selectedHeaders.value.filter(
    (header: any) => header.key !== 'placeHolder'
  )
  store.setSelectedHeaders(filteredHeaders)

  try {
    const settings = {
      selectedColumns: {
        [collection]: filteredHeaders.map(header => header.key)
      }
    }
    await saveUserSettings(settings)
    console.log('User column settings saved successfully')
  } catch (error) {
    console.error('Failed to save user column settings:', error)
    // You might want to show an error message to the user here
  }

  emit('submit', props.headers)
  resetForm()
}

const isDragging = ref(false)

function dblClickDrag(evt: any) {
  // move item to other list
  const clickedItem = evt.target.innerText
  const clickedList = evt.target.parentElement.parentElement.parentElement.id
  console.debug('dblClickDrag', clickedItem, clickedList)

  if (clickedItem === 'filteredHeaders') {
    const addToSelectedHeaders = filteredHeaders.value.find(
      (header: any) => header.title === clickedItem
    )
    selectedHeaders.value = [
      ...selectedHeaders.value,
      addToSelectedHeaders
    ].filter((header: any) => header.title !== 'Drag Columns Here')
  } else {
    // remove from selectedHeaders
    selectedHeaders.value = selectedHeaders.value.filter(
      (header: any) => header.title !== clickedItem || header.title === ''
    )
  }
  selectedHeaders.value =
    clickedList === 'filteredHeaders'
      ? (selectedHeaders.value = [
          ...selectedHeaders.value,
          filteredHeaders.value.find(
            (header: any) => header.title === clickedItem
          )
        ])
      : selectedHeaders.value.filter(
          (header: any) => header.title !== clickedItem
        )
}

const dragOptions = {
  animation: 0,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost'
}

const dragAllColumns = (list: any) => {
  console.debug('dragAllColumns', list)
  if (list === 'filteredHeaders') {
    selectedHeaders.value = [...selectedHeaders.value, ...filteredHeaders.value]
  } else {
    selectedHeaders.value = []
  }
}
// watch props.isAddRemoveColumnsDialogVisible for changes
watch(
  () => props.isAddRemoveColumnsDialogVisible,
  val => {
    if (val) {
      collection.value = props.collection
      headers.value = props.headers

      // if selectedHeaders is empty, use the default headers displayed in the table
      const savedSelectedHeaders = store.getSelectedHeaders()
      selectedHeaders.value =
        (savedSelectedHeaders.length &&
          savedSelectedHeaders.filter(
            (header: any) => header.key !== 'placeHolder'
          )) ||
        headers.value

      /* selectedHeaders.value = store.getSelectedHeaders().length
        ? store
            .getSelectedHeaders()
            .filter((header: any) => header.key !== 'placeHolder')
        : appendHeaders */
      // selectedHeaders.value = store.getSelectedHeaders()
      // + [{ title: 'Drag Columns Here', key: 'placeHolder' }]
      console.debug('watch', collection.value, selectedHeaders.value)
    }
  }
)

// get 80vw of the screen width in pixels
const dialogWidth = computed(() => {
  const width = window.innerWidth
  const widthInPixels = (width * 80) / 100
  return widthInPixels
})
</script>

<template>
  <VDialog
    :width="dialogWidth"
    :model-value="props.isAddRemoveColumnsDialogVisible"
    @update:model-value="
      val => $emit('update:isAddRemoveColumnsDialogVisible', val)
    "
  >
    <VCard
      v-if="headers"
      title="Add/Remove Columns"
      :subtitle="collection"
      v-draggable
    >
      <!-- 👉 Dialog close btn -->
      <DialogCloseBtn
        @click="$emit('update:isAddRemoveColumnsDialogVisible', false)"
      />
      <VCardText>
        <!-- 👉 Form -->
        <VForm class="mt-4" @submit.prevent="onFormSubmit">
          <VRow>
            <VCol cols="12" sm="6" class="text-center mb-4 justify-start">
              <VList>
                <VListSubheader
                  class="py-3 text-align-center d-flex justify-center align-center"
                  >Columns Available</VListSubheader
                >
                <draggable
                  :list="filteredHeaders"
                  itemKey="title"
                  group="columns"
                  id="filteredHeaders"
                >
                  <template #item="{ element, index }">
                    <VListItem
                      class="v-list-item justify-center align-center"
                      color="primary"
                      variant="flat"
                      v-show="element.title"
                      :key="element.title"
                    >
                      <VListItemSubtitle
                        :class="
                          element.key === 'placeHolder' ? 'placeHolder' : ''
                        "
                        v-on:dblclick="dblClickDrag"
                        >{{ element.title }}</VListItemSubtitle
                      >
                    </VListItem>
                  </template>
                </draggable>
              </VList>
              <VBtn
                @click="dragAllColumns('filteredHeaders')"
                color="primary"
                class="mt-4"
                v-if="filteredHeaders.length > 1"
                >Select All {{ filteredHeaders.length }} Columns</VBtn
              >
            </VCol>

            <VCol cols="12" sm="6" class="text-center mb-4 justify-end">
              <VList>
                <VListSubheader
                  class="py-3 text-align-center d-flex justify-center align-center"
                  >Columns Displayed</VListSubheader
                >

                <draggable
                  :list="selectedHeaders"
                  itemKey="title"
                  :component-data="{
                    tag: 'ul',
                    name: 'flip-list',
                    type: 'transition'
                  }"
                  v-bind="dragOptions"
                  @start="isDragging = true"
                  @end="isDragging = false"
                  group="columns"
                  id="selectedHeaders"
                >
                  <template #item="{ element, index }">
                    <VListItem
                      class="v-list-item justify-center align-center"
                      color="primary"
                      variant="flat"
                      :key="element.title"
                      v-show="element.title"
                      v-if="
                        (element.key === 'placeHolder' &&
                          selectedHeaders.length === 1) ||
                        element.key !== 'placeHolder'
                      "
                    >
                      <VListItemSubtitle
                        :class="
                          element.key === 'placeHolder' ? 'placeHolder' : ''
                        "
                        v-on:dblclick="dblClickDrag"
                        >{{ element.title }}</VListItemSubtitle
                      >
                    </VListItem>
                  </template>
                </draggable>
              </VList>
              <VBtn
                @click="dragAllColumns('selectedHeaders')"
                color="primary"
                class="mt-4"
                v-if="selectedHeaders.length > 1"
                >Unselect All {{ selectedHeaders.length - 1 }} Columns</VBtn
              >
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12" class="text-center mt-4">
              <VBtn type="submit" class="me-3" color="primary"> save </VBtn>

              <VBtn variant="tonal" color="secondary" @click="resetForm">
                Cancel
              </VBtn>
            </VCol>
          </VRow>
        </VForm>
      </VCardText>
    </VCard>
  </VDialog>
</template>
<style scoped>
.placeHolder {
  padding: 2em;
  border-width: 1.75px;
  border-style: dashed;
  background: #e3e3e32b;
  color: #ccc;
  font-style: italic;
  font-weight: 200;
  margin-block: 1em;
  margin-inline: 0;
  opacity: 0.9;
}

.v-list {
  padding: 0;
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
  background-color: rgb(var(--v-theme-background));

  /* background: #e3e3e3; */
  inline-size: 90%;
}

.v-list-item {
  border-block-start: 1px solid
    rgba(var(--v-border-color), var(--v-border-opacity));
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &:hover {
    background-color: #cccccc3b;
    border-block-start: 1px solid
      rgba(var(--v-border-color), var(--v-border-opacity));
    cursor: grabbing;
  }

  &:focus {
    cursor: grabbing;
  }

  &:focus-visible {
    cursor: grabbing;
  }
}

.dragArea {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
  border-radius: 4px;
  background: #e3e3e3;
  inline-size: 90%;
}

.v-list-subheader + div {
  max-block-size: calc(100vh - 400px);
  overflow-y: auto;
}
</style>
