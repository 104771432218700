import { store } from "@/stores/store";
import { useStorage } from "@vueuse/core";
import { Remote, wrap } from "comlink";
import { defineStore } from "pinia";
import { flattenObj } from "./flattenObj";
import { BrokerState } from "./types";

export const useBrokerStore = defineStore("broker", {
  state: (): BrokerState => ({
    data: {},
    initialized: false,
    connection: {
      protocol: "tcp",
      host: "",
      port: null,
      endpoint: "",
      clean: true,
      connectTimeout: 30 * 1000, // ms
      reconnectPeriod: 4000, // ms
      clientId: "hq_client_",
      username: "",
      password: "",
      resubscribe: false,
    },
    subscription: [
      {
        topic: "",
        qos: 0,
        subscribed: false,
        name: "",
        description: "",
      },
    ],
    receiveNews: [],
    qosList: [0, 1, 2],
    workerFn: null as Remote<import("../workers/mqttWorker").MqttWorker> | null,
    subscribeSuccess: false,
    connecting: false,
    connected: null,
    retryTimes: 0,
    longReset: false,
    hardReset: false,
    collections: {},
    services: {},
    errors: [],
    selectedCabSight: useStorage("selectedCabSight", {
      uuid: "",
      description: "All",
    }),
    eventVersions: {},
    processMqttMessage: null,
    client: undefined,
  }),
  getters: {
    isConnected(state) {
      return state.connected;
    },
    dataList(state) {
      return state?.collections?.Cab?.data || [];
    },
    dataListByCabSight(): Array<Object> {
      const cabSightUuid = this.selectedCabSight.uuid || "";
      return (
        this.dataList.filter(
          (item: any) =>
            item["Author.Uuid"] === cabSightUuid || cabSightUuid === ""
        ) || []
      );
    },
    cabSights(state) {
      /* const cabSights = state.data?.cabsight?.Cab || {};
      const cabSight = (
        Object.values(cabSights)
          .map((item: any) => {
            return {
              uuid: item?.[0]?.Author?.Uuid || item?.[0]?.["Author.Uuid"] || "",
              description:
                item?.[0]?.Author?.["Service Account Description"] ||
                item?.[0]?.["Author.Service Account Description"] ||
                "",
            };
          })
          .filter((item: any) => item?.[0]?.uuid) || []
      ).filter(
        (item: any, index: number, self: any) =>
          index === self.findIndex((t: any) => t.uuid === item.uuid)
      ); */

      const cabSights = state.collections.Cab?.data || [];
      // get unique cabSights from the data
      const cabSight = cabSights
        .map((item: any) => {
          return {
            uuid: item["Author.Uuid"] || item["Author.Uuid"] || "",
            description: item["Author.Service Account Description"] || "",
          };
        })
        .filter(
          (item: any, index: number, self: any) =>
            index === self.findIndex((t: any) => t.uuid === item.uuid)
        );
      return cabSight;
    },
  },
  actions: {
    setSelectedCabSight(cabSight: { uuid: string; description: string }) {
      this.selectedCabSight = cabSight;
      store.setItem("selectedCabSight", cabSight);
    },
    async init() {
      if (!this.connecting && this.retryTimes <= 5) {
        try {
          this.initializeBroker();

          const { protocol, host, port, endpoint, ...options } =
            this.connection;
          const connectUrl = `${protocol}://${host}:${port}${endpoint}`;

          const worker = new Worker(
            new URL("../workers/mqttWorker.ts", import.meta.url),
            { type: "module" }
          );
          this.workerFn =
            wrap<import("../workers/mqttWorker").MqttWorker>(worker);

          await this.workerFn.initializeMqtt(connectUrl, options);

          this.connected = true;
          this.connecting = false;

          await this.doSubscribe();

          worker.onmessage = (event) => {
            if (event.data.type === "message") {
              this.handleMessage(event.data.topic, event.data.message);
            }
          };
        } catch (error) {
          this.connecting = false;
          this.connected = false;
          console.error("mqtt.connect error", error);
        }
      } else {
        console.warn(
          this.connecting ? "already connecting" : "retry times exceeded"
        );
      }
    },

    async handleMessage(topic: string, message: string) {
      if (!this.workerFn) {
        console.error("Worker function is not initialized");
        return;
      }

      const processedMessage = await this.workerFn.processMqttMessage(
        topic,
        message
      );

      if (processedMessage.error) {
        console.error("Error processing MQTT message:", processedMessage.error);
        return;
      }

      const { payload } = processedMessage;

      this.receiveNews.push(payload);
      if (this.receiveNews.length > 500) this.receiveNews.shift();

      // assert processedData contains event, collection, item, id, data

      if (!processedMessage.processedData) {
        console.error("processedData is not defined");
        return;
      }

      // console.log("processedMessage", processedMessage);
      this.updateDataStore(processedMessage.processedData);
    },
    async disconnect() {
      try {
        if (this.workerFn) {
          await this.workerFn.disconnect();
          this.connecting = false;
          this.connected = false;
          console.log("MQTT Disconnected.");
        }
      } catch (error) {
        console.log(error as Error);
      }
    },
    initializeBroker() {
      console.info("initializing broker", this);
      this.connecting = true;
      this.connection.clientId = `hq_client_${Math.random()
        .toString(16)
        .substring(2, 8)}`;

      // console.log("store.auth.authData", store.auth.authData);
      // this.connection.username = "admin";
      this.connection.username =
        store.auth.authData["Cloud Resources"]["MQTT Broker"].Username;
      // this.connection.password = "password";
      this.connection.password =
        store.auth.authData["Cloud Resources"]["MQTT Broker"].Password;
      const url = new URL(
        store.auth.authData["Cloud Resources"]["MQTT Broker"].URI.replace(
          /\\/g,
          ""
        )
      );
      this.connection.host = url.hostname;
      this.connection.port = Number(url.port);
      this.connection.protocol = url.protocol.replace(":", "");
      this.connection.endpoint = url.pathname || "";

      // Get the current environment
      const currentEnvironment =
        store.auth.authData["Cloud Resources"]["Configuration Broker"].Env;

      // delete topics
      this.subscription = [];
      Object.keys(store.auth.authData["MQTT Topics"]).forEach((key) => {
        const topicPath = store.auth.authData["MQTT Topics"][key].Path.replace(
          /^\/+/,
          ""
        ).replace(/\/+$/, "");

        // Only add topics that match the current environment
        if (
          topicPath.startsWith(currentEnvironment + "/") ||
          topicPath.startsWith("global/")
        ) {
          if (
            !this.subscription.find(
              (item: { topic: any }) => item.topic === topicPath
            )
          ) {
            this.subscription.push({
              topic: topicPath,
              qos: 0,
              Name: store.auth.authData["MQTT Topics"][key].Name,
              Description: store.auth.authData["MQTT Topics"][key].Description,
            });
          }
        }
      });

      // setup services

      if (!this.initialized) this.initialized = true;

      if (this.expireInterval) clearInterval(this.expireInterval);

      this.expireInterval = setInterval(() => {
        this.expireItems(this.data);
      }, 7500);
    },
    async updateDataStore(payload: {
      type?: string;
      collection: string;
      item: string;
      uuidKey?: string;
      uuid?: string;
      data: any;
      event?: string;
    }) {
      try {
        const {
          type = "unknown",
          collection,
          item,
          uuidKey,
          uuid,
          data,
          event,
        } = payload;

        if (!this.workerFn) {
          console.error("Worker function is not initialized");
          return;
        }
        // console.log("updateDataStore", { type, collection, item, uuidKey, uuid, data, event });
        switch (type) {
          case "status":
            const statusResult = await this.workerFn.updateStatusData(
              collection,
              item,
              uuidKey!,
              uuid!,
              data
            );
            this.updateStatusDataStore(
              uuid!,
              statusResult.serviceName,
              statusResult.flattenedData
            );
            break;
          case "available":
            const availableResult = await this.workerFn.updateAvailableData(
              collection,
              item,
              uuidKey!,
              uuid!,
              data,
              event
            );
            this.updateAvailableDataStore(
              collection,
              item,
              uuidKey!,
              uuid!,
              availableResult
            );
            break;
          case "batchHeartbeat":
            this.updateBatchHeartbeatDataStore(collection, item, data);
            break;
          case "japiPublishTo":
            this.handleJapiPublishTo(data);
            break;
          case "anywhere":
            const anywhereResult = await this.workerFn.updateAnywhereData(data);
            if (anywhereResult) {
              this.updateAnywhereDataStore(
                anywhereResult.uuid,
                anywhereResult.newPayload,
                anywhereResult.flattenedPayload
              );
            }
            break;
          case "unknown":
            if (data.MostRecent) {
              const mostRecentResult = await this.workerFn.updateMostRecentData(
                uuid!,
                data
              );
              this.updateAnywhereDataStore(
                uuid!,
                mostRecentResult,
                mostRecentResult
              );
            } else {
              // console.warn("Update type is undefined, payload:", payload);
            }
            break;
          default:
            console.warn("Unknown update type:", type);
        }

        this.updateEventVersions(collection, data);
      } catch (error) {
        console.error("Error in updateDataStore:", error);
      }
    },

    updateBatchHeartbeatDataStore(
      collection: string,
      item: string,
      devices: any[]
    ) {
      devices.forEach((device) => {
        const id = device.id;
        if (!this.data[collection]) {
          this.data[collection] = {};
        }
        if (!this.data[collection][item]) {
          this.data[collection][item] = {};
        }
        if (!this.data[collection][item][id]) {
          this.data[collection][item][id] = [];
        }

        const existingIndex = this.data[collection][item][id].findIndex(
          (record: any) => record.id === id
        );
        if (existingIndex !== -1) {
          this.data[collection][item][id][existingIndex] = device;
        } else {
          this.data[collection][item][id].push(device);
        }

        if (!this.collections[item]) {
          this.collections[item] = {
            data: [],
            headers: [],
            selected: [],
            status: {},
            title: item,
            category: "Sensors",
            id: "id",
            loading: false,
            source: collection,
          };
        }

        const existingCollectionIndex = this.collections[item].data.findIndex(
          (record: any) => record.id === id
        );
        if (existingCollectionIndex !== -1) {
          this.collections[item].data[existingCollectionIndex] = device;
        } else {
          this.collections[item].data.push(device);
        }
      });

      this.updateCollectionHeaders(item);
    },

    updateStatusDataStore(id: string, serviceName: string, flattenedData: any) {
      /* if (!this.collections[serviceName]) {
        console.log(
          "new collection",
          serviceName,
          Object.keys(this.collections)
        );
        this.collections[serviceName] = {
          data: [],
          headers: [],
          selected: [],
          status: {},
          title: serviceName,
          category: "Services",
          id: "Service Account Uuid",
          loading: false,
          source: serviceName,
        };
      } */

      /* flattenedData.id = id;
      const index = this.collections[serviceName].data.findIndex(
        (x: any) => x.id === id
      );
      console.log("updateStatusDataStore", {
        id,
        serviceName,
        flattenedData,
        index,
        count: this.collections[serviceName].data.length,
      });

      if (index === -1) {
        this.collections[serviceName].data.push(flattenedData);
      } else {
        this.collections[serviceName].data[index] = {
          ...this.collections[serviceName].data[index],
          ...flattenedData,
        };
      } */

      // add to services
      if (!this.services[serviceName]) {
        this.services[serviceName] = {};
      }
      this.services[serviceName][id] = flattenedData;

      this.updateCollectionHeaders(serviceName);
    },

    updateAvailableDataStore(
      collection: string,
      item: string,
      idKey: string,
      id: string,
      flattenedData: any
    ) {
      if (!this.data[collection]) {
        this.data[collection] = {};
      }
      if (!this.data[collection][item]) {
        this.data[collection][item] = {};
      }

      const hardwareUuid =
        flattenedData.IdpDevice?.["Hardware Uuid"] ||
        flattenedData["IdpDevice.Hardware Uuid"];

      if (hardwareUuid) {
        if (!this.data[collection][item][id]) {
          this.data[collection][item][id] = [];
        }

        const existingIndex = this.data[collection][item][id].findIndex(
          (record: any) =>
            (record.IdpDevice?.["Hardware Uuid"] ||
              record["IdpDevice.Hardware Uuid"]) === hardwareUuid
        );

        if (existingIndex !== -1) {
          // Update existing record
          this.data[collection][item][id][existingIndex] = flattenedData;
        } else {
          // Add new record
          this.data[collection][item][id].push(flattenedData);
        }
      } else {
        console.warn(
          "Hardware Uuid not found in flattenedData:",
          flattenedData
        );
        if (!this.data[collection][item][id]) {
          this.data[collection][item][id] = [];
        }
        this.data[collection][item][id].push(flattenedData);
      }

      if (!this.collections[item]) {
        this.collections[item] = {
          data: [],
          headers: [],
          selected: [],
          status: {},
          title: item,
          category: "Sensors",
          id: idKey,
          loading: false,
          source: collection,
        };
      }

      flattenedData.id = id;

      if (collection === "cabsight" && item === "Cab") {
        this.collections.Cab.data = Object.values(
          this.data.cabsight.Cab
        ).flatMap((value) =>
          (value as any[]).map((record) =>
            flattenObj(record as { [key: string]: any })
          )
        );
      } else {
        const existingIndex = this.collections[item].data.findIndex(
          (record: any) =>
            (record.IdpDevice?.["Hardware Uuid"] ||
              record["IdpDevice.Hardware Uuid"]) === hardwareUuid
        );

        if (existingIndex !== -1) {
          // Update existing record
          this.collections[item].data[existingIndex] = flattenedData;
        } else {
          // Add new record
          this.collections[item].data.push(flattenedData);
        }
      }

      this.updateCollectionHeaders(item);
    },

    handleJapiPublishTo(data: any) {
      const cab = store.anywhereRegisterFields.find(
        (x: any) => x.traceUuid === data.TraceUuid
      );
      if (cab) {
        cab.status = data.Success ? "success" : "error";
        cab.errorMessage = data.Message;
      } else {
        console.error("cab not found", data);
      }
    },

    updateAnywhereDataStore(
      id: string,
      newPayload: any,
      flattenedPayload: any
    ) {
      const messagesCollection = "Anywhere Messages";
      const anywhereCollection = "Anywhere";

      this.updateCollection(messagesCollection, id, newPayload);

      // Update the Anywhere collection in the apiStore instead of the brokerStore
      store.api.updateAnywhereCollection(id, flattenedPayload);

      store.api.rebuildHeaders(anywhereCollection).catch((error: any) => {
        console.error("rebuildHeaders error", error);
      });
    },

    updateCollection(collectionName: string, id: string, data: any) {
      if (!this.data[collectionName]) {
        this.data[collectionName] = {};
      }
      this.data[collectionName][id] = {
        ...this.data[collectionName][id],
        ...data,
      };

      if (!this.collections[collectionName]) {
        this.collections[collectionName] = {
          data: [],
          headers: [],
          selected: [],
          status: {},
          title: collectionName,
          category: "Messages",
          id: "anywhere_id",
          loading: false,
          source: "Anywhere",
        };
      }

      data.id = id;
      const index = this.collections[collectionName].data.findIndex(
        (x: any) => x.id === id
      );
      if (index === -1) {
        this.collections[collectionName].data.push(data);
      } else {
        this.collections[collectionName].data[index] = {
          ...this.collections[collectionName].data[index],
          ...data,
        };
      }

      this.updateCollectionHeaders(collectionName);
    },

    async updateCollectionHeaders(collectionName: string) {
      if (store.collections[collectionName]?.data.length > 0 && this.workerFn) {
        const keys = Object.keys(store.collections[collectionName].data[0]);
        store.collections[collectionName].headers =
          await this.workerFn.updateCollectionHeaders(keys);
      } else {
        if (store.collections[collectionName]?.data.length === 0) {
          console.error("Collection is empty", collectionName);
        } else {
          console.error("Worker function is not initialized");
        }
      }
    },

    updateEventVersions(collection: string, data: any) {
      const version = data["Author.Version"] || data["Version"];
      if (version) {
        if (!this.eventVersions[collection]) {
          this.eventVersions[collection] = [];
        }
        if (!this.eventVersions[collection].includes(version)) {
          this.eventVersions[collection].push(version);
          /* store.api.rebuildHeaders(collection).catch((error: Error) => {
            console.error("rebuildHeaders error", error);
          }); */
        }
      }
    },
    handleOnReConnect() {
      this.retryTimes += 1;
      if (this.retryTimes > 5) {
        try {
          if (this.longReset) {
            this.client.end();
            store.broker.connecting = false;
            store.broker.connected = false;
            console.error("MQTT Disconnected. Long Reset failed, exiting... ");
            // throw snackbar error
            store.setSnack("MQTT Disconnected.", {
              color: "error",
              variant: "elavated",
              location: "bottom",
              buttonText: "Reconnect",
              buttonTextColor: "white",
              buttonIcon: "mdi-refresh",
              timeout: -1,
              buttonAction: () => {
                console.log("Reconnecting...");
                store.broker.retryTimes = 0;
                store.broker.hardReset = false;
                store.broker.longReset = false;
                store.broker.init();
                store.snack.visible = false;
              },
            });
          } else {
            if (!this.hardReset) {
              console.error(
                "MQTT connections maximum retry limit reached. Hard Resetting..."
              );
              this.hardReset = true;
              this.retryTimes = 0;
              this.client.end();
              this.connecting = false;
              this.connected = false;
              this.init();
            } else {
              console.error(
                "MQTT Hard Reset failed. Waiting for 60 seconds before retrying..."
              );
              this.longReset = true;
              this.hardReset = false;
              this.client.end();
              this.connecting = false;
              this.connected = false;
              this.retryTimes = 0;
              setTimeout(() => {
                this.longReset = false;
                this.init();
              }, 60000);
            }
          }
        } catch (error) {
          console.log(error as Error);
        }
      } else {
        console.warn("MQTT Reconnecting...", this.retryTimes);
      }
    },
    async manualSubscribe(topic: {
      subscribed: boolean;
      topic: any;
      qos: any;
    }) {
      if (!this.workerFn) return;
      try {
        await this.workerFn.subscribe(topic.topic, { qos: topic.qos });
        this.subscribeSuccess = true;
        topic.subscribed = true;
        console.log("Subscribe to topics success", topic);
        this.subscription.push(topic);
      } catch (error) {
        console.error("Subscribe to topics error", error as Error);
      }
    },
    async manualUnSubscribe(topic: any) {
      if (!this.workerFn) return;
      try {
        await this.workerFn.unsubscribe(topic.topic);
        this.subscription = this.subscription.filter(
          (x) => x.topic !== topic.topic
        );
        console.log("Unsubscribe success", topic);
      } catch (error) {
        console.log("Unsubscribe error", error as Error);
      }
    },
    async doSubscribe() {
      if (!this.workerFn) return;
      console.warn("begin doSubscribe");
      for (const topic of this.subscription) {
        if (topic.topic !== "#" && topic.topic !== "Org/+/Anywhere/message") {
          try {
            console.log("subscribing to topic", topic.topic);
            await this.workerFn.subscribe(topic.topic, topic.qos);
            topic.subscribed = true;
            this.subscribeSuccess = true;
          } catch (error) {
            console.error(
              "Subscribe to topics error",
              topic.topic,
              error as Error
            );
            topic.subscribed = false;
          }
        }
      }
      console.warn("end doSubscribe");
    },
    async doUnSubscribe() {
      if (!this.workerFn) return;
      for (const topic of this.subscription) {
        try {
          await this.workerFn.unsubscribe(topic.topic);
        } catch (error) {
          console.log("Unsubscribe error", error as Error);
        }
      }
    },
    async doPublish(payLoad: { topic: string; message: string }) {
      if (!this.workerFn) return;
      const { topic, message } = payLoad;
      try {
        await this.workerFn.publish(topic, message);
      } catch (error) {
        console.log("Publish error", error as Error);
        store.setSnack(error as Error, {
          color: "error",
          variant: "elavated",
          location: "bottom",
          buttonText: "Close",
          buttonTextColor: "white",
        });
      }
    },
    expireItems(obj: { [x: string]: any }): void {
      // check if the object contains a timestamp
      try {
        if (
          !(typeof obj === "object") ||
          obj === null ||
          (typeof obj === "object" && Object.keys(obj).length === 0)
        ) {
          return;
        }

        if (obj?.timestamp && obj?.DeviceKind) {
          // console.debug("expireItems", obj);
          const expireTimes = { default: 90, cab: 90 }; // JSON.parse(import.meta.env.VITE_ITEM_EXPIRE);

          const expireTime =
            (expireTimes[obj.DeviceKind.toLowerCase()] || expireTimes.default) *
            1000;

          const now = new Date().getTime();
          const timestamp = obj.timestamp;
          const diff = now - timestamp; // in milliseconds
          if (diff > expireTime) {
            this.deleteItem(obj);
            // console.log("expired item", { obj, diff, expireTime });
          } else {
            // console.log("not expired item", { obj, diff, expireTime });
          }
        } else if (typeof obj === "object" && obj !== null) {
          for (const key in obj) {
            if (obj[key] && typeof obj[key] === "object") {
              this.expireItems(obj[key]);
            }
          }
        }
      } catch (error) {
        console.error(error as Error, obj);
      }
    },
    deleteItem(obj: any) {
      const keys = Object.keys(this.data);
      for (const collection of keys) {
        const itemKeys = Object.keys(this.data[collection]);
        for (const item of itemKeys) {
          const uuidKeys = Object.keys(this.data[collection][item]);
          for (const uuid of uuidKeys) {
            if (uuid === obj.uuid || uuid === obj.Uuid || uuid === obj.Mac) {
              // delete from data
              // console.warn("delete", collection, item, uuid);
              delete this.data[collection][item][uuid];

              // delete from collections
              this.collections.Cab.data = this.collections.Cab.data.filter(
                (x: { [x: string]: any }) => x.uuid !== uuid
              );

              // delete from selected
              this.collections.Cab.selected =
                this.collections.Cab.selected.filter(
                  (x: { [x: string]: any }) => x.uuid !== uuid
                );
            }
          }
        }
      }
    },
    clearMessages() {
      this.receiveNews = [];
    },
    generateRandomMac() {
      const hexDigits = "0123456789ABCDEF";
      let mac = "";
      for (let i = 0; i < 6; i++) {
        mac += hexDigits.charAt(Math.floor(Math.random() * 16));
        mac += hexDigits.charAt(Math.floor(Math.random() * 16));
        if (i < 5) mac += ":";
      }

      return mac;
    },
    generatePacket() {
      const eventTypes = ["Available", "Marked"];

      const randomEvent =
        eventTypes[Math.floor(Math.random() * eventTypes.length)];

      const randomMac = this.generateRandomMac();

      const payLoad = [
        {
          Event: randomEvent,
        },
        {
          Mac: randomMac,
          DeviceKind: "Cab",
          Firmware: "2.0",
          "Public Key": "public_key",
        },
      ];

      this.doPublish({
        topic: "Service/CabSight/unbound",
        message: JSON.stringify(payLoad),
      });
    },
    convertToValidJson(jsonString: string) {
      const parts = jsonString.split("}{");
      if (parts.length === 2) {
        return "[" + parts.join("},{") + "]";
      }
      return jsonString;
    },
  },
});
