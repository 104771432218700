<script setup lang="ts">
import { store } from '@/stores/store'
import { computed, ref } from 'vue'
import { useTheme } from 'vuetify'
import ProgressSnackbar from './ProgressSnackbar.vue'

const vuetifyTheme = useTheme()

const currentTheme = vuetifyTheme.current.value.colors

import { ComponentCustomProperties } from 'vue'
declare module 'vue' {
  interface ComponentCustomProperties {
    $vuetify: any
  }
}

interface Organization {
  Name: string
  Uuid: string
  Anywhere: {
    Email: string
    Password: string
    ElitePublicKey: string
  }
}

interface RequestFormFactor {
  id: string
  formfactor: string
  batteryreplaceable: string
}

interface Props {
  requestFormFactor: Array<RequestFormFactor>
  isRequestFormFactorDialogVisible: boolean
}

interface Emit {
  (e: 'update:isRequestFormFactorDialogVisible', value: boolean): void
  (e: 'submit', value: RequestFormFactor): void
}

// HardwareId, SystemID, and Organization is passed in as props
const props = withDefaults(defineProps<Props>(), {
  requestFormFactor: () => [],
  isRequestFormFactorDialogVisible: true
})

const emit = defineEmits<Emit>()

const requestFormFactor = ref(props.requestFormFactor)
const deviceCount = computed(() => props.requestFormFactor.length)

function resetForm() {
  emit('update:isRequestFormFactorDialogVisible', false)
  requestFormFactor.value = structuredClone(toRaw(props.requestFormFactor))
}

const showProgressSnackbar = ref(false)
const progressSnackbarText = ref('')
const progressSnackbarTotal = ref(0)
const progressSnackbarCurrent = ref(0)

const progressSnackbarProps = computed(() => ({
  show: showProgressSnackbar.value,
  text: progressSnackbarText.value,
  totalItems: progressSnackbarTotal.value,
  currentItem: progressSnackbarCurrent.value,
  position: 'bottom' as const
}))

async function onFormSubmit() {
  try {
    const collection = 'Cab'
    console.debug('payLoads', props.requestFormFactor)

    // remove empty form factors
    const requestFormFactor = props.requestFormFactor.filter(
      (x: any) => x.length === 2
    )

    // add formFactor to payload
    requestFormFactor.forEach((x: any) => {
      if (x.length != 2) {
        return
      }
      x[1].formfactor = selectedFormFactor.value
      x[1].batteryreplaceable =
        selectedFormFactor.value === 'Round' ? 'CR2032' : null
    })

    console.debug('payLoads', requestFormFactor)

    if (requestFormFactor.length === 0) {
      store.setSnack('No devices selected', {
        color: 'error',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'white'
      })
      return
    }

    showProgressSnackbar.value = true
    progressSnackbarText.value = `Binding ${collection}s...`
    progressSnackbarTotal.value = props.requestFormFactor.length
    progressSnackbarCurrent.value = 0

    const results: any[] = []
    for (let index = 0; index < requestFormFactor.length; index++) {
      const payload = requestFormFactor[index]
      const result = await store.api.postData(payload)
      console.debug('result', result)
      progressSnackbarCurrent.value = index + 1
      results.push(result)
    }

    showProgressSnackbar.value = false

    const finalResults = results.flat()
    const processedCount = finalResults.filter(r => r.success).length

    console.debug('results', { finalResults })
    // if promise is resolved, remove results from selectedItems
    if (processedCount > 0) {
      /* finalResults.forEach((x: any) => {
        if (x.success) {
          store.collections[collection].selected = store.collections[
            collection
          ].selected.filter((y: any) => y !== x.id)
        }
      }) */

      // clear selected items using store mutation
      store.clearSelectedItems(collection)

      console.debug(
        'selected remaining:',
        collection,
        store.collections[collection].selected
      )

      store.setSnack(`Successfully bound ${processedCount} ${collection}s`, {
        color: 'info',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'white'
      })

      // refresh data
      await store.api.refreshData('Anywhere')

      // clear selected form factor and organization
      selectedFormFactor.value = ''
      requestFormFactor.length = 0
      props.requestFormFactor.length = 0
    }
    props.requestFormFactor.forEach(formFactor => {
      emit('submit', formFactor)
    })
    resetForm()
  } catch (error) {
    console.error('Error in onFormSubmit', error)
    showProgressSnackbar.value = false
    let errorMessage = 'An unknown error occurred'

    if (error && typeof error === 'object') {
      if (
        Array.isArray(error) &&
        error.length > 0 &&
        typeof error[0] === 'object'
      ) {
        const firstError = error[0]
        if ('Message' in firstError) {
          const messageContent = firstError.Message
          if (typeof messageContent === 'string') {
            const match = messageContent.match(/\[message\] => (.+)/)
            errorMessage = match ? match[1] : messageContent
          }
        } else if ('message' in firstError) {
          errorMessage = firstError.message
        }
      } else if ('Message' in error) {
        const messageContent = error.Message
        if (typeof messageContent === 'string') {
          const match = messageContent.match(/\[message\] => (.+)/)
          errorMessage = match ? match[1] : messageContent
        }
      } else if ('message' in error) {
        errorMessage = (error as { message: string }).message
      }
    }

    if (errorMessage === 'An unknown error occurred') {
      errorMessage =
        'An unexpected error occurred while trying to bind the device. Please try again or contact support if the issue persists.'
    }
    console.error('Full API Response:', JSON.stringify(error, null, 2))

    store.setSnack(`Failed to bind: ${errorMessage}`, {
      color: 'error',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
  }
}

import badge from '/src/assets/images/badge.png'
import pendant from '/src/assets/images/pendant.png'

const selectedFormFactor = ref('')
const formFactors: { bgImage: string; value: string }[] = [
  {
    bgImage: badge,
    value: 'Rectangular'
  },
  {
    bgImage: pendant,
    value: 'Round'
  }
]

watch(selectedFormFactor, newValue => {
  if (newValue) {
    onFormSubmit()
  }
})
</script>

<template>
  <VDialog
    :width="$vuetify.display.smAndDown ? 'auto' : 610"
    :model-value="props.isRequestFormFactorDialogVisible"
    @update:model-value="
      val => $emit('update:isRequestFormFactorDialogVisible', val)
    "
  >
    <!-- 👉 Dialog close btn -->
    <DialogCloseBtn
      @click="$emit('update:isRequestFormFactorDialogVisible', false)"
    />

    <VCard
      v-if="props.requestFormFactor"
      class="pa-sm-5 pa-3"
      title="Select Form Factor"
      :subtitle="`Select the appropriate form factor for all (${deviceCount}) devices.`"
    >
      <VCardText>
        <!-- 👉 Form -->
        <VForm class="mt-4">
          <VRow>
            <VCol cols="12" class="text-center mb-4">
              <CustomRadiosWithImage
                v-model:selected-radio="selectedFormFactor"
                :radio-content="formFactors"
                :grid-column="{ sm: '6', cols: '12' }"
              />
            </VCol>
          </VRow>
        </VForm>
      </VCardText>
    </VCard>
  </VDialog>
  <ProgressSnackbar v-bind="progressSnackbarProps" />
</template>
<style>
.checks {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.v-text-field__suffix {
  font-size: 0.8em !important;
}

.readonly {
  border: none !important;
}
</style>
