<script setup lang="ts">
import BulkAddOrganizationsDialog from '@/components/BulkAddOrganizationsDialog.vue'
import CreateEliteDialog from '@/components/CreateEliteDialog.vue'
import CreateOrganizationDialog from '@/components/CreateOrganizationDialog.vue'
import { store } from '@/stores/store'
import { computed, ref } from 'vue'

const props = defineProps({
  rightCardHeight: {
    type: Number,
    default: 0
  }
})

const isCreateOrganizationDialogVisible = ref(false)
const isCreateEliteDialogVisible = ref(false)
const isBulkAddOrganizationsDialogVisible = ref(false)
const newOrganizationParentId = ref('')
const isEditMode = ref(false)
const organizationToEdit = ref({} as any)
const eliteToEdit = ref({} as any)
const isMinimized = ref(false)

import { ActionList } from '@/types/entities'

const rowClass = (item: any) => {
  const itemId = item?.item?.id || ''
  const rowClass =
    itemId === store.activeOrganization ? { class: 'selected' } : { class: '' }

  /*   console.log(
    'rowClass',
    { item, itemId, activeOrg: store.activeOrganization },
    rowClass
  ) */

  return rowClass
}

const icons = {
  add: 'mdi-plus',
  edit: 'mdi-pencil',
  remove: 'mdi-delete',
  refresh: 'mdi-refresh',
  viewDetails: 'mdi-eye',
  addElite: 'mdi-plus'
}

const actionsList: ActionList = {
  Organization: [
    { title: 'Edit Organization', value: 'edit', singleSelect: true },
    { title: 'Remove Organization', value: 'remove' } /* ,
    { title: 'View Details', value: 'viewDetails', singleSelect: true } */
  ],
  All: [
    { title: 'Add Organization', value: 'add' },
    /*  { title: 'Bulk Add Organizations', value: 'bulkAdd' }, */
    { title: 'Refresh', value: 'refresh' }
  ]
}

const actions = computed(() => {
  const selectedOrg =
    store.collections.Organization.selected.length === 1
      ? store.collections.Organization.data.find(
          org => org.id === store.collections.Organization.selected[0]
        )
      : null

  return (
    store.collections.Organization.selected.length > 1
      ? actionsList.Organization.filter(x => !x.singleSelect)
      : store.collections.Organization.selected.length === 0
      ? [...actionsList.All]
      : actionsList.Organization.filter(
          action =>
            !action.condition || (selectedOrg && action.condition(selectedOrg))
        )
  ).map(x => {
    return {
      ...x,
      icon: !x.icon ? icons[x.value as keyof typeof icons] : x.icon
    }
  })
})

const itemActions = computed(() => {
  return (item: any) => {
    const actions = [
      ...actionsList.Organization,
      { title: 'Add Elite', value: 'addElite', singleSelect: true }
    ]

    if (item && item.subisallowed) {
      actions.unshift({ title: 'Add Organization', value: 'add' })
    }

    return actions.map(x => ({
      ...x,
      icon: !x.icon ? icons[x.value as keyof typeof icons] : x.icon
    }))
  }
})

const entityActions = {
  remove: async () => {
    try {
      console.info('remove Organization')
      const selectedItems = store.collections.Organization.selected

      // Confirmation prompt
      const confirmResult = await new Promise(resolve => {
        store.setSnack(
          `Are you sure you want to remove ${selectedItems.length} Organization(s)?`,
          {
            color: 'warning',
            variant: 'elevated',
            location: 'bottom',
            buttonText: 'Confirm',
            buttonTextColor: 'white',
            timeout: -1, // Don't auto-hide
            closeButtonText: 'Cancel',
            buttonAction: () => resolve(true),
            onClose: () => resolve(false)
          }
        )
      })

      if (!confirmResult) {
        console.info('Remove operation cancelled by user')
        store.collections.Organization.selected = []
        return
      }

      const payLoads = [
        { Method: 'Remove Organization' },
        {
          id: selectedItems[0]
        }
      ]
      const results = await store.api.postData(payLoads)
      const finalResults = JSON.parse(JSON.stringify(results))

      const processedCount = finalResults.processed.length

      console.debug('results', { finalResults })
      if (finalResults.success) {
        if (finalResults?.processed?.length) {
          finalResults.processed.forEach(x => {
            store.collections.Organization.selected =
              store.collections.Organization.selected.filter(y => y !== x.id)
          })
        } else {
          store.clearSelectedItems('Organization')
        }

        console.debug(
          'selected remaining:',
          store.collections.Organization.selected
        )

        store.setSnack(`Removed 1 Organization`, {
          color: processedCount ? 'success' : 'warning',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: '#333333'
        })

        await store.api.refreshData('Organization')
      }
    } catch (error: any) {
      console.error('remove', error[0].Message)
      store.setSnack(`Failed to remove Organization: ${error?.[0]?.Message}`, {
        color: 'error',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'white'
      })
    }
  },
  add: async () => {
    try {
      isEditMode.value = false
      console.info(
        'add Organization',
        store.collections.Organization.selected[0]
      )
      newOrganizationParentId.value =
        store.collections.Organization.selected[0] ||
        selectedParentOrg.value.id ||
        ''

      organizationToEdit.value = {
        id: '',
        name: '',
        dsc: '',
        organization_id: newOrganizationParentId.value
      }
      isCreateOrganizationDialogVisible.value = true

      store.collections.Organization.selected = []
    } catch (error) {
      console.error('add', error)
    }
  },
  edit: async () => {
    try {
      isEditMode.value = true
      const selectedOrgId = store.collections.Organization.selected[0]
      const orgToEdit = store.collections.Organization.data.find(
        (org: { Name: string; id: string }) => org.id === selectedOrgId
      )

      console.info(
        'edit Organization',
        store.collections.Organization.selected[0],
        orgToEdit
      )

      if (orgToEdit) {
        newOrganizationParentId.value = orgToEdit.organization_id
        organizationToEdit.value = orgToEdit
        isCreateOrganizationDialogVisible.value = true
      } else {
        throw new Error('Selected organization not found')
      }

      store.collections.Organization.selected = []
    } catch (error) {
      console.error('edit', error)
    }
  },
  refresh: async () => {
    try {
      console.info('refresh Organization')

      store.collections.Organization.selected = []
      store.collections.Organization.data = []

      await store.api.refreshData('Organization')
    } catch (error) {
      console.error('refresh', error)
    }
  },
  bulkAdd: async () => {
    try {
      console.info('bulk add Organizations')
      isBulkAddOrganizationsDialogVisible.value = true
    } catch (error) {
      console.error('bulkAdd', error)
    }
  },
  viewDetails: async () => {
    console.info('viewDetails Organization')
    const selectedItem = store.collections.Organization.selected[0]
    const item = store.collections.Organization.data.find(
      (x: { id: string }) => x.id === selectedItem
    )
    console.debug('viewDetails', { selectedItem, item })
    store.collections.Organization.selected = []
    // Implement view details logic here
  },
  addElite: async item => {
    try {
      console.info('add Elite', item)
      eliteToEdit.value = {
        organization_id: item.id,
        organization_name: item.name
      }
      isCreateEliteDialogVisible.value = true
    } catch (error) {
      console.error('addElite', error)
    }
  }
}

const handleClick = (value: any) => {
  console.debug('handleClick', { value })
  const action = entityActions[value as keyof typeof entityActions]
  if (typeof action === 'function') {
    action(value) // Pass the value as an argument to the action function
  } else {
    console.error(`Action ${value.id} is not a function`)
  }
}

const handleClickInline = (value: any, item: any) => {
  console.debug('handleClickInline', { value, item })

  store.collections.Organization.selected = []
  store.collections.Organization.selected.push(item.id)

  if (value === 'addElite') {
    entityActions.addElite(item)
  } else {
    handleClick(value)
  }
}

// get right-card-height from props rounded down to nearest 10
const rightCardHeight = computed(() => {
  return Math.floor(props.rightCardHeight / 10) * 10 - 123
})

async function itemSelected(_event: any, obj: any = null, isGroupOpen: any) {
  console.debug('itemSelected', { _event, items: _event.items, obj })
  if (_event?.items?.[0]?.raw.organization_id) {
    const org = _event?.items?.[0]?.raw.organization_id || ''
    console.log('org', org, _event.items[0].raw)
    // toggle group

    const opened = isGroupOpen(_event)
    if (opened) {
      // add to activeParentOrg
      store.setActiveParentOrg(org)
    } else {
      // remove from activeParentOrg
      store.setActiveParentOrg('')
      store.setActiveOrganization('')
      console.log('store.activeParentOrg removed', store.activeParentOrg)
    }
  } else {
    // add or remove from selectedItems
    if (obj) {
      const selected = await store.setActiveOrganization(obj.item.id)
      if (selected) {
        // make sure .selected isn't out of view
        const table = document.getElementById('organization')
        if (table) {
          const selectedRow = table.querySelector('.selected')
          if (selectedRow) {
            selectedRow.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'center'
            })

            // check again in case the table is still loading
            setTimeout(() => {
              const table = document.getElementById('organization')
              if (table) {
                const selectedRow = table.querySelector('.selected')
                if (selectedRow) {
                  if (selectedRow.getBoundingClientRect().top < 0) {
                    selectedRow.scrollIntoView({
                      behavior: 'smooth',
                      block: 'end',
                      inline: 'center'
                    })
                  }
                }
              }
            }, 1000)

            setTimeout(() => {
              const table = document.getElementById('organization')
              if (table) {
                const selectedRow = table.querySelector('.selected')
                if (selectedRow) {
                  selectedRow.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'center'
                  })
                }
              }
            }, 1500)
          }
        }
      }
    }
  }

  watchEffect(() => {
    if (selectedParentOrg.value.id) {
      store.setActiveParentOrg(selectedParentOrg.value.id)
    } else {
      store.setActiveParentOrg('')
    }
  })
}

const insertHeaders = [
  {
    title: 'Organization',
    key: 'name',
    align: 'start' as const,
    sortable: true,
    groupable: false
  },
  {
    title: 'Description',
    key: 'dsc',
    align: 'end' as const,
    sortable: true,
    groupable: false
  },
  /*  {
    title: 'id',
    key: 'id',
    align: 'start' as const,
    sortable: true,
    groupable: false
  },
  {
    title: 'name',
    key: 'name',
    align: 'start' as const,
    class: 'd-none',
    sortable: true,
    groupable: false
  }, */
  {
    title: '',
    key: 'actions',
    align: 'center' as const,
    sortable: false,
    groupable: false,
    width: '48px'
  }
]
const selectedParentOrg = ref({ id: '', name: 'All Organizations' })

const composeHeaders = () => {
  const headers: never[] = []
  return [...insertHeaders, ...headers]
}

import type { Organization } from '@/types/entities'

const composeItems = (): Organization[] => {
  return !selectedParentOrg.value.id
    ? store.collections.Organization.data
    : store.collections.Organization.data.filter(
        (x: Organization) => x.organization_id === selectedParentOrg.value.id
      )
}
const search = ref('')
const isLoading = computed(() => store.collections['Organization'].loading)

const parentOrgList = computed(() => {
  // find all unique organization_id values in organizations and return the organization_id and name of each
  const uniqueOrganizationIds = [
    ...new Set(
      store.collections.Organization.data.map((x: any) => x.organization_id)
    )
  ]

  return [
    {
      id: '',
      name: 'All Organizations'
    },
    ...uniqueOrganizationIds.map((x: any) => {
      return {
        id: x,
        name: store.getOrganizationNameById(x)
      }
    })
  ]
})
</script>

<template>
  <createOrganizationDialog
    v-model:isCreateOrganizationDialogVisible="
      isCreateOrganizationDialogVisible
    "
    :parentOrganizationId="newOrganizationParentId || selectedParentOrg.id"
    :editMode="isEditMode"
    :organizationToEdit="organizationToEdit"
    @organization-created="store.api.refreshData('Organization')"
    @organization-updated="store.api.refreshData('Organization')"
  />
  <createEliteDialog
    v-model:isCreateEliteDialogVisible="isCreateEliteDialogVisible"
    :organizationId="eliteToEdit.organization_id"
    :organizationName="eliteToEdit.organization_name"
    @elite-created="store.api.refreshData('Elite')"
  />
  <BulkAddOrganizationsDialog
    v-model:isBulkAddOrganizationsDialogVisible="
      isBulkAddOrganizationsDialogVisible
    "
    @organizations-created="store.api.refreshData('Organization')"
  />

  <VCard title="Organizations" class="h-100">
    <template #append>
      <VRow align="center">
        <VCol>
          <AppSelect
            v-model="selectedParentOrg"
            :items="parentOrgList"
            density="compact"
            item-value="id"
            item-title="name"
            return-object
            single-line
            label=""
            outlined
          />
        </VCol>
        <VCol>
          <AppTextField
            v-model="search"
            density="compact"
            :placeholder="
              !selectedParentOrg.id
                ? 'Search All Organizations'
                : 'Search ' + selectedParentOrg.name
            "
            :append-inner-icon="search ? 'tabler-x' : 'tabler-search'"
            @click:append-inner="search = ''"
            dense
            outlined
            class="pa-1"
          />
        </VCol>
      </VRow>
    </template>

    <VDivider />

    <div v-if="!isMinimized">
      <VSkeletonLoader
        v-for="n in 10"
        :key="n"
        class="mx-auto"
        type="avatar, text"
        :loading="isLoading"
        v-if="isLoading"
      />

      <VSkeletonLoader class="mx-auto" type="avatar, text" :loading="isLoading">
        <VDataTableVirtual
          id="organization"
          :headers="composeHeaders()"
          :items="composeItems()"
          :search="search"
          item-value="id"
          item-title="name"
          :hover="true"
          density="compact"
          class="text-body-2"
          @click:row="itemSelected"
          :selected="[store.activeOrganization]"
          :loading="isLoading"
          fixed-header
          :height="rightCardHeight"
          :max-height="rightCardHeight - 110"
          :row-props="rowClass"
        >
          <template #item.name="{ item }: { item: any }">
            <div class="v-data-table-column--align-start">
              <strong>{{ item.name }}</strong>
              <div class="text-caption">{{ item.id }}</div>
            </div>
          </template>
          <template #item.dsc="{ item }">
            <div class="v-data-table-column--align-start">
              <div class="text-caption">{{ item.dsc }}</div>
            </div>
          </template>
          <template #header.actions>
            <IconMoreBtn :menu-list="actions" :onItemClick="handleClick">
              <v-icon>tabler-dots-vertical</v-icon>
            </IconMoreBtn>
          </template>
          <template #item.actions="{ item }">
            <IconMoreBtn
              :menu-list="itemActions(item)"
              :onItemClick="value => handleClickInline(value, item)"
              :item="item.id"
            >
              <v-icon>tabler-dots-vertical</v-icon>
            </IconMoreBtn>
          </template>
          <template #no-data>
            <VCard class="no-data pt-5 pb-5">
              <VCardText>
                No results found for <strong>{{ search }}</strong
                >.
                <sup><v-icon @click="search = ''">mdi-close</v-icon></sup>
              </VCardText>
            </VCard>
          </template>
        </VDataTableVirtual>
      </VSkeletonLoader>
    </div>
  </VCard>
</template>

<style lang="scss">
.v-table {
  padding-inline-start: 0.75em;

  tr:not(:last-child) {
    td {
      border: none !important;
    }
  }
}

.v-skeleton-loader__bone.v-skeleton-loader__avatar {
  block-size: 30px;
  inline-size: 30px;
  margin-block: 10px;
  margin-inline: 13px;
  max-block-size: 30px;
  max-inline-size: 30px;
  min-block-size: 30px;
  min-inline-size: 30px;
}

td.data-table-group {
  padding-block: 0 !important;
  padding-inline: 6px !important;
}

.expand > button > .v-btn__overlay {
  display: block !important;
  border-radius: 50%;
  background-color: rgba(var(--v-theme-secondary), 0.8) !important;
  opacity: 1 !important;
}

.v-data-table-footer {
  justify-content: center;
  border: 1px solid rgb(245 245 245 / 15%);
  border-width: 1px 0 0;
  margin-block-start: 0;
  padding-block: 1.25em;
}

.v-data-table__tr:hover {
  background-color: #34343412;
  cursor: pointer;
}

.active-organization {
  background-color: rgba(var(--v-theme-primary), 0.1);
}

.active-organization:hover {
  background-color: rgba(var(--v-theme-primary), 0.15);
}

.selected {
  background-color: rgba(50, 115, 220, 10%) !important;
}

.selected > td.v-data-table__td.v-data-table-column--align-start {
  background: inherit !important;
  font-weight: 700 !important;
}

.v-data-table-header__content {
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 0.25px;
}

div.v-table__wrapper {
  overflow: scroll;

  /* border-block-end: 1px solid rgba(var(--v-border-color), var(--v-border-opacity)); */
}

/* stylelint-disable-next-line no-descending-specificity */
.data-table-group {
  cursor: pointer;
}

tr.v-data-table__tr.v-data-table__tr--clickable {
  block-size: 5em;
}

.v-card-item {
  grid-template-columns: max-content;
  padding-block: 1.4em;
  padding-inline: 1.5em;
}
</style>
