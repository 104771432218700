import { store } from "@/stores/store";
import { InteractionType } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";

let graphClient: Client | null = null;

const getGraphClient = (): Client => {
  if (!graphClient) {
    const msalInstance = store.auth.msalInstance;
    if (!msalInstance) {
      throw new Error("MSAL instance is not available.");
    }

    let account = msalInstance.getActiveAccount();
    if (!account) {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        account = accounts[0];
        msalInstance.setActiveAccount(account);
      } else {
        throw new Error("No accounts found. Please sign in.");
      }
    }

    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      msalInstance,
      {
        account: account,
        interactionType: InteractionType.Popup,
        scopes: ["User.Read", "User.ReadWrite", "User.ReadBasic.All"],
      }
    );

    graphClient = Client.initWithMiddleware({ authProvider });
  }
  return graphClient;
};

export const createEntraUser = async (userData: any) => {
  try {
    const client = getGraphClient();
    console.log(
      "Attempting to create Entra User with data:",
      JSON.stringify(userData, null, 2)
    );

    const isExternalUser = !userData.userPrincipalName.endsWith('@resptechltd.onmicrosoft.com');

    if (isExternalUser) {
      // Invite external user
      const invitationResult = await inviteExternalUser(userData);
      console.log("External user invited successfully:", invitationResult);
      return invitationResult;
    } else {
      // Create internal user
      // Ensure required properties are present and valid
      const requiredFields = [
        "displayName",
        "userPrincipalName",
        "mailNickname",
        "passwordProfile",
      ];
      for (const field of requiredFields) {
        if (!userData[field]) {
          throw new Error(`${field} is required`);
        }
      }

      if (!userData.accountEnabled) {
        userData.accountEnabled = true;
      }

      // Ensure mailNickname is set (can be derived from userPrincipalName)
      if (!userData.mailNickname) {
        userData.mailNickname = userData.userPrincipalName.split("@")[0];
      }

      // Log the full request details
      console.log("Request URL: /users");

      // Remove any fields that are not part of the User resource type or are empty
      const validUserData: any = {
        accountEnabled: userData.accountEnabled,
        displayName: userData.displayName,
        mailNickname: userData.mailNickname,
        userPrincipalName: userData.userPrincipalName,
        passwordProfile: userData.passwordProfile,
        givenName: userData.givenName,
        surname: userData.surname,
        mail: userData.mail,
      };

      // Only include non-empty optional fields
      if (userData.jobTitle) validUserData.jobTitle = userData.jobTitle;
      if (userData.department) validUserData.department = userData.department;
      if (userData.mobilePhone) validUserData.mobilePhone = userData.mobilePhone;
      if (userData.officeLocation) validUserData.officeLocation = userData.officeLocation;

      console.log(
        "Sanitized user data for creation:",
        JSON.stringify(validUserData, null, 2)
      );

      const response = await client.api("/users").post(validUserData);
      console.log("Entra User created successfully:", response);
      return response;
    }
  } catch (error) {
    console.error("Error creating Entra User:", error);
    if (error instanceof Error && "response" in error) {
      const errResponse = (error as any).response;
      console.error(
        "Error response:",
        errResponse.status,
        errResponse.statusText
      );
      console.error(
        "Error details:",
        JSON.stringify(errResponse.data, null, 2)
      );
    } else {
      console.error(
        "Error object:",
        JSON.stringify(error, Object.getOwnPropertyNames(error))
      );
    }

    // Check for specific error conditions
    if (
      error instanceof Error &&
      "statusCode" in error &&
      error.statusCode === 404
    ) {
      console.error(
        "404 Not Found error. Please check if the application has the necessary permissions to create users."
      );
    } else if (
      error instanceof Error &&
      "statusCode" in error &&
      (error as any).statusCode === 403
    ) {
      console.error(
        "403 Forbidden error. The application lacks the required permissions to create users."
      );
    } else if (
      error instanceof Error &&
      "statusCode" in error &&
      (error as any).statusCode === 400
    ) {
      console.error(
        "400 Bad Request error. Please check the user data for any invalid or missing required fields."
      );
    }

    // Log additional error properties
    if (error instanceof Error) {
      console.error("Error name:", error.name);
      console.error("Error message:", error.message);
      if (error.stack) {
        console.error("Error stack:", error.stack);
      }
    } else {
      console.error("Unknown error:", error);
    }

    if (error instanceof Error) {
      throw new Error(
        `Failed to create Entra User: ${error.message || "Unknown error"}`
      );
    } else {
      throw new Error("Failed to create Entra User: Unknown error");
    }
  }
};

export const updateEntraUser = async (userId: string, userData: any) => {
  try {
    const client = getGraphClient();
    console.log("Attempting to update Entra User with ID:", userId);
    console.log("User data:", JSON.stringify(userData, null, 2));

    const validFields = [
      "displayName",
      "givenName",
      "surname",
      "userPrincipalName",
      "mail",
      "jobTitle",
      "department",
      "mobilePhone",
      "officeLocation",
    ];

    const validUserData = Object.fromEntries(
      Object.entries(userData).filter(
        ([key, value]) => validFields.includes(key) && value !== ""
      )
    );

    // Handle forceChangePasswordNextSignIn separately
    if (
      userData.passwordProfile &&
      userData.passwordProfile.forceChangePasswordNextSignIn !== undefined
    ) {
      validUserData.passwordProfile = {
        forceChangePasswordNextSignIn:
          userData.passwordProfile.forceChangePasswordNextSignIn,
      };
    }

    await client.api(`/users/${userId}`).patch(validUserData);
    console.log("Entra User updated successfully");

    // Fetch and return the updated user data
    const updatedUser = await client.api(`/users/${userId}`).get();
    console.log("Updated user data:", updatedUser);
    return updatedUser;
  } catch (error) {
    console.error("Error updating Entra User:", error);
    if (error instanceof Error && "response" in error) {
      if (
        error instanceof Error &&
        "response" in error &&
        typeof error.response === "object" &&
        error.response !== null
      ) {
        console.error(
          "Error response:",
          (error.response as any).status,
          (error.response as any).statusText
        );
      }
      if (typeof error.response === "object" && error.response !== null) {
        console.error(
          "Error details:",
          JSON.stringify((error.response as any).data, null, 2)
        );
      }
    }
    if (error instanceof Error) {
      throw new Error(
        `Failed to update Entra User: ${error.message || "Unknown error"}`
      );
    } else {
      throw new Error("Failed to update Entra User: Unknown error");
    }
  }
};

export const deleteEntraUser = async (userId: string) => {
  const client = getGraphClient();
  return await client.api(`/users/${userId}`).delete();
};

export const getEntraUsers = async () => {
  try {
    const client = getGraphClient();
    const response = await client
      .api("/users")
      .select(
        "id,displayName,givenName,surname,userPrincipalName,mail,jobTitle,department,mobilePhone,officeLocation"
      )
      .get();
    return response.value || [];
  } catch (error) {
    console.error("Error fetching Entra users:", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export const getEntraGroups = async () => {
  try {
    const client = getGraphClient();
    const response = await client
      .api("/groups")
      .select("id,displayName")
      .filter("startswith(displayName, 'Headquarters')")
      .get();
    return response.value || [];
  } catch (error) {
    console.error("Error fetching Entra groups:", error);
    return [];
  }
};

export const addUserToGroup = async (userId: string, groupId: string) => {
  try {
    const client = getGraphClient();

    // Check if the user is already a member of the group
    const members = await client
      .api(`/groups/${groupId}/members`)
      .select("id")
      .get();

    const isAlreadyMember = members.value.some(
      (member: any) => member.id === userId
    );

    if (isAlreadyMember) {
      console.log("User is already a member of the group");
      return true;
    }

    // If not a member, add the user to the group
    await client.api(`/groups/${groupId}/members/$ref`).post({
      "@odata.id": `https://graph.microsoft.com/v1.0/directoryObjects/${userId}`,
    });

    console.log("User successfully added to the group");
    return true;
  } catch (error) {
    console.error("Error adding user to group:", error);
    throw error;
  }
};

export const getUserGroups = async (userId: string) => {
  try {
    const client = getGraphClient();
    const response = await client
      .api(`/users/${userId}/memberOf`)
      .select("id,displayName")
      .get();

    console.log("User groups:", response.value);

    // Filter the groups on the client side
    const headquartersGroups = response.value.filter(
      (group: { id: string; displayName: string }) =>
        group.displayName?.startsWith("Headquarters")
    );

    return headquartersGroups || [];
  } catch (error) {
    console.error("Error fetching user groups:", error);
    return [];
  }
};

export const saveUserSettings = async (settings: any) => {
  try {
    const client = getGraphClient();
    const extension = {
      "@odata.type": "microsoft.graph.openTypeExtension",
      "extensionName": "com.resptech.userSettings",
      ...settings
    };

    await client
      .api('/me/extensions')
      .post(extension);

    console.log('Settings saved successfully');
  } catch (error) {
    console.error('Error saving settings:', error);
    throw error;
  }
};

export const getUserSettings = async () => {
  try {
    const client = getGraphClient();
    const result = await client
      .api('/me/extensions')
      .filter("id eq 'com.resptech.userSettings'")
      .get();

    if (result.value && result.value.length > 0) {
      console.log('Retrieved settings:', result.value[0]);
      return result.value[0];
    } else {
      console.log('User settings not found, returning empty object');
      return {};
    }
  } catch (error) {
    console.error('Error retrieving settings:', error);
    if (error.statusCode === 404) {
      console.log('User settings not found, returning empty object');
      return {};
    }
    throw error;
  }
};
const inviteExternalUser = async (userData: any) => {
  const client = getGraphClient();
  const invitationData = {
    invitedUserEmailAddress: userData.mail,
    invitedUserDisplayName: userData.displayName,
    inviteRedirectUrl: "https://hq.responsetech.ltd",
    sendInvitationMessage: true,
    invitedUserMessageInfo: {
      customizedMessageBody: "You have been invited to join Headquarters."
    }
  };

  console.log("Sending invitation for external user:", userData.mail);
  const invitationResult = await client.api("/invitations").post(invitationData);
  console.log("Invitation sent successfully:", invitationResult);

  // Use the invitedUser.id from the invitation result
  const invitedUserId = invitationResult.invitedUser.id;
  console.log("Invited user ID:", invitedUserId);

  // Wait for a longer period to allow the invitation to be processed
  const initialDelay = 3000; // 3 seconds
  console.log(`Waiting for ${initialDelay/1000} seconds before attempting to fetch the user...`);
  await new Promise(resolve => setTimeout(resolve, initialDelay));

  // Retry fetching the invited user with exponential backoff
  const maxRetries = 10;
  let invitedUser: any = null;
  for (let i = 0; i < maxRetries; i++) {
    try {
      console.log(`Attempt ${i + 1} to fetch invited user...`);
      invitedUser = await client.api(`/users/${invitedUserId}`).get();
      console.log("Successfully fetched invited user:", invitedUser);
      break;
    } catch (error) {
      console.error(`Error fetching invited user (attempt ${i + 1}):`, error);
      if (i === maxRetries - 1) throw error;
      const delay = Math.pow(2, i) * 1000;
      console.log(`Waiting ${delay/1000} seconds before next attempt...`);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }

  if (!invitedUser) {
    throw new Error("Failed to fetch invited user after multiple retries");
  }

  console.log("Updating invited user with additional information...");
  // Update the invited user with additional information
  const updatedUserData = {
    givenName: userData.givenName,
    surname: userData.surname,
    jobTitle: userData.jobTitle,
    department: userData.department,
    mobilePhone: userData.mobilePhone,
    officeLocation: userData.officeLocation,
  };

  await client.api(`/users/${invitedUserId}`).patch(updatedUserData);
  console.log("User updated successfully");

  return {
    ...invitationResult,
    userPrincipalName: invitedUser.userPrincipalName,
    id: invitedUserId
  };
};
