<script setup lang="ts">
import router from '@/router'
import { store } from '@/stores/store'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { ref, watch, computed } from 'vue'

defineProps({
  showPermissions: Boolean,
  location: String
})

function urlB64ToUint8Array(base64String: string | any[]) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)

  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i)
    outputArray[i] = rawData.charCodeAt(i)

  return outputArray
}

const applicationServerKey = urlB64ToUint8Array(
  'BDIfi754CZCX8UR8PuCNsdEvTf-J6HmYM_0oAwcnqch0cX0nDXQNyhGgTAQsBLw1q54oJbdXpJSqWatzsNoALsk'
)

let subscription: any
const isSubscribed = ref(false)
let swRegistration: any

const pushButtonLabel = ref('Enable Push Messaging')
const pushButtonDisabled = ref(false)
const showDebug = ref(false)

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
  onRegistered(r: any) {
    swRegistration = r
    console.log('Service Worker is registered', {
      permission: Notification.permission,
      swRegistration
    })
  },
  onRegisterError(error: any) {
    console.error('Service worker registration error:', error)
  }
})

async function close() {
  offlineReady.value = false
  needRefresh.value = false
}

async function refreshApp() {
  await updateServiceWorker()
  window.location.reload()
}

function push_sendSubscriptionToServer(_method: string) {
  const key = subscription.getKey('p256dh')
  const token = subscription.getKey('auth')
  const contentEncoding = (PushManager.supportedContentEncodings || [
    'aesgcm'
  ])[0]

  // Implement your server communication logic here
}

watch(isSubscribed, () => {
  console.log('isSubscribed changed', isSubscribed.value)
  if (isSubscribed.value) {
    swRegistration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey
      })
      .then((subscribeRes: any) => {
        subscription = subscribeRes
        pushButtonLabel.value = 'Push Messaging'
        push_sendSubscriptionToServer('POST')
      })
      .catch((e: any) => {
        isSubscribed.value = false
        if (Notification.permission === 'denied') {
          console.warn('Permission for Notifications was denied')
          pushButtonDisabled.value = true
        } else {
          console.error('Unable to subscribe to push', {
            permission: Notification.permission,
            e
          })
        }
      })
  } else {
    isSubscribed.value = false
    pushButtonLabel.value = 'Push Messaging'
    if (subscription) {
      subscription.unsubscribe().then(() => {
        push_sendSubscriptionToServer('DELETE')
      })
    }
  }
})

const isAuthenticated = computed(() => {
  return store.isAuthenticated
})

watch(isAuthenticated, (newValue, oldValue) => {
  console.debug('###### isAuthenticated', { newValue, oldValue })
  if (!oldValue) return
  if (newValue) {
    const authMessage = newValue ? 'Authenticated' : 'Not Authenticated'
    store.setSnack(authMessage, {
      color: store.isAuthenticated ? 'success' : 'error',
      variant: 'elevated',
      location: 'bottom right',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
  }
  if (isAuthenticated.value) {
    // redirect to home page
    router.push('/')
    store.init()
  } else {
    // redirect to login page
    router.push('/')
  }
})

const snack = ref(store.getSnack)
</script>

<template>
  <div>
    <VSnackbar
      v-model="offlineReady"
      location="top"
      color="secondary"
      variant="tonal"
      vertical
    >
      App is ready to work offline
      <template #actions>
        <VBtn size="small" variant="text" @click="close">
          Close
        </VBtn>
      </template>
    </VSnackbar>
    <VSnackbar v-model="needRefresh" location="top" vertical>
      An update is available. Refresh to update.
      <template #actions>
        <VBtn size="small" variant="text" @click="close">
          Close
        </VBtn>
        <VBtn
          size="small"
          color="primary"
          variant="text"
          @click="refreshApp"
        >
          Refresh
        </VBtn>
      </template>
    </VSnackbar>

    <VCard v-show="showDebug || showPermissions" title="Permissions">
      <VCardText>
        <VRow>
          <VCol cols="12">
            <VSwitch v-model="isSubscribed" :label="pushButtonLabel" />
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </div>
</template>
