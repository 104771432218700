<script setup lang="ts">
import { vDraggable } from '@/directives/draggable'
import { store } from '@/stores/store'
import { SnackArgs } from '@/stores/types'
import { Elite } from '@/types/entities'
import { ref, watch } from 'vue'
import { mask } from 'vue-the-mask'
import SelectOrganizationDialog from './SelectOrganizationDialog.vue'

const vMask = mask

const validatePublicKey = (key: string | undefined | null): boolean => {
  if (!key) return false
  // Basic validation for PEM format public key
  const pemRegex =
    /^-----BEGIN PUBLIC KEY-----\n[\s\S]*?\n-----END PUBLIC KEY-----$/
  return pemRegex.test(key.trim())
}

const formatPublicKey = (key: string | undefined | null): string => {
  if (!key || key === undefined) return ''
  const cleanKey = key
    .replace(/-----(BEGIN|END) PUBLIC KEY-----/g, '')
    .replace(/\s/g, '')
  const formattedKey = cleanKey.match(/.{1,64}/g)?.join('\n') || ''
  return `-----BEGIN PUBLIC KEY-----\n${formattedKey}\n-----END PUBLIC KEY-----`
}

const props = defineProps({
  isCreateEliteDialogVisible: Boolean,
  organizationId: String,
  organizationName: String,
  editMode: Boolean,
  eliteToEdit: {
    type: Object,
    default: undefined
  },
  parentOrganizationId: String
})

console.log('CreateEliteDialog props:', props)

const emit = defineEmits([
  'update:isCreateEliteDialogVisible',
  'elite-created',
  'elite-updated'
])

const elite = ref({
  account_id: '',
  activity_account_id: '',
  activity_date: undefined,
  sentryio_enabled: undefined,
  id: '',
  name: '',
  dsc: '',
  serialnumber: '',
  inserviceat: undefined,
  rootpassword: '',
  backuppassword: '',
  cpu: '',
  publickey: '',
  mac: '',
  organization_id: '',
  organization_name: '',
  organization_dsc: '',
  username: '',
  password: ''
} as Elite)

const originalOrganizationId = props.organizationId || '' // Save the original organization ID for comparison

// Initialize elite.organization_id with the provided organizationId or parentOrganizationId
watch(
  () => props.organizationId || props.parentOrganizationId,
  newValue => {
    if (newValue) {
      elite.value.organization_id = newValue
    }
  },
  { immediate: true }
)

const resetElite = () => {
  return {
    account_id: '',
    activity_account_id: '',
    activity_date: undefined,
    sentryio_enabled: undefined,
    id: '',
    name: '',
    dsc: '',
    serialnumber: '',
    inserviceat: undefined,
    rootpassword: '',
    backuppassword: '',
    cpu: '',
    publickey: '',
    mac: '',
    organization_id: props.organizationId || '',
    organization_name: '',
    organization_dsc: '',
    username: '',
    password: ''
  }
}

// Watch for changes in props.organizationId or props.parentOrganizationId and update elite.organization_id
watch(
  [() => props.organizationId, () => props.parentOrganizationId],
  ([newOrgId, newParentOrgId]) => {
    if (newOrgId) {
      elite.value.organization_id = newOrgId
    } else if (newParentOrgId) {
      elite.value.organization_id = newParentOrgId
    }
  },
  { immediate: true }
)

// Watch for changes in props.eliteToEdit and update elite
watch(
  () => props.eliteToEdit,
  newValue => {
    console.debug('props.eliteToEdit', newValue)
    if (newValue) {
      elite.value = {
        account_id: newValue.account_id || '',
        activity_account_id: newValue.activity_account_id || '',
        activity_date: newValue.activity_date
          ? new Date(newValue.activity_date)
          : new Date(),
        sentryio_enabled: newValue.sentryio_enabled || undefined,
        id: newValue.id || '',
        name: newValue.name || '',
        dsc: newValue.dsc || '',
        serialnumber: newValue.serialnumber || '',
        inserviceat: newValue.inserviceat
          ? new Date(newValue.inserviceat)
          : new Date(),
        rootpassword: newValue.rootpassword || '',
        backuppassword: newValue.backuppassword || '',
        cpu: newValue.cpu || '',
        publickey: newValue.publickey || '',
        mac: newValue.mac || '',
        organization_id: newValue.organization_id || '',
        organization_name: newValue.organization_name || '',
        organization_dsc: newValue.organization_dsc || '',
        username: newValue.username || '',
        password: newValue.password || ''
      }
    }
  },
  { immediate: true }
)

// Ensure the dialog is populated when it becomes visible
watch(
  () => props.isCreateEliteDialogVisible,
  newValue => {
    console.debug('isCreateEliteDialogVisible', newValue, props)

    if (newValue) {
      if (props.editMode && props.eliteToEdit) {
        elite.value = {
          ...(props.eliteToEdit as typeof elite.value),
          organization_id:
            props.eliteToEdit.organization_id || props.organizationId || '',
          inserviceat: props.eliteToEdit.inserviceat
            ? props.eliteToEdit.inserviceat.split('T')[0]
            : new Date().toISOString().split('T')[0]
        }
      } else {
        resetForm()
      }
    } else {
      resetForm()
    }
  }
)

const getOrganizationName = (id: string) => {
  return id ? store.getOrganizationNameById(id) || id : ''
}

// Update elite.organization_id and elite.organization_name when an organization is selected
const handleOrganizationSelected = (organizationId: string) => {
  elite.value.organization_id = organizationId
  elite.value.organization_name = store.getOrganizationNameById(organizationId)
  isSelectOrganizationDialogVisible.value = false
}

const showPassword = ref(false)
const showBackupPassword = ref(false)
const isSelectOrganizationDialogVisible = ref(false)

watch(
  () => props.organizationId,
  newValue => {
    elite.value.organization_id = newValue || ''
  }
)

const validateFields = orgainzation_id => {
  const requiredFields = [
    'serialnumber',
    'inserviceat',
    'rootpassword',
    'backuppassword',
    'cpu',
    'mac'
  ]
  if (orgainzation_id) {
    requiredFields.push('organization_id')
  }
  if (!props.editMode) {
    requiredFields.push('publickey')
  }
  const emptyFields = requiredFields.filter(field => !elite.value[field])
  if (emptyFields.length > 0) {
    throw new Error(
      `Please fill in all required fields: ${emptyFields.join(', ')}`
    )
  }
}

const parseErrorMessage = (message: string) => {
  const match = message.match(/\*ERROR\*\s*(.+)/)
  return match ? match[1] : message
}

const resetForm = () => {
  elite.value = resetElite()
  console.debug('resetForm', elite.value)
}

const macLookupSuccess = ref(false)
const macLookupFailed = ref(false)

const validateMacAddress = async () => {
  const macAddress = elite.value.mac.toUpperCase()
  elite.value.mac = macAddress

  // Check if the MAC address is valid (17 characters with colons)
  if (macAddress.length === 17) {
    const macWithoutColons = macAddress.replace(/:/g, '')
    try {
      const result = await store.api.postToAPI([
        [{ Method: 'Select Elite Commission' }, { mac: macWithoutColons }]
      ])

      console.log('API response:', result)

      if (
        result &&
        result[0] &&
        result[0][0] &&
        result[0][0].Success &&
        result[0][1] &&
        result[0][1].Records
      ) {
        const records = result[0][1].Records
        elite.value.serialnumber = records.SerNo ? records.SerNo.toString() : ''
        elite.value.inserviceat = records.InSrvAt
          ? records.InSrvAt.split(' ')[0]
          : '' // Get only the date part
        elite.value.rootpassword = records.RootPassword || ''
        elite.value.backuppassword = records.BackupPassword || ''
        elite.value.cpu = records.Cpu || ''
        elite.value.publickey = records.PublicKey || ''
        macLookupSuccess.value = true
        macLookupFailed.value = false
      } else {
        console.error('Invalid or unexpected API response:', result)
        macLookupSuccess.value = false
        macLookupFailed.value = true
        // clear the fields

        elite.value.serialnumber = ''
        elite.value.inserviceat = new Date()
        elite.value.rootpassword = ''
        elite.value.backuppassword = ''
        elite.value.cpu = ''
        elite.value.publickey = ''

        store.setSnack('MAC address lookup failed. Please try again.', {
          color: 'error',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: 'white'
        })
      }
    } catch (error) {
      console.error('Error fetching Elite Commission data:', error)
      macLookupSuccess.value = false
      macLookupFailed.value = true
      // clear the fields
      elite.value.serialnumber = ''
      elite.value.inserviceat = new Date()
      elite.value.rootpassword = ''
      elite.value.backuppassword = ''
      elite.value.cpu = ''
      elite.value.publickey = ''

      store.setSnack('MAC address lookup failed. Please try again.', {
        color: 'error',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'white'
      })
    }
  } else {
    macLookupSuccess.value = false
    macLookupFailed.value = false
  }
}

const saveElite = async () => {
  try {
    if (!macLookupSuccess.value) {
      throw new Error(
        'Please enter a valid MAC address and perform a lookup first.'
      )
    }

    validateFields(false)
    if (!elite.value.organization_id) {
      isSelectOrganizationDialogVisible.value = true
      return
    }
    validateFields(true)

    // remove values that are not needed for the API
    const eliteCopy = { ...elite.value }
    const apiKeys = [
      'organization_id',
      'name',
      'dsc',
      'serialnumber',
      'inserviceat',
      'rootpassword',
      'backuppassword',
      'cpu',
      'mac',
      'publickey'
    ]

    if (props.editMode) {
      apiKeys.push('id', 'username', 'password')
    }

    for (const key in eliteCopy) {
      if (!apiKeys.includes(key)) {
        delete eliteCopy[key]
      }
    }

    const organizationChanged =
      eliteCopy.organization_id !== originalOrganizationId
    const otherFieldsChanged = Object.keys(eliteCopy).some(
      key =>
        key !== 'organization_id' &&
        eliteCopy[key] !== (props.eliteToEdit ?? {})[key]
    )

    let results: any[][] = []

    if (props.editMode) {
      if (organizationChanged) {
        const migrateResult: any[][] = await store.api.postToAPI([
          [
            { Method: 'Migrate Elite' },
            { id: eliteCopy.id, organization_id: eliteCopy.organization_id }
          ]
        ])
        results.push(migrateResult)

        if (
          migrateResult &&
          migrateResult[0] &&
          migrateResult[0][0] &&
          migrateResult[0][0].Success
        ) {
          // Update the organization_id for the subsequent Modify Elite call
          eliteCopy.organization_id = originalOrganizationId
        } else {
          throw new Error('Failed to migrate Elite')
        }
      }

      if (otherFieldsChanged || !organizationChanged) {
        // Remove organization_id from the payload for Modify Elite
        const { organization_id, ...modifyPayload } = eliteCopy
        const modifyResult = await store.api.postToAPI([
          [{ Method: 'Modify Elite' }, { ...modifyPayload }]
        ])
        results.push(modifyResult)
      }
    } else {
      const insertResult = await store.api.postToAPI([
        [{ Method: 'Insert Elite' }, { ...eliteCopy }]
      ])
      results.push(insertResult)
    }

    const allSuccessful = results.every(
      result => result && result[0] && result[0][0] && result[0][0].Success
    )

    if (allSuccessful) {
      if (props.editMode) {
        emit('elite-updated')
      } else {
        emit('elite-created')
      }
      emit('update:isCreateEliteDialogVisible', false)
      resetForm()
      store.setSnack(
        `Elite ${props.editMode ? 'updated' : 'created'} successfully`,
        {
          color: 'success',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: 'white'
        }
      )
    } else {
      const errorMessages = results
        .filter(
          result =>
            !(result && result[0] && result[0][0] && result[0][0].Success)
        )
        .map(result =>
          result && result[0] && result[0][0] && result[0][0].Message
            ? parseErrorMessage(result[0][0].Message)
            : `Failed to ${props.editMode ? 'update' : 'create'} Elite`
        )
      throw new Error(errorMessages.join(', '))
    }
  } catch (error) {
    console.error(
      `Error ${props.editMode ? 'updating' : 'creating'} Elite:`,
      error
    )
    let errorMessage = `Failed to ${props.editMode ? 'update' : 'create'} Elite`
    if (error instanceof Error) {
      errorMessage = error.message
    } else if (typeof error === 'object' && error !== null) {
      try {
        const parsedError = JSON.parse(JSON.stringify(error))
        if (parsedError[0] && parsedError[0].Message) {
          const message = parsedError[0].Message
          const match = message.match(/ERROR:\s*(.+?)(?=\\n|$)/)
          errorMessage = match ? match[1] : message
        } else {
          errorMessage = JSON.stringify(error)
        }
      } catch (parseError) {
        errorMessage = JSON.stringify(error)
      }
    }
    store.setSnack(
      `Failed to ${
        props.editMode ? 'update' : 'create'
      } Elite: ${errorMessage}`,
      {
        color: 'error',
        variant: 'flat',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'red',
        buttonColor: 'white',
        zIndex: 99999,
        opacity: 1,
        timeout: 7000,
        buttonAction: () => {
          store.snack.visible = false
        }
      } as SnackArgs
    )
  }
}
</script>

<template>
  <template>
    <VDialog
      :model-value="isCreateEliteDialogVisible"
      @update:model-value="
        val => $emit('update:isCreateEliteDialogVisible', val)
      "
      max-width="500px"
      persistent
    >
      <VCard v-draggable>
        <!-- 👉 Dialog close btn -->
        <VIcon
          class="icon-close"
          @click="$emit('update:isCreateEliteDialogVisible', false)"
          icon="tabler-x"
        />
        <VCardTitle
          >{{ props.editMode ? 'Edit' : 'Create New' }} Elite</VCardTitle
        >
        <VCardText>
          <VForm @submit.prevent="saveElite" autocomplete="off" ref="form">
            <AppTextField
              v-model="elite.organization_name"
              :placeholder="props.organizationName || 'Select Organization'"
              label="Organization"
              readonly
              outlined
              dense
              @click="isSelectOrganizationDialogVisible = true"
              autocomplete="off"
            >
              <template v-slot:append-inner>
                <VIcon
                  @click="isSelectOrganizationDialogVisible = true"
                  color="primary"
                >
                  mdi-chevron-down
                </VIcon>
              </template>
            </AppTextField>
            <AppTextField
              v-model="elite.name"
              label="Name"
              :rules="[v => !!v || 'Name is required']"
              required
              outlined
              dense
            ></AppTextField>
            <AppTextField
              v-model="elite.dsc"
              label="Description"
              :rules="[v => !!v || 'Description is required']"
              required
              outlined
              dense
            ></AppTextField>
            <VRow>
              <VCol cols="6">
                <AppTextField
                  v-model="elite.mac"
                  label="MAC Address"
                  required
                  outlined
                  dense
                  v-mask="'XX:XX:XX:XX:XX:XX'"
                  placeholder="00:00:00:00:00:00"
                  @input="validateMacAddress"
                >
                  <template v-slot:append-inner>
                    <VIcon v-if="macLookupSuccess" color="success"
                      >mdi-check</VIcon
                    >
                    <VIcon v-else-if="macLookupFailed" color="error"
                      >mdi-alert</VIcon
                    >
                  </template>
                </AppTextField>
              </VCol>
              <VCol cols="6">
                <AppTextField
                  v-model="elite.serialnumber"
                  label="Serial Number"
                  required
                  outlined
                  dense
                  :readonly="!!elite.serialnumber"
                ></AppTextField>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="6">
                <AppTextField
                  v-model="elite.inserviceat"
                  label="In Service At"
                  type="date"
                  :rules="[v => !!v || 'In Service At is required']"
                  required
                  outlined
                  dense
                  :readonly="!!elite.inserviceat"
                ></AppTextField>
              </VCol>
              <VCol cols="6">
                <AppTextField
                  v-model="elite.cpu"
                  label="CPU"
                  :rules="[v => !!v || 'CPU is required']"
                  required
                  outlined
                  dense
                  :readonly="!!elite.cpu"
                ></AppTextField>
              </VCol>
            </VRow>
            <VRow v-if="false">
              <VCol cols="6">
                <AppTextField
                  v-model="elite.rootpassword"
                  label="Root Password"
                  :type="showPassword ? 'text' : 'password'"
                  :append-inner-icon="
                    showPassword ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="showPassword = !showPassword"
                  required
                  outlined
                  dense
                  autocomplete="new-password"
                  :readonly="!!elite.rootpassword"
                ></AppTextField>
              </VCol>
              <VCol cols="6">
                <AppTextField
                  v-model="elite.backuppassword"
                  label="Backup Password"
                  :type="showBackupPassword ? 'text' : 'password'"
                  :append-inner-icon="
                    showBackupPassword ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="showBackupPassword = !showBackupPassword"
                  required
                  outlined
                  dense
                  autocomplete="new-password"
                  :readonly="!!elite.backuppassword"
                ></AppTextField>
              </VCol>
            </VRow>

            <AppTextarea
              v-if="!props.editMode && false"
              v-model="elite.publickey"
              label="Public Key"
              placeholder="public key..."
              required
              dense
              rows="3"
              :readonly="!!elite.publickey"
            ></AppTextarea>
            <VCol cols="12" class="text-center">
              <VSpacer />
              <VBtn
                variant="flat"
                color="primary"
                @click="$emit('update:isCreateEliteDialogVisible', false)"
                >Cancel</VBtn
              >
              <VBtn
                variant="flat"
                color="primary"
                @click="saveElite"
                :disabled="props.editMode ? macLookupFailed : !macLookupSuccess"
              >
                {{ props.editMode ? 'Update' : 'Create' }}
              </VBtn>
            </VCol>
          </VForm>
        </VCardText>
      </VCard>
    </VDialog>

    <SelectOrganizationDialog
      v-model:isSelectOrganizationDialogVisible="
        isSelectOrganizationDialogVisible
      "
      @organization-selected="handleOrganizationSelected"
      :selectOrganizationIdData="elite.organization_id"
    />
  </template>
</template>

<style lang="scss" scoped>
.icon-close {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  inset-block-start: 0.75em;
  inset-inline-end: 0.95em;
}

.v-card-title {
  margin-block-end: -0.75em;
  padding-block: 0.95em 0;
  padding-inline: 1.25em 0;
}
</style>
