/* stylelint-disable liberty/use-logical-spec */
<script setup lang="ts">
import { store } from '@/stores/store'
import { ActionList, Alarm, EliteLicense } from '@/types/entities'
import {
  Aggregate,
  ColumnChooser,
  ColumnMenu,
  CommandColumn,
  ContextMenu,
  DetailRow,
  ColumnDirective as EColumn,
  ColumnsDirective as EColumns,
  GridComponent as EjsGrid,
  ExcelExport,
  Filter,
  Freeze,
  Grid,
  Group,
  PdfExport,
  Reorder,
  Resize,
  RowDD,
  Sort,
  Toolbar,
  VirtualScroll
} from '@syncfusion/ej2-vue-grids'
import { format, parseISO } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { computed, onMounted, ref } from 'vue'
import { VTooltip } from 'vuetify/lib/components/index.mjs'
import { findKey, isEmpty } from '../@core/utils/index'
import { flattenObj } from '../stores/flattenObj'
import AddRemoveColumnsDialog from './AddRemoveColumnsDialog.vue'
import AnywhereMessagesDialog from './AnywhereMessagesDialog.vue'
import AnywhereUpgradeDialog from './AnywhereUpgradeDialog.vue'
import CreateEliteDialog from './CreateEliteDialog.vue'
import CreateOrganizationDialog from './CreateOrganizationDialog.vue'
import ProgressSnackbar from './ProgressSnackbar.vue'
import RequestFormFactorDialog from './RequestFormFactorDialog.vue'
import RequestLicenseDialog from './RequestLicenseDialog.vue'
import SelectOrganizationDialog from './SelectOrganizationDialog.vue'

let gridInstance = ref(new Grid())
provide('Grid', Grid)
provide('grid', [
  Aggregate,
  ColumnChooser,
  ColumnMenu,
  CommandColumn,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  Freeze,
  Group,
  PdfExport,
  Reorder,
  Resize,
  RowDD,
  Sort,
  Toolbar,
  VirtualScroll
])
const toolbarItems = ref([
  'Print',
  'ExcelExport',
  'CsvExport',
  'Search',
  'ColumnChooser'
])

function toolbarClick(args: {
  item: { id: any; properties: { id: string | string[] } }
}) {
  console.log(
    'toolbarClick',
    args,
    args.item,
    args.item.id,
    args.item.properties,
    gridInstance.value
  )

  if (args.item.properties.id.includes('pdfexport')) {
    gridInstance.value.pdfExport()
  } else if (args.item.properties.id.includes('excelexport')) {
    console.log('excelexport', args)
    gridInstance.value.excelExport()
  } else if (args.item.properties.id.includes('print')) {
    gridInstance.value.print()
  } else if (args.item.properties.id.includes('search')) {
    gridInstance.value.search(search.value)
  } else if (args.item.properties.id.includes('columnchooser')) {
    // gridInstance.value.columnchooser()
  } else if (args.item.properties.id.includes('csvexport')) {
    const csvRep = gridInstance.value.csvExport()
    console.log('csvRep', csvRep)
  }
}
const props = defineProps({
  collection: { type: String, default: '' },
  showSelect: { type: Boolean, default: false },
  itemValue: { type: String, default: 'id' },
  itemTitle: { type: String, default: 'name' },
  showLoading: { type: Boolean, default: false },
  expandableRows: { type: Boolean, default: false },
  filter: { type: Object, default: {} },
  relationship: { type: Object, default: {} }
})

interface Item {
  name: any
  mac: any
  Mac: any
  firmware: any
  builtOn: any
  inserviceat: any
  activitydate: any
  organization_id: any
  id: any
}

interface Organization {
  id: string
  Name: string
  UUID: string
}

interface License {
  id: string
  activity_account_id: string
  elite_id: string
  Product: string
  Version: number
  HardwareId: string
  SystemId: string
  EosLevel: string
  NumberOfNotifications: number
  NumberOfSensors: number
  NumberOfPCAlertSeats: number
  NumberOfPCDuressSeats: number
  NumberOfAnywhereButtons: number
  ModemSupport: boolean
  CloudSupport: boolean
  SSOSupport: boolean
  Organization: Organization
}

type FinalLicense = any

interface Device {
  name: string
  mac: string
  firmware: string
  builtOn: string
  inServiceAt: string
  activityDate: string
  organization: string
  id: string
  organization_id: string
}

const showSelect = computed(() => props.showSelect)
const expandableRows = computed(() => props.expandableRows)
const relationship = computed(() => props.relationship)
const search = ref('')

const expandableData = ref([])
const expandableHeaders = ref([])
const expandableRowsLoading = ref(false)
const booleanFields = ref([] as Array<string>)
const allFields = ref([] as Array<string>)
const licenseHistory = ref([] as Array<any>)

const isRequestLicenseDialogVisible = ref(false)
const isCreateOrganizationDialogVisible = ref(false)
const isCreateEliteDialogVisible = ref(false)
const newOrganizationParentId = ref('')
const isEditMode = ref(false)
const eliteToEdit = ref({} as any)
const organizationToEdit = ref({} as any)

const scrollToTable = () => {
  const table = document.querySelector('.layout-footer')
  if (table) {
    console.log('scrollToTable', table)
    table.scrollIntoView({
      behavior: 'smooth'
    })
  }
}

const selectedCollection = computed(() => {
  return store.selectedCollection
})

const collection = computed(() => {
  setTimeout(() => {
    scrollToTable()
  }, 1000)

  scrollToTable()
  if (typeof selectedCollection.value === 'string') {
    return selectedCollection.value
      .replace('(Bound)', '')
      .replaceAll(' ', '')
      .trim()
  } else {
    return ''
  }
})

const selectedCollectionTitle = computed(() => {
  return (
    store.collections[store.selectedCollection].title || collection.value || ''
  )
})

onMounted(() => {
  console.log('Entities card mounted')
  if (collection.value !== '') {
    if (store.collections[collection.value] === undefined) {
      console.debug('Mounted collection not found', collection.value)
      store.collections[collection.value] = {
        data: [],
        headers: [],
        selected: []
      }
    }
  }
})

const { parentCollection, childKey } = relationship.value

const activeParentOrg = computed(() => store.getActiveParentOrgId)
const activeOrganization = computed(() => store.getActiveOrganizationId)

const organizationsByParentOrg = computed(() => {
  return store.collections.Organization.data.filter(
    (x: { organization_id: string }) =>
      x.organization_id === activeParentOrg.value
  )
})

const collectionItemsByParentOrg = (collection: string) => {
  return computed(() => {
    return store.collections[collection].data.filter(
      (x: { organization_id: string; id: string }) =>
        organizationsByParentOrg.value.find(
          (y: { id: string }) => y.id === x.organization_id || y.id === x.id
        )
    )
  })
}

const getTableData = computed(() =>
  (parentCollection && collection.value !== 'Cab'
    ? activeOrganization.value
      ? store.collections[collection.value].data.filter(
          (x: any) => x[childKey] === store.getActiveOrganizationId
        )
      : activeParentOrg.value
      ? collection.value === 'Organization'
        ? organizationsByParentOrg.value
        : getCollectionItemsByParentOrg.value
      : store.collections[collection.value].data
    : store.broker.dataListByCabSight
  ).map((x: any) => {
    // delete x.publickey
    // if Cab, look up organization id in Anywhere Collection
    if (collection.value === 'Cab') {
      const anywhereItem = store.collections['Anywhere'].data.find(
        (y: { id: object }) => y.id === x.uuid
      )

      if (anywhereItem && anywhereItem.organization_id)
        x.organization_id = anywhereItem.organization_id
    }
    return x
  })
)

const tableData = computed(() => getTableData.value)

async function itemSelected(_event: any, obj: any = null): Promise<void> {
  if (!obj?.item?.value) return
  console.debug(
    'itemSelected',
    {
      _event,
      obj
    },
    store.collections[collection.value].selected
  )

  if (expandableRows.value && collection.value === 'Elite') {
    readLicenseHistory(obj.item.value)
  } else {
    if (
      store.collections[collection.value] &&
      !store.collections[collection.value].selected.includes(obj.item.value)
    )
      store.collections[collection.value].selected.push(obj.item.value)
  }
}

const handleExpanded = (value: any) => {
  console.debug('handleExpanded', {
    expandableRows: expandableRows.value,
    value
  })
  if (value && expandableRows.value) {
    expandableRowsLoading.value = true
    itemSelected(null, { item: { value } })
  }
}

const icons = {
  add: 'mdi-plus',
  edit: 'mdi-pencil',
  remove: 'mdi-delete',
  refresh: 'mdi-refresh',
  viewDetails: 'mdi-eye',
  addElite: 'mdi-plus',
  bind: 'mdi-link',
  unbind: 'mdi-link-off',
  test: 'mdi-test-tube',
  upgrade: 'mdi-arrow-up',
  requestLicense: 'mdi-license',
  clear: 'mdi-close',
  migrate: 'mdi-transfer-right'
}

const actionsList: ActionList = {
  Elite: [
    {
      title: 'Request License',
      value: 'requestLicense',
      singleSelect: true,
      icon: 'mdi-license'
    },
    { title: 'Edit', value: 'edit', singleSelect: true },
    { title: 'Remove', value: 'remove' }
  ],
  Cab: [
    {
      title: 'Bind',
      value: 'bind'
    },
    {
      title: 'Unbind',
      value: 'unbind'
    },
    { title: 'View Details', value: 'viewDetails', singleSelect: true },
    { title: 'Upgrade', value: 'upgrade' }
  ],
  Anywhere: [
    { title: 'Migrate', value: 'bind' },
    { title: 'Test', value: 'test' },
    { title: 'View Details', value: 'viewDetails', singleSelect: true },
    { title: 'Upgrade', value: 'upgrade' }
  ],
  All: [{ title: 'Refresh', value: 'refresh' }],
  CabItem: [{ title: 'Clear List', value: 'clear' }],
  Organization: [
    { title: 'Add', value: 'add', singleSelect: true },
    { title: 'Edit', value: 'edit', singleSelect: true },
    { title: 'Remove', value: 'remove' },
    { title: 'View Details', value: 'viewDetails', singleSelect: true }
  ],
  EliteTopLevel: [{ title: 'Add', value: 'addElite', singleSelect: true }]
}

const actions = computed(() => {
  if (collection.value === '') return []
  let actions =
    collection.value === 'Cab' ||
    Object.keys(store.services).includes(collection.value)
      ? (actionsList[collection.value] || []).concat(actionsList['CabItem'])
      : (actionsList[collection.value] || []).concat(actionsList['All'])

  // Add collection-specific top-level actions
  if (collection.value === 'Elite') {
    actions = actions.concat(actionsList['EliteTopLevel'])
  }

  // filter out actions that have a condition that returns false
  actions = actions.filter(
    (x: { condition?: (item: any) => boolean }) =>
      !x.condition || x.condition(store.collections[collection.value].data[0])
  )

  // remove actions that are singleSelect and more than one item is selected
  // only return items in actionsList['All'] if no items are selected
  const returnVal = (
    store.collections[collection.value].selected.length > 1
      ? actions.filter((x: { singleSelect?: boolean }) => !x.singleSelect)
      : store.collections[collection.value].selected.length === 0
      ? actionsList['All'].concat(
          collection.value === 'Elite' ? actionsList['EliteTopLevel'] : []
        )
      : actions
  ).map(x => {
    return {
      ...x,
      icon:
        x.icon ||
        icons[x.title.toLowerCase() as keyof typeof icons] ||
        icons[x.value as keyof typeof icons] ||
        'mdi-bullet'
    }
  })
  console.debug('actions', collection.value, { actions, returnVal })
  return returnVal
})

const itemActions = computed(() => {
  if (Object.keys(store.services).includes(collection.value)) return []
  if (collection.value === '') return []
  return (
    actionsList[collection.value]?.filter(
      action => action.value !== 'addElite'
    ) || []
  )
    .filter(
      (x: { condition?: (item: any) => boolean }) =>
        !x.condition || x.condition(store.collections[collection.value].data[0])
    )
    .map(x => {
      return {
        ...x,
        icon:
          icons[x.title.toLowerCase() as keyof typeof icons] ||
          icons[x.value] ||
          'mdi-bullet'
      }
    })
})

console.debug('actions', collection.value, { actions })

const isSelectOrganizationDialogVisible = ref(false)
const selectOrganizationIdData = ref('')

const isRequestFormFactorDialogVisible = ref(false)
const requestFormFactor = ref([] as any)

const showProgressSnackbar = ref(false)
const progressSnackbarText = ref('')
const progressSnackbarTotal = ref(0)
const progressSnackbarCurrent = ref(0)

const entityActions = {
  remove: async (collection: string) => {
    try {
      console.info('remove', collection)
      const selectedItems = store.collections[collection].selected

      // Confirmation prompt
      const confirmResult = await new Promise(resolve => {
        store.setSnack(
          `Are you sure you want to remove ${selectedItems.length} ${collection}(s)?`,
          {
            color: 'warning',
            variant: 'elevated',
            location: 'bottom',
            buttonText: 'Confirm',
            buttonTextColor: 'white',
            timeout: -1, // Don't auto-hide
            closeButtonText: 'Cancel',
            buttonAction: () => resolve(true),
            onClose: () => resolve(false)
          }
        )
      })

      if (!confirmResult) {
        console.info('Remove operation cancelled by user')
        // unselect items
        store.collections[collection].selected = []
        return
      }

      const payLoads = selectedItems.map((x: any) => [
        { Method: `Remove ${collection}` },
        { id: x }
      ])
      const results = await store.api.postData(payLoads)
      const finalResults = JSON.parse(JSON.stringify(results))

      const processedCount = finalResults.processed.length

      console.debug('results', { finalResults })
      // if promise is resolved, remove results from selectedItems
      if (finalResults.success) {
        finalResults.processed.forEach((x: any) => {
          store.collections[collection].selected = store.collections[
            collection
          ].selected.filter(
            (y: any) => y !== x.mac && y !== x.id && y !== x.Mac
          )
        })

        console.debug(
          'selected remaining:',
          store.collections[collection].selected
        )

        store.setSnack(`Removed ${processedCount} ${collection}s`, {
          color: processedCount ? 'success' : 'warning',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: '#333333'
        })

        // refresh data
        console.log('refreshData', collection, 'remove')
        await store.api.refreshData(collection)
      }
    } catch (error) {
      console.error('remove', error)
      store.setSnack(
        `Failed to remove ${collection}: ${(error as Error).message}`,
        {
          color: 'error',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: 'white'
        }
      )
    }
  },
  add: async (collection: string) => {
    try {
      isEditMode.value = false
      if (collection === 'Organization') {
        console.info(
          'add',
          collection,
          store.collections.Organization.selected[0]
        )
        // get selected organization id
        newOrganizationParentId.value =
          store.collections.Organization.selected[0] || ''

        organizationToEdit.value = {
          id: '',
          name: '',
          dsc: ''
        }
        isCreateOrganizationDialogVisible.value = true

        // reset selected
        store.collections.Organization.selected = []
      } else {
        // TODO: Implement add functionality for other collections
      }
    } catch (error) {
      console.error('add', error)
    }
  },
  edit: async (collection: string) => {
    try {
      isEditMode.value = true
      if (collection === 'Organization') {
        const selectedOrgId = store.collections.Organization.selected[0]
        const orgToEdit = store.collections.Organization.data.find(
          (org: { Name: string; id: string }) => org.id === selectedOrgId
        )

        console.info(
          'edit',
          collection,
          store.collections.Organization.selected[0],
          orgToEdit
        )

        if (orgToEdit) {
          newOrganizationParentId.value = orgToEdit.organization_id
          organizationToEdit.value = orgToEdit
          isCreateOrganizationDialogVisible.value = true
        } else {
          throw new Error('Selected organization not found')
        }

        // reset selected
        store.collections.Organization.selected = []
      } else if (collection === 'Elite') {
        const selectedEliteId = store.collections.Elite.selected[0]
        const eliteToEditData = store.collections.Elite.data.find(
          (elite: { id: string }) => elite.id === selectedEliteId
        )

        if (eliteToEditData) {
          eliteToEdit.value = eliteToEditData
          isCreateEliteDialogVisible.value = true
          console.info(
            'edit',
            collection,
            store.collections.Elite.selected[0],
            eliteToEditData
          )
        } else {
          throw new Error('Selected Elite not found')
        }

        // reset selected
        store.collections.Elite.selected = []
      } else {
        // TODO: Implement edit functionality for other collections
      }
    } catch (error) {
      console.error('edit', error)
    }
  },
  upgrade: async (collection: string) => {
    try {
      console.info('upgrade', collection)
      // rtl_unique_identifier is the uuid of selected Cabsight
      if (!store.broker.selectedCabSight?.uuid) {
        store.setSnack('No Cabsight selected', {
          color: 'error',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: 'white'
        })
        return
      }
      store.openAnywhereUpgradeDialog()
    } catch (error) {
      console.error('upgrade', error)
    }
  },
  bind: async (collection: string) => {
    try {
      console.info(collection === 'Cab' ? 'bind' : 'migrate', collection)

      if (collection === 'Cab')
        selectOrganizationIdData.value =
          selectOrganizationIdData.value || store.getActiveOrganizationId

      console.debug('selectOrganizationIdData', selectOrganizationIdData.value)

      // if no organization is selected open selectOrganizationDialog
      if (!selectOrganizationIdData.value) {
        isSelectOrganizationDialogVisible.value = true
        return
      }

      const selectedItems = store.collections[collection].selected
      console.log('selectedItems', selectedItems)

      // update and check bound list if collection is Cab
      let boundList = []
      if (collection === 'Cab') {
        const options = {
          method: 'POST',
          headers: store.api.apiData().headers,
          body: new URLSearchParams({
            MethodArray: `[{"Method":"Select Anywhere Bound"}]`,
            '': ''
          })
        }
        const response = await fetch(store.api.apiData().api.URI, options)
        const boundData = await response.json()
        boundList =
          boundData[0] && boundData[0].Success ? boundData[1].Records : []
      }

      const payLoads = selectedItems.map((x: any) => {
        // check if mac is already bound
        const isBound =
          collection === 'Cab'
            ? boundList.find((y: any) => y.id === x)
            : ({} as any)
        if (isBound && collection === 'Cab') {
          // look up orgainzation name of bound device
          const organization = store.collections['Organization'].data.find(
            (z: any) => z.id === isBound.organization_id
          )

          console.debug('isBound', isBound, organization)
          store.setSnack(`Cab ${x} is already bound to ${organization.name}`, {
            color: 'info',
            variant: 'elevated',
            location: 'bottom',
            buttonText: 'Close',
            buttonTextColor: 'white'
          })

          // remove from selected
          store.collections[collection].selected = selectedItems.filter(
            (y: { id: any }) => y.id === x
          )

          return []
        } else {
          if (x) {
            const method =
              collection === 'Cab' ? 'Bind Anywhere' : 'Migrate Anywhere'

            // lookup app version by id
            const item = store.collections[collection].data.find(
              (y: { uuid: string; id: string }) => y.uuid === x || y.id === x
            ) || { AppVersion: '' }

            const appVersion = item.AppVersion || item.firmware

            if (!appVersion) {
              console.error('App version not found for id:', x, item)
              return []
            }

            console.warn('processing: ', x, { appVersion })

            return [
              { Method: method },
              {
                id: x,
                organization_id: selectOrganizationIdData.value,
                firmware: appVersion
              }
            ]
          }
        }
      })

      if (payLoads.length) {
        requestFormFactor.value = payLoads
        console.debug('payLoads', requestFormFactor.value)

        selectOrganizationIdData.value = ''

        // prompt for form factor if collection is Cab
        if (collection === 'Cab') {
          isRequestFormFactorDialogVisible.value = true
        } else {
          showProgressSnackbar.value = true
          progressSnackbarText.value = `${
            collection === 'Cab' ? 'Binding' : 'Migrating'
          } ${collection}s...`
          progressSnackbarTotal.value = payLoads.length
          progressSnackbarCurrent.value = 0

          const results = [] as any[]
          for (let index = 0; index < payLoads.length; index++) {
            const payload = payLoads[index]
            const result = await store.api.postData([payload])
            progressSnackbarCurrent.value = index + 1
            results.push(result)
          }

          showProgressSnackbar.value = false

          const finalResults = results.flat()
          const processedCount = finalResults.filter(r => r.success).length

          console.debug('results', { results, finalResults })
          // if promise is resolved, remove results from selectedItems
          if (processedCount > 0) {
            finalResults.forEach((x: any) => {
              if (x.success) {
                store.collections[collection].selected = store.collections[
                  collection
                ].selected.filter((y: any) => y !== x.id)
              }
            })

            console.debug(
              'selected remaining:',
              collection,
              store.collections[collection].selected
            )

            store.setSnack(
              `Successfully ${
                collection === 'Cab' ? 'bound' : 'migrated'
              } ${processedCount} ${collection}s`,
              {
                color: 'info',
                variant: 'elevated',
                location: 'bottom',
                buttonText: 'Close',
                buttonTextColor: 'white'
              }
            )

            // refresh data
            console.log('refreshData', collection, 'bind')

            await store.api.refreshData(collection)
            if (collection === 'Anywhere') {
              console.log('refreshData', collection, 'bind 2')
              await store.api.refreshData('Anywhere Messages')
            }
          }
        }
      } else console.log(`No ${collection}s selected`)
    } catch (error) {
      console.error('bind', error)
      showProgressSnackbar.value = false
    }
  },
  unbind: async (collection: any) => {
    try {
      console.info('unbound', collection)
      const selectedItems = store.collections[collection].selected
      const payLoads = selectedItems.map((x: any) => [
        { Method: 'Remove Anywhere' },
        { id: x }
      ])

      showProgressSnackbar.value = true
      progressSnackbarText.value = `Unbinding ${collection}s...`
      progressSnackbarTotal.value = payLoads.length
      progressSnackbarCurrent.value = 0

      const results = [] as any[]
      for (let index = 0; index < payLoads.length; index++) {
        const payload = payLoads[index]
        const result = await store.api.postData([payload])
        progressSnackbarCurrent.value = index + 1
        results.push(result)
      }

      showProgressSnackbar.value = false

      const finalResults = results.flat()
      const processedCount = finalResults.filter(r => r.success).length

      console.debug('results', { results, finalResults })
      // if promise is resolved, remove results from selectedItems
      if (processedCount > 0) {
        // Clear all selected items
        store.collections[collection].selected = []

        console.debug(
          'selected cleared:',
          store.collections[collection].selected
        )

        store.setSnack(
          `Successfully unbound ${processedCount} ${collection}s`,
          {
            color: 'info',
            variant: 'elevated',
            location: 'bottom',
            buttonText: 'Close',
            buttonTextColor: 'white'
          }
        )

        // refresh data
        /* console.log('refreshData', collection, 'unbind')
        await store.api.refreshData(collection) */
      }
    } catch (error) {
      console.error('unbind', error)
      showProgressSnackbar.value = false
      store.setSnack(`Failed to unbind: ${(error as Error).message}`, {
        color: 'error',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'white'
      })
    }
  },
  clear: async (collection: any) => {
    console.info('clear', collection)
    store.collections[collection].selected = []
    store.collections[collection].data = []
  },
  test: async (collection: any) => {
    try {
      console.info('test', collection)
      const selectedItems = store.collections[collection].selected
      const items = selectedItems
        .filter((x: any) => x !== null && x !== '' && x)
        .map((x: any) => ({
          Uuid: x,
          EventUuid: uuidv4(),
          TestUuid: uuidv4()
        }))
      const payLoad = [{ Method: `Perform ${collection} Test` }, items]

      console.debug('payLoad', payLoad)

      const results = await store.api.postToAPI([payLoad])
      const finalResults = JSON.parse(JSON.stringify(results))

      const processedCount = selectedItems.length

      console.debug('results', { finalResults })
      // if promise is resolved, remove results from selectedItems
      if (finalResults[0][0].Success) {
        store.collections[collection].selected = []
        store.setSnack(`${finalResults[0][0]?.Message}`, {
          color: 'success',
          variant: 'flat',
          location: 'bottom',
          buttonText: '',
          buttonTextColor: 'white'
        })
      }

      console.debug(
        'selected remaining:',
        store.collections[collection].selected
      )
    } catch (error) {
      console.error('test', error)
    }
  },
  refresh: async (collection: string) => {
    try {
      console.info('refresh', collection)

      store.collections[collection].selected = []
      store.collections[collection].data = []

      // if a service, requery conf broker
      if (Object.keys(store.services).includes(collection)) {
        store.auth.authorize(false).then((result: any) => {
          // find the "Services" key in result
          const services = result[1]['Services']

          // find the collection in services by "Service Account Type" key
          const service = services.find(
            (x: any) => x['Service Account Type'] === collection
          )

          // populate the collection with the service Instances
          store.collections[collection].data = service['Instances']
        })
        return
      }
      if (collection !== 'Cab') {
        console.log('refreshData', collection, 'refresh')
        await store.api.refreshData(collection)
        if (collection === 'Anywhere') {
          console.log('refreshData', collection, 'refresh 2')
          await store.api.refreshData('Anywhere Messages')
        }
      } else {
        // publish refresh
        const payLoad = [
          { Method: 'Refresh Anywhere List', TraceUuid: uuidv4() },
          {
            rtl_unique_identifier: store.broker.selectedCabSight.uuid
          }
        ]

        // post payLoad to api
        const result = await store.api.postToAPI([payLoad])
        console.debug('result', result)
      }
    } catch (error) {
      console.error('refresh', error)
    }
  },
  requestLicense: async (collection: string) => {
    try {
      console.debug(
        'requestLicense',
        collection,
        store.collections[collection].selected
      )
      const selectedItem = store.collections[collection].selected[0]
      const eliteObj = store.collections[collection].data.find(
        (x: { id: any }) => x.id === selectedItem
      )

      // get organization id for the selected elite
      const organization = store.getOrganizationDetailsById(
        eliteObj['organization_id']
      )

      console.debug('organization', organization)
      store.collections[collection].selected = []

      // get current license for elite
      const endpoint = 'bl/resptech/license/select'

      const result = (
        (await store.api.postToEndpoint(endpoint, [
          { elite_id: [eliteObj['id']] }
        ])) as any
      )[0]

      const value = result.value // JSON.parse(result.value)
      delete result.value

      console.log('result', result, value)

      const currentLicense = Object.assign({}, result, value)

      console.debug('currentLicense', currentLicense)

      const eosLevel = currentLicense?.['EOS Level'] || '0'
      const eosValue = extractInteger(eosLevel) || 0
      console.debug('eosLevel', eosLevel, eosValue)

      const version = currentLicense?.['Version'] || '1.0'

      const licenseObj = {
        id: currentLicense?.id || '',
        activity_account_id:
          currentLicense?.activity_account_id ||
          organization?.activity_account_id ||
          '',
        elite_id: eliteObj['id'],
        Version: parseFloat(version),
        Product: currentLicense?.['Product'] || 'Centurion Elite',
        HardwareId: eliteObj['mac'],
        SystemId: organization.systemid,
        EosLevel: eosLevel,
        NumberOfNotifications: eosValue * 5,
        NumberOfSensors: currentLicense?.['Number of Inovonics'] || 0,
        NumberOfPCAlertSeats: currentLicense?.['Number of PCAlert Seats'] || 0,
        NumberOfPCDuressSeats:
          currentLicense?.['Number of PCDuress Seats'] || 0,
        NumberOfAnywhereButtons:
          currentLicense?.['Number of Anywhere Buttons'] || 0,
        ModemSupport: currentLicense?.['Modem Support'] || false,
        CloudSupport: currentLicense?.['Cloud Support'] || false,
        SSOSupport: currentLicense?.['SSO Support'] || false,
        Organization: {
          Name: organization.name,
          UUID: organization.id,
          organization_id: organization.orgainzation_id
        }
      }

      license.value = licenseObj
      isDialogVisible.value = true
      store.collections[collection].selected = []
      console.debug('licenseObj', licenseObj)
    } catch (error) {
      console.error('requestLicense', error)
    }
  },
  addElite: async (collection: string) => {
    try {
      isEditMode.value = false
      console.info('add', collection)

      eliteToEdit.value = {
        id: '',
        name: '',
        dsc: ''
      }
      isCreateEliteDialogVisible.value = true
    } catch (error) {
      console.error('add', collection, error)
    }
  },
  viewDetails: async (collection: string) => {
    console.info('viewDetails', collection)
    const selectedItem = store.collections[collection].selected[0]
    const item = store.collections[collection].data.find(
      (x: { id: string; uuid: string }) =>
        x.id === selectedItem || x.uuid === selectedItem
    )
    console.debug('viewDetails', { selectedItem, item })
    // unselct item
    store.collections[collection].selected = []
    anywhereDetails(item)
  }
}

const handleOrganizationSelected = (organizationId: string) => {
  console.debug('handleOrganizationSelected', organizationId)
  selectOrganizationIdData.value = organizationId
  isSelectOrganizationDialogVisible.value = false
  entityActions.bind(collection.value)
}

const handleClick = (value: any, item: any) => {
  // Handle click event
  const valueId: string = value.id
  console.debug('handleClick', { value, item, valueId })

  const action = entityActions[value as keyof typeof entityActions]
  if (typeof action === 'function') {
    action(collection.value)
  } else {
    console.error(`Action ${value} is not a function`)
  }
}

const handleClickInline = (value: any, item: any) => {
  // Handle click event
  // if row level checkbox isn't checked, check it
  console.debug('handleClickInline', { value, item })

  if (
    !store.collections[collection.value].selected.includes(item.id || item.uuid)
  ) {
    store.collections[collection.value].selected.push(item.id || item.uuid)
  }

  handleClick(value, item)
}

const cabSightSelections = computed(() => {
  /* [
  {
    description: 'All',
    uuid: ''
  },
  ...store.broker.cabSights
  ] */

  // return store.broker.cabSights with count of devices
  const cabSights =
    store.broker.cabSights.map((x: any) => {
      const count = store.collections['Cab'].data.filter(
        (y: any) => y['Author.Uuid'] === x.uuid
      ).length
      return {
        uuid: x.uuid,
        description: `${x.description} (${count})`
      }
    }) || []
  console.debug('cabSightSelections', cabSights)
  cabSights.push({ description: 'All', uuid: '' })
  return cabSights
})

const readLicenseHistory = async (elite_id: Array<string>) => {
  try {
    // get license id by elite_id

    if (!elite_id.length) {
      expandableRowsLoading.value = false
      return
    }

    console.debug(
      'current',
      store.collections['License'].data.filter(
        x => elite_id.indexOf(x.elite_id) !== -1
      ),
      elite_id.filter(x => {
        if (!store.collections['License'].data.find(y => y.elite_id === x))
          return x
      })
    )
    booleanFields.value = []
    const payLoad = [
      {
        elite_id: elite_id.filter(x => {
          if (!store.collections['License'].data.find(y => y.elite_id === x))
            return x
        })
      }
    ]
    if (payLoad[0].elite_id.length) {
      const endpoint = 'bl/resptech/license/select'
      const results = await store.api.postToEndpoint(endpoint, payLoad)

      if (results.length) {
        // add results to store
        store.collections['License'].data =
          store.collections['License'].data.concat(results)
      }
    }

    // get license history for all elite_ids
    licenseHistory.value = store.collections['License'].data.filter(
      (x): boolean => elite_id.indexOf(x.elite_id) !== -1
    )

    console.log('current', licenseHistory.value)

    const licenses = /* current.concat(history) */ licenseHistory.value.map(
      x => {
        const json = x.value // JSON.parse(x.value)

        const flattened = flattenObj(
          Array.isArray(json)
            ? json.reduce((acc, cur) => {
                return { ...acc, ...cur }
              }, {})
            : json
        )
        // sort flattened object by key
        const sorted = Object.keys(flattened)
          .sort()
          .reduce((acc, key) => {
            return { ...acc, [key]: flattened[key] }
          }, {})

        // convert boolean true values to string true
        Object.keys(sorted).forEach((key: string) => {
          if (
            sorted[key] === true ||
            sorted[key] === 'true' ||
            sorted[key] === false ||
            sorted[key] === 'false'
          ) {
            console.debug('boool key', key)
            const camelCaseKey = key
            // delete sorted[key]
            if (booleanFields.value.indexOf(camelCaseKey) === -1)
              booleanFields.value.push(camelCaseKey)

            sorted[camelCaseKey] = !!sorted[key]
          }
        })

        if (x.value) sorted['License'] = x.value
        sorted['elite_id'] = x.elite_id

        const defaultValues = {
          Version: sorted['Product'] ? 1.01 : 1.0,
          Product: 'Centurion Elite'
        }

        // fill in missing keys with default values
        Object.keys(defaultValues).forEach(key => {
          if (!sorted[key]) sorted[key] = defaultValues[key]
        })
        console.debug('sorted', sorted)
        return sorted
      }
    )

    // remove elements with empty license
    const finalLicenses = licenses.filter(x => isEmpty(x['License']) === false)
    // move boolean fields to end of object
    finalLicenses.forEach(x => {
      booleanFields.value.forEach(y => {
        const value = x[y]
        delete x[y]
        x[y] = value
      })
    })
    console.debug('finalLicenses', finalLicenses)

    const deleteKeys = [
      'Signature',
      'Organization.Anywhere',
      'Organization.Name',
      'Organization.UUID',
      'elite_id',
      'Mac',
      'License'
    ]

    // get all unique keys for headers
    const headers = Array.from(
      new Set(
        finalLicenses
          .map(x => Object.keys(x).filter(x => deleteKeys.indexOf(x) === -1))
          .flat()
      )
    ).map(x => {
      return {
        key: x,
        title: x
      }
    })

    headers.push({ title: 'License', key: 'actions' })

    const centerHeaders = [
      'Number of Anywhere Buttons',
      'Number of Notifications',
      'Number of PCAlert Seats',
      'Number of PCDuress Seats',
      'Number of Sensors',
      'Version',
      'Modem Support',
      'SSO Support',
      'actions'
    ]
    // add align center to centerHeaders
    headers.forEach(x => {
      if (centerHeaders.indexOf(x.key) !== -1) x['align'] = 'center'
      // remove 'Number of' from key
      if (x.key.indexOf('Number of') !== -1) {
        x.title = x.title.replace('Number of', '')
      }
    })

    expandableData.value = finalLicenses as any
    expandableHeaders.value = headers as any
    expandableRowsLoading.value = false
    allFields.value = Object.keys(finalLicenses?.[0] || {})
  } catch (error) {
    console.error('Error in readLicenseHistory:', error)
    expandableRowsLoading.value = false
    // You might want to set an error state or show a notification to the user
    store.setSnack('Failed to read license history', {
      color: 'error',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
  }
}

const downloadLicense = (licenseJson: any) => {
  const license = licenseJson // JSON.parse(licenseJson)
  console.debug('downloadLicense', licenseJson)
  // create a blob of the data and initiate a download
  let stringifiedLicense = JSON.stringify(license)
    .replace('[', '')
    .replace(']', '')
    .replace('},{', '}{')
  stringifiedLicense = stringifiedLicense.replace(/\\n/g, '\n') // replace escaped newlines with actual newlines
  const blob = new Blob([stringifiedLicense], {
    type: 'text/plain;charset=utf-8'
  })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `license_${license?.['Mac'].replaceAll(':', '') || 'unknown'}_${
    license['Version']
  }_${license?.['Organization']?.['Name'] || 'Elite'}.json`.toLocaleLowerCase()
  document.body.appendChild(a)
  a.click()
  a.remove()
}

const headers = computed(() => {
  if (collection.value === 'Cab' || collection.value === 'Pet') {
    return [
      { key: 'Mac', title: 'Mac Address' },
      { key: 'AppVersion', title: 'AppVersion' },
      { key: 'Author.BuiltOn', title: 'Built On' },
      { key: 'BatteryPercent', title: 'Battery', align: 'center' }
    ]
  } else if (collection.value === 'Anywhere') {
    const baseColumns = [
      { key: 'MostRecent.CabDevice.SensorNumber', title: 'Sensor Number' },
      { key: 'name', title: 'Name' },
      { key: 'activity_date', title: 'Activity Date' },
      { key: 'dsc', title: 'Description' },
      { key: 'mac', title: 'Mac Address' }
    ]
    const noOrgColumns = [{ key: 'organization_id', title: 'Organization' }]
    const orgColumns = [
      {
        key: 'CabDevice.BatteryPercent',
        title: 'Cab Battery',
        align: 'center'
      },
      { key: 'IsAlarmActive', title: 'Alarm Active', align: 'center' },
      { key: 'TestDelivered', title: 'Test Result', align: 'center' }
    ]
    return activeOrganization.value
      ? baseColumns.concat(orgColumns)
      : baseColumns.concat(noOrgColumns)
  } else {
    return expandableRows.value
      ? [{ title: '', key: 'data-table-expand' }].concat(
          store.collections[collection.value].headers
        )
      : store.collections[collection.value].headers
  }
})

const filterHeaders = {
  Elite: [
    'publickey',
    'username',
    'password',
    'backuppassword',
    'rootpassword',
    'entitytype_id_cloud_core',
    'entitytype_id_cloud_notification',
    'entitytype_id_cloud_webpagebase',
    'entitytype_id_dns_mqbroker',
    'entitytype_id_url_remaster',
    'organization_id'
  ],
  Envoy: [
    'publickey',
    'username',
    'password',
    'backuppassword',
    'rootpassword',
    'entitytype_id_cloud_core',
    'entitytype_id_cloud_notification',
    'entitytype_id_cloud_webpagebase',
    'entitytype_id_dns_mqbroker',
    'entitytype_id_url_remaster',
    'organization_id'
  ],
  Gateway: [
    'publickey',
    'username',
    'password',
    'backuppassword',
    'rootpassword',
    'entitytype_id_cloud_core',
    'entitytype_id_cloud_notification',
    'entitytype_id_cloud_webpagebase',
    'entitytype_id_dns_mqbroker',
    'entitytype_id_url_remaster',
    'organization_id'
  ],
  // regex for starts with "Details" ie "Details  Elite Configuration  Notification Text" or "Details  Sent At"
  //Anywhere: [/Details.*/, 'organization_id'],
  cabsight: ['Author.Service Account Type'],
  petsight: ['Author.Service Account Type'],
  license: ['Author.Service Account Type'],
  admin: ['Author.Service Account Type'],
  auth: ['Author.Service Account Type']
}

const addHeaders = {
  /* Elite: [{ title: 'Request License', key: 'action', align: 'center' }], */
  /* Anywhere: [{ key: "action", title: "Details", align: "center" }],
  Cab: [{ key: "action", title: "Details", align: "center" }], */
}

const filteredHeaders = computed(() => {
  if (typeof headers.value !== 'object' || headers.value.length === undefined)
    return []

  const collectionHeaders = store.collections[collection.value].headers

  /* console.warn('filteredHeaders', {
    collection: collection.value,
    headers: headers.value,
    collectionHeaders
  }) */

  const filteredHeaders = headers.value.filter((x: { key: any }) => {
    const filterKeys = filterHeaders[collection.value] || []
    const matchRegex = filterKeys.find((y: any) => x.key.match(y))
    return !filterKeys.includes(x.key) && !matchRegex
  })

  const collectionHeadersKeys = collectionHeaders.map((x: { key: any }) => {
    return { align: ' d-none', ...x }
  })

  const mergedHeaders = collectionHeadersKeys.concat(filteredHeaders)

  console.warn('filteredHeaders', collection, {
    collectionHeaders,
    filteredHeaders,
    mergedHeaders
  })

  return mergedHeaders
})

const selectedHeaders = computed(() => store.getSelectedHeaders() || [])

const finalHeaders = computed(() => {
  const finalHeaders = selectedHeaders.value.length
    ? selectedHeaders.value.map((x: { key: any; align: string }) => {
        // return x without align if set to d-none
        return x.align === ' d-none' ? { ...x, align: '' } : x
      })
    : filteredHeaders.value

  // if no active organization, add organization to headers if not already there
  if (!activeOrganization.value) {
    const orgAvailable = finalHeaders.findIndex(
      (x: { key: string }) => x.key === 'organization_id'
    )

    if (orgAvailable === -1) {
      finalHeaders.push({
        key: 'organization_id',
        title: 'Organization',
        align: 'center'
      })
    }
  }

  // merge itemActions with final headers
  const addAddHeaders = addHeaders[collection.value] || []
  if (itemActions.value && itemActions.value.length) {
    // check if addAddHeaders already has itemActions
    const hasItemActions = addAddHeaders.find(
      (x: { key: string }) => x.key === 'itemActions'
    )
    if (!hasItemActions)
      addAddHeaders.push({
        key: 'itemActions',
        // title is more button
        title: '',
        align: 'end',
        sortable: false
      })
  }

  finalHeaders.push(...addAddHeaders)
  return finalHeaders
})

const isDialogVisible = ref(false)
const license = ref({} as EliteLicense)

function extractInteger(s: string): number | null {
  const match = s.match(/\d+/)
  return match ? parseInt(match[0]) : null
}

const isAnywhereDialogVisible = ref(false)
const device = ref({} as Device)

const anywhereDetails = (anywhereObj: {
  name: any
  mac: any
  Mac: any
  firmware: any
  builtOn: any
  inserviceat: any
  activitydate: any
  organization_id: any
  id: any
}) => {
  console.debug('anywhereDetails', anywhereObj)
  const dialogData = {
    name: anywhereObj.name,
    mac: anywhereObj.mac || anywhereObj.Mac,
    firmware: anywhereObj.firmware,
    builtOn: anywhereObj['Author.BuiltOn'] || '',
    inServiceAt: anywhereObj.inserviceat
      ? formatDate(anywhereObj.inserviceat)
      : '',
    activityDate: anywhereObj.activitydate
      ? formatDate(anywhereObj.activitydate)
      : '',
    organization: anywhereObj.organization_id
      ? getOrgName(anywhereObj.organization_id)
      : '',
    id: anywhereObj.id,
    organization_id: anywhereObj.organization_id
  }
  console.log('refreshData', 'Anywhere Messages', 'anywhereDetails')
  store.api.refreshData('Anywhere Messages', {
    organization_id: anywhereObj.organization_id,
    anywhere_id: anywhereObj.id
  })

  // subscribe to anywhere messages
  /* store.broker.manualSubscribe({
    topic: `Org/${anywhereObj.organization_id}/Anywhere/mostRecent`,
    qos: 0
  }) */
  device.value = dialogData
  isAnywhereDialogVisible.value = true
}

// Helper functions
function formatDate(isoDate: string) {
  return format(parseISO(isoDate), 'MMM d, yyyy')
}

function getOrgName(orgId: any) {
  // Lookup organization name by id
  return store.collections['Organization'].data.find(
    (org: { id: any }) => org.id === orgId
  ).name
}

const batteryPercent = item => {
  // get battery percent from anywhere_messages
  const anywhereMessage = store.collections['Anywhere Messages'].data.find(
    (x: { id: any }) => x.id === item.id
  )

  const batteryPercent =
    anywhereMessage?.CabDevice?.BatteryPercent ||
    anywhereMessage?.BatteryPercent ||
    anywhereMessage?.Periodic?.CabDevice?.BatteryPercent ||
    anywhereMessage?.Periodic?.BatteryPercent ||
    item['BatteryPercent'] ||
    item['CabDevice.BatteryPercent'] ||
    null
  // console.debug("batteryPercent", item.anywhere_id, batteryPercent);
  return batteryPercent
}

const testResult = (item): { icon: string; tooltip: string } => {
  const errors = [] as Array<string>

  // check "IsCabConnected": true && "BatteryLevelSufficient": true,   "PermissionsValid": true,   "EliteConfigured": true,   "TestDelivered": true
  if (item['TestResults.IsCabConnected'] !== true)
    errors.push('Cab not connected')
  if (item['TestResults.BatteryLevelSufficient'] !== true)
    errors.push('Battery level insufficient')
  if (item['TestResults.PermissionsValid'] !== true)
    errors.push('Permissions invalid')
  if (item['TestResults.EliteConfigured'] !== true)
    errors.push('Elite not configured')
  if (item['TestResults.TestDelivered'] !== true)
    errors.push('Test not delivered')

  // console.debug("testResult", { errors, item, msg });

  // return json with icon and tooltip message
  return {
    icon: errors.length ? 'mdi-alert-circle' : 'mdi-check',
    tooltip: errors.length ? errors.join(', ') : 'Test successful'
  }
}

const alarmActive = (item: Alarm) => {
  try {
    // console.warn('alarmActive item', item)
    // get alarm active from anywhere_messages
    const anywhereMessage =
      store.collections['Anywhere Messages'].data.find(
        (x: { anywhere_id: any }) => x.anywhere_id === item.anywhere_id
      ) || {}

    // const alarmActive = anywhereMessage?.Alarm || item["Alarm"] || null;

    const alarmActive =
      findKey(anywhereMessage, 'Alarm.IsActive') ||
      findKey(item, 'Alarm.IsActive') ||
      null

    /* const alarmCleared =
      anywhereMessage?.Alarm?.ClearedAt ||
      item['Alarm']?.ClearedAt ||
      anywhereMessage?.Alarm?.Alarm?.ClearedAt ||
      item['Alarm']?.Alarm?.ClearedAt ||
      null */
    const alarmCleared =
      findKey(anywhereMessage, 'ClearedAt') ||
      findKey(item, 'ClearedAt') ||
      null

    // console.warn('alarmCleared', item.anywhere_id, alarmCleared)
    /* const alarmTriggeredAt =
      anywhereMessage?.Alarm?.TriggeredAt ||
      anywhereMessage?.Alarm?.Alarm?.TriggeredAt ||
      item['Alarm']?.Alarm?.TriggeredAt ||
      item['Alarm']?.TriggeredAt ||
      null */

    const alarmTriggeredAt =
      findKey(anywhereMessage, 'TriggeredAt') ||
      findKey(item, 'TriggeredAt') ||
      null

    const icon = alarmActive ? 'mdi-alert' : ''

    // (alarmActive && alarmCleared) || !alarmActive ? '' : 'mdi-alert'

    const result = {
      icon,
      tooltip: `Alarm triggered at ${alarmTriggeredAt}`,
      alarm: alarmActive
      // alarm: anywhereMessage?.Alarm || item['Alarm'] || null
    }

    // console.debug("alarmActive", item.anywhere_id, result, item, anywhereMessage);

    return result
  } catch (error) {
    console.error('alarmActive', error, item)
    return {
      icon: '',
      tooltip: '',
      alarm: null
    }
  }
}

const getCollectionItemsByParentOrg = ref(
  collectionItemsByParentOrg(collection.value)
)

const baseSearchKeys = computed(() => {
  /*   if (collection.value === "Cab") {
    return [
      { title: "Mac Address", key: "Mac" },
      { title: "Firmware", key: "Firmware" },
      { title: "Built On", key: "Author.BuiltOn" },
      { title: "Battery", key: "BatteryPercent" },
    ];
  } else if (collection.value === "Anywhere") {
    return [
      { title: "Mac Address", key: "mac" },
      { title: "Firmware", key: "firmware" },
      { title: "In Service At", key: "inserviceat" },
      { title: "Label", key: "CabDevice.Label" },
      { title: "Battery", key: "CabDevice.BatteryPercent" },
      { title: "Alarm Active", key: "AlarmActive" },
      { title: "Test Result", key: "TestDelivered" },
      { title: "Organization", key: "organization_id" },
    ];
  } else { */
  const baseKeys = store.collections[collection.value]?.headers || []

  return baseKeys.length
    ? baseKeys.map((x: { title: string; key: string }) => {
        return {
          title: x.title,
          key: x.key
        }
      })
    : []

  // }
})

const searchKeys = computed(() => {
  return [
    { title: 'All', key: '' },
    ...baseSearchKeys.value.sort((a: { title: string }, b: { title: string }) =>
      a.title > b.title ? 1 : -1
    )
  ] as Array<{
    title: string
    key: string
  }>
})

const selectedSearchKey = ref({ ...searchKeys.value[0] })

// watch anywhere_messages for changes and update ctollectionItemsByParentOrg
/* watch(
  () => store.collections["Anywhere Messages"].data,
  (newValue, oldValue) => {
    console.debug("anywhere_messages", {
      newValue,
      oldValue,
    });

    // if new value is array, loop through and check for changes to BatteryPercent

    if (typeof newValue === "object" && newValue.length) {
      newValue.forEach((x, index) => {
        if (
          x?.CabDevice?.BatteryPercent !==
          oldValue[index]?.CabDevice?.BatteryPercent
        ) {
          // refresh collection
          store.api.refreshData("Anywhere Messages");
          if (collection.value === "Anywhere") {
            store.api.refreshData("Anywhere");
          }
        }
      });
    }

    /* if (
      newValue[0].CabDevice.BatteryPercent !==
      newValue[1].CabDevice.BatteryPercent
    ) {
      // locate row in collectionItemsByParentOrg and update
      const index = getCollectionItemsByParentOrg.value.findIndex(
        (x) => x.id === newValue[0].anywhere_id
      );
      console.debug("index", index);
      if (index !== -1) {
        getCollectionItemsByParentOrg.value[index] = newValue[0];
      }
    } * /
  },
  { deep: true }
); */

// add/remove columns dialog
const isAddRemoveColumnsDialogVisible = ref(false)

const addRemoveColumns = () => {
  console.debug('addRemoveColumns', { headers })
  isAddRemoveColumnsDialogVisible.value = true
}

const requestLicense = async (payLoad: any) => {
  try {
    console.debug('requestLicense', payLoad)
    const results = await store.api.postToAPI([payLoad])

    console.debug('results', results, results.length)
    if (results.length) {
      const finalResults = JSON.parse(JSON.stringify(results))?.[0] || {}
      console.debug('finalResults', !!finalResults[0].Success)
      if (finalResults[0].Success) {
        store.setSnack(`Successfully requested license`, {
          color: 'success',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: '#333333'
        })
        isDialogVisible.value = false
      } else {
        throw new Error(finalResults[0].Message || 'Failed to request license')
      }
    } else {
      throw new Error('No response from API')
    }
  } catch (error) {
    console.error('Error in requestLicense:', error)
    store.setSnack(`Failed to request license: ${(error as Error).message}`, {
      color: 'error',
      variant: 'elevated',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'white'
    })
  }
}

const isLocalDev = process.env.NODE_ENV === 'development'

const getTableHeight = computed(() => {
  // get available height after .match-height to bottom of viewport
  const matchHeight = document.querySelector('.match-height')
  const matchHeightHeight = matchHeight ? matchHeight.clientHeight : 0
  const tableHeight = window.innerHeight - matchHeightHeight - 100
  return tableHeight
})

const selectionSettings = {
  persistSelection: true,
  type: 'Multiple',
  checkboxOnly: true
}
</script>

<template>
  <AddRemoveColumnsDialog
    v-model:isAddRemoveColumnsDialogVisible="isAddRemoveColumnsDialogVisible"
    :filteredHeaders="filteredHeaders"
    :headers="headers"
    :collection="collection"
  />

  <RequestLicenseDialog
    v-model:isDialogVisible="isDialogVisible"
    :license="(license as FinalLicense)"
    @submit="requestLicense"
    v-if="collection === 'Elite'"
  />

  <RequestFormFactorDialog
    v-model:isRequestFormFactorDialogVisible="isRequestFormFactorDialogVisible"
    :requestFormFactor="requestFormFactor"
    v-if="collection === 'Cab'"
  />
  <AnywhereMessagesDialog
    v-model:isAnywhereDialogVisible="isAnywhereDialogVisible"
    :device="device"
  />
  <SelectOrganizationDialog
    v-model:isSelectOrganizationDialogVisible="
      isSelectOrganizationDialogVisible
    "
    @organization-selected="handleOrganizationSelected"
  />
  <AnywhereUpgradeDialog
    v-model:isAnywhereUpgradeDialogVisible="
      store.isAnywhereUpgradeDialogVisible
    "
  />
  <CreateOrganizationDialog
    v-model:isCreateOrganizationDialogVisible="
      isCreateOrganizationDialogVisible
    "
    :parentOrganizationId="newOrganizationParentId"
    :editMode="isEditMode"
    :organizationToEdit="organizationToEdit"
    @organization-created="store.api.refreshData('Organization')"
    @organization-updated="store.api.refreshData('Organization')"
  />
  <CreateEliteDialog
    v-model:isCreateEliteDialogVisible="isCreateEliteDialogVisible"
    :editMode="isEditMode"
    :eliteToEdit="eliteToEdit"
    :organizationId="store.getActiveOrganizationId"
    @elite-created="store.api.refreshData('Elite')"
    @elite-updated="store.api.refreshData('Elite')"
  />
  <CreateOrganizationDialog
    v-model:isCreateOrganizationDialogVisible="
      isCreateOrganizationDialogVisible
    "
    :parentOrganizationId="newOrganizationParentId"
    :editMode="isEditMode"
    :organizationToEdit="organizationToEdit"
    @organization-created="store.api.refreshData('Organization')"
    @organization-updated="store.api.refreshData('Organization')"
  />
  <ProgressSnackbar
    :show="showProgressSnackbar"
    :text="progressSnackbarText"
    :total-items="progressSnackbarTotal"
    :current-item="progressSnackbarCurrent"
  />
  <VCard
    class="entities"
    :title="selectedCollectionTitle"
    :subtitle="
      store.activeOrganizationDisplay?.name ||
      (store.collections.ParentOrg.data.find((x: Organization) => x.id === store.activeParentOrg) )?.name ||
      ''
    "
  >
    <template #append>
      <VRow>
        <VCol cols="12" md="1" class="label">
          <div class="pt-1">
            <VTooltip bottom>
              <template v-slot:activator="{ props }">
                <icon-btn
                  v-bind="props"
                  name="editColumns"
                  @click="addRemoveColumns"
                >
                  <v-icon color="secondary" class="editColumns"
                    >tabler-columns-3</v-icon
                  >
                </icon-btn>
              </template>
              <span>Add/Remove Columns</span>
            </VTooltip>
          </div>
        </VCol>

        <VCol cols="12" md="4" class="label" v-if="collection === 'Cab'">
          <VSelect
            :items="cabSightSelections"
            item-title="description"
            item-value="uuid"
            label="CabSight"
            v-model="store.broker.selectedCabSight"
            return-object
            item-color="secondary"
            class="pa-1"
          />
        </VCol>

        <VCol cols="12" :md="collection === 'Cab' ? 3 : 4" class="label">
          <VSelect
            :items="searchKeys"
            item-title="title"
            item-value="key"
            v-model="selectedSearchKey"
            dense
            outlined
            hide-details
            label="Search by"
            color="secondary"
            class="pa-1"
          />
        </VCol>
        <VCol cols="12" md="7" class="label">
          <AppTextField
            v-model="search"
            density="compact"
            :placeholder="'Search'"
            :append-inner-icon="search ? 'tabler-x' : 'tabler-search'"
            @click:append-inner="search = ''"
            dense
            outlined
            class="pa-1"
          />
        </VCol>
      </VRow>
    </template>
    <VDivider />

    <ejs-grid
      ref="gridInstance"
      :dataSource="tableData"
      :allowSelection="true"
      :allowSorting="true"
      :allowFiltering="true"
      :filterSettings="{ type: 'Menu' }"
      :allowPaging="false"
      :allowGrouping="true"
      :groupSettings="{ showGroupedColumn: true }"
      :allowResizing="true"
      :allowReordering="true"
      :allowExcelExport="true"
      :allowPdfExport="true"
      :allowKeyboard="true"
      :allowMultiSorting="true"
      :allowColumnChooser="true"
      :showColumnChooser="true"
      :allowRowDragAndDrop="true"
      :allowTextWrap="true"
      :toolbar="toolbarItems"
      :enableStickyHeader="true"
      :enableVirtualization="true"
      :height="getTableHeight"
      :enableHeaderFocus="true"
      :enableHover="true"
      width="100%"
      :toolbarClick="toolbarClick"
    >
      <e-columns>
        <e-column
          type="checkbox"
          :allowFiltering="false"
          :allowSorting="false"
          width="60"
        ></e-column>

        <e-column
          v-for="header in finalHeaders"
          :key="header.key"
          :field="header.key"
          :headerText="header.title || header.key"
          :textAlign="header.align"
          :visible="header.align !== ' d-none' && header.key !== 'itemActions'"
        >
        </e-column>
        <e-column headerText="" template="actionsColumnTemplate" width="90">
        </e-column>
      </e-columns>

      <template v-slot:actionsColumnTemplate="{ data }">
        <div>
          <IconMoreBtn
            :name="`itemActions${data.id}`"
            :menu-list="itemActions"
            :onItemClick="value => handleClickInline(value, data)"
            :item="data.id"
            class="moreBtn"
          >
            <v-icon color="black">tabler-dots-vertical</v-icon>
          </IconMoreBtn>
        </div>
      </template>
    </ejs-grid>
  </VCard>
</template>

<style lang="scss">
.refresh-button {
  margin: 16px;
}

svg.iconify {
  block-size: 2em;
  inline-size: 2em;
}

.editColumns {
  cursor: pointer;
  margin-inline: -3px;
}

.search-input {
  display: inline-block;
  inline-size: 82%;
}

.moreBtn {
  float: inline-end;
}

.moreBtnDiv {
  position: absolute;
  inset-inline-end: -1.4em;
}

.moreBtnDiv .more-btn {
  background-color: transparent;
}

table table {
  border-width: 1px 1px 1px 13px;
  border-style: solid;
  border-color: #d0d0d0;
  margin-block: 1em;
  margin-inline: 0 1em;
  max-inline-size: fit-content;
}

.expandable-row {
  border-width: 1px 0 0;
  border-style: solid;
  border-color: #d8d7d8;
  padding-inline: 0;
  padding-inline-start: 0;
}

.v-table__wrapper table,
.e-virtualtable .e-table {
  min-inline-size: 100%;
}

.v-table__wrapper table th:first-child,
.v-table__wrapper table td:first-child,
.v-table__wrapper table th:last-child,
.v-table__wrapper table td:last-child,
.e-table .e-row .e-rowcell:first-child,
.e-table .e-row .e-rowcell:last-child {
  position: sticky !important;
  z-index: 1;
  background-color: rgba(
    var(--v-theme-grey-50),
    var(--v-high-emphasis-opacity)
  ) !important;
}

.v-table__wrapper table th:first-child,
.v-table__wrapper table td:first-child,
.e-virtualtable .e-table .e-row .e-rowcell:first-child {
  inset-inline-start: 0;
}

.v-table__wrapper table th:first-child,
.e-virtualtable .e-table th:first-child {
  z-index: 9999 !important;
  padding-block: 1.5em !important;
}

/* stylelint-disable-next-line no-descending-specificity */
.v-table__wrapper table th:last-child,
.v-table__wrapper table td:last-child,
.e-virtualtable .e-table .e-row .e-rowcell:last-child {
  inline-size: 1em !important;
  inset-inline-end: 0;
  padding-block: 0 !important;
  padding-inline: 1.75em !important;
}

.entity-table > .v-table__wrapper,
.entity-table > .e-virtualtable {
  overflow: auto;
}

.e-grid {
  font-family: inherit;
  font-size: inherit;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
  font-size: 1em;
}
</style>
