<script setup lang="ts">
import { store } from '@/stores/store'
import { SnackArgs } from '@/stores/types'
import { Elite } from '@/types/entities'
import { ref, watch } from 'vue'
import { mask } from 'vue-the-mask'

const vMask = mask

const props = defineProps({
  organizationId: String,
  initialEliteName: String,
  initialEliteDesc: String
})

const emit = defineEmits([
  'elite-created',
  'mac-lookup-success',
  'mac-lookup-failed'
])

const elite = ref({
  account_id: '',
  activity_account_id: '',
  activity_date: undefined,
  sentryio_enabled: undefined,
  id: '',
  name: props.initialEliteName || '',
  dsc: props.initialEliteDesc || '',
  serialnumber: '',
  inserviceat: new Date(),
  rootpassword: '',
  backuppassword: '',
  cpu: '',
  publickey: '',
  mac: '',
  organization_id: props.organizationId || '',
  organization_name: '',
  organization_dsc: '',
  username: '',
  password: ''
} as Elite)

const macLookupSuccess = ref(false)

watch(
  () => props.initialEliteName,
  newValue => {
    if (newValue) {
      elite.value.name = newValue
    }
  }
)

watch(
  () => props.initialEliteDesc,
  newValue => {
    if (newValue) {
      elite.value.dsc = newValue
    }
  }
)

const showPassword = ref(false)
const showBackupPassword = ref(false)

const macLookupFailed = ref(false)

const validateMacAddress = async () => {
  const macAddress = elite.value.mac.toUpperCase()
  elite.value.mac = macAddress

  // Check if the MAC address is valid (17 characters with colons)
  if (macAddress.length === 17) {
    const macWithoutColons = macAddress.replace(/:/g, '')
    try {
      const result = await store.api.postToAPI([
        [{ Method: 'Select Elite Commission' }, { mac: macWithoutColons }]
      ])

      console.log('API response:', result)

      if (
        result &&
        result[0] &&
        result[0][0] &&
        result[0][0].Success &&
        result[0][1] &&
        result[0][1].Records
      ) {
        const records = result[0][1].Records
        elite.value.serialnumber = records.SerNo ? records.SerNo.toString() : ''
        elite.value.inserviceat = records.InSrvAt
          ? records.InSrvAt.split(' ')[0]
          : '' // Get only the date part
        elite.value.rootpassword = records.RootPassword || ''
        elite.value.backuppassword = records.BackupPassword || ''
        elite.value.cpu = records.Cpu || ''
        elite.value.publickey = records.PublicKey || ''
        macLookupSuccess.value = true
        macLookupFailed.value = false
        emit('mac-lookup-success')
      } else {
        console.error('Invalid or unexpected API response:', result)
        macLookupSuccess.value = false
        macLookupFailed.value = true
        // clear the fields
        elite.value.serialnumber = ''
        elite.value.inserviceat = new Date()
        elite.value.rootpassword = ''
        elite.value.backuppassword = ''
        elite.value.cpu = ''
        elite.value.publickey = ''

        emit('mac-lookup-failed')
      }
    } catch (error) {
      console.error('Error fetching Elite Commission data:', error)
      macLookupSuccess.value = false
      macLookupFailed.value = true
      // clear the fields
      elite.value.serialnumber = ''
      elite.value.inserviceat = new Date()
      elite.value.rootpassword = ''
      elite.value.backuppassword = ''
      elite.value.cpu = ''
      elite.value.publickey = ''

      emit('mac-lookup-failed')
    }
  } else {
    macLookupSuccess.value = false
    macLookupFailed.value = false
  }
}

const validatePublicKey = (key: string | undefined | null): boolean => {
  if (!key) return false
  const pemRegex =
    /^-----BEGIN PUBLIC KEY-----\n[\s\S]*?\n-----END PUBLIC KEY-----$/
  return pemRegex.test(key.trim())
}

const formatPublicKey = (key: string | undefined | null): string => {
  if (!key || key === undefined) return ''
  const cleanKey = key
    .replace(/-----(BEGIN|END) PUBLIC KEY-----/g, '')
    .replace(/\s/g, '')
  const formattedKey = cleanKey.match(/.{1,64}/g)?.join('\n') || ''
  return `-----BEGIN PUBLIC KEY-----\n${formattedKey}\n-----END PUBLIC KEY-----`
}

const validateFields = () => {
  const requiredFields = [
    'serialnumber',
    'inserviceat',
    'rootpassword',
    'backuppassword',
    'cpu',
    'mac',
    'publickey'
  ]
  const emptyFields = requiredFields.filter(field => !elite.value[field])
  if (emptyFields.length > 0) {
    throw new Error(
      `Please fill in all required fields: ${emptyFields.join(', ')}`
    )
  }
}

const saveElite = async (organization_id: string) => {
  try {
    if (!macLookupSuccess.value) {
      throw new Error(
        'Please enter a valid MAC address and perform a lookup first.'
      )
    }

    elite.value.organization_id = organization_id
    console.log('Creating Elite:', elite.value)
    if (!elite.value.organization_id) {
      throw new Error('Organization ID is required')
    }

    validateFields()
    if (!validatePublicKey(elite.value.publickey)) {
      throw new Error(
        'Invalid public key format. Please enter a valid PEM format public key.'
      )
    }

    const eliteCopy = { ...elite.value }
    const apiKeys = [
      'organization_id',
      'name',
      'dsc',
      'serialnumber',
      'inserviceat',
      'rootpassword',
      'backuppassword',
      'cpu',
      'mac',
      'publickey'
    ]

    for (const key in eliteCopy) {
      if (!apiKeys.includes(key)) {
        delete eliteCopy[key]
      }
    }

    const payLoad = [
      { Method: 'Insert Elite' },
      {
        ...eliteCopy,
        publickey: formatPublicKey(eliteCopy.publickey)
      }
    ]

    const result = await store.api.postToAPI([payLoad])
    if (result && result[0] && result[0][0] && result[0][0].Success) {
      emit('elite-created')
      store.setSnack('Elite created successfully', {
        color: 'success',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'white'
      })

      // refresh Collection
      store.api.refreshData('Elite')
    } else {
      const errorMessage =
        result && result[0] && result[0][0] && result[0][0].Message
          ? result[0][0].Message
          : 'Failed to create Elite'
      throw new Error(errorMessage)
    }
  } catch (error) {
    console.error('Error creating Elite:', error)
    let errorMessage = 'Failed to create Elite'
    if (error instanceof Error) {
      errorMessage = error.message
    }
    store.setSnack(`Failed to create Elite: ${errorMessage}`, {
      color: 'error',
      variant: 'flat',
      location: 'bottom',
      buttonText: 'Close',
      buttonTextColor: 'red',
      buttonColor: 'white',
      zIndex: 99999,
      opacity: 1,
      timeout: 7000,
      buttonAction: () => {
        store.snack.visible = false
      }
    } as SnackArgs)
  }
}

watch(
  () => props.organizationId,
  newValue => {
    if (newValue) {
      elite.value.organization_id = newValue
    }
  },
  { immediate: true }
)

defineExpose({ saveElite, macLookupSuccess })
</script>

<template>
  <VForm @submit.prevent="saveElite" autocomplete="off">
    <AppTextField
      v-model="elite.name"
      label="Elite Name"
      :rules="[(v: any) => !!v || 'Name is required']"
      required
      outlined
      dense
    ></AppTextField>
    <AppTextField
      v-model="elite.dsc"
      label="Description"
      :rules="[(v: any) => !!v || 'Description is required']"
      required
      outlined
      dense
    ></AppTextField>
    <VRow>
      <VCol cols="6">
        <AppTextField
          v-model="elite.mac"
          label="MAC Address"
          :rules="[(v: any) => !!v || 'MAC Address is required']"
          required
          outlined
          dense
          v-mask="'XX:XX:XX:XX:XX:XX'"
          placeholder="00:00:00:00:00:00"
          @input="validateMacAddress"
        >
          <template v-slot:append-inner>
            <VIcon v-if="macLookupSuccess" color="success">mdi-check</VIcon>
            <VIcon v-else-if="macLookupFailed" color="error">mdi-alert</VIcon>
          </template>
        </AppTextField>
      </VCol>
      <VCol cols="6">
        <AppTextField
          v-model="elite.serialnumber"
          label="Serial Number"
          :rules="[(v: any) => !!v || 'Serial Number is required']"
          required
          outlined
          dense
          :readonly="!!elite.serialnumber"
        ></AppTextField>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="6">
        <AppTextField
          v-model="elite.inserviceat"
          label="In Service At"
          type="date"
          :rules="[(v: any) => !!v || 'In Service At is required']"
          required
          outlined
          dense
          :readonly="!!elite.inserviceat"
        ></AppTextField>
      </VCol>
      <VCol cols="6">
        <AppTextField
          v-model="elite.cpu"
          label="CPU"
          :rules="[(v: any) => !!v || 'CPU is required']"
          required
          outlined
          dense
          :readonly="!!elite.cpu"
        ></AppTextField>
      </VCol>
    </VRow>
    <VRow v-if="macLookupSuccess && false">
      <VCol cols="6">
        <AppTextField
          :model-value="elite.rootpassword ? '********' : ''"
          label="Root Password"
          type="password"
          required
          outlined
          dense
          autocomplete="new-password"
          readonly
        ></AppTextField>
      </VCol>
      <VCol cols="6">
        <AppTextField
          :model-value="elite.backuppassword ? '********' : ''"
          label="Backup Password"
          type="password"
          required
          outlined
          dense
          autocomplete="new-password"
          readonly
        ></AppTextField>
      </VCol>
    </VRow>
    <AppTextField
      v-if="macLookupSuccess && false"
      :model-value="elite.publickey ? '********' : ''"
      label="Public Key"
      type="password"
      required
      outlined
      dense
      readonly
    ></AppTextField>
  </VForm>
</template>

<style lang="scss" scoped>
form .v-row {
  margin-block-start: 0 !important;
}
</style>
