import router from "@/router";
import { getEntraUsers } from "@/services/graphApiService";
import { createPinia, defineStore } from "pinia";
import { useApiStore } from "./apiStore";
import { useAuthStore } from "./authStore";
import { useBrokerStore } from "./brokerStore";
import { DataState, SnackArgs } from "./types";
const pinia = createPinia();

const useDataStore = defineStore("data", {
  state: (): DataState => {
    return {
      data: {
        selectOrganizationId: "",
        testMode: false,
      },
      auth: useAuthStore(pinia),
      broker: useBrokerStore(pinia),
      api: useApiStore(pinia),
      domain: window.location.hostname,
      entraUsers: [],
      entraDevices: [], // Add this line
      activeParentOrg: "",
      activeOrganization: "",
      activeOrganizationDisplay: { name: "", id: "", organization_id: "" },
      selectedCollection: "",
      selectedService: "",
      selectedHeaders: [],
      snack: {
        visible: false,
        snackMessage: "",
        args: {
          color: "info",
          variant: "elevated",
          location: "bottom",
          buttonText: "Close",
          buttonTextColor: "white",
          buttonIcon: "",
          buttonAction: () => {},
          timeout: 6000,
          closeButtonText: "Close",
          onClose: () => {}, // Add the onClose property
        } as SnackArgs,
      },
      MsgUuids: [],
      collectionRefs: {},
      activeCard: "leftCard",
      isQRCodeGenerationDialogVisible: false,
      isAnywhereUpgradeDialogVisible: false,
      selectedFirmware: "",
      isAnywhereRegisterDialogVisible: false,
      anywhereRegisterFields: [],
      jsonPacket: {
        PetMfg: { Mac: "", DateOfMfg: "", SenNo: null },
      },
      selectedElite: "",
      showProgressSnackbar: false,
      progressSnackbarText: "",
      progressSnackbarTotal: 0,
      progressSnackbarCurrent: 0,
      serviceWorkerStatus: "unknown",
      serviceWorkerClientId: "unknown",
    };
  },
  getters: {
    mqttClient: (state) => state.broker.client,
    collections: (state) =>
      Object.assign(
        state.api?.collections || {},
        state.broker?.collections || {},
        {
          EntraUsers: {
            data: state.entraUsers,
            title: "Entra Users",
            category: "External",
          },
        }
      ),
    getSnack: (state) => state.snack,
    stores: (state) => state,
    getActiveOrganization: (state) => state.activeOrganizationDisplay,
    getActiveParentOrgId: (state) => state.activeParentOrg,
    getActiveOrganizationId: (state) => state.activeOrganization,
    services: (state) => state.broker?.services || {},
    isAuthenticated: (state) => state.auth.getIsAuthenticated,
    getDomain: (state) => state.domain.replaceAll(".", "-"),
    getTestMode: (state) => state.data.testMode,
    getServiceWorkerStatus: (state) => state.serviceWorkerStatus,
    getEnvironment: (state) => {
      return state.auth.getEnvironment;
    },
  },
  actions: {
    setSelectedElite(elite: string) {
      this.selectedElite = elite;
    },
    updateServiceWorkerStatus(
      status: "unregistered" | "registered" | "updated" | "offline",
      clientId: string
    ) {
      this.serviceWorkerClientId = clientId;
      this.serviceWorkerStatus = status;
    },
    setTestMode(value: boolean) {
      this.data.testMode = value;
    },
    getSelectedHeaders() {
      // get from local storage by collection
      const selected =
        this.getItem(`selectedHeaders-${this.selectedCollection}`) || [];

      // console.debug("getSelectedHeaders", selected);
      // if we have selected headers, return them

      if (selected.length > 0) {
        this.selectedHeaders = selected;
      } else {
        // otherwise, get the headers from the collection
        const headers =
          this.collections[this.selectedCollection]?.headers || [];
        this.selectedHeaders = headers.length
          ? headers.map((x: any) => {
              return {
                ...x,
                selected: true,
              };
            })
          : [];
      }

      return this.selectedHeaders;
    },
    setSelectedHeaders(headers: Array<any>) {
      this.selectedHeaders = headers;
      // persist to local storage by collection
      this.setItem(
        `selectedHeaders-${this.selectedCollection}`,
        this.selectedHeaders
      );
    },
    async checkLoggedIn() {
      console.debug("store.checkLoggedIn", store.auth.getIsAuthenticated);
      // are they authenticated with adfs?
      if (store.auth.getIsAuthenticated) {
        // if so, initialize the api and broker
        this.init();
      } else {
        // if not, wait for them to authenticate
        store.auth
          .adfsLogin()
          .then((result: any) => {
            console.debug("adfsLogin complete", result);
            this.init();
          })
          .catch((error: any) => {
            console.warn("store.checkLoggedIn", error);
          });
      }
    },
    async init() {
      this.auth
        .authorize()
        .then((result: any) => {
          console.debug("authorize with auth broker complete", result);
          store.api
            .init()
            .then(() => {
              console.debug("store.api.init");

              store.broker.init();
              this.fetchEntraUsers(); // Add this line to fetch Entra users
              router.push("/");
            })
            .catch((error: Error) => {
              console.error("store.api.init", error);
              // rethrow error
              throw error;
            });
        })
        .catch((error: Error) => {
          console.error("store.init", error);
          if (
            error.message.includes("Login required") ||
            error.message.includes("Expired")
          ) {
            store.auth.logout();
          }
        });
    },

    async fetchEntraUsers() {
      try {
        console.log("Fetching Entra users...");

        this.entraUsers = await getEntraUsers();
        console.log("Entra users fetched successfully:", this.entraUsers);
      } catch (error) {
        console.error("Error fetching Entra users:", error);
        this.setSnack("Failed to fetch Entra users. Please try again.", {
          color: "error",
          location: "top",
          buttonText: "Dismiss",
          buttonTextColor: "white",
        });
      }
    },
    getOrganizationNameById(id: string) {
      const organization = this.api.collections.Organization.data.find(
        (x: any) => x.id === id
      );
      return organization ? organization.name : "";
    },
    getOrganizationDetailsById(id: string) {
      return this.api.collections.Organization.data.find(
        (x: any) => x.id === id
      );
    },
    getItemDetailsById(collection: string, id: string) {
      return this.api.collections[collection].data.find(
        (x: any) => x.id === id
      );
    },
    getItemDetailsByKey(collection: string, key: string, value: string) {
      return this.api.collections[collection].data.find(
        (x: any) => x[key] === value
      );
    },
    getItemNameById(collection: string, id: string) {
      // console.log("getItemNameById", collection, id);
      return (
        (
          this.api.collections[collection].data.find((x: any) => x.id === id) ||
          {}
        ).name || id
      );
    },
    async setSnack(
      message: any,
      args: {
        color: any;
        variant?: any;
        location: any;
        buttonText: any;
        buttonTextColor: any;
        buttonIcon?: any;
        buttonAction?: Function;
        buttonColor?: any;
        timeout?: number;
        closeButtonText?: string;
        onClose?: Function;
      }
    ) {
      this.snack.snackMessage = message;
      this.snack.visible = true;
      this.snack.args = {
        ...args,
        zIndex: 9999,
        opacity: 1,
      };
    },
    setActiveParentOrg(org: string) {
      this.activeParentOrg = org;
      // set activeOrganizationDisplay
      if (org) {
        this.activeOrganizationDisplay =
          this.api.collections.Organization.data.find((x: any) => x.id === org);

        console.info("setActiveParentOrg", org, this.activeOrganizationDisplay);
      } else {
        this.activeOrganizationDisplay = { name: "", id: "" };
      }
    },
    async setActiveOrganization(org: string) {
      console.info("setActiveOrganization", org, this.activeOrganization);
      // set activeOrganization
      this.activeOrganization = this.activeOrganization === org ? "" : org;
      // set activeOrganizationDisplay
      this.activeOrganizationDisplay = this.activeOrganization
        ? await this.getActiveOrganizationDetails()
        : { name: "", id: "" };

      // set activeParentOrg if activeOrganization is set
      if (this.activeOrganization !== "") {
        this.activeParentOrg = this.api.collections["Organization"]?.data.find(
          (x: any) => x.id === org
        ).organization_id;
      } else if (org) {
        console.info("setActiveOrganization", org, this.activeOrganization);

        this.setActiveParentOrg(
          this.api.collections["Organization"]?.data.find(
            (x: any) => x.id === org
          ).organization_id
        );
      }

      console.info(
        "setActiveOrganization",
        org,
        this.activeOrganizationDisplay
      );

      if (this.activeOrganization !== "") {
        // subscribe to mqtt topics
        /* await store.api.refreshData("Anywhere Messages", {
          organization_id: this.activeOrganization,
        });
        store.broker.manualSubscribe({
          topic: `Org/${this.activeOrganization}/Anywhere/message`,
          qos: 0,
        }); */
      }

      return !!this.activeOrganization;
    },
    async getActiveOrganizationDetails() {
      // return activeOrganizationDetails in promise
      return new Promise(async (resolve, reject) => {
        try {
          const payLoad = [
            { Method: "Select V_Organization" },
            { id: this.activeOrganization },
          ];
          const result = await this.api.postToAPI([payLoad]);
          console.info("getActiveOrganizationDetails", result);
          if (result[0][0].Success) {
            resolve(result[0][1].Records.pop());
          } else {
            reject({ name: "", id: "" });
          }
        } catch (error) {
          console.error("getActiveOrganizationDetails", error);
          reject({ name: "", id: "" });
        }
      });
    },
    setItem(key: string, value: any) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getItem(key: string) {
      return JSON.parse(localStorage.getItem(key) || "{}");
    },
    // Dialog-related actions
    openAnywhereRegisterDialog(packet: {
      Mac: string;
      DateOfMfg: string;
      SenNo: number;
    }) {
      this.jsonPacket = packet;
      this.isAnywhereRegisterDialogVisible = true;
    },
    closeAnywhereRegisterDialog() {
      this.isAnywhereRegisterDialogVisible = false;
      this.jsonPacket = { Mac: "", DateOfMfg: "", SenNo: "" };
    },
    openAnywhereUpgradeDialog() {
      this.isAnywhereUpgradeDialogVisible = true;
    },
    closeAnywhereUpgradeDialog() {
      this.isAnywhereUpgradeDialogVisible = false;
    },
    setSelectedFirmware(firmware: string) {
      this.selectedFirmware = firmware;
    },
    clearSelectedItems(collection: string) {
      if (this.collections[collection]) {
        this.collections[collection].selected = [];
      }
    },
  },
});

const store = useDataStore(pinia);

// initialize the brokerStore.
store.checkLoggedIn();

export {
  pinia,
  store,
  useApiStore,
  useAuthStore,
  useBrokerStore,
  useDataStore,
};
