import { store } from "@/stores/store";
import { setupLayouts } from "virtual:generated-layouts";
import { createRouter, createWebHistory } from "vue-router";
import routes from "~pages";
import { isUserLoggedIn } from "./utils";

console.debug("###### in router routes", JSON.stringify(routes, null, 2));
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      redirect: (to) => {
        // do we get here?
        console.log("in routes to", to);

        const userData = JSON.parse(localStorage.getItem("userData") || "{}");
        const userRole = userData?.idTokenClaims?.roles[0] || "None";
        console.debug("in routes userRole", userData, userRole);

        if (userRole === "admin") return { name: "index" };
        if (userRole === "user") return { name: "index" };

        return { name: "login", query: to.query };
      },
    },
    {
      path: "/logout",
      name: "logout",
      redirect: () => {
        /* localStorage.removeItem("userData");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userAbilities");
        let msalInstance = new PublicClientApplication(store.auth.msalConfig);
        console.debug("in routes msalInstance", msalInstance);
        msalInstance.logoutPopup(); */
        store.auth.logout();

        return { name: "login" };
      },
    },
    /*     {
      path: "/login",
      name: "login",
      component: () => import("~pages/Login.vue"),
    }, */
    /*     {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: () => import("~pages/NotFound.vue"),
    }, */
    ...setupLayouts(routes),
  ],
});

router.beforeEach((to) => {
  const isLoggedIn = isUserLoggedIn();

  console.debug("=====================to", to, { isLoggedIn });

  if (to.meta.redirectIfLoggedIn && isLoggedIn) return "/";
});

export default router;
