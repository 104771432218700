<template>
  <div class="h-100 d-flex align-center justify-space-between">
    <!-- 👉 Footer: left content -->
    <span class="d-flex align-center">
      &copy;
      {{ new Date().getFullYear() }}
      Response Technologies. All Rights Reserved
    </span>
        <span class="d-md-flex gap-x-4 text-primary" v-if="isDev">

      <input type="checkbox" v-model="testMode" id="testMode" class="test-check" />
      <label for="testMode" class="form-check-label"> Test Mode </label>
      {{ store.getTestMode ? "ON" : "OFF" }}
    </span>
  </div>
</template>

<script setup lang="ts">
// import SelectDomain from "./SelectDomain.vue";
import { store } from "@/stores/store";

const testMode = ref(store.getTestMode);
watch(testMode, (val: boolean) => {
  store.setTestMode(val);
});

const isDev =
  window.location.href.includes('dev') ||
  window.location.href.includes('localhost')
</script>

<style>
.test-check {
  position: relative;
  display: block !important;
  block-size: 1.5em;
  cursor: pointer;
  inline-size: 1.5em;
  margin-inline-end: 0.5em;
}

.test-check:checked {
  background-color: #fff;
}

.test-check :checked::after {
  position: absolute;
  display: block;
  border-radius: 0.25em;
  background-color: #007bff;
  block-size: 0.5em;
  content: '';
  inline-size: 0.5em;
  inset-block-start: 0.25em;
  inset-inline-start: 0.25em;
}
</style>
