<script setup lang="ts">
import EntitiesCard from '@/components/EntitiesCard.vue'
import EntitiesCardTest from '@/components/EntitiesCardTest.vue'
import { store } from '@/stores/store'
import { onKeyStroke, useMouseInElement } from '@vueuse/core'
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue'
import AnywhereRegisterDialog from '../components/AnywhereRegisterDialog.vue'
import ProgressSnackbar from '../components/ProgressSnackbar.vue'
import DashboardCollections from './components/DashboardCollections.vue'
import DashboardOrganizations from './components/DashboardOrganizations.vue'

const showSelect = ref(true)
const showLoading = ref(true)

const selectedCollection = computed(() => {
  return store.selectedCollection
})
const displayCollection = computed(() => {
  if (selectedCollection.value === '') return ''
  return selectedCollection?.value?.replace('(Bound)', '').trim() || ''
})

const authStore = ref(store.auth)

const leftCard = ref<HTMLElement | any>()
const rightCard = ref<HTMLElement | any>()
const bottomCard = ref<HTMLElement>()
const activeCard = ref(store.activeCard)

const rightCardHeight = ref(0)

const resizeObserver = new ResizeObserver(entries => {
  const getRightCardHeight = entries?.[0]?.contentRect?.height || 0

  // Check if the leftCard's DOM element is still present
  if (document.contains(leftCard?.value?.$el || null)) {
    rightCardHeight.value = getRightCardHeight
    leftCard.value.$el.style.height = `${getRightCardHeight + 22}px`
  }
})

const observeHeight = () => {
  if (rightCard.value?.$el) {
    // Use the $el property to get the DOM element from the Vue component instance
    resizeObserver.observe(rightCard.value.$el)
  }
}

// Since leftCard is only used to apply styles, we watch rightCard's changes
// and apply the height to leftCard when rightCard changes.
watch(rightCard, newVal => {
  if (newVal?.$el && leftCard.value?.$el) {
    // rightCardHeight.value = newVal.$el.offsetHeight;
    leftCard.value.$el.style.height = `${newVal.$el.offsetHeight}px`
  }
})

onMounted(async () => {
  await nextTick()
  observeHeight()
})

onBeforeUnmount(() => {
  resizeObserver.disconnect()
})

// Watch for changes in rightCard to re-attach ResizeObserver if necessary
/* watch(rightCard, (newVal, oldVal) => {
  if (oldVal?.$el) {
    resizeObserver.unobserve(oldVal.$el);
  }
  if (newVal?.$el) {
    resizeObserver.observe(newVal.$el);
  }
}); */

// if no input has focus, capture alpha keys using onkeystroke
const alphaArray = 'abcdefghijklmnopqrstuvwxyz'.split('')

onKeyStroke(alphaArray, e => {
  if (e.key === 'f') {
    console.log('f key pressed', activeCard.value)
  }
})

const { isOutside: outsideLeft } = useMouseInElement(leftCard)
const { isOutside: outsideRight } = useMouseInElement(rightCard)
const { isOutside: outsideBottom } = useMouseInElement(bottomCard)

const isOrganizationActive = computed(() => !!store.activeOrganization)

// Manage the dialog state from dataStore
const isAnywhereRegisterDialogVisible = computed(
  () => store.isAnywhereRegisterDialogVisible
)

const closeAnywhereRegisterDialog = () => {
  store.closeAnywhereRegisterDialog()
}

// Progress Snackbar controls
const showProgressSnackbar = ref(false)
const totalItems = ref(0)
const currentItem = ref(0)
const progressText = ref('Processing...')

const startBatchOperation = (total: number, text = 'Processing...') => {
  totalItems.value = total
  currentItem.value = 0
  progressText.value = text
  showProgressSnackbar.value = true
}

const updateBatchProgress = (current: number) => {
  currentItem.value = current
}

const endBatchOperation = () => {
  showProgressSnackbar.value = false
}

const displayMode = ref<'percent' | 'fraction'>('percent')
</script>

<template>
  <div>
    <AnywhereRegisterDialog />
    <ProgressSnackbar
      :show="showProgressSnackbar"
      :total-items="totalItems"
      :current-item="currentItem"
      :text="progressText"
      :display-mode="displayMode"
    />

    <VRow class="match-height">
      <VCol
        cols="12"
        md="6"
        lg="5"
        class="full-height leftCard"
        :class="outsideLeft ? 'inactiveCard' : 'activeCard'"
        ref="leftCard"
      >
        <DashboardOrganizations :right-card-height="rightCardHeight" />
        <!-- <DashboardOrganizationDetail v-if="isOrganizationActive" /> -->
      </VCol>

      <!-- <VCol
        cols="12"
        :md="store.getActiveOrganizationId ? 12 : 6"
        :lg="store.getActiveOrganizationId ? 12 : 7"
        class="full-height rightCard"
        :class="outsideRight ? 'inactiveCard' : 'activeCard'"
        ref="rightCard"
      > -->
      <VCol
        cols="12"
        md="6"
        lg="7"
        class="full-height rightCard"
        :class="outsideRight ? 'inactiveCard' : 'activeCard'"
        ref="rightCard"
      >
        <DashboardCollections />
      </VCol>
    </VRow>

    <VRow>
      <VCol
        cols="12"
        ref="bottomCard"
        :class="outsideBottom ? 'inactiveCard' : 'activeCard'"
      >
        <EntitiesCard
          v-if="displayCollection && store.getTestMode === false"
          :collection="displayCollection"
          item-title="DeviceKind"
          :item-value="selectedCollection === 'Cab' ? 'uuid' : 'id'"
          :show-select="showSelect"
          :show-loading="showLoading"
          :expandable-rows="displayCollection === 'Elite'"
          :relationship="{
            parentCollection: 'Organization',
            parentKey: 'id',
            childKey: 'organization_id'
          }"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <EntitiesCardTest
          v-if="displayCollection && store.getTestMode === true"
          :collection="displayCollection"
          item-title="DeviceKind"
          :item-value="selectedCollection === 'Cab' ? 'uuid' : 'id'"
          :show-select="showSelect"
          :show-loading="showLoading"
          :expandable-rows="false"
          :relationship="{
            parentCollection: 'Organization',
            parentKey: 'id',
            childKey: 'organization_id'
          }"
        />
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss">
.activeCard > .v-card {
  /* stylelint-disable value-list-comma-newline-after */

  /* stylelint-disable declaration-colon-newline-after */
  box-shadow: 0 5px 15px rgba(var(--v-shadow-key-umbra-color), 0.75),
    0 0 transparent, 0 0 transparent !important;
}

.inactiveCard > .v-card {
  box-shadow: 0 4px 18px rgba(var(--v-shadow-key-umbra-color), 0.1),
    0 0 transparent, 0 0 transparent;
  opacity: 0.9;

  // box-shadow: none !important;
}

.v-field {
  font-size: 0.875rem;
}

.v-card-item {
  grid-template-columns: max-content;
}

.leftCard {
  min-block-size: 32em;
}

/* stylelint-disable-next-line max-line-length */
td.v-data-table__td.v-data-table-column--align-start.v-data-table-group-header-row__column.v-data-table-group-header-row__column {
  white-space: nowrap;
}

.v-data-table .v-table__wrapper > table > thead > tr > td,
.v-data-table .v-table__wrapper > table > thead > tr th,
.v-data-table .v-table__wrapper > table tbody > tr > td,
.v-data-table .v-table__wrapper > table tbody > tr th {
  background: none;
}

.v-data-table-rows-no-data {
  background: transparent !important;
  min-block-size: 9em;
  opacity: 0.8;
}

/* .selected > div > table > tbody > tr {
  background-color: rgba(50, 115, 220, 10%) !important;
} */

/* stylelint-disable-next-line no-descending-specificity */
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 5%);
}

/* animate hiding and showing rows */

.no-row {
  background-color: transparent !important;
}
</style>
