// 👉 IsEmpty
export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === "") return true;

  return !!(Array.isArray(value) && value.length === 0);
}

// 👉 IsNullOrUndefined
export function isNullOrUndefined(value: unknown): value is undefined | null {
  return value === null || value === undefined;
}

// 👉 IsEmptyArray
export function isEmptyArray(arr: unknown): boolean {
  return Array.isArray(arr) && arr.length === 0;
}

// 👉 IsObject
export function isObject(obj: unknown): obj is Record<string, unknown> {
  return (
    obj !== null && !!obj && typeof obj === "object" && !Array.isArray(obj)
  );
}

export function isToday(date: Date) {
  const today = new Date();

  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
}

// 👉 Recursive Object Search by key
// example: findKey(obj, "key")
// returns: value as any
export function findKey(obj: Record<string, unknown>, key: string): unknown {
  if (typeof obj !== "object" || obj === null) return undefined;
  if (key in obj) return obj[key];

  return Object.values(obj).reduce((acc, val) => {
    if (acc !== undefined) return acc;

    if (typeof val === "object")
      return findKey(val as Record<string, unknown>, key);

    return undefined;
  }, undefined);
}
