// src/stores/apiStore.ts
import { findKey } from "@/@core/utils";
import { useDebounceFn } from "@vueuse/core";
import { defineStore } from "pinia";
import { camelToTitle, flattenObj } from "./flattenObj";
import { store } from "./store";

interface dataStore {
  data: Array<any>;
  headers: Array<any>;
  selected: Array<any>;
  loading: boolean;
  title: string;
  category: string;
  source?: string;
  where?: string;
}

type CollectionList = Array<string>;

interface Collections {
  [key: string]: dataStore;
}

interface ApiState {
  recovery: boolean;
  collectionList: CollectionList;
  collections: Collections;
  errors: any[];
  debouncedFunctions: { [key: string]: Function };
}

interface PostDataContent {
  id: string;
  name: string;
  dsc: string;
  mac: string;
  publickey: string;
  inserviceat: string;
  username: string;
  activitydate: string;
  organization_id: string;
}

type PostData = Array<PostDataContent>;

interface Options {
  method: string;
  headers: Headers;
  body: URLSearchParams;
}

interface JsonOptions {
  method: string;
  headers: Headers;
  body: string;
}

type FlatRecord = {} | any;

type MethodOptions = {
  organization_id: string;
  id: string;
};

export const useApiStore = defineStore("api", {
  state: (): ApiState => {
    return {
      recovery: false,
      collectionList: [
        "Elite",
        "Organization",
        "Gateway",
        "Envoy",
        "License",
        "Anywhere",
        "Anywhere Messages",
        "ParentOrg",
      ],
      debouncedFunctions: {},
      collections: {
        Organization: {
          data: [],
          headers: [],
          selected: [],
          loading: true,
          title: "",
          category: "",
          source: "Organization",
          // where: "Organization.organization_id !== Organization.id",
        },
        Gateway: {
          data: [],
          headers: [],
          selected: [],
          loading: true,
          title: "",
          category: "",
          source: "api",
        },
        Envoy: {
          data: [],
          headers: [],
          selected: [],
          loading: true,
          title: "",
          category: "",
          source: "api",
        },
        License: {
          data: [],
          headers: [],
          selected: [],
          loading: true,
          title: "",
          category: "",
          source: "api",
        },
        Elite: {
          data: [],
          headers: [],
          selected: [],
          loading: true,
          title: "",
          category: "",
          source: "api",
        },
        Anywhere: {
          data: [],
          headers: [],
          selected: [],
          loading: true,
          title: "",
          category: "",
          source: "Anywhere Bound",
        },
        ["Anywhere Messages"]: {
          data: [],
          headers: [],
          selected: [],
          loading: true,
          title: "",
          category: "",
          source: "V_Anywhere_Messages_Most_Recent",
        },
        ParentOrg: {
          data: [],
          headers: [],
          selected: [],
          loading: true,
          title: "",
          category: "",
          source: "Organization",
          where: "Organization.organization_id === Organization.id",
        },
      },
      errors: [],
    };
  },
  actions: {
    apiData(contentType?: string | undefined) {
      const headers = new Headers();

      if (contentType) headers.append("Content-Type", contentType);

      headers.append(
        "Authorization",
        // import.meta.env.VITE_AUTH_BROKER_TOKEN as string
        // `Bearer ${import.meta.env.VITE_AUTH_BROKER_TOKEN}`
        `Bearer ${store.auth.idToken}`
      );

      /* store.auth.authData["Cloud Resources"]["Configuration Broker"]["URI"] =
        store.auth.authData["Cloud Resources"]["Configuration Broker"]["URI"] +
        "?traceuuid=" +
        uuidv4(); */

      /* console.log(
          "apiData",
          store.auth.authData["Cloud Resources"]["Configuration Broker"]
        ); */
      return {
        /* headers: {
          cookie: `PHPSESSID=${store.auth.user.PHPSESSID}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "X-USERNAME": store.auth.user["X-USERNAME"],
          "X-API-KEY": store.auth.user["X-API-KEY"],

        }, */
        headers,
        api: {
          ...store.auth.authData["Cloud Resources"]["Configuration Broker"],
          URI: store.auth.authData["Cloud Resources"]["Configuration Broker"][
            "URI"
          ],
        },
      };
    },
    postToEndpoint(endpoint: string, data: PostData) {
      return new Promise<any>(async (resolve, reject) => {
        let recordsProcessed: Array<any> = [];

        try {
          // data.forEach(async (payLoad: Array<any>) => {
          if (typeof data === "object" && !data.length) data = [...data];
          console.log(`${data.length} payload(s) passed to apiStore.`);
          for (const payLoad of data) {
            /* console.info(
              "payLoad",
              payLoad,
              endpoint.replace(/(^\s+|\s+$)/g, ""),
              this.apiData()
            ); */

            // post to api
            const response = await fetch(
              // strip leading/trailing spaces from endpoint
              this.apiData()
                .api.URI.replace("EliteProxy", "")
                .replace(/\/$/, "") +
                "/" +
                endpoint.replace(/(^\s+|\s+$)/g, ""),
              {
                method: "POST",
                headers: this.apiData("application/json").headers,
                body: JSON.stringify(payLoad),
              }
            );
            const responseText = await response.text();
            const result = JSON.parse(responseText);

            // validate response
            const validateResponse = await this.validateAPIResponse(result);
            if (!validateResponse) {
              if (this.recovery) {
                this.recovery = false;
                console.error("Recovery successful, retrying...");
                this.postToEndpoint(endpoint, data);
              } else {
                console.error("Post to endpoint failed", result);
                reject(result);
              }
            }
            recordsProcessed.push(...result.recordSet);
          }
        } catch (err: any) {
          this.errors.push(err);
          console.error("Error", err);
          store.setSnack(err, {
            color: "error",
            variant: "elevated",
            location: "bottom",
            buttonText: "Close",
            buttonTextColor: "white",
          });

          reject(err);
        }
        resolve(recordsProcessed);
      });
    },
    postToAPI(data: PostData) {
      return new Promise<any>(async (resolve, reject) => {
        console.log(`${data.length} payload(s) passed to apiStore.`);
        let recordsProcessed: Array<any> = [];

        try {
          // data.forEach(async (payLoad: Array<any>) => {
          if (typeof data === "object" && !data.length) data = [...data];
          for (const payLoad of data) {
            // console.info("payLoad", payLoad);

            // post to api
            const response = await fetch(this.apiData().api.URI, {
              method: "POST",
              headers: this.apiData().headers,
              body: new URLSearchParams({
                MethodArray: JSON.stringify(payLoad),
              }),
            });
            const responseText = await response.text();
            const result = JSON.parse(responseText);

            // validate response
            const validateResponse = await this.validateAPIResponse(result);
            if (!validateResponse) {
              if (this.recovery) {
                this.recovery = false;
                console.error("Recovery successful, retrying...");
                this.postToAPI(data);
              } else {
                console.error("Post failed", result);
                reject(result);
              }
            }

            recordsProcessed.push(result);
          }
        } catch (err) {
          this.errors.push(err);
          console.error("Error", err);
          store.setSnack(err, {
            color: "error",
            variant: "elevated",
            location: "bottom",
            buttonText: "Close",
            buttonTextColor: "white",
          });
        }
        resolve(recordsProcessed);
      });
    },
    postData(data: Array<any>) {
      return new Promise<any>(async (resolve, reject) => {
        console.log(`${data.length} payload(s) passed to apiStore.`, data);

        try {
          const payLoad = data;
          // data.forEach(async (payLoad: Array<any>) => {
          /* for (const payLoad of data) {
            // console.info("payLoad", payLoad); */

          try {
            // determine collection via method
            /* const collection = payLoad[0].Method.split(" ")[1];

              // set bound/unbound event to send to broker
              const action = payLoad[0].Method.includes("Delete")
                ? "Unbound"
                : "Bound";

              const deviceKind = payLoad[0].Method.includes("Anywhere")
                ? "Cab"
                : collection; */

            // send payload to api
            const response = await fetch(this.apiData().api.URI, {
              method: "POST",
              headers: this.apiData().headers,
              body: new URLSearchParams({
                MethodArray: JSON.stringify(payLoad),
                "": "",
              }),
            });
            const responseText = await response.text();
            const result = JSON.parse(responseText);

            // console.log("result", result);

            // validate response
            const validateResponse = await this.validateAPIResponse(result);

            // if not successful, set recovery flag and refresh token
            // the caller will retry the request if recovery is set
            // if successful, resolve

            if (!validateResponse) {
              if (this.recovery) {
                this.recovery = false;
                console.warn("Recovery successful, retrying...");
                this.postData(data);
              } else {
                console.error("Post failed", payLoad, result);
                reject(result);
              }
            }

            /**
             * [
                  {
                      "id": "00168401-480d-4045-8394-3afc29b16fa2"
                  }
               ]
             */

            console.log("result", payLoad);
          } catch (err) {
            this.errors.push(err);
            console.error("Error processing payload", err, payLoad);
            store.setSnack(err, {
              color: "error",
              variant: "elevated",
              location: "bottom",
              buttonText: "Close",
              buttonTextColor: "white",
            });

            /**
             * payLoad example
             * [
                {
                    "Method": "Unbind Anywhere"
                },
                [
                    {
                        "id": "0016bdde-7988-4045-8394-3afc29b16fa2"
                    }
                ]
              ]
             */
            return reject({
              success: false,
              result: err,
              // return the id array
              processed: payLoad[1],
            });
          }
          //}

          resolve({ success: true, processed: payLoad[1] });
        } catch (err) {
          console.error("Error", err);
          store.setSnack(err, {
            color: "error",
            variant: "elevated",
            location: "bottom",
            buttonText: "Close",
            buttonTextColor: "white",
          });

          reject({ success: false, result: err, processed: [] });
        }
      });
    },
    debouncedRefreshData(collection: string, methodOptions?: MethodOptions) {
      const key = `${collection}-${JSON.stringify(methodOptions)}`;
      if (!this.debouncedFunctions) {
        this.debouncedFunctions = {};
      }

      if (!this.debouncedFunctions[key]) {
        this.debouncedFunctions[key] = useDebounceFn(
          (collection: string, methodOptions?: MethodOptions) =>
            this.refreshDataImpl(collection, methodOptions),
          300
        );
      }

      return this.debouncedFunctions[key](collection, methodOptions);
    },

    refreshData(collection: string, methodOptions?: MethodOptions) {
      return this.debouncedRefreshData(collection, methodOptions);
    },

    refreshDataImpl(collection: string, methodOptions?: MethodOptions) {
      return new Promise<void>(async (resolve, reject) => {
        // console.trace("refreshing", { collection, methodOptions });

        try {
          const sourceCollection =
            this.collections[collection]?.source === "api" ||
            !this.collections[collection]?.source
              ? collection
              : this.collections[collection].source;

          let readCmd = `Select ${sourceCollection}`;

          // Special case for Anywhere Bound with an id
          if (collection === "Anywhere" && methodOptions?.id) {
            readCmd = "Select Anywhere";
          }

          // console.warn("readCmd", readCmd, { sourceCollection });

          if (
            collection === "Anywhere Messages" &&
            !methodOptions?.organization_id
          ) {
            // console.log("no organization_id, skipping");
            resolve();
            return;
          }

          const switchCategory = (collection: string) => {
            switch (true) {
              case collection.includes("Org"):
                return "Organizations";
              case collection.includes("Messages"):
                return "Messages";
              case collection.includes("Anywhere"):
                return "Sensors";
              case collection.includes("Entra"):
                return "External";
              default:
                return "Devices";
            }
          };
          let category = switchCategory(collection);

          if (!this.collections[collection]) return;

          this.collections[collection].category = category;

          if (!this.collections[collection].source) {
            this.collections[collection].source = collection;
            return;
          }

          this.collections[collection].loading = true;

          const fetchFromApi = async () => {
            /* if (
              collection === "Anywhere Messages" &&
              !methodOptions?.organization_id
            )
              return [{ Success: true }, { Records: [] }]; */

            const MethodArray: any = [
              {
                Method: readCmd,
              },
            ];

            if (methodOptions) {
              MethodArray.push(methodOptions);
            }

            const response = await fetch(this.apiData().api.URI, {
              method: "POST",
              headers: this.apiData().headers,
              body: new URLSearchParams({
                MethodArray: JSON.stringify(MethodArray),
              }),
            });
            const responseText = await response.text();
            const responseData = JSON.parse(responseText);

            if (
              this.collections[collection].source &&
              this.collections[collection].source !== "api"
            ) {
              console.log("fetchFromApi", responseData);
              const data = responseData[1]?.Records || [];
              const where = this.collections[collection]?.where;

              const filteredData = data.filter((Organization: any) => {
                if (where && Organization) {
                  // Replace eval with a safer alternative
                  return this.evaluateCondition(where, Organization);
                } else {
                  return true;
                }
              });
              return [responseData[0], { Records: filteredData }];
            } else {
              return responseData;
            }
          };

          const responseData = await fetchFromApi();

          // validate response
          try {
            const validateResponse = await this.validateAPIResponse(
              responseData
            );
            // console.warn("validateResponse", validateResponse, responseData);

            if (!validateResponse) {
              if (this.recovery) {
                this.recovery = false;
                console.warn("Recovery successful, retrying...");
                this.refreshData(collection, methodOptions);
              } else {
                console.error("Refresh failed", responseData);
                if (this.collections[collection]?.loading)
                  this.collections[collection].loading = false;
                return reject(responseData);
              }
            }
          } catch (err) {
            console.error("validateResponse", err);
            if (this.collections[collection]?.loading)
              this.collections[collection].loading = false;
            return reject(err);
          }

          if (collection === "Anywhere Messages") {
            // add data to Anywhere Messages collection in new object based on the Reason key
            responseData[1].Records.forEach((record: any) => {
              // json decode the message
              const message = record.message;
              const mac = findKey(message, "Mac") || "";
              const anywhere_id = record.anywhere_id || "";

              const newRecord = {
                anywhere_id,
                mac,
                ...message,
              };

              // search for record in collection
              const index = this.collections[collection].data.findIndex(
                (y: any) => y.anywhere_id === anywhere_id
              );

              // console.warn("newRecord", newRecord, index);
              // if record exists, update it
              if (index > -1) {
                this.collections[collection].data[index] = Object.assign(
                  this.collections[collection].data[index],
                  newRecord
                );
              }
              // otherwise, add it
              else {
                this.collections[collection].data.push(newRecord);
              }

              // update Anywhere by anywhere_id
              const index2 = this.collections["Anywhere"].data.findIndex(
                (y: any) => y.id === anywhere_id
              );

              if (index2 !== -1) {
                const flattenedRecord = flattenObj(newRecord);
                this.collections["Anywhere"].data[index2] = Object.assign(
                  this.collections["Anywhere"].data[index2],
                  flattenedRecord
                );
                this.rebuildHeaders("Anywhere");
              }
            });
          } else {
            if (collection !== "Anywhere") {
              this.collections[collection].data =
                responseData[0] && responseData[0].Success
                  ? responseData[1].Records
                  : [];
            } else {
              // For Anywhere collection, merge the API data with existing data
              const apiData =
                responseData[0] && responseData[0].Success
                  ? responseData[1].Records
                  : [];

              apiData.forEach((apiItem: any) => {
                const existingIndex = this.collections[
                  collection
                ].data.findIndex((item: any) => item.id === apiItem.id);
                if (existingIndex !== -1) {
                  this.collections[collection].data[existingIndex] = {
                    ...this.collections[collection].data[existingIndex],
                    ...apiItem,
                  };
                } else {
                  this.collections[collection].data.push(apiItem);
                }
              });
            }
          }

          this.collections[collection].headers =
            this.collections[collection].data.length > 0
              ? Object.keys(this.collections[collection].data[0]).map((x) => {
                  return {
                    title: camelToTitle(
                      x.replace(/_/g, " ").replace(/\./g, " ").trim()
                    ),
                    key: x,
                  };
                })
              : {};
          if (this.collections[collection]?.loading)
            this.collections[collection].loading = false;
          this.collections[collection].title =
            collection === "Anywhere"
              ? "Anywhere (Bound)"
              : category === "Devices"
              ? camelToTitle(collection) + " (Bound)"
              : camelToTitle(collection);

          this.rebuildHeaders(collection)
            .then(() => {
              resolve(responseData);
            })
            .catch((err) => {
              reject(err);
            });
        } catch (err) {
          this.errors.push(err);
          console.error("Refresh failed", err, collection);
          if (this.collections[collection]?.loading)
            this.collections[collection].loading = false;
          reject(err);
        }
      });
    },
    async validateAPIResponse(response: any) {
      try {
        // validate response, if not successful set recovery flag, refresh token, return false
        // the caller will retry the request if recovery is set and return false
        // if successful, return true
        // response can be either response[0].Success or response.success

        const responseSuccess =
          findKey(response, "Success") || findKey(response, "success") || false;
        const responseMessage =
          findKey(response, "Message") || findKey(response, "message") || "";

        if (!responseSuccess) {
          // if token expired, refresh token
          if (store.auth.checkIdTokenExpiration() && !this.recovery) {
            console.warn("Token expired, refreshing", this.apiData(), {
              accessToken: store.auth.accessToken,
              idToken: store.auth.idToken,
            });
            this.recovery = true;
            const getAccessToken = await store.auth.getAccessToken();
            console.warn("token refreshed...", getAccessToken);
            return false;
          } else {
            this.recovery = false;
            if (
              typeof responseMessage === "string" &&
              responseMessage.includes("Expired") &&
              !store.auth.checkIdTokenExpiration()
            ) {
              console.error(
                "Token not expired, but API response is:",
                response
              );
              console.error("Token", {
                currentToken: store.auth.idToken,
                isExpired: store.auth.checkIdTokenExpiration(),
                expDate: store.auth.idTokenExpDate,
              });
              store.setSnack("Token not expired, but IDP returns expired", {
                color: "error",
                variant: "elevated",
                location: "bottom",
                buttonText: "Close",
                buttonTextColor: "white",
              });
            } else {
              store.setSnack(responseMessage, {
                color: "error",
                variant: "elevated",
                location: "bottom",
                buttonText: "Close",
                buttonTextColor: "white",
              });
            }

            this.errors.push(response);
            console.error("Validate API Response failed", response);
            return false;
          }
        } else {
          return true;
        }
      } catch (err) {
        this.errors.push(err);
        console.error("Validate API Response failed", err);
        return false;
      }
    },
    rebuildHeaders(collection: string) {
      return new Promise<void>(async (resolve, reject) => {
        try {
          if (!store.collections[collection])
            return reject(new Error(`Collection ${collection} not found.`));
          const newHeaders =
            this.collections[collection].data.length > 0
              ? Object.keys(
                  this.collections[collection].data.reduce((a: any, b: any) => {
                    return { ...a, ...b };
                  })
                )
                  .map((x) => {
                    return {
                      title: camelToTitle(
                        // remove anything before the first period
                        x
                          .substring(x.indexOf(".") + 1)
                          .replace(/_/g, " ")
                          .replace(/\./g, " ")
                          .replaceAll("  ", " ")
                          .trim()
                      ),
                      key: x,
                    };
                  })
                  .filter(
                    (header, index, self) =>
                      index === self.findIndex((h) => h.key === header.key)
                  )
              : [];

          // if newHeaders is different from existing headers, update
          if (
            !newHeaders.length ||
            JSON.stringify(newHeaders) ===
              JSON.stringify(this.collections[collection].headers)
          ) {
            /* console.log(
              "Headers are the same, skipping update",
              newHeaders,
              this.collections[collection].headers
            ); */

            return resolve();
          }

          this.collections[collection].headers = newHeaders;
          /* console.log("Headers updated", collection, newHeaders); */
          return resolve();
        } catch (err) {
          console.error(err, collection);
          reject(err);
        }
      });
    },
    init() {
      return new Promise<void>(async (resolve, reject) => {
        // add collections from Services
        // console.log("store.auth.data[1].Services", store.auth.data[1].Services);
        if (store.auth.data[1].Services.length) {
          store.auth.data[1].Services.forEach((service: any) => {
            // add rows to collection from Instances
            if (service.Instances.length) {
              service.Instances.forEach((instance: any) => {
                // add to services if not already there
                if (!store.broker.services[service["Service Account Type"]])
                  store.broker.services[service["Service Account Type"]] = {};

                // add to services if not already there
                if (
                  !store.broker.services[service["Service Account Type"]][
                    instance.Uuid
                  ]
                )
                  store.broker.services[service["Service Account Type"]][
                    instance.Uuid
                  ] = {
                    id: instance.Uuid,
                    "Author.Uuid": instance.Uuid,
                    "Author.Name": instance["Service Account Name"],
                    "Author.Service Account Description":
                      instance["Service Account Description"],
                  };
              });
            }
          });
        }

        // add collections for api collections
        this.collectionList.forEach(async (collection: any) => {
          try {
            console.log("adding api collection:", collection, this.collections);
            await this.refreshData(collection);
            if (this.collections[collection]?.loading)
              this.collections[collection].loading = false;
            console.log(
              "Collection initialized:",
              collection,
              this.collections[collection].data.length
            );

            /* const response = await fetch(this.apiData().api.URI, options);
            const responseText = await response.text();
            const responseData = JSON.parse(responseText); */
            // console.log("Fetched Text!", collection, responseText);
            // console.log("Fetched!", responseData);

            // if Anywhere_Messages, json decode the message
            /* if (collection === "Anywhere_Messages") {
              responseData[1].Records.forEach((record: any) => {
                record = Object.assign(record, JSON.parse(record.message));
                const mac = record?.CabDevice?.Mac || "";

                // update Anywhere by mac
                const index = this.collections["Anywhere"].data.findIndex(
                  (y: any) => y.mac === mac
                );
                console.log("record", record, index);
                if (index > -1) {
                  // flatten record message
                  const flatRecord: FlatRecord = flattenObj(record);
                  delete flatRecord.id;
                  this.collections["Anywhere"].data[index] = Object.assign(
                    this.collections["Anywhere"].data[index],
                    flatRecord
                  );
                }
              });
            } */

            /* this.collections[collection].data =
              responseData[0] && responseData[0].Success
                ? responseData[1].Records
                : []; */
          } catch (err: any) {
            this.errors.push(err);
            console.error(err, collection);
            if (this.collections[collection]?.loading)
              this.collections[collection].loading = false;
            reject(err);
          }
        });
        resolve();
      });
    },
    evaluateCondition(condition: string, organization: any): boolean {
      // This method will safely evaluate the condition without using eval
      const conditions = condition.split("&&").map((c) => c.trim());
      return conditions.every((c) => {
        const [left, operator, right] = c.split(/\s*(===|!==)\s*/);
        const leftValue = left
          .split(".")
          .reduce((obj, key) => obj?.[key], organization);
        const rightValue = right.replace(/['"]/g, "");

        switch (operator) {
          case "===":
            return leftValue === rightValue;
          case "!==":
            return leftValue !== rightValue;
          default:
            console.warn(`Unsupported operator: ${operator}`);
            return false;
        }
      });
    },
    updateAnywhereCollection(uuid: string, flattenedPayload: any) {
      const collection = "Anywhere";
      if (!this.collections[collection]) {
        console.error(`Collection ${collection} not found.`);
        return;
      }

      const index = this.collections[collection].data.findIndex(
        (x: any) => x.id === uuid
      );
      if (index !== -1) {
        this.collections[collection].data[index] = {
          ...this.collections[collection].data[index],
          ...flattenedPayload,
        };
      } else {
        this.collections[collection].data.push({
          id: uuid,
          ...flattenedPayload,
        });
      }

      this.rebuildHeaders(collection);
    },
  },
});
