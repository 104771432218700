export function flattenObj(ob: { [x: string]: any }): { [key: string]: any } {
  const result: { [key: string]: any } = {};

  function recurse(current: any, property: string) {
    if (Object(current) !== current || Array.isArray(current)) {
      result[property] = current;
    } else {
      for (const key in current) {
        recurse(current[key], property ? `${property}.${key}` : key);
      }
    }
  }

  recurse(ob, '');

  // Strip number dot from key names
  Object.keys(result).forEach(key => {
    if (/^\d+\./.test(key)) {
      const newKey = key.replace(/^\d+\./, '');
      result[newKey] = result[key];
      delete result[key];
    }
  });

  return result;
}

export function camelToTitle(camelCase: string) {
  return camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}

export function titleToCamel(titleCase: string) {
  return titleCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toLowerCase())
    .trim()
    .replace(/ /g, "");
}
