<script setup lang="ts">
import { vDraggable } from '@/directives/draggable'
import { store } from '@/stores/store'
import { Organization } from '@/types/entities'
import { computed, ComputedRef, ref, watch } from 'vue'
import CreateEliteForm from './CreateEliteForm.vue'
import SelectOrganizationDialog from './SelectOrganizationDialog.vue'
const props = defineProps({
  isCreateOrganizationDialogVisible: Boolean,
  parentOrganizationId: String,
  editMode: Boolean,
  organizationToEdit: {
    type: Object as PropType<Organization>,
    default: undefined
  }
})

const emit = defineEmits([
  'update:isCreateOrganizationDialogVisible',
  'organization-created',
  'organization-updated'
])

const createElite = ref(false)
const isCreateEliteDialogVisible = ref(false)

const organization = ref({
  id: '',
  name: '',
  dsc: '',
  organization_id: '',
  subisallowed: false,
  serialnumber: ''
}) as Ref<Organization>

const isSelectOrganizationDialogVisible = ref(false)

const eliteName = ref('')
const eliteDesc = ref('')

const updateEliteNameAndDesc = () => {
  if (createElite.value) {
    eliteName.value = organization.value.name
      ? `${organization.value.name} Elite`
      : ''
    eliteDesc.value = organization.value.dsc
      ? `${organization.value.dsc} Elite`
      : ''
  } else {
    eliteName.value = ''
    eliteDesc.value = ''
  }
}

watch(
  () => props.organizationToEdit,
  newValue => {
    if (newValue) {
      organization.value = { ...newValue }
    } else {
      organization.value = {
        id: '',
        name: '',
        dsc: '',
        organization_id: props.parentOrganizationId || '',
        subisallowed: false
      }
    }
  },
  { immediate: true }
)

// Initialize organization_id with parentOrganizationId if provided
watch(
  () => props.parentOrganizationId,
  newValue => {
    if (newValue) {
      organization.value.organization_id = newValue
    }
  },
  { immediate: true }
)

const getOrganizationName = (id: string) => {
  return id ? store.getOrganizationNameById(id) || id : ''
}

const handleOrganizationSelected = (organizationId: string) => {
  organization.value.organization_id = organizationId
  isSelectOrganizationDialogVisible.value = false
  console.log('Parent organization selected:', organizationId)
}

const saveOrganization = async () => {
  try {
    if (!organization.value.name || !organization.value.dsc) {
      throw new Error('Organization Name and Description are required')
    }

    const method = props.editMode
      ? 'Modify Organization'
      : 'Insert Organization'

    const payLoad = [
      { Method: method },
      {
        id: organization.value.id,
        name: organization.value.name,
        dsc: organization.value.dsc,
        organization_id:
          organization.value.organization_id ||
          props.parentOrganizationId ||
          '',
        subisallowed: Boolean(organization.value.subisallowed),
        serialnumber: organization.value.serialnumber
      }
    ]

    if (
      organization.value.organization_id &&
      organization.value.organization_id !==
        'e9c967e7-3364-4074-b1a0-56ce9589d3c6'
    ) {
      payLoad[1].serialnumber = organization.value.serialnumber
    }

    if (props.editMode) {
      payLoad[1].id = organization.value.id
    } else {
      delete payLoad[1].id
    }

    // Ensure organization_id is not empty
    if (!payLoad[1].organization_id) {
      throw new Error('Parent Organization is required')
    }

    const result = await store.api.postToAPI([payLoad])
    if (result[0][0].Success) {
      emit(props.editMode ? 'organization-updated' : 'organization-created')

      store.setSnack(
        `Organization ${props.editMode ? 'updated' : 'created'} successfully`,
        {
          color: 'success',
          variant: 'elevated',
          location: 'bottom',
          buttonText: 'Close',
          buttonTextColor: 'white'
        }
      )

      if (createElite.value && !props.editMode) {
        console.log('Organization', result[0][1].Records[0])
        await saveElite(result[0][1].Records[0])

        console.log('Elite created', {
          createElite: createElite.value,
          saveElite: saveElite
        })
      } else {
        console.log('Elite not created', { createElite: createElite.value })
      }

      emit('update:isCreateOrganizationDialogVisible', false)
      organization.value = {
        id: '',
        name: '',
        dsc: '',
        organization_id: '',
        subisallowed: false
      }
    } else {
      throw new Error(
        result[0][0].Message ||
          `Failed to ${props.editMode ? 'update' : 'create'} organization`
      )
    }
  } catch (error) {
    console.error(
      `Error ${props.editMode ? 'updating' : 'creating'} organization:`,
      error
    )
    store.setSnack(
      `Failed to ${props.editMode ? 'update' : 'create'} organization: ${
        (error as Error).message
      }`,
      {
        color: 'error',
        variant: 'elevated',
        location: 'bottom',
        buttonText: 'Close',
        buttonTextColor: 'white'
      }
    )
  }
}

const eliteFormRef = ref<InstanceType<typeof CreateEliteForm> | null>(null)

const saveElite = async (organization_id: string) => {
  if (createElite.value && !props.editMode && eliteFormRef.value) {
    await eliteFormRef.value.saveElite(organization_id)
  } else if (createElite.value && !props.editMode) {
    console.error('Elite form reference not found')
  }
}

const handleEliteCreated = () => {
  isCreateEliteDialogVisible.value = false
  emit('update:isCreateOrganizationDialogVisible', false)
  organization.value = { id: '', name: '', dsc: '', organization_id: '' }
}

const newlyCreatedOrganizationId = computed(() => {
  return organization.value.id || ''
})

const isCreateButtonDisabled: ComputedRef<boolean> = computed(() => {
  return createElite.value && !(eliteFormRef.value as any)?.macLookupSuccess
})
</script>

<template>
  <VDialog
    :model-value="isCreateOrganizationDialogVisible"
    @update:model-value="
    (val: any) => val && $emit('update:isCreateOrganizationDialogVisible', val)
    "
    max-width="500px"
    persistent
  >
    <VCard v-draggable>
      <!-- 👉 Dialog close btn -->
      <VIcon
        class="icon-close"
        @click="$emit('update:isCreateOrganizationDialogVisible', false)"
        icon="tabler-x"
      />

      <VCardTitle>
        {{ editMode ? 'Edit' : 'Create New' }} Organization
      </VCardTitle>
      <VCardText>
        <VForm @submit.prevent="saveOrganization">
          <AppTextField
            :model-value="getOrganizationName(organization.organization_id)"
            label="Parent Organization"
            readonly
            outlined
            dense
            @click="isSelectOrganizationDialogVisible = true"
            autocomplete="off"
            :disabled="editMode"
          >
            <template v-slot:append-inner>
              <VIcon
                @click="isSelectOrganizationDialogVisible = true"
                color="primary"
              >
                mdi-chevron-down
              </VIcon>
            </template>
          </AppTextField>
          <AppTextField
            v-model="organization.name"
            label="Organization Name"
            :rules="[(v: any) => !!v || 'Organization Name is required']"
            required
            outlined
            dense
          ></AppTextField>
          <AppTextField
            v-model="organization.dsc"
            label="Description"
            :rules="[(v: any) => !!v || 'Description is required']"
            required
            outlined
            dense
          ></AppTextField>
          <AppTextField
            v-if="
              organization.organization_id &&
              organization.organization_id !==
                'e9c967e7-3364-4074-b1a0-56ce9589d3c6'
            "
            v-model="organization.serialnumber"
            label="Serial Number"
            :rules="[(v: any) => !!v || 'Serial Number is required']"
            required
            outlined
            dense
          ></AppTextField>
          <VCheckbox
            v-model="organization.subisallowed"
            label="Allow Sub-Organizations"
            outlined
            dense
          ></VCheckbox>
          <VCheckbox
            v-if="!editMode"
            v-model="createElite"
            label="Create an Elite for this Organization"
            @change="updateEliteNameAndDesc"
          ></VCheckbox>
          <template v-if="createElite && !editMode">
            <VDivider class="my-4" />
            <CreateEliteForm
              ref="eliteFormRef"
              :organizationId="newlyCreatedOrganizationId"
              :initialEliteName="eliteName"
              :initialEliteDesc="eliteDesc"
              @elite-created="handleEliteCreated"
            />
          </template>
          <VCol cols="12" class="text-center">
            <VSpacer />
            <VBtn
              variant="flat"
              color="primary"
              @click="$emit('update:isCreateOrganizationDialogVisible', false)"
              >Cancel</VBtn
            >
            <VBtn
              variant="flat"
              color="primary"
              @click="saveOrganization"
              :disabled="isCreateButtonDisabled"
              >{{ editMode ? 'Update' : 'Create' }}</VBtn
            >
          </VCol>
        </VForm>
      </VCardText>
    </VCard>
  </VDialog>

  <SelectOrganizationDialog
    v-model:isSelectOrganizationDialogVisible="
      isSelectOrganizationDialogVisible
    "
    @organization-selected="handleOrganizationSelected"
    :selectOrganizationIdData="organization.organization_id"
    :isFromCreateOrganizationDialog="true"
  />
</template>

<style lang="scss" scoped>
.icon-close {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  inset-block-start: 0.75em;
  inset-inline-end: 0.95em;
}

.v-card-title {
  margin-block-end: -0.75em;
  padding-block: 0.95em 0;
  padding-inline: 1.25em 0;
}
</style>
