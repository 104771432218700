<script setup lang="ts">
import { vDraggable } from '@/directives/draggable'
import { store } from '@/stores/store'
import { computed, ref, toRaw } from 'vue'
import { useTheme } from 'vuetify/lib/framework.mjs'

const vuetifyTheme = useTheme()
const currentTheme = vuetifyTheme.current.value.colors

const customFilter = (_value: any, search: string, item: any) => {
  if (!search) return true

  search = search.toString().toLowerCase().trim()
  return ['name', 'id', 'dsc'].some(key => {
    const itemValue = item.raw[key]
    return itemValue && itemValue.toString().toLowerCase().includes(search)
  })
}

interface Props {
  selectOrganizationIdData?: Organization
  isSelectOrganizationDialogVisible: boolean
  isFromCreateOrganizationDialog?: boolean
}

type Organization = string

interface Emit {
  (e: 'update:isSelectOrganizationDialogVisible', value: boolean): void
  (e: 'organization-selected', value: Organization): void
}

const props = withDefaults(defineProps<Props>(), {
  selectOrganizationIdData: '',
  isSelectOrganizationDialogVisible: false,
  isFromCreateOrganizationDialog: false
})
const search = ref('')

const emit = defineEmits<Emit>()
const parentOrgId = ref('')
const selectOrganizationIdData = ref(props.selectOrganizationIdData)
const organizationId = ref(props.selectOrganizationIdData)

function resetForm() {
  emit('update:isSelectOrganizationDialogVisible', false)
  selectOrganizationIdData.value = structuredClone(
    toRaw(props.selectOrganizationIdData)
  )
  parentOrgId.value = ''
  organizationId.value = ''
  search.value = ''
}

function onFormSubmit(event: Event) {
  console.debug('onFormSubmit', event)
  const selectedOrganizationId = organizationId.value || ''
  console.debug('onFormSubmit', selectedOrganizationId)
  emit('organization-selected', selectedOrganizationId)
  resetForm()
}

async function itemSelected(_event: any, obj: any = null, isGroupOpen: any) {
  console.debug('itemSelected', { _event, items: _event.items, obj })
  if (_event?.items?.[0]?.parentorg_id) {
    const org = _event?.items?.[0]?.parentorg_id || ''
    // toggle group
    obj(_event)

    const opened = isGroupOpen(_event)
    if (opened) {
      // add to activeParentOrg
      parentOrgId.value = org
      // router.replace({ path: `/parent/${org}` });
    } else {
      // remove from activeParentOrg
      parentOrgId.value = ''
      organizationId.value = ''
      // router.replace({ path: `/` });
    }
  } else {
    // add or remove from selectedItems
    if (obj?.item?.id) {
      organizationId.value = obj.item.id
      onFormSubmit(_event)
    } else {
      organizationId.value = ''
      onFormSubmit(_event)
    }
  }

  // if activeOrganization is set, then hide the table footer
  /*   if (store.activeOrganization) {
    const tableFooter: HTMLElement | any =
      document.querySelector(".v-data-table-footer") || null;
    if (tableFooter) {
      tableFooter.style.display = "none";
    }
  } else {
    const tableFooter: HTMLElement | any =
      document.querySelector(".v-data-table-footer") || null;
    if (tableFooter) {
      tableFooter.style.display = "";
    }
  } */
}

const insertHeaders = [
  {
    title: 'Organization',
    key: 'data-table-group',
    align: 'start' as const,
    sortable: true,
    groupable: false
  },
  {
    title: 'Description',
    key: 'dsc',
    align: 'end' as const,
    sortable: true,
    groupable: false
  }
]

const parentOrg = (parentOrg: string) => {
  const parent = store.collections.ParentOrg.data.find(
    (x: any) => x.id === parentOrg
  )
  return parent?.Dsc || ''
}

const composeHeaders = () => {
  const headers = []
  return [...insertHeaders, ...headers]
}

const composeItems = () => {
  let items = store.collections.Organization.data

  if (props.isFromCreateOrganizationDialog) {
    items = items.filter((x: any) => x.subisallowed === true)
  }

  return items.map((x: any) => {
    return {
      ...x
      /* parentorg_id: x.parentorg_id || "",
      "data-table-group": parentOrg(x.parentorg_id), */
    }
  })
}

const groupBy = [{ key: 'data-table-group' }]
const isLoading = computed(() => store.collections['Organization'].loading)

const dialogWidth = computed(() => {
  const width = window.innerWidth
  const widthInPixels = (width * 80) / 100
  const maxWidth = 700
  return { width: widthInPixels, maxWidth }
})
</script>
<template>
  <VDialog
    :width="dialogWidth.width"
    :max-width="dialogWidth.maxWidth"
    :model-value="props.isSelectOrganizationDialogVisible"
    @update:model-value="
      val => $emit('update:isSelectOrganizationDialogVisible', val)
    "
  >
    <VCard title="Select Organization" v-draggable>
      <template #append>
        <AppTextField
          v-model="search"
          density="compact"
          :placeholder="'Search'"
          :append-inner-icon="search ? 'tabler-x' : 'tabler-search'"
          @click:append-inner="search = ''"
          dense
          outlined
          class="pa-1"
        />
      </template>
      <!-- 👉 Dialog close btn -->
      <VIcon
        class="icon-close"
        @click="$emit('update:isSelectOrganizationDialogVisible', false)"
        icon="tabler-x"
      />

      <VForm class="mt-4" @submit.prevent="onFormSubmit">
        <VDivider />

        <VDataTableVirtual
          id="Organization"
          :headers="composeHeaders()"
          :items="composeItems()"
          :group-by="groupBy"
          :search="search"
          :custom-filter="customFilter"
          item-value="id"
          item-title="name"
          :hover="true"
          density="compact"
          :class="parentOrgId ? 'selected' : ''"
          class="text-body-2"
          @click:row="itemSelected"
          v-model:selected="organizationId"
          :loading="isLoading"
          height="400px"
          fixed-header
        >
          <template #[`item.data-table-group`]="{ item }: { item: any }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ml-7">
                <span
                  class="d-block font-weight-bold text--primary text-truncate"
                  >{{ item.name }}</span
                >
                <small>{{ item.id }}</small>
              </div>
            </div>
          </template>

          <template
            v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }"
          >
            <tr @click="itemSelected(item, toggleGroup, isGroupOpen)">
              <td :colspan="columns.length" :class="item.key + ' mt-3'">
                <!-- <VBtn
                  size="28"
                  color="white"
                  variant="plain"
                  rounded
                  class="my-3 mx-2"
                >
                  <VIcon
                    size="18"
                    :icon="isGroupOpen(item) ? '$expand' : '$next'"
                  />
                </VBtn> -->
                {{ item.value }}
                <!--                 <VBtn
                  size="28"
                  class="float-right my-3 mx-2"
                  color="white"
                  variant="plain"
                  rounded
                  v-show="parentOrgId"
                >
                  <VIcon icon="tabler-x" size="24" />
                </VBtn> -->
              </td>
            </tr>
          </template>

          <template #no-data>
            <VCard class="no-data pt-5 pb-5">
              <VCardText> No results found for '{{ search }}' </VCardText>
            </VCard>
          </template>
        </VDataTableVirtual>
      </VForm>
    </VCard>
  </VDialog>
</template>
<style lang="scss">
.data-table-group {
  cursor: pointer;
}

.v-data-table__wrapper > table > thead > tr > th {
  position: sticky;
  z-index: 2;
  background-color: var(--v-theme-surface);
  inset-block-start: 0;
}

.v-table {
  tbody {
    tr:not(:last-child) {
      td {
        border: none !important;
      }
    }
  }
}

td.data-table-group {
  padding-block: 0 !important;
  padding-inline: 6px !important;
}

.v-data-table-footer {
  justify-content: center;
  border: 1px solid rgb(245 245 245 / 15%);
  border-width: 1px 0 0;
  margin-block-start: 0;
  padding-block: 1.25em;
}

.v-data-table__tr:hover {
  background-color: #34343412;
  cursor: pointer;
}

/* .selected
  > div.v-table__wrapper
  > table
  > tbody
  > tr.v-data-table__tr.v-data-table__tr--clickable
  > td.v-data-table__td {
  background: rgb(252 254 255 / 15%) !important;
} */

.selected .data-table-group {
  background-color: rgb(148, 154, 181) !important;
  color: white;
  font-size: 1.2em;
  font-weight: 800;

  /* padding: 0.6em 0.35em 0.6em !important; */
  margin-block-start: 0 !important;
}

.v-data-table-header__content {
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 0.25px;
}

div.v-table__wrapper {
  overflow: scroll;
}

tr.v-data-table__tr.v-data-table__tr--clickable {
  block-size: 5em;
}

.app-text-field {
  min-inline-size: 13em;
}

.v-data-table__wrapper {
  overflow: auto;
}

.icon-close {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  inset-block-start: 0.35em;
  inset-inline-end: 0.35em;
}
</style>
